import React from "react";

import moment from "moment";

import { withAuth } from "components/container/withAuth";
import Wrapper from "components/ui/Wrapper";
import CourseUpdate from "components/ui/CourseUpdate";
import MembershipSelector from "components/ui/MembershipSelector";
import Header from "./Header";
import Loading from "components/ui/Loading";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router";

import { NavLink } from "react-router-dom";
import s from "./MeetingPage.module.scss";

class ReportPageUi extends React.Component {
  render() {
    if (this.props.data.loading) {
      return (
        <main className={s.main}>
          <Loading />
        </main>
      );
    }

    const membership = this.props.data?.data?.user?.membership;
    const meeting = membership?.meeting;
    const courseUpdate = meeting?.courseUpdate;
    const meetingStartTime = meeting
      ? moment(meeting?.startTime).format("dddd DD.MM.YY [kl] HH:mm")
      : null;

    return (
      <React.Fragment>
        <main className={s.main}>
          <div className={s.wrapper}>
            <div className={s.header}>
              <Wrapper className={s.headerWrapper}>
                <div className={s.actionButtons}>
                  <MembershipSelector
                    membershipName={membership?.name}
                    membershipId={membership?.membershipId}
                  />
                  <NavLink to="/verktoy/" className={s.toolsButton}>
                    Din verktøykasse
                  </NavLink>
                </div>
                <Header
                  week={meeting?.startTime}
                  membershipId={membership?.membershipId}
                  prev={membership?.prevMeeting?.courseMeetingId}
                  next={membership?.nextMeeting?.courseMeetingId}
                />
              </Wrapper>
            </div>
            <div>
              <Wrapper>
                <div>
                  <span style={{ fontWeight: "900" }}>Møte:</span>{" "}
                  {meetingStartTime}{" "}
                  <span style={{ fontWeight: "900" }}>Adresse:</span>{" "}
                  {membership?.course?.fullAddress}
                </div>
              </Wrapper>
            </div>
            {courseUpdate && <CourseUpdate courseUpdate={courseUpdate} />}
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const QUERY = gql`
  query GetWeekReport($membershipId: Int!, $courseMeetingId: Int!) {
    user {
      membership(membershipId: $membershipId) {
        membershipId
        name
        course {
          fullAddress
        }
        nextMeeting(courseMeetingId: $courseMeetingId) {
          courseMeetingId
          startTime
        }
        prevMeeting(courseMeetingId: $courseMeetingId) {
          courseMeetingId
          startTime
        }
        meeting(courseMeetingId: $courseMeetingId) {
          courseMeetingId
          startTime
          courseUpdate {
            courseUpdateId
            title
            description
            videoEmbed
            pdfUri
            downloadUrl
            googleStorageFile
          }
        }
      }
    }
  }
`;

const ReportPage = ({ auth, ...props }) => {
  let params = useParams();
  const variables = {
    membershipId: params?.membershipId,
    courseMeetingId: params?.courseMeetingId,
  };
  const result = useQuery(QUERY, { variables }, { fetchPolicy: "no-cache" });
  return <ReportPageUi data={result} auth={auth} {...props} />;
};

export default withAuth(ReportPage);
