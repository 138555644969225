import React, { Component, Fragment } from "react";
import Loading from "../ui/Loading";
import DateNavigation from "../ui/DateNavigation";
import AboutModal from "../ui/AboutModal";
import DietReportSections from "../ui/DietReportSections";
import DeleteEntriesButton from "../ui/DeleteEntriesButton";
import AddEntries from "../ui/AddEntries";
import CopyEntries from "../ui/CopyEntries";
import DailyMenuForm from "../ui/DailyMenuForm";
import DietExtras from "../ui/DietExtras";
import NotFound from "../ui/NotFound";

import { useQuery } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import Meal from "./DietPage/Meal";
import Paywall from "components/ui/Paywall";
import { GET_DIET_DATA, GET_SEARCH_SUGGESTIONS } from "queries";
import moment from "moment";
import { locale } from "../../utils/locale";
import { getCalories } from "../../utils";
import { withAuth } from "../container/withAuth";
import LoadingContext from "components/ui/Loading/LoadingContext";
import "./DietPage.scss";

moment.updateLocale("nb", locale.nb);

function parseDailyDiaryCache({ data }) {
  const edges = data?.request?.diary?.dailyDiaryCache?.edges;
  return edges ? JSON.parse(edges[0].node.data) : null;
}

function assignEntriesToMeals(registrations) {
  const meals = {
    breakfast: {
      id: "breakfast",
      name: "Frokost",
      calories: 0,
      entries: [],
    },
    lunch: {
      id: "lunch",
      name: "Lunsj",
      calories: 0,
      entries: [],
    },
    snackMeal: {
      id: "snackMeal",
      name: "Mellommåltider",
      calories: 0,
      entries: [],
    },
    dinner: {
      id: "dinner",
      name: "Middag",
      calories: 0,
      entries: [],
    },
    supper: {
      id: "supper",
      name: "Kveldsmat",
      calories: 0,
      entries: [],
    },
    voucherMeal: {
      id: "voucherMeal",
      name: "Utfordring",
      calories: 0,
      entries: [],
    },
  };

  registrations.forEach((registration) => {
    const registrationData = JSON.parse(
      registration.data.replace(/gramsPerUNit/g, "gramsPerUnit")
    );

    const entry = {
      ...registration,
      ...{
        tag: registration.tag.trim(),
        data: registrationData,
      },
    };

    if (meals[entry.tag] && !registrationData.hasOwnProperty("error")) {
      meals[entry.tag].calories += getCalories(entry);
      meals[entry.tag].entries.push(entry);
    }
  });

  return Object.keys(meals).map((meal) => {
    return meals[meal];
  });
}

function DietPageWithData(props) {
  const loadingIndicator = React.useContext(LoadingContext);

  const dailyDiaryCache = parseDailyDiaryCache(props.data);
  const diary = props?.data?.data?.request?.diary;

  if (!diary) {
    return <NotFound>Dagbok ikke funnet</NotFound>;
  }

  const diaryRegistrationEdges = diary?.diaryRegistration?.edges;

  const registrations = diaryRegistrationEdges.map((edge) => edge.node);
  const meals = assignEntriesToMeals(registrations);

  let extras = {
    data: {
      numGlassOfWater: null,
      supplementPillWasTaken: null,
      nutsEaten: null,
    },
  };

  registrations.forEach((registration) => {
    if (registration.tag.trim() === "extras") {
      extras = {
        ...registration,
        ...{
          tag: registration.tag.trim(),
          data: JSON.parse(
            registration.data.replace(
              /'supplementPillWasTaken/,
              "supplementPillWasTaken"
            )
          ),
        },
      };
    }
  });

  return (
    <DietPageUI
      loadingIndicator={loadingIndicator}
      user={props.auth.user}
      meals={meals}
      extras={extras}
      registrations={props.data}
      match={props.match}
      dailyDiaryCache={dailyDiaryCache}
      {...props}
    />
  );
}

const prefetchSearchSuggestions = (client, { favorites = [] }) => {
  const recipes = favorites
    .filter((f) => f.objType === "recipe")
    .map((f) => f.objId);
  const ingredients = favorites
    .filter((f) => f.objType === "food_product")
    .map((f) => f.objId);

  client.query({
    query: GET_SEARCH_SUGGESTIONS,
    variables: { recipes, ingredients },
  });
};

class DietPageUI extends Component {
  state = {
    isAboutModalOpen: false,
    loading: this.props.registrations.loading,
  };

  showAboutModal = () => this.setState({ isAboutModalOpen: true });

  closeAboutModal = () => this.setState({ isAboutModalOpen: false });

  render() {
    const {
      loadingIndicator,
      user,
      meals,
      extras,
      registrations,
      client,
      match,
      dailyDiaryCache,
    } = this.props;

    const diaryRegistrationEdges =
      registrations?.data?.request?.diary?.diaryRegistration?.edges;

    if (!registrations.loading) {
      prefetchSearchSuggestions(client, user);
    }

    return (
      <Fragment>
        <Paywall isVisible={!user.hasPermissions} />
        {registrations.loading && <Loading />}
        <div className="DietPage">
          <div className="panel">
            <DateNavigation date={moment(match.params.date)} {...this.props} />
            <div className="DietReport group">
              <DietReportSections
                meals={meals}
                dailyDiaryCache={dailyDiaryCache}
              />
            </div>
          </div>

          <div className="MealRegistration">
            <div className="Wrapper">
              <div className="MealRegistrationHeader">
                <h2 className="MealRegistration__title">Registrer mat</h2>
              </div>
              <div className="meals">
                {meals.map((meal) => (
                  <Meal key={meal.id} meal={meal} />
                ))}
                <section className="meals-item meals-item--extras is-open">
                  <h3>Vann / Tran / Vitaminer</h3>
                  <DietExtras extras={extras} />
                </section>
                {!registrations.loading && user.hasPermissions && (
                  <div className="MealRegistrationFooter">
                    {!diaryRegistrationEdges.length ? (
                      <React.Fragment>
                        <DailyMenuForm />
                        <CopyEntries
                          render={({ copy }) => (
                            <button
                              className="Button Button__save"
                              onClick={(e) => copy(e, loadingIndicator)}
                            >
                              <i className="icon-file-copy"></i> Kopier dagen
                              fra igår
                            </button>
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <AddEntries
                          render={({ add }) => (
                            <button
                              className="Button Button__save"
                              onClick={add}
                            >
                              <i className="icon-align-bottom"></i> Lagre dag
                              som standard-dag?
                            </button>
                          )}
                        />
                        <DeleteEntriesButton
                          registrations={diaryRegistrationEdges}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="DietPage__links Wrapper">
            <a href="/min-mat/matvarer/" className="myFoodLink">
              <i className="icon-chef-hat-1"></i> Min mat
            </a>
            <button className="aboutLink" onClick={this.showAboutModal}>
              <i className="icon-info"></i> Om denne siden
            </button>
            {this.state.isAboutModalOpen && (
              <AboutModal close={this.closeAboutModal} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

function DietPage({ ...props }) {
  let params = useParams();
  let location = useLocation();
  let navigate = useNavigate();

  const result = useQuery(GET_DIET_DATA, {
    variables: {
      dateRegistrations: `${params?.date}T00:00:00`,
      dateCache: params?.date,
    },
  });

  return (
    <DietPageWithData
      {...props}
      data={result}
      match={{ location, navigate, params }}
    />
  );
}

export default withAuth(withApollo(DietPage));
