import React from 'react'
import ReactDOM from 'react-dom'
import GdprModal from './GdprModal'
import styles from '../Popup.module.scss'

class Gdpr extends React.Component {
  state = {
    isVisible: false
  }

  render() {
    return (
      <React.Fragment>
        <button className={styles.button} onClick={this.open}>
          Endre
        </button>
        {this.state.isVisible &&
          ReactDOM.createPortal(
            <GdprModal {...this.props} close={this.close} />,
            document.getElementById('modal-root')
          )
        }
      </React.Fragment>
    )
  }

  open = () => {
    this.setState({ isVisible: true })
  }

  close = () => {
    this.setState({ isVisible: false })
    this.props.refetch()
  }
}

export default Gdpr