import { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import s from "./styles/InstructorCard.module.scss";
import { toast } from "react-toastify";

const START_MEMBERSHIP = gql`
  mutation RunMembershipAction(
    $membershipId: Int!
    $courseId: Int!
    $weekday: Int
    $frequency: Int
  ) {
    membership {
      startCourseMembership(
        membershipId: $membershipId
        courseId: $courseId
        courseType: "nettcourse"
        weekday: $weekday
        frequency: $frequency
      ) {
        status
      }
    }
  }
`;

function Image({ instructor }) {
  const image =
    instructor.image ??
    "https://storage.googleapis.com/NEDiVEKT-app/instructors/no_image_user.png";

  return (
    <img
      src={`https://www.roede.com/cdn-cgi/image/w=500,h=375,q=60,f=auto/${image}`}
      alt={instructor.name}
    />
  );
}

export default function InstructorCard({
  membershipId,
  refetch,
  course,
  weekday,
  setNettcourseInstructorCallback,
  dayName,
}) {
  const [startCourseMembership] = useMutation(START_MEMBERSHIP);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const start = async () => {
    if (!membershipId && !setNettcourseInstructorCallback) return;
    if (loading) return;
    if (!membershipId) {
      setNettcourseInstructorCallback({
        courseId: null,
        nettcourseInstructorId: course.id,
        name: `${course?.nettcourse?.name} med ${course?.instructor?.name} `,
        description: "",
        place: "",
        time: `${dayName}`,
        weekday: weekday,
      });
      return;
    }
    setLoading(true);
    const courseId = course.id;
    const frequency = 4;
    const variables = { membershipId, courseId, weekday, frequency };
    try {
      const result = await startCourseMembership({ variables });
      if (result?.data?.membership?.startCourseMembership?.status !== "error") {
        await refetch();
        navigate(`/coach/${membershipId}?redirect_status=quickstart_started`);
      } else {
        toast.error(
          "Something went wrong, you cannot assigned to this course at the moment."
        );
      }

      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={s.card} data-loading={loading}>
      <Image instructor={course.instructor} />
      <h4>{course.instructor.name}</h4>
      <p>{course.instructor.title}</p>
      {(membershipId || setNettcourseInstructorCallback) && (
        <button onClick={start}>Velg</button>
      )}
    </div>
  );
}
