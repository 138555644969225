import { gql } from '@apollo/client'

const getPlaceCourses = gql`
  query PlaceCourses(
    $placeId: String
    $category: String
    $courseType: Int
    $radius: Int
  ) {
    place(placeId: $placeId) {
      id: formattedAddress
      courses(category: $category, courseType: $courseType, radius: $radius)
    }
  }
`

export default getPlaceCourses
