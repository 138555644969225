import { gql } from '@apollo/client'

export default gql`
  mutation SubmitAnswer(
    $step: String!
    $reportId: Int!
    $membershipId: Int!
    $data: JSONString!
  ) {
    netcourse {
      netcourseSaveAnswers(
        step: $step
        reportId: $reportId
        membershipId: $membershipId
        data: $data
      ) {
        status
        message
      }
    }
  }
`
