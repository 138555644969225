import React from 'react'
import withRouter from '../../../utils/withRouter'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_DIET_DATA as query } from 'queries'
import DeleteRegistrations from 'components/container/DeleteRegistrations'
import cloneDeep from 'lodash/cloneDeep'

class DeleteButton extends React.Component{
  onDelete = () => {
    const { client, match } = this.props

    const variables = {
      dateRegistrations: `${match.params.date}T00:00:00`,
      dateCache: match.params.date,
    }

    const cache = client.readQuery({ query, variables })
    const data = cloneDeep(cache)

    data.request.diary.diaryRegistration.edges = []

    client.writeQuery({ query, variables, data })
  }

  render() {
     return (
      <DeleteRegistrations onDelete={this.onDelete} render={({ deleteRegistrations }) => (
        <button className="Button Button__delete" onClick={deleteRegistrations}>
          <i className="icon-trash"></i> Slett dagen
        </button>
      )}/>
    )
  }
}

export default withRouter(withApollo(DeleteButton))
