import moment from "moment";
import { useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import s from "./Membership.module.scss";
import MembershipActionModal from "./MembershipAction";
import Renewal from "./Renewal";

export default function Membership({ membership, refetch, listing }) {
  const actions = JSON.parse(membership.availibleActions || "[]");

  return (
    <div className={s.membership} data-listing={listing ? true : false}>
      <div className={s.membershipDetails} data-status={membership.status}>
        <h1> {membership.name} </h1>
        <div className={s.membershipDetailsInner}>
          {membership.activatedDate && (
            <p>
              <strong>Kjøpt: </strong>

              {moment(membership.activatedDate).format("DD/MM-YY")}
            </p>
          )}
          {membership.stripeStatus === "trialing" && (
            <p>
              <strong>Prøvetid til: </strong>
              {moment(membership.stripeTrialEnd).format("DD/MM-YY")}
            </p>
          )}

          {membership.stripeStatus !== "trialing" && membership.contractTo && (
            <p>
              <strong>Bindingstid til: </strong>
              {moment(membership.contractTo).format("DD/MM-YY")}
            </p>
          )}

          {membership.statusText && (
            <p>
              <strong>Varighet: </strong>
              {membership.statusText}
            </p>
          )}
          {membership.stripeCurrentPeriodEnd && (
            <p>
              <strong>Neste forfall: </strong>
              {moment(membership.stripeCurrentPeriodEnd).format(
                "DD/MM-YY hh:mm"
              )}
            </p>
          )}
          {membership.status === "active" && (
            <>
              {membership.course &&
                membership.paymentSource !== "o2" &&
                membership.nextScheduledMeetingStartTime && (
                  <p>
                    <strong>Neste møte:</strong>{" "}
                    {moment(membership.nextScheduledMeetingStartTime).format(
                      "dddd DD MMMM [kl] HH:mm"
                    )}
                  </p>
                )}
              {membership.course && (
                <p>
                  <strong>Adresse: </strong>

                  <span>{membership.course.fullAddress}</span>
                </p>
              )}

              {membership.instructor && (
                <p>
                  <strong>Veileder:</strong> {membership.instructor?.name}{" "}
                  <a href={`mailto:${membership.instructor.email}`}>
                    {membership.instructor.email}
                  </a>{" "}
                  {membership.instructor.phone}{" "}
                </p>
              )}
            </>
          )}
          {membership.nettcourseInstructor && (
            <>
              {membership.nextPaidReportTime && (
                <p>
                  <strong>Neste leveringsdag:</strong>{" "}
                  {moment(membership.nextPaidReportTime).format("dddd DD MMMM")}
                </p>
              )}

              {membership.instructor && (
                <p>
                  <strong>Veileder:</strong> {membership.instructor?.name}{" "}
                  <a href={`mailto:${membership.instructor.email}`}>
                    {membership.instructor.email}
                  </a>{" "}
                  {membership.instructor.phone}{" "}
                </p>
              )}

              {membership?.lastReport?.id && (
                <p>
                  <Link
                    to={`/coach/${membership.id}/rapport/${membership.lastReport.id}`}
                    className={s.lastReport}
                  >
                    Tilbakemeldinger
                  </Link>
                </p>
              )}
            </>
          )}
        </div>{" "}
        {/* membershipDetailsInner - end */}
        {listing && (
          <div className={s.membershipActions}>
            {membership.status === "active" &&
              membership.membershipTypeId === 21 && (
                <a
                  href="https://www.kry.no/roede-legen/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bestill legetime
                </a>
              )}

            {actions.map((action, index) => (
              <ActionButton
                key={index}
                action={action}
                membership={membership}
                refetch={refetch}
              />
            ))}

            <PaymentSource paymentSource={membership.paymentSource} />
          </div>
        )}
        {!listing && canBeRenewed(membership) && (
          <div className={s.renewal}>
            <h2>Fornying</h2>
            <Renewal
              membership={membership}
              courseId={membership?.course?.courseId}
              nettcourseinstructorId={
                membership?.nettcourseInstructor?.instructorId
              }
            />
          </div>
        )}
        {listing && (membership.courseSignup || canBeRenewed(membership)) && (
          <div className={s.renewalLinks}>
            <Link to={`/medlemskap/${membership.id}`} className={s.lastReport}>
              {membership.courseSignup
                ? "Fullfør påmelding av kurs"
                : "Forny medlemskap"}
            </Link>
          </div>
        )}
      </div>{" "}
      {/* membershipDetails - end */}
    </div> /* membership - end */
  );
}

export function canBeRenewed(membership) {
  return (
    membership.status === "active" &&
    (membership.membershipType === "nettcourse" ||
      membership.membershipType === "course") &&
    (membership.paymentSource === "manual" ||
      membership.paymentSource === "stripe_prepaid") &&
    membership.prepaidMeetings <= membership.frequency + 1 &&
    membership.renewalStatus !== "pending_membership"
  );
}

function PaymentSource({ paymentSource }) {
  if (paymentSource === "o2") {
    return (
      <>
        Medlemskap i gammelt system.{" "}
        <a href="https://www.roede.com/o2/User-Profile/init#tab5">
          Administrer medlemskap
        </a>
      </>
    );
  }

  if (paymentSource === "manual") {
    return (
      <>
        Manuelt oppsatt medlemskap, kan fornyes når det er 4 eller færre møter
        igjen.
      </>
    );
  }

  if (paymentSource === "stripe_prepaid") {
    return (
      <>
        Forhåndsbetalt medlemskap, kan fornyes når det er 4 eller færre møter
        igjen.
      </>
    );
  }

  if (paymentSource === "ios") {
    return <>Medlemskap kjøpt i Apple App Store, endringer må gjøres der.</>;
  }

  if (paymentSource === "android") {
    return <>Medlemskap kjøpt i Google Play Store, endringer må gjøres der.</>;
  }

  return null;
}

function ActionButton(props) {
  const { action } = props;
  const [isVisible, setIsVisible] = useState(false);
  const open = () => setIsVisible(true);
  const close = () => setIsVisible(false);

  return (
    <>
      <div>
        <button type="button" data-action={action.action} onClick={open}>
          {action.name} <span className={s.readmore}>Les mer</span>
        </button>
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <MembershipActionModal {...props} close={close} />,
          document.getElementById("modal-root")
        )}
    </>
  );
}
