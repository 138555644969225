import React from 'react'
import UnitScreen from '../UnitScreen'
import { withDate } from 'components/util/withDate'
import { graphql } from '@apollo/client/react/hoc'
import { GET_FOOD_PRODUCT, GET_FREQUENTLY_USED_UNITS } from 'queries'

const getSafe = (fn) => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}

const AddFoodProductWithData = ({
  foodProduct: { loading, error, request },
  frequentUnits: {loading: loadin1, error: error1, request: unitRequest},
  tag, cancel, close, date
}) => {
  const food = getSafe(() => request.kitchen.ingredients.edges[0].node)
  const fqUnit = getSafe(() => unitRequest.diary.frequentlyUsedUnits.slice(0, 3))

  if (food) {
    const foodUnits = food.units.edges
      .map(edge => edge.node)
      .map(unit => ({ name: unit.unit, gramsPerUnit: unit.gramsPerUnit }))

    return <UnitScreen
      entry={{
        registrationId: null,
        category: 'food',
        tag: tag,
        date_time: date,
        description: null,
        calories: 0,
        data: {
          id: food.objectId,
          type: 'product',
          name: food.name,
          image: null,
          quantity: null,
          unit: null,
          units: foodUnits,
          allergens: food.allergens,
          heartFriendly: food.isHeartFriendly,
          quality: food.dietCategory,
          nutrition: {
            kcalPer100Grams: food.kcal100g,
            gramsOfProteinPer100Grams: food.protein100g,
            gramsOfCarbohydratePer100Grams: food.carbs100g,
            gramsOfFatPer100Grams: food.fat100g,
            milligramsOfCalciumPer100Grams: food.calcium100g,
            gramsOfFruitsPer100Grams: food.fruits100g,
            gramsOfVegetablesPer100Grams: food.veg100g,
            gramsOfFiberPer100Grams: food.fiber100g,
            gramsOfAlcoholPer100Grams: food.alcohol100g,
            gramsOfNutsPer100Grams: food.nuts100g
          },
          frequentUnits: fqUnit
        },
      }}
      cancel={cancel}
      close={close}
    />
  }

  return null
}
const GetFrequentlyUsedUnitsWrapper = graphql(GET_FREQUENTLY_USED_UNITS, {
  name: "frequentUnits",
  options: ({ objectId }) => ({ variables: { foodId: objectId, foodType: 'product' } })
})(AddFoodProductWithData)

const GetFoodProductWrapper = graphql(GET_FOOD_PRODUCT, {
  name: "foodProduct",
  options: ({ objectId }) => ({ variables: { objectId: [objectId] } })
})(GetFrequentlyUsedUnitsWrapper)

export default withDate(GetFoodProductWrapper)
