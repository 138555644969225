// @flow

import React from 'react'
import styles from './Progress.module.scss'
import Row from './Row'
import TextUnder from './TextUnder'
import isInRange from 'library/utils/isInRange'
import guardBounds from 'library/utils/guardBounds'
import R from 'res/R'

export default class Progress extends React.Component {
  static defaultProps = {
    decimals: 0
  }

  render() {
    const { value, minValue, maxValue, recommended, name, unit, decimals } = this.props
    let recommendedText = ''
    let amountText = ''

    if (recommended instanceof Array) {
      // Anbefalt 15 - 30 %
      const min = recommended[0] * 100
      const max = recommended[1] * 100
      recommendedText = `av anbefalt ${min}% - ${max}%`
      amountText = `${Math.round(value * 100)}%`
    } else if (recommended >= 0) {
      // Anbefalt 1900 kcal
      if (recommended > 0) {
        recommendedText = `av anbefalt ${recommended} ${unit}`
      }
      const amount = (decimals)
        ? parseFloat(value).toFixed(decimals)
        : Math.round(value)
      amountText = `${amount} ${unit}`
    }

    const isGood = isInRange(value, minValue, maxValue)

    const textUnder = (
      <TextUnder
        amountText={amountText}
        recommendedText={recommendedText}
        name={name} />
    )

    const content = (
      <div className={styles.container}>
        {this.makeProgressBar(value, maxValue, isGood)}
      </div>
    )

    return (
      <Row
        content={content}
        face={isGood ? R.images.faceHappy : R.images.faceSad}
        textUnder={textUnder}
        height={80}
      />
    )
  }

  makeProgressBar = (value: number, maxValue: number, isGood: boolean) => {
    const progress = guardBounds(0, value / maxValue, 1)
    const color = isGood ? styles.goodProgress : styles.badProgress
    const width = Math.round(progress * 100)

    return (
      <div className={styles.progressContainer}>
        <div
          className={`${styles.progressBar} ${color}`}
          style={{ width: `${width}%` }} />
      </div>
    )
  }
}
