import React, { Component } from 'react';
import moment from 'moment';

const withDate = (WrappedComponent) => {
  return class extends Component {
    render() {
      const dateParam = window.location.pathname.match(/\d{4}-\d{2}-\d{2}/) || [moment().format('YYYY-MM-DD')];
      const date = moment(dateParam.shift()).format('YYYY-MM-DDT00:00:00');

      return <WrappedComponent date={date} {...this.props} />;
    }
  };
}

export { withDate };
