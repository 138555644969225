import { gql } from '@apollo/client';

const setProperty =  gql`
mutation User($key: String, $value: String) {
  user {
    setProperties(key: $key, value: $value) {
      status
    }
  }
}`;

export default setProperty;
