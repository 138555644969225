import React from 'react'
import { ProgressBar, ProgressImage, ProgressFlag, ActivityProgressBar } from './ProgressBars'

import WidgetDates from './WidgetDates'
import { nFormat } from './format.js'
import format from './format.js'
import moment from 'moment'
import dietScore from 'conf/dietScore'
import './styles/widgets.scss'
import s from './styles/Focus.module.scss'

const weekdays = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag',
]

export default function FoodResult({ report }) {
  const data = JSON.parse(report.diaryData)
  if (!data) {
    return <span />
  }
  const { days, week } = data
  const numSteps = format(week.exercise?.numSteps || 0, 0)
  const minutes = format(week.exercise?.minutes % 60, 0)
  const hours = format(week.exercise?.minutes / 60, 0)
  const { weight, waist } = data.measurements
  const { weekdates } = report
  const kcalMenu = report.user.kcalMenu || '0'

  return (
    <>
    <div className={s.container}>
    <h2> Dine registreringer denne uken</h2>
          <p>
          Her ser du resultatene fra din dagbok. Verdiene oppdateres løpende ettersom du registrerer mat, trening, vekt og mål i Appen eller på Roede.com. 
          </p>
          <p>
          Ukens resultat leveres automatisk til meg på din Roede-dag. Viktig at du også husker å registerere vekt og cm-mål på leveringsdagen (se leveringsfrist øverst på siden).
          </p>
    </div>
    <div className="widget-wrapper">
      <div className="widget-row">
        <div className="widget-section widget-food" data-featured="true">
        <h4>Ukens kosthold</h4>
          <table>
            <thead>
              <tr>
                <th>Dag</th>
                <th>Kcal</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {days.map((day, index) => (
                <tr key={`day-${index}`}>
                  <td>
                    {weekdays[moment(weekdates[index]).format('e')]}
                    <em> {moment(weekdates[index]).format('DD.MM.YYYY')}</em>
                  </td>
                  <td>{nFormat(day?.diet?.totalEatenKcal)}</td>
                  <td>{nFormat(day?.diet?.score)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Snitt</td>
                <td>{nFormat(week?.diet?.totalEatenKcal)}</td>
                <td>{nFormat(week?.diet?.score)}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          className="widget-section widget-food"
          data-featured="true"
          style={{ visibility: 'hidden' }}
        />
      </div>
      <div className="widget-row">
        <div className="widget-section widget-food" data-featured="true">
          <h4>
            Energi <span>(snitt)</span>
          </h4>
          <ul>
            <ProgressBar
              value={week.diet?.energyDensity}
              recommended={
                dietScore.energyDistribution.normal.energyDensity.recommended
              }
              min={dietScore.energyDistribution.normal.energyDensity.minShare}
              max={dietScore.energyDistribution.normal.energyDensity.maxShare}
              title="Energitetthet"
              unit="kcal"
            />
            <ProgressBar
              value={week.diet?.energyShareFromEatenCarbs}
              recommended={
                dietScore.energyDistribution.normal.carbohydrate.recommended
              }
              min={dietScore.energyDistribution.normal.carbohydrate.minShare}
              max={dietScore.energyDistribution.normal.carbohydrate.maxShare}
              title="Karbohydrater"
              unit="%"
            />
            <ProgressBar
              value={week.diet?.energyShareFromProteins}
              recommended={
                dietScore.energyDistribution.normal.protein.recommended
              }
              min={dietScore.energyDistribution.normal.protein.minShare}
              max={dietScore.energyDistribution.normal.protein.maxShare}
              title="Proteiner"
              unit="%"
            />
            <ProgressBar
              value={week.diet?.energyShareFromEatenFat}
              recommended={dietScore.energyDistribution.normal.fat.recommended}
              min={dietScore.energyDistribution.normal.fat.minShare}
              max={dietScore.energyDistribution.normal.fat.maxShare}
              title="Fett"
              unit="%"
            />
            <ProgressBar
              value={week.diet?.totalEatenKcal}
              recommended={dietScore.kcal.menus[kcalMenu].recommended}
              min={dietScore.kcal.menus[kcalMenu].min}
              max={dietScore.kcal.menus[kcalMenu].max}
              title="Energiinntak"
              unit="kcal"
            />
            <ProgressBar
              value={week.diet?.score}
              recommended={dietScore.score.recommended}
              min={dietScore.score.min}
              max={dietScore.score.max}
              title="Kostholdsscore"
              unit="poeng"
            />
          </ul>
        </div>
        <div className="widget-section widget-food" data-featured="false">
          <h4>
            Næring <span>(snitt)</span>
          </h4>
          <ul>
            <ProgressBar
              value={week.diet?.totalEatenFruits}
              recommended={dietScore.fruit.recommendedGrams}
              min={dietScore.fruit.minGrams}
              max={dietScore.fruit.maxGrams}
              title="Frukt"
              unit="g"
              inline
            />
            <ProgressBar
              value={week.diet?.totalEatenVegetables}
              recommended={dietScore.vegetables.recommendedGrams}
              min={dietScore.vegetables.minGrams}
              max={dietScore.vegetables.maxGrams}
              title="Grønnsaker"
              unit="g"
              inline
            />
            <ProgressBar
              value={week.diet?.totalEatenCalcium}
              recommended={dietScore.calcium.recommendedMilligrams}
              min={dietScore.calcium.minMilligrams}
              max={dietScore.calcium.maxMilligrams}
              title="Kalsium"
              unit="mg"
              inline
            />
            <ProgressBar
              value={week.diet?.totalEatenFiber}
              recommended={dietScore.fiber.recommendedGrams}
              min={dietScore.fiber.minGrams}
              max={dietScore.fiber.maxGrams}
              title="Fiber"
              unit="g"
              inline
            />
            <ProgressBar
              value={week.diet?.totalEatenNuts}
              recommended={dietScore.nuts.recommendedGrams}
              min={dietScore.nuts.minGrams}
              max={dietScore.nuts.maxGrams}
              title="Nøtter"
              unit="g"
              inline
            />
            <ProgressFlag
              value={week.diet?.supplementPillWasTaken}
              recommended={dietScore.supplementPillTaken.minNumberOf}
              title="Tran/multivitamin"
              image="vitamin"
            />
            <ProgressImage
              value={week.diet?.numEntriesHeartFriendly}
              recommended={dietScore.heartFriendly.minNumberOf}
              title="Hjertevennlig"
              unit="stykk"
              image="heart"
            />
            <ProgressImage
              value={week.diet?.numGlassOfWater}
              recommended={dietScore.water.recommendedNumGlasses}
              title="Vann"
              unit="glass"
              image="glass"
            />
            <ProgressBar
              value={week.diet?.voucherMealKcal}
              recommended={0}
              min={0}
              max={25 * dietScore.vouchers.daily.recommendedNumberOf}
              title="Din utfording"
              unit="kcal"
              inline
            />
          </ul>
        </div>
      </div>
      <div className="widget-row">
        <div className="widget-section widget-activity">
          <p>Denne uka gikk du:</p>
          <h4>
            <span>{numSteps}</span> skritt
          </h4>
          <ActivityProgressBar
            value={week.exercise?.numSteps}
            recommended={70000}
          />
        </div>
        <div className="widget-section widget-activity">
          <p>Denne uka trente du:</p>
          <h4>
            <span>{hours}</span> timer &nbsp;
            <span>{minutes}</span> min
          </h4>
          <ActivityProgressBar
            value={week.exercise?.minutes}
            recommended={30 * 7}
          />
        </div>
      </div>
      <div className="widget-row">
        <div className="widget-section widget-weight">
          <h4>Vekt</h4>
          <ul>
            <li>
              Vekt nå: <strong>{format(weight.current.value, 1)} kg</strong>
            </li>
            <li>
              Endring siden forrige veiing:{' '}
              <strong>{format(weight.diff_last_week.value, 1)} kg</strong>
            </li>
            <li>
              Endring i vekt siden kursstart:{' '}
              <strong>{format(weight.diff_course_start.value, 1)} kg</strong>
            </li>
            <li>
              Endring i vekt siden startdato:{' '}
              <strong>{format(weight.diff_start_date.value, 1)} kg</strong>
            </li>
          </ul>
        </div>
        <div className="widget-section widget-weight">
          <h4>Midjemål</h4>
          <ul>
            <li>
              Midjemål nå: <strong>{format(waist.current.value, 1)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden forrige veiing:{' '}
              <strong>{format(waist.diff_last_week.value, 1)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden kursstart:{' '}
              <strong>{format(waist.diff_course_start.value, 1)} cm</strong>
            </li>
            <li>
              Endring i midjemål siden startdato:{' '}
              <strong>{format(waist.diff_start_date.value, 1)} cm</strong>
            </li>
          </ul>
        </div>
      </div>
      <WidgetDates weekdates={weekdates} />
    </div>
    </>
  )
}
