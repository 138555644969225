import { gql } from '@apollo/client';

const getWeight = gql`
  query DiaryEntries (
    $date_to: DateTime
  ) {
    request {
      diary {
        diaryRegistration (
          dateTo: $date_to,
          category: "measurements"
          orderByDate: "desc",
          limit: 1,
        ) {
          edges {
            node {
              registrationId
              dateTime
              data
            }
          }
        }
      }
    }
  }
`;

export default getWeight;