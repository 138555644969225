import React from "react";
import { Query } from "@apollo/client/react/components";
import { graphql } from "@apollo/client/react/hoc";
import Loading from "../../components/ui/Loading";
import "../ProfilePage/styles.scss";
import Goals from "../../components/ui/Popup/Goals";
import AboutMe from "../../components/ui/Popup/AboutMe";
import WeightGoal from "../../components/ui/Popup/WeightGoal";
import Activities from "../../components/ui/Popup/Activities";
import NewsLetter from "../../components/ui/Popup/NewsLetter";

import { useNavigate, useParams } from "react-router-dom";

import {
  GET_GOALS,
  GET_ABOUT_ME,
  GET_WEIGHT_GOAL,
  GET_ACTIVITIES,
  GET_NEWSLETTERSETTINGS,
  ONBORDING_DONE,
} from "queries";

class OnbordingPage extends React.Component {
  refetch() {
    const slug = this.props.slug;
    const navigate = this.props.navigate;
    const pages = [
      "mal",
      "om-meg",
      "onsket-vekt",
      "aktivitetsniva",
      "nyhetsbrevsinnstillinger",
    ];
    const page = pages.indexOf(slug);

    if (pages.length > page + 1) {
      navigate("/onboarding/" + pages[page + 1] + "/", { replace: true });
    } else {
      this.props
        .mutate({
          variables: { key: "is_onboarding_done", value: true },
        })
        .then(({ data }) => {
          const res = data.user.setProperties.status;
          if (res === "success") {
            navigate("/", { replace: true });
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  render() {
    const slug = this.props.slug;

    if (slug === "mal") {
      return <GoalsData refetch={this.refetch.bind(this)}></GoalsData>;
    }

    if (slug === "om-meg") {
      return <AboutMeData refetch={this.refetch.bind(this)}></AboutMeData>;
    }

    if (slug === "onsket-vekt") {
      return (
        <WeightGoalData refetch={this.refetch.bind(this)}></WeightGoalData>
      );
    }

    if (slug === "aktivitetsniva") {
      return (
        <ActivitiesWithData
          refetch={this.refetch.bind(this)}
        ></ActivitiesWithData>
      );
    }

    if (slug === "nyhetsbrevsinnstillinger") {
      return (
        <NewsLetterWithData
          refetch={this.refetch.bind(this)}
        ></NewsLetterWithData>
      );
    }
  }
}

const GoalsData = ({ refetch }) => (
  <Query query={GET_GOALS} variables={{ refetch: refetch }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading></Loading>;

      const { user: { userData: { motivation = "" } = {} } = {} } = data;

      return (
        <Goals show={true} motivation={motivation} refetch={refetch}></Goals>
      );
    }}
  </Query>
);

const AboutMeData = ({ refetch }) => (
  <Query query={GET_ABOUT_ME} variables={{ refetch: refetch }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading></Loading>;

      const {
        user: {
          userData: {
            gender = null,
            height = null,
            initialWeight = null,
            birthDate = null,
          } = {},
        } = {},
      } = data;

      return (
        <AboutMe
          show={true}
          gender={gender}
          height={height}
          weight={initialWeight}
          birthDate={birthDate}
          refetch={refetch}
        ></AboutMe>
      );
    }}
  </Query>
);

const WeightGoalData = ({ refetch }) => (
  <Query query={GET_WEIGHT_GOAL} variables={{ refetch: refetch }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading></Loading>;

      const {
        user: {
          userData: { weightGoal = 0, height = 0, initialWeight = 0 } = {},
        } = {},
      } = data;

      return (
        <WeightGoal
          show={true}
          weightGoal={weightGoal}
          height={height}
          weight={initialWeight}
          refetch={refetch}
        ></WeightGoal>
      );
    }}
  </Query>
);

const ActivitiesWithData = ({ refetch }) => (
  <Query query={GET_ACTIVITIES} variables={{ refetch: refetch }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading></Loading>;

      const {
        user: { userData: { activityLevel = 0, exerciseHabits = 0 } = {} } = {},
      } = data;

      return (
        <Activities
          show={true}
          activityLevel={activityLevel}
          exerciseHabits={exerciseHabits}
          refetch={refetch}
        ></Activities>
      );
    }}
  </Query>
);

const NewsLetterWithData = ({ refetch }) => (
  <Query query={GET_NEWSLETTERSETTINGS} variables={{ refetch: refetch }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading></Loading>;

      const {
        user: {
          userData: {
            newsletterDinnerTips = 0,
            newsletterWeekly = 0,
            newsletterInformation = 0,
          } = {},
        } = {},
      } = data;

      return (
        <NewsLetter
          show={true}
          newsletterDinnerTips={newsletterDinnerTips}
          newsletterWeekly={newsletterWeekly}
          newsletterInformation={newsletterInformation}
          refetch={refetch}
        ></NewsLetter>
      );
    }}
  </Query>
);

const OnbordingWrapper = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  return <OnbordingPage slug={params.slug} navigate={navigate} {...props} />;
};

export default graphql(ONBORDING_DONE)(OnbordingWrapper);
