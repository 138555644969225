import { gql } from '@apollo/client'

const connectToPolar = gql`
  mutation UnRegisterUserFromPolar {
    user {
      unRegisterUserFromPolar {
        status
        reason
      }
    }
  }
`
export default connectToPolar
