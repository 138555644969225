import { gql } from '@apollo/client'

const getSearchSuggestions = gql`
  query SuggestionsQuery (
    $recipes: [Int]
    $ingredients: [Int]
  ) {
    kitchen {
      recipes (recipeId: $recipes) {
        edges {
          node {
            id: recipeId
            recipeId
            name
            kcal100g
            image {
              url
            }
          }
        }
      }
      ingredients (objId: $ingredients) {
        edges {
          node {
            objectId
            name
            kcal100g
          }
        }
      }
      mealClock {
        category
        slots {
          recipe {
            id: recipeId
            recipeId
            name
            kcal100g
            image {
              url
            }
          }
        }
      }
    }
    diary {
      customMealResult {
        edges {
          node {
            objectId
            name
          }
        }
      }
      recipeCustomResult {
        edges {
          node {
            objectId
            name
            kcal100g
          }
        }
      }
      foodProductCustomResult {
        edges {
          node {
            objectId
            name
            kcal100g
          }
        }
      }
    }
  }
`

export default getSearchSuggestions
