import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { TOGGLE_FAVORITE } from 'queries'

class DeleteFavoriteWithMutation extends React.Component {
  state = {
    deleting: false,
    deleted: false,
  }

  deleteFavorite = () => {
    const { objectId, objectType = 'food_product', mutate } = this.props

    if (! this.state.deleting) {
      this.setState({ deleting: true })

      mutate({
        variables: { objectId, objectType }
      }).then(({ data }) => {
        this.setState({ deleted: true })
      }).catch((error) => {
        this.setState({ deleting: false })
        console.log(error)
      })
    }
  }

  render() {
    if (this.state.deleted) {
      return null
    }

    return this.props.render({
      deleting: this.state.deleting,
      deleteFavorite: this.deleteFavorite,
    })
  }
}

const DeleteFavorite = graphql(TOGGLE_FAVORITE)(DeleteFavoriteWithMutation)

export default DeleteFavorite