import formStyles from "assets/styles/Form.module.scss";
import Navbar from "components/ui/Navbar";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { withAuth } from "../../container/withAuth";
import { ReactComponent as RoedeLogo } from "./roede-logo-vert.svg";
import "./styles.scss";

// import { createBrowserHistory } from 'history';

const Header = ({ auth }) => {
  const [open, setOpen] = useState(false);
  const user = auth?.user;
  let history = useLocation();

  useEffect(() => {
    // console.log("test 1", history);

    setOpen(false);

    // return history.listen((location) => {
    //     console.log("test 2");
    // })
  }, [history]);

  return (
    <>
      <header className="header">
        <div className="logo">
          <a href="https://www.roede.com/">
            <RoedeLogo />
          </a>
        </div>
        <Navbar user={user} />
        <div className="buttons">
          <button
            type="button"
            className="usermenu-toggle"
            aria-controls="sitemenu"
            onClick={() => setOpen(!open)}
          >
            Bruker
          </button>
        </div>
        <nav id="usermenu" className="usermenu" data-open={open}>
          <ul>
            <li>
              <Link to="/profil/">Min profil</Link>
            </li>
            <li>
              <Link to="/medlemskap">Medlemskap</Link>
            </li>
            <li>
              <Link to="/min-mat/matvarer/">Min mat</Link>
            </li>
            <li>
              <a href="https://tv.roede.com/roede-app">
                Kom i gang med NEDiVEKT-appen
              </a>
            </li>
            <li>
              <a href="/podcast">Podcast</a>
            </li>
            {user?.activeInstructor && (
              <>
                <li>
                  <a href="https://assistent.roede.com">Assistenten</a>
                </li>
              </>
            )}
          </ul>
          <div className={formStyles.form}>
            <button disabled={auth?.loadingLogin} onClick={auth?.logout}>
              Logg ut
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};

export default withAuth(Header);
