import React, { Fragment } from 'react'
import ViewInfoModal from '../ViewInfoModal'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

class ViewEntry extends React.Component {
  state = {
    data: null
  }

  handleClick = data => {
    this.setState({ data })
  }

  closeModal = () => {
    this.setState({ data: null })
    if (this.props.callback) { this.props.callback() }
  }

  render() {
    const { entry: { data }, children, callback } = this.props

    return (
      <Fragment>
        <span onClick={() => this.handleClick(data)}>{children}</span>
        {this.state.data && this.state.data.type === 'recipe' &&
          <ReditectToRecipe callback={callback} recipeId={this.state.data.id} />
        }
        {this.state.data && this.state.data.type !== 'recipe' &&
          <ViewInfoModal
            data={this.state.data}
            close={this.closeModal}
          />
        }
      </Fragment>
    )
  }
}


const ReditectToRecipeWithData = ({ data: { request } }) => {
  const slug = request?.kitchen?.recipes?.edges[0]?.node?.slug

  if (slug) {
    window.open(`https://www.roede.com/oppskrifter/${slug}`, '_blank')
  }

  return null
}

const GET_RECIPE_SLUG = gql`
  query getRecipe ($recipeId: [Int]) {
    request {
      kitchen {
        recipes (recipeId: $recipeId, first: 1) {
          edges {
            node {
              id: recipeId
              slug
            }
          }
        }
      }
    }
  }
`

const ReditectToRecipe = graphql(GET_RECIPE_SLUG, {
  options: ({ recipeId }) => ({ variables: { recipeId } })
})(ReditectToRecipeWithData)

export default ViewEntry
