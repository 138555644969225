import React from 'react';
import Modal from '../Modal';
import './styles.scss';

const numberFormat = (num) => {
  return Number(num).toLocaleString('nb-NO', {minimumFractionDigits: 1});
}

const ViewInfoModal = ({ data, close }) => (
  <Modal title="Matvareinformasjon" onClose={close}>
    <Modal.Body>
      <div className="infoModal">
        <h2 className="infoModal__title">{data.name}</h2>
        <p>
          <i className="icon-spell-check"></i> Allergener: {data.allergens.join(', ')}
        </p>
        <table>
          <tbody>
            <tr>
              <th colSpan="2">Matvareinformasjon per 100 g</th>
            </tr>
            <tr>
              <td>Energi</td>
              <td>{numberFormat(data.nutrition.kcalPer100Grams)} Kcal</td>
            </tr>
            <tr>
              <td>Fett</td>
              <td>{numberFormat(data.nutrition.gramsOfFatPer100Grams)} g</td>
            </tr>
            <tr>
              <td>Karbohydrat</td>
              <td>{numberFormat(data.nutrition.gramsOfCarbohydratePer100Grams)} g</td>
            </tr>
            <tr>
              <td>Protein</td>
              <td>{numberFormat(data.nutrition.gramsOfProteinPer100Grams)} g</td>
            </tr>
            <tr>
              <td>Kalsium</td>
              <td>{numberFormat(data.nutrition.milligramsOfCalciumPer100Grams)} mg</td>
            </tr>
            <tr>
              <td>Frukt</td>
              <td>{numberFormat(data.nutrition.gramsOfFruitsPer100Grams)} g</td>
            </tr>
            <tr>
              <td>Grønnsaker</td>
              <td>{numberFormat(data.nutrition.gramsOfVegetablesPer100Grams)} g</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" onClick={close}>Lukk</button>
    </Modal.Footer>
  </Modal>
);

export default ViewInfoModal;
