import { useSearchParams } from 'react-router-dom'





import Message from 'components/ui/Message'



export default function RenewedMembershipNotice() {
  
  const [searchParams] = useSearchParams()
  const redirectStatus = searchParams.get('redirect_status')

  if (redirectStatus === 'succeeded'){
    


    return (
      <Message type='success' >
        <strong>Kjøp er vellykket</strong> – Det kan ta litt tid før antall gjenværende møter oppdateres
        </Message>
    )



        
  }

  if (redirectStatus === 'quickstart_started'){
    


    return (
      <Message type='success'>
          <strong>Påmelding er vellykket</strong>

          </Message>
    )
  }
  return null
}



