import React from 'react'
import Modal from 'components/ui/Modal'
import { formatQuantity } from 'utils'
import './styles.scss'

const handleFormatQuantity = event => {
  event.target.value = formatQuantity(event.target.value)
}

class EntryFormModal extends React.Component {
  addIngredient = e => {
    e.preventDefault()
    e.stopPropagation()

    const data = {
      ...this.props.entry.data,
      quantity: parseFloat(e.target.elements.quantity.value.replace(',', '.')),
      unit: e.target.elements.entryAmount.value,
    }

    const ingredient = {
      ...this.props.entry,
      data,
    }

    this.props.addIngredient(ingredient)
  }

  render() {
    const {
      close,
      entry: { data: { name, quantity, unit, units } }
    } = this.props

    return (
      <Modal title="Legg inn" onClose={close} enableOverlayClose={false}>
        <form onSubmit={e => this.addIngredient(e)} className="ingredientForm">
          <Modal.Body>
            <h2>{name}</h2>
            <div className="group">
              <div className="field field-1-3">
                <label htmlFor="entryQuantity">Antall</label>
                <input className="amount" id="entryQuantity" type="text" name="quantity" onChange={handleFormatQuantity} defaultValue={quantity} autoFocus />
              </div>
              <div className="field field-2-3">
                <label htmlFor="entryAmount">Type måle-enhet</label>
                <select id="entryAmount" defaultValue={unit}>
                  {units.map((unit) => (
                    <option value={unit.id} key={unit.name}>{unit.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={close}>Avbryt</button>
            <button type="submit">Lagre</button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default EntryFormModal
