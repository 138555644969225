import React from 'react'
import UnitScreen from '../UnitScreen'
import { withDate } from 'components/util/withDate'
import { graphql } from '@apollo/client/react/hoc'
import { GET_RECIPE } from 'queries'

const AddRecipeWithData = ({ data: { loading, error, request }, tag, cancel, close, date }) => {
  if (request && request.kitchen && request.kitchen.recipes && request.kitchen.recipes.edges[0].node) {
    const recipe = request.kitchen.recipes.edges[0].node
    const recipeImage = ((recipe || {}).image || {}).url || null

    return <UnitScreen
      entry={{
        registrationId: null,
        category: 'food',
        tag: tag,
        date_time: date,
        description: null,
        calories: 0,
        data: {
          id: recipe.recipeId,
          type: 'recipe',
          name: recipe.name,
          image: recipeImage,
          quantity: null,
          unit: null,
          units: [
            {
              name: 'gram',
              gramsPerUnit: 1,
            },
            {
              name: 'porsjoner',
              gramsPerUnit: recipe.portionGrams,
            },
          ],
          allergens: recipe.allergens.edges.map(edge => edge.node.name),
          heartFriendly: recipe.isHeartFriendly,
          quality: recipe.dietCategory,
          nutrition: {
            kcalPer100Grams: recipe.kcal100g,
            gramsOfProteinPer100Grams: recipe.protein100g,
            gramsOfCarbohydratePer100Grams: recipe.carbs100g,
            gramsOfFatPer100Grams: recipe.fat100g,
            milligramsOfCalciumPer100Grams: recipe.calcium100g,
            gramsOfFruitsPer100Grams: recipe.fruits100g,
            gramsOfVegetablesPer100Grams: recipe.veg100g,
            gramsOfFiberPer100Grams: recipe.fiber100g,
            gramsOfNutsPer100Grams: recipe.nuts100g
          }

        },
      }}
      cancel={cancel}
      close={close}
    />
  }

  return null
}

const AddRecipe = withDate(graphql(GET_RECIPE, {
    options: ({ recipeId }) => ({ variables: { recipeId } })
})(AddRecipeWithData))

export default AddRecipe
