import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import s from "../ReportPage/styles/Header.module.scss";

function NavItem({ name, value, membershipId }) {
  const tabIndex = value ? undefined : -1;
  return (
    <Link to={`/tema/${value}/`} tabIndex={tabIndex}>
      {name}
    </Link>
  );
}

class Header extends React.Component {
  render() {
    const { week, prev, next } = this.props;
    const weekNumber = moment(week).format("W");

    return (
      <div className={s.header}>
        <h4>
          <strong>Uke {weekNumber}</strong>
        </h4>
        <div className={s.nav}>
          <NavItem name="Forrige" value={prev} />
          <NavItem name="Neste" value={next} />
        </div>
      </div>
    );
  }
}

export default Header;
