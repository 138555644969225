import React from 'react'
import styles from './Row.module.scss'

export default class Row extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        {this.props.content}
        {this.props.textUnder}
        <img src={this.props.face} alt="" className={styles.face} />
      </div>
    )
  }
}
