import { gql } from '@apollo/client'

const getMessages = gql`
  query GetMessages (
    $slotId: Int
  ) {
    user {
      messages (
        slotId: $slotId
      ) {
        edges {
          node {
            messageId
            title
            messageBody
          }
        }
      }
    }
  }
`

export default getMessages
