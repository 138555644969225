import React from 'react'
import DeleteFavorite from './DeleteFavorite'
import { GET_FOOD_PRODUCT } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { withAuth } from 'components/container/withAuth'
import { getSafe } from 'utils'

const FavoriteFoodProductsListWithData = ({
  data: { loading, request, refetch },
  auth: { user },
}) => {
  const foodProducts = getSafe(() => (
    request.kitchen.ingredients.edges.map(edge => ({
      ...edge.node,
      isFavorite: true,
    }))
  ), [])

  if (loading) {
    return <div className="loading" />
  }

  if (foodProducts.length === 0) {
    return (
      <div className="emptyResultset">
        <i className="icon-info"></i> Ingen favorittmatvarer
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <th>Mine favorittmatvarer</th>
        </tr>
        {foodProducts.map(foodProduct => (
          <DeleteFavorite
            key={foodProduct.objectId}
            objectId={foodProduct.objectId}
            render={({ deleting, deleteFavorite }) => (
              <tr data-deleting={deleting}>
                <td>
                  <i className="icon-star" onClick={deleteFavorite} />
                  {foodProduct.name}
                </td>
              </tr>
            )}
          />
        ))}
      </tbody>
    </table>
  )
}

const GetFoodProductWrapper = graphql(GET_FOOD_PRODUCT, {
  options: ({ auth: { user } }) => ({
    variables: {
      objectId: user.favorites
        .filter(favorite => favorite.objType === 'food_product')
        .map(favorite => favorite.objId)
    }
  })
})(FavoriteFoodProductsListWithData)

export default withAuth(GetFoodProductWrapper)
