import s from './Section.module.scss'

// interface Props {
//   children: React.ReactNode;
//   className: string;
// }

export function Picture({ src, fallback, alt = '', className }: {alt?: string; className?: string; src?: any; fallback?: any;}) {
  return (
    <picture>
      <source srcSet={src} type="image/webp" />
      <img src={fallback} alt={alt} className={className} />
    </picture>
  )
}

export function Wrapper({ children, className, align }: {children?: React.ReactNode; className?: string; align?: any;}) {
  const classNames = className ? `${s.wrapper} ${className}` : s.wrapper
  return (
    <div className={classNames} data-align={align}>
      {children}
    </div>
  )
}

export function Media({ children, className, cover }: {children?: React.ReactNode; className?: string; cover?: any;}) {
  const classNames = className ? `${s.media} ${className}` : s.media
  return (
    <div className={classNames} data-cover={cover}>
      {children}
    </div>
  )
}

export function Content({ children, className }: {children?: React.ReactNode; className?: string;}) {
  const classNames = className ? `${s.content} ${className}` : s.content
  return <div className={classNames}>{children}</div>
}

export function Section({ children, className, align, variant }: {children?: React.ReactNode; className?: string; align?: any; variant?: any;}) {
  const classNames = className ? `${s.section} ${className}` : s.section
  return (
    <section className={classNames} data-variant={variant}>
      <Wrapper align={align}>{children}</Wrapper>
    </section>
  )
}
