import { gql } from '@apollo/client'

const deleteCustomFoodProduct = gql`
  mutation DeleteCustomFoodProduct (
    $objectId: Int!
  ) {
    diary {
      deleteCustomFoodProduct (
        objectId: $objectId
      ) {
        status
      }
    }
  }
`

export default deleteCustomFoodProduct
