const allergensFields = [
  {
    label: 'Inneholder gluten?',
    value: 'Gluten',
  },
  {
    label: 'Inneholder melk?',
    value: 'Melk',
  },
  {
    label: 'Inneholder egg?',
    value: 'Egg',
  },
  {
    label: 'Inneholder sennep?',
    value: 'Sennep',
  },
  {
    label: 'Inneholder fisk?',
    value: 'Fisk',
  },
  {
    label: 'Inneholder bløtdyr?',
    value: 'Bløtdyr',
  },
  {
    label: 'Inneholder skalldyr?',
    value: 'Skalldyr',
  },
  {
    label: 'Inneholder nøtter?',
    value: 'Nøtter',
  },
  {
    label: 'Inneholder peanøtter?',
    value: 'Peanøtter',
  },
  {
    label: 'Inneholder soya?',
    value: 'Soya',
  },
  {
    label: 'Inneholder sesamfrø?',
    value: 'Sesam',
  },
  {
    label: 'Inneholder selleri?',
    value: 'Selleri',
  },
  {
    label: 'Inneholder lupin?',
    value: 'Lupin',
  },
  {
    label: 'Inneholder svoveldioksid og sulfitter?',
    value: 'Svoveldioksid og sulfitter',
  },
]

export { allergensFields }
