import faceHappy from './images/faceHappy.png'
import faceSad from './images/faceSad.png'
import faceSmile from './images/faceSmile.png'
import glass from './images/glass.png'
import glassFilled from './images/glassFilled.png'
import heartEmpty from './images/heartEmpty.png'
import heartFull from './images/heartFull.png'
import omega from './images/omega.png'
import omegaUnchecked from './images/omegaUnchecked.png'

const images = {
  faceHappy,
  faceSad,
  faceSmile,
  glass,
  glassFilled,
  heartEmpty,
  heartFull,
  omega,
  omegaUnchecked,
}

export default images
