import { gql } from '@apollo/client';

const deleteEntries = gql`
  mutation DeleteEntries (
      $registrationId: Int,
      $tag: String,
      $category: String,
      $date_time: DateTime
  ){
    diary {
      diaryRemove (
          registrationId: $registrationId,
          category: $category,
          tag:$tag,
          dateTime: $date_time
      ) {
        status
      }
    }
  }
`;

export default deleteEntries;