import React, { Component } from 'react'
import Modal from '../Modal'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY, GET_DIARY_ENTRIES, GET_FREQUENTLY_USED_UNITS } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'
import moment from 'moment'
import withRouter from '../../../utils/withRouter'
import { formatQuantity } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import './styles.scss'

const handleFormatQuantity = event => {
  event.target.value = formatQuantity(event.target.value)
}

const EntryFormModalContainer = ({ entry, close, mutate, match, frequentUnits={} }) => {
  let quantityRef
  let unitRef
  let entryData = entry.data

  const getSafe = (fn) => {
    try {
      return fn()
    } catch (e) {
      return undefined
    }
  }

  const setFrequentValues = (event, obj) => {
    event.preventDefault();
    event.stopPropagation();

    quantityRef.value = obj.quantity;
    unitRef.value = obj.name;
    close();
    saveEntry(event);
  }

  const saveEntry = event => {
    event.preventDefault()

    const quantityRefValue = parseFloat(quantityRef.value.replace(',', '.'))
    const selectedUnit = entryData.units.find(unit => unit.name.trim() === unitRef.value)
    const calories = Math.round((selectedUnit.gramsPerUnit / 100) * entryData.nutrition.kcalPer100Grams * quantityRefValue)
    const description = `${quantityRefValue} ${unitRef.value} ${entryData.name} ${calories} Kcal`

    // remove frequentUnits from data
    delete entry.data.frequentUnits

    const variables = {
      ...entry,
      ...{
        calories,
        description,
        data: JSON.stringify({
          ...entryData,
          ...{
            quantity: quantityRefValue,
            unit: unitRef.value,
          },
          'source': 'web',
        }),
      }
    }

    const optimisticResponse = {
      diary: {
        diaryRegister: {
          status: 'success',
          registrationId: entry.registrationId || Math.round(Math.random() * -1000000),
          __typename: 'DiaryRegister',
        },
        __typename: 'DiaryMutation',
      },
      __typename: 'Mutation',
    }

    const update = (proxy, { data: { diary } }) => {
      const registrationId = diary.diaryRegister.registrationId
      const dateTime = moment(match.params.date).format('YYYY-MM-DDT00:00:00')
      const lastModified = moment(match.params.date).format('YYYY-MM-DDTHH:mm:ss')

      const cache = proxy.readQuery({
        query: GET_DIARY_ENTRIES,
        variables: { date_from: dateTime, category: 'food' },
      })

      const data = cloneDeep(cache)

      const edge = {
        node: {
          ...variables,
          registrationId,
          dateTime,
          lastModified,
          __typename: 'DiaryRegistration'
        },
        __typename: 'DiaryRegistrationEdge',
      }

      const index = data.request.diary.diaryRegistration.edges.findIndex(e => (
        e.node.registrationId === edge.node.registrationId
      ))

      if (index === -1) {
        data.request.diary.diaryRegistration.edges.push(edge)
      } else {
        data.request.diary.diaryRegistration.edges[index] = edge
      }

      proxy.writeQuery({
        data,
        query: GET_DIARY_ENTRIES,
        variables: { date_from: dateTime, category: 'food' },
      })
    }

    close()

    mutate({
      variables,
      refetchQueries: refetchQueries(),
      optimisticResponse,
      update,
    })
  }

  const { name, quantity, unit, units } = entryData

  const {
    request: {
      diary: {
        frequentlyUsedUnits = []
      } = {}
    } = {}
  } = frequentUnits;

  const fqUnit = getSafe(() => frequentlyUsedUnits.slice(0, 3));

  return (
    <Modal title="Legg inn" onClose={close} enableOverlayClose={false}>
      <form onSubmit={saveEntry}>
        <Modal.Body>
          <h2>{name}</h2>
          <div className="group">
            <div className="field field-1-3">
              <label htmlFor="entryQuantity">Antall</label>
              <input className="amount" id="entryQuantity" type="text" onChange={handleFormatQuantity} name="quantity" defaultValue={quantity && quantity.toString().replace('.', ',')} autoFocus ref={input => quantityRef = input } autoComplete="off" required />
            </div>
            <div className="field field-2-3">
              <label htmlFor="entryAmount">Type måle-enhet</label>
              <select id="entryAmount" defaultValue={unit} ref={input => unitRef = input}>
                {units.map((unit) => (
                  <option value={unit.id} key={unit.name}>{unit.name}</option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {fqUnit.length > 0 && <div className="buttonWrapper">
            {fqUnit.map((fqu, key) => (
              <button key={key} type="submit" onClick={(e) => setFrequentValues(e, fqu)}>{`${fqu.quantity} ${fqu.name}`}</button>
            ))}
          </div>}
          <button type="button" onClick={close}>Avbryt</button>
          <button type="submit">Lagre</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

class EntryFormModalBuffer extends Component {

  render() {
    const {props} = this;

    const {
      entry: {
        data: { id, type } = {}
      } = {}
    } = props;


    return ['product', 'custom product'].includes(type) ? <EntryFormModalWrapper
      {...props}
      foodId={id}
      foodType={type}
    ></EntryFormModalWrapper> : <EntryFormModalContainer
      {...props}
    ></EntryFormModalContainer>
  }
}

const EntryFormModalWrapper = graphql(GET_FREQUENTLY_USED_UNITS, {
  name: "frequentUnits",
  options: ({ foodId, foodType }) => ({ variables: { foodId: foodId, foodType: foodType } })
})(EntryFormModalContainer);

const EntryFormModal = withRouter(graphql(ADD_ENTRY)(EntryFormModalBuffer))

export default EntryFormModal
