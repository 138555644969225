import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useCoachingMembershipURL } from 'utils'
import { graphql } from '@apollo/client/react/hoc'
import GET_ACTIVE_MEMBERSHIPS from 'queries/getActiveMemberships'
import s from './MembershipSelector.module.scss'


function MembershipSelectorWrapped({ data, membershipName }) {
  const [open, setOpen] = useState(false)

  const memberships =
    data?.user?.memberships?.edges?.map((edge) => edge.node).filter((edge) => (edge.membershipType !== 'membership' ||  edge.membershipTypeObject?.courseSignup === true )) || []

  
  let status = open ? 'open' : ''
  status = open && data.loading ? 'loading' : status

  return (
    <div className={s.container}>
      <button
        className={s.button}
        data-status={status}
        onClick={() => setOpen(!open)}
      >
        Ditt kurs: <b className={s.name}>{membershipName}</b>
      </button>
      {open && memberships.length > 0 && (
        <MembershipDropdown memberships={memberships} setOpen={setOpen} />
      )}
    </div>
  )
}
function Membership({membership, setOpen}){
  const coach_url = useCoachingMembershipURL(membership)
  return(
    <NavLink
          key={membership.membershipId}
          to={coach_url}
          className={({ isActive }) =>
          [
            s.option,
            isActive ?  s.active : null,
          ]
            .filter(Boolean)
            .join(" ")
        }
          onClick={() => setOpen(false)}
        >
          <b className={s.type}>{membership.name}</b> {membership?.instructor?.name}
        </NavLink>
  )

}

function MembershipDropdown({ memberships, setOpen }) {
  
  return (
    <div className={s.dropdown}>
      {memberships.map((membership) => (
        <Membership key={membership.id} membership={membership} setOpen={setOpen} />
      ))}
    </div>
  )
}

export default graphql(GET_ACTIVE_MEMBERSHIPS)(MembershipSelectorWrapped)
