import { useState } from "react";
import ReactDOM from "react-dom";
import CourseDetails from "./CourseDetails";
import s from "./styles/CourseCard.module.scss";

export default function CourseCard(props) {
  const { course } = props;
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const tabIndex = course.status === "full" ? -1 : null;
  if (course.inGroup) {
    return null;
  }

  return (
    <div className={s.course}>
      {course.showStartTime ? (
        <time>{course.startTime ? course.startTime : "Ingen tid"}</time>
      ) : (
        <span className={s.noTime} />
      )}
      <div
        href={course.link}
        className={s.card}
        data-status={course.status}
        tabIndex={tabIndex}
        data-open={isOpen}
        onClick={open}
      >
        <h4>{course.name}</h4>

        <span className={s.time}>
          {course.startTime && (
            <>
              <span>{`${course.startTime} - ${course.endTime}`}</span>
              {course.otherTimes.map((otherTime) => {
                return (
                  <span
                    key={otherTime.courseId}
                  >{`${otherTime.startTime} - ${otherTime.endTime}`}</span>
                );
              })}
            </>
          )}
        </span>
        <span
          data-type={course.type}
          className={s.courseLocation}
          data-multiple={course.otherTimes && course.otherTimes.length > 0}
        >
          {course.place}
        </span>
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <CourseDetails {...props} close={close} />,
          document.getElementById("modal-root")
        )}
    </div>
  );
}

export function NettCourseCard(props) { 
  const { course } = props;
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const tabIndex = course.status === "full" ? -1 : null;
  if (course.inGroup) {
    return null;
  }

  return (
    <div className={s.course}>
      {course.showStartTime ? (
        <time>{course.startTime ? course.startTime : "Ingen tid"}</time>
      ) : (
        <span className={s.noTime} />
      )}
      <div
        href={course.link}
        className={s.card}
        data-status={course.status}
        tabIndex={tabIndex}
        data-open={isOpen}
        onClick={open}
      >
        <h4>{course.name}</h4>

        <span className={s.time}>
          {course.startTime && (
            <>
              <span>{`${course.startTime} - ${course.endTime}`}</span>
              {course.otherTimes.map((otherTime) => {
                return (
                  <span
                    key={otherTime.courseId}
                  >{`${otherTime.startTime} - ${otherTime.endTime}`}</span>
                );
              })}
            </>
          )}
        </span>
        <span
          data-type={course.type}
          className={s.courseLocation}
          data-multiple={course.otherTimes && course.otherTimes.length > 0}
        >
          {course.place}
        </span>
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <CourseDetails {...props} close={close} />,
          document.getElementById("modal-root")
        )}
    </div>
  );
}
