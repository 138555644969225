import React from 'react'
import MealActions from './MealActions'
import AddRegistration from './AddRegistration'
import Registration from './Registration'
import CopyRegistrations from 'components/container/CopyRegistrations'
import s from './Meal.module.scss'
import moment from 'moment'

const getCurrentMealFromDayTime = () => {
  const hour = moment().format('H')

  if (hour >= 4 && hour < 11) {
      return 'breakfast'
  } else if (hour >= 11 && hour < 13) {
      return 'lunch'
  } else if (hour >= 13 && hour < 15) {
      return 'snackMeal'
  } else if (hour >= 15 && hour < 19) {
      return 'dinner'
  } else if ((hour >= 19 && hour <= 23) || (hour >= 0 && hour < 4)) {
      return 'supper'
  }
}

const isCurrentMeal = meal => getCurrentMealFromDayTime() === meal.id

class Meal extends React.Component {
  state = {
    deleting: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.deleting && (this.props.meal.entries.length !== prevProps.meal.entries.length)) {
      this.setState({ deleting: false })
    }
  }

  onDelete = () => this.setState({ deleting: true })

  render() {
    const { meal } = this.props
    const { deleting } = this.state

    return (
      <section className={s.section}>
        <header className={s.header} data-iscurrent={isCurrentMeal(meal)}>
          <h3 className={s.title}>
            {meal.name}
            <span className={s.calories}>
              {meal.calories} <em className={s.unit}>kcal</em>
            </span>
          </h3>
          <div className={s.actions}>
            <MealActions meal={meal} onDelete={this.onDelete} />
            <AddRegistration meal={meal.id} />
          </div>
        </header>
        <ul className={s.registrations} data-deleting={deleting}>
          {meal.entries.map(registration => (
            <Registration
              key={registration.registrationId}
              meal={meal}
              registration={registration}
            />
          ))}
          {!meal.entries.length && (
            <li className={s.buttonContainer}><CopyButton meal={meal} /></li>
          )}
        </ul>
      </section>
    )
  }
}

const CopyButton = (props) => (
  <CopyRegistrations {...props} render={({ copyRegistrations }) => (
    <button className={s.copyButton} onClick={copyRegistrations}>
      <i className="icon-file-copy" /> Kopier fra i går
    </button>
  )}/>
)

export default Meal
