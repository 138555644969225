import { gql } from "@apollo/client";

export default gql`
  query MembershipQuery($membershipId: Int!) {
    user {
      userData {
        id: userId
        postalCode
      }
      membership(membershipId: $membershipId) {
        id: membershipId
        membershipId
        name
        membershipType
        membershipTypeId
        paymentSource
        availibleActions
        statusText
        prepaidMeetings
        frequency
        weekday
        courseSignup
        status
        stripeStatus
        stripeSubscriptionEarliestCancel
        activatedDate
        nextScheduledMeetingStartTime
        nextPaidReportTime
        renewalStatus
        course {
          id: courseId
          courseId
          fullAddress
        }
        nettcourseInstructor {
          instructorId
        }
        instructor {
          id: instructorId
          name
          email
          phone
        }
        lastReport {
          id: reportId
        }
      }
    }
  }
`;
