import React, { Fragment, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function WeekCalendar({ close, section, ...props }) {
  let params = useParams();
  let navigate = useNavigate();
  let year: number = parseInt(params.year);
  let week: number = parseInt(params.week);

  const [month, setMonth] = useState(
    moment().year(year).isoWeek(week).startOf("month")
  );

  const getListOfWeeksInMonth = () => {
    const selectedMonth: number = parseInt(month.clone().format("M"), 10);
    const currentMonth: number = parseInt(moment().format("M"), 10);
    const startOfMonth: moment.Moment = month.clone().startOf("month");
    const lastDayOfMonth: number = parseInt(
      month.clone().endOf("month").format("D"),
      10
    );
    const currentDay: number = parseInt(moment().format("D"), 10);
    const dayOffset: number = month.startOf("month").weekday();
    const lastWeekMonth: string = startOfMonth
      .clone()
      .startOf("week")
      .add(5, "week")
      .format("W");
    const weekOfSelectedMonth: string = month
      .clone()
      .endOf("month")
      .format("W");
    const arraySize: number = lastWeekMonth === weekOfSelectedMonth ? 6 : 5;

    return Array(arraySize)
      .fill(undefined)
      .map((_, i) => {
        const currWeek: moment.Moment = startOfMonth.clone().add(i, "week");
        const currentYear: number = parseInt(currWeek.format("YYYY"), 10);
        const currentWeek: number = parseInt(currWeek.format("W"), 10);
        const startOfWeek: number = parseInt(
          moment()
            .year(currentYear)
            .isoWeek(currentWeek)
            .startOf("week")
            .format("D"),
          10
        );
        const todayClassname: string =
          selectedMonth === currentMonth ? "today" : "";
        const weekDays = [];

        if (i === 0) {
          Array(dayOffset)
            .fill(undefined)
            .map((_, i) =>
              weekDays.push({
                number: null,
                className: "empty",
              })
            );
          Array(7 - dayOffset)
            .fill(undefined)
            .map((_, i) =>
              weekDays.push({
                number: i + 1,
                className: i + 1 === currentDay ? todayClassname : "",
              })
            );
        } else {
          Array(7)
            .fill(undefined)
            .map((_, i) =>
              weekDays.push({
                number:
                  startOfWeek + i > lastDayOfMonth ? null : startOfWeek + i,
                className:
                  startOfWeek + i === currentDay
                    ? todayClassname
                    : startOfWeek + i > lastDayOfMonth
                      ? "empty"
                      : "",
              })
            );
        }

        return {
          year: currentYear,
          number: currentWeek,
          className:
            year === currentYear && week === currentWeek ? "selected" : "",
          days: weekDays,
        };
      });
  };

  const setWeek = (event) => {
    const element =
      event.target.tagName.toLowerCase() === "li"
        ? event.target.parentElement
        : event.target;
    const year = element.getAttribute("data-year");
    const week = element.getAttribute("data-week");

    navigate(`/${section}/${year}/${week}/`);
    close();
  };

  const weeks = getListOfWeeksInMonth();

  return (
    <Fragment>
      <div className="overlay calendar-overlay" onClick={close}></div>
      <div className="calendar calendar--week">
        <div className="calendar-header">
          <button onClick={() => setMonth(month.clone().add(-1, "month"))}>
            <i className="icon-arrow-2-left"></i>
          </button>
          <strong>{month.format("MMMM YYYY")}</strong>
          <button onClick={() => setMonth(month.clone().add(1, "month"))}>
            <i className="icon-arrow-2-right"></i>
          </button>
        </div>
        <ul className="calendar-weekdays">
          <li>uke</li>
          <li>&nbsp;</li>
          <li>ma</li>
          <li>ti</li>
          <li>on</li>
          <li>to</li>
          <li>fr</li>
          <li>lø</li>
          <li>sø</li>
        </ul>
        <ul className="calendar-weeks">
          {weeks.map((week) => (
            <li key={week.number} className={week.className}>
              <ul
                data-year={week.year}
                data-week={week.number}
                onClick={(e) => setWeek(e)}
              >
                <li>{week.number}</li>
                <li className="spacer">&nbsp;</li>
                {week.days.map((day, index) => (
                  <li key={index} className={day.className}>
                    {day.number || "\u00A0"}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
}
