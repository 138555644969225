import React from 'react';
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { getSafe } from 'utils'
import s from './UnpaidInvoiceNotice.module.scss'
import Wrapper from 'components/ui/Wrapper';


import { GET_INVOICES } from 'queries';

interface Props {}

const UnpaidInvoiceNotice:React.FC<Props> = ({...props}) => {
  const {data, loading} = useQuery(GET_INVOICES, {
    variables: {status: 'open' }
  })

  const formatAmount = (amount:number) => (
    new Intl.NumberFormat('no-NO' ).format(amount/100)
  )

  if(loading) return null

  const invoices = getSafe(() => data?.user?.invoices?.edges?.map(edge => edge.node)) || [];

  return <Wrapper>
    <div className={s.unpaidInvoices}>
      {invoices.map(invoice => {
        const data = getSafe(() => JSON.parse(invoice.data)) || {}
        return(
          <div key={invoice.id} className={s.unpaidInvoice}>
            Faktura {moment(invoice.dateTime).format("DD/MM-YY")} på {formatAmount(data.amount_due)},- for {invoice.membership?.name} mangler betaling. <a href={data.hosted_invoice_url}  target="_blank" rel="noopener noreferrer">Betal nå</a>
          </div>
        )}
      )}
    </div>
  </Wrapper>
}
export default UnpaidInvoiceNotice
