import { durationOptions } from './RegisterExercise/ExerciseModal/durationOptions'

const formatPoints = points => {
  if (!points) {
    return 0
  }

  return points.toFixed(1)
}

const formatCalories = calories => {
  if (calories === null) {
    return ''
  }

  return calories.toLocaleString('nb-NO')
}

const formatNumSteps = numSteps => {
  if (numSteps === null) {
    return ''
  }

  return numSteps.toLocaleString('nb-NO')
}

const formatDuration = minutes => {
  return durationOptions.find(duration => duration.value === minutes).text
}

export {
  formatPoints,
  formatCalories,
  formatNumSteps,
  formatDuration,
}