import { gql } from '@apollo/client'

export default gql`
  query getMembershipTypeCourses(
    $membershipId: Int!
    $placeId: String!
    $radius: Int
  ) {
    user {
      membership(membershipId: $membershipId) {
        availibleCourses(placeId: $placeId, radius: $radius) {
          edges {
            node {
              id: courseId
              meetingWeekday
              meetingStartTime
              meetingEndTime
              instructorId
              instructor {
                id: instructorId
                instructorId
                name
                image
                email
                phone
                title
                bioBullet1
                bioBullet2
                bioBullet3
                shortDescription
              }
              fullAddress
              firstBookableMeetingStartTime
              name
              fewSpots
              isFull
              coursetypeComment
              courseTypeId
              courseType {
                id: courseTypeId
                courseTypeId
                name
                description
                descriptionLong
                meetingLocation
                schedulingAlgorithm
              }
            }
          }
        }
        availibleNettcourses {
          edges {
            node {
              id: instructorId
              nettcourseId
              availableSpots
              nettcourse{
                id: nettcourseId
                name
              }
              instructor {
                id: instructorId
                name
                image
                title
              }
            }
          }
        }
      }
    }
    courseTypeDescriptions {
      edges {
        node {
          id: courseTypeDescriptionId
          courseTypeDescriptionId
          courseTypes
          nettcourse
          name
          description
          image
        }
      }
    }
  }
`
