import { gql } from '@apollo/client';

const getCustomRecipe = gql`
  query getCustomRecipe ($objectId: Int) {
    request {
      diary {
        recipeCustomResult (objectId: $objectId) {
          edges {
            node {
              objectId
              o2Id
              kcal100g
              name
              data
            }
          }
        }
      }
    }
  }
`;

export default getCustomRecipe;