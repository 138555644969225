import useAuth from "components/auth/useAuth";
import Wrapper from "components/ui/Wrapper";
import s from "./ToolboxPage.module.scss";


export default function RoedeMethodPage() {
  const auth = useAuth();  
  const toolboxData = auth.user.toolbox  

  return (
    <Wrapper>
      <h1 className={s.heading}>Din Verktøykasse</h1>
      <div className={s.tools}>
        {toolboxData.map((section, i) => (
          <div key={i} className={s.toolSection}>
            {section.icon && <img src={section.icon} alt={section.title} />}
            <h2>{section.title}</h2>
            <ul>
              {section.files.map((file, j) => (
                <li key={`${i}.${j}`}>
                  <a href={file.file}>{file.title}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
