import React from 'react'
import Wrapper from '../Wrapper'

import s from './CourseUpdate.module.scss'
import pdfDocument from './pdf-document.png'

const CourseUpdate = ({courseUpdate}) => {

  console.log(courseUpdate)
  
  return (
    <div className={s.courseUpdate}>
      <Wrapper>
        <div className={s.container}>
          <div className={s.content}>
            <div>
              <h2 className={s.sectionTitle}>Ukens tema:</h2>
              <h3 className={s.title}>{courseUpdate?.title}</h3>
              <div
                className={s.description}
                dangerouslySetInnerHTML={{ __html: courseUpdate?.description }}
              />
            </div>

            {courseUpdate?.googleStorageFile && (
              <div className={s.downloadWrapper} ><img src={pdfDocument} alt="PDF icon" /><a href={`https://api.roede.com/${courseUpdate?.downloadUrl}`} className={s.download}>Last ned temahefte</a></div>
            )}

            {!courseUpdate?.downloadUrl && courseUpdate?.pdfUri && (
              <div className={s.downloadWrapper} ><img src={pdfDocument} alt="PDF icon" /> <a href={`https://api.roede.com/${courseUpdate?.pdfUri}`} className={s.download}>Last ned temahefte</a></div>
            )}

            <div
              className={s.videoEmbed}
              dangerouslySetInnerHTML={{ __html: courseUpdate?.videoEmbed }}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

export default CourseUpdate
