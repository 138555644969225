import Modal from "../Modal";
import "./styles.scss";

const AboutModal = ({ close }) => (
  <Modal title="Om denne siden" onClose={close} size="default">
    <Modal.Body>
      <div className="aboutModal">
        <p>
          <em>
            Flere av funksjonene på denne siden, er kun tilgjengelig for
            kursdeltakere og Gull-medlemmer i Roede-klubben.
          </em>
          <br />
          <br />
        </p>
        <h2>Registrering</h2>
        <p>
          På denne siden kan du registrere trening, skritt og mat. Klikk på de
          ulike fanene Kosthold, Mosjon og Vekt&mål for å navigere riktig.
        </p>
        <p>
          I kursperioden vil ukens summering av kcal, kostholdsscore, kosemerker
          og treningspoeng være tilgjengelige for din veileder.
          <br />
          <br />
        </p>
        <h2>Registrer mat</h2>
        <p>
          Gå til riktig måltid og klikk på Legg inn. Skriv navnet på matvaren du
          skal legge inn, og det kommer opp ulike forslag. Velg riktig matvare
          ved å klikke eller trykke Enter. Velg deretter mengde i gram eller
          bruk rullgardinen for å få opp andre alternative mengder. Når du nå
          klikker Lagre eller Enter blir matvaren registrert.
        </p>
        <h3>Tips for søk i matvaretabellen</h3>
        <p>
          Dersom du ikke finner riktig matvare, anbefaler vi at du prøver å søke
          noen alternative navn for matvaren. Eksempel: Du søker ”melk” og får
          ikke opp noen matvare. Du kan da søke ”skummet” eller ”lettmelk”, og
          du vil finne riktig melk. Du kan også prøve å søke på produsent.
        </p>
        <h3>Lagre som eget måltid</h3>
        <p>
          Spiser du f. eks. samme frokost hver dag, kan du lagre denne som eget
          måltid. Når du har lagt inn hele frokosten, klikker du Lagre som eget
          måltid, gir måltidet et navn og klikker Lagre. Dersom du har spist det
          samme til f. eks. lunsj i dag som du gjorde i går, kan du også
          forenkle registreringen ved å klikke Kopier lunsj fra i går.
          Tilsvarende kan du gjøre for de andre måltidene.
        </p>
        <h3>Utfordring</h3>
        <p>
          Under kosemerker registrerer du det du har spist som du vil skal gå av
          kosemerkene. Her kan det av og til bli mange og andre dager ingen.
          Kosemerkene inngår derfor ikke i beregningen av dagens Kostholdsscore.
          Bruk av kosemerkene påvirker likevel kostholdsscoren ved at de
          påvirker det totale energiinntaket.
        </p>
        <h3>Vann/Tran/Vitaminer</h3>
        <p>
          Her registrerer du antall glass vann du har drukket ved å trykke på
          riktig antall. Kryss av for om du har tatt tran og/eller
          multivitaminmineral.
          <br />
          <br />
        </p>
        <h2>Mitt kosthold</h2>
        <p>
          Her får du en status på dagens matinntak. Trykk på den grønne pilen
          for å åpne vinduet med dine tilbakemeldinger. Etter hvert som du
          registrerer, vil søylene og feltene fylles. Når du har spist mer enn
          900 kcal, vil dagens Kostholdsscore bli beregnet og vil justeres for
          alt videre du registrerer. Husk at du må registrere vann, tran og
          kosemerker i feltene til venstre for at det skal komme med i
          oppsummeringen øverst i bildet.
          <br />
          <br />
        </p>
        <h2>Registrer skritt</h2>
        <p>
          Bruker du skritteller, kan du registrere antall skritt. Skritt gir
          ikke automatisk treningspoeng. Ønsker du at skrittene skal gi
          treningspoeng må du i tillegg registrere aktiviteten under trening.Har
          du valgt automatisk overføring fra Polar, importeres «gårsdagens»
          totalantall skritt hver formiddag.
          <br />
          <br />
        </p>
        <h2>Registrer trening </h2>
        <p>
          Velg først riktig type aktivitet fra rullgardinen og legg deretter inn
          antall minutter du har trent. Trykk Lagre og antall{" "}
          <strong>treningspoeng</strong> vil automatisk oppsummeres. Husk at du
          må ha registrert vekten din for at treningspoengene skal beregnes.
        </p>
        <p>
          Dersom <strong>din treningsform mangler</strong> anbefaler vi at du
          registrerer treningen din som en tilsvarende aktivitet som gjør deg
          omtrent like svett, andpusten og sliten.
        </p>
        <p>
          For å få <strong>styrkepoeng</strong> må du registrere treningen din
          som styrketrening. Har du trent en kombinasjon av kondisjon og styrke
          må du dele antall minutter på f. eks. aerobic og styrketrening.
        </p>
        <p>
          Nå kan du få overført dine treningsdata{" "}
          <strong>
            direkte fra din Polar pulsklokke eller Polar Loop til dagboken
          </strong>{" "}
          her i Roede-klubben. Du kan overføre både din treningsøkt og antall
          skritt. Gå til{" "}
          <a href="https://www.roede.com/o2/User-Profile#tab7">
            Innstillinger/Automatisk registrering
          </a>{" "}
          for å koble din Roede-bruker sammen med din Polar-bruker.
        </p>
        <p>
          Ved bruk av andre pulsklokker velger du Pulsklokke øverst i
          rullgardinen, beskriv type aktivitet (eks løping, rulleski, sykling)
          og deretter legger du inn antall kcal klokken har registrert som
          energiforbruk. Husk at pulsklokken kun viser et grovt estimat på
          energiforbruk, særlig pulsklokker uten GPS-funksjon.
          <br />
          <br />
        </p>
        <h2>Min trening</h2>
        <p>
          Her får du oppsummert antall treningspoeng og antall skritt, både per
          dag og per uke. Når du oppnår antall anbefalte treningspoeng vil disse
          utheves. Når du har registrert trening som defineres som styrketrening
          vil du i tillegg få uthevede manualer. Én per registrerte økt på mer
          enn 15 minutter.
        </p>
        <h3>Din vurdering av dagen</h3>
        <p>
          I dette feltet kan du krysse av for hvordan dagen din har vært samt
          skrive en kommentar. Bruk gjerne dette feltet som en liten dagbok til
          deg selv. Hva har fungert bra? Hva er du spesielt fornøyd med? Hva har
          eventuelt gått feil og hvordan vil du håndtere samme situasjon neste
          gang?
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button type="submit" onClick={close}>
        Lukk
      </button>
    </Modal.Footer>
  </Modal>
);

export default AboutModal;
