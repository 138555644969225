import { getSafe } from 'utils'

const transformMeasurementsData = ({
  measurements: measurementsData,
  summary: summaryData,
}) => {
  const measurements = parseMeasurements(measurementsData)
  const summary = parseSummary(summaryData)

  return { measurements, summary }
}

const parseSummary = data => {
  const summary = getSafe(() => JSON.parse(data.diary.summaryMeasurements).computedValues) || {}

  const {
    currentWeight = 0,
    lastWeekWeightDifference = 0,
    startDayWeight = 0,
    currentWaistCircumference = 0,
    lastWeekWaistCircumferenceDifference = 0,
    startDayWaistCircumference = 0,
  } = summary

  return {
    weight: {
      current: currentWeight,
      changeSinceLastWeek: lastWeekWeightDifference,
      changeSinceStartDate: startDayWeight,
    },
    waistCircumference: {
      current: currentWaistCircumference,
      changeSinceLastWeek: lastWeekWaistCircumferenceDifference,
      changeSinceStartDate: startDayWaistCircumference,
    },
  }
}

const parseMeasurements = data => {
  const entry = getSafe(() => data.diary.diaryRegistration.edges.map(edge => edge.node)[0])

  if (entry) {
    return {
      ...JSON.parse(entry.data),
      ...{ registrationId: entry.registrationId }
    }
  }

  return {
    registrationId: null,
    weight: 0,
    neckCircumference: 0,
    upperArmCircumference: 0,
    chestCircumference: 0,
    waistCircumference: 0,
    hipCircumference: 0,
    thighCircumference: 0,
    weekRating: '',
  }
}

export { transformMeasurementsData }