import BookCarousel from "components/ui/BookCarousel";
import { Content, Media, Section } from "components/ui/Section";
import ImgStore from "components/ui/Section/ImgStore";
import moment from "moment";
import React from "react";
import Wrapper from "../../components/ui/Wrapper";
import MeasurementsSummary from "../WeekSummaryPage/MeasurementsSummary";
import "./styles.scss";

// className is required in components Media, Content, Section,  so to satisfy the compiler and keep the functionality the same we provide undefined for now...
// we should remove this once this component are migrated to TS

const DashboardWidthData = () => {
  const defaultYear = moment().format("YYYY");
  const defaultWeek = moment().format("W");

  return (
    <React.Fragment>
      <Wrapper>
        <div className="measurementsSummary">
          <MeasurementsSummary
            linkUrl={`/ukesammendrag/${defaultYear}/${defaultWeek}/`}
            linkTitle="Gå til Resultater"
          />
        </div>
      </Wrapper>
      <Wrapper>
        <BookCarousel />
      </Wrapper>
      <Section align="left" variant="secondary" className={undefined}>
        <Media cover className={undefined}>
          <ImgStore />
        </Media>

        <Content className={undefined}>
          <h2>Gode tilbud i nettbutikken</h2>
          <p>
            I nettbutikken vår får du kjøpt mat, snacks, kokebøker, vekter,
            pulsklokker og treningsutstyr.
          </p>
          <a href="/butikk/" data-button="secondary">
            Se gode tilbud
          </a>
        </Content>
      </Section>
    </React.Fragment>
  );
};

const Homepage = DashboardWidthData;
export default Homepage;
