import React, { Component } from 'react'
import Downshift from 'downshift'
import AddCustomFoodProductModal from 'components/ui/AddCustomFoodProductModal' //
import EntryFormModal from 'components/ui/EntryFormModal' //
import { graphql } from '@apollo/client/react/hoc'
import { SEARCH_FOOD } from 'queries'
import { getSafe, debounce } from 'utils'
import './styles.scss'

class IngredientSearch extends Component {
  state = {
    foodProductModalData: null,
    entryModalData: null,
    currValue: '',
  }

  openCustomFoodModal = (foodProductModalData) => {
    this.setState({ foodProductModalData })
  }

  openEntryModal = (entryModalData) => {
    this.setState({ entryModalData })
  }

  onStateChange = debounce(({ inputValue }) => {
    if (typeof inputValue !== 'undefined') {
      this.setState({ currValue: inputValue })
    }
  }, 250)

  render() {
    const { currValue } = this.state
    const { placeholder, onChange, selectedItem } = this.props

    return (
      <Downshift
        onStateChange={this.onStateChange}
        onChange={onChange}
        selectedItem={selectedItem}
        itemToString={i => (i == null ? '' : i.name)}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          inputValue,
          itemToString,
          selectedItem,
          highlightedIndex,
          isOpen,
        }) =>
          <div className="ingredientSearch">
            <input {...getInputProps({placeholder})} className="ingredientSearch__input" />
            {isOpen && inputValue.length >= 2 && currValue.length >= 2
              ? <IngredientSearchMenuWithData
                  {...{
                    inputValue: currValue,
                    selectedItem,
                    highlightedIndex,
                    getItemProps,
                  }}
                  openCustomFoodModal={this.openCustomFoodModal}
                />
              : null}
            {this.state.foodProductModalData &&
              <AddCustomFoodProductModal
                inputValue={this.state.foodProductModalData}
                close={() => this.setState({ foodProductModalData: null })}
                openEntryModal={this.openEntryModal}
              />
            }
            {this.state.entryModalData &&
              <EntryFormModal
                entry={this.state.entryModalData}
                close={() => this.setState({ entryModalData: null })}
              />
            }
          </div>}
      </Downshift>
    )
  }
}

const IngredientSearchMenu = ({
  data: { loading, error, request, loadMore },
  highlightedIndex,
  getItemProps,
  inputValue,
  openCustomFoodModal,
}) => {
  if (loading) {
    return <div style={{position: 'absolute', zIndex: 1}}>Loading...</div>
  }

  const results = getSafe(() => request.diary.diarySearch.edges.map(e => e.node)) || []
  const hasNextPage = getSafe(() => request.diary.diarySearch.pageInfo.hasNextPage, false)

  return (
    <div className="ingredientSearch__menu">
      <div style={{ maxHeight: '300px', overflowY: 'scroll' }} onScroll={(e) => {
        if (hasNextPage && (e.target.scrollTop >= (e.target.firstChild.clientHeight - 300))) {
          loadMore()
        }
      }}>
        <div>
        {results.map((item, index) =>
          <div className="ingredientSearch__item"
            {...getItemProps({
              item,
              index,
              key: item.objId,
              style: {
                backgroundColor: highlightedIndex === index ? '#f6f6f6' : 'white',
              },
            })}
          >
            {item.name}
            <span className="ingredientSearch__itemKcal">{item.kcal100g} kcal/100g</span>
          </div>
        )}
        {hasNextPage && <div className="ingredientSearch__loadingItems" />}
        </div>
      </div>
      {/*
      <button className="ingredientSearch__addButton" onClick={() => openCustomFoodModal(inputValue)}>
        <i className="icon-plus-circle"></i> Klikk her for å legge til matvaren
      </button>
      */}
    </div>
  )
}

const IngredientSearchMenuWithData = graphql(SEARCH_FOOD, {
  options: ({ inputValue: keyword, types = ['food_product', 'custom_food_product'], first = 10 }) => ({
    variables: { keyword, types, first }
  }),
  props({ data: { loading, error, request, fetchMore } }) {
    return {
      data: {
        loading,
        error,
        request,
        loadMore: () => {
          return fetchMore({
            variables: {
              cursor: request.diary.diarySearch.pageInfo.endCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.request.diary.diarySearch.edges
              const pageInfo = fetchMoreResult.request.diary.diarySearch.pageInfo
              return {
                request: {
                  __typename: previousResult.request.__typename,
                  diary: {
                    __typename: previousResult.request.diary.__typename,
                    diarySearch: {
                      __typename: previousResult.request.diary.diarySearch.__typename,
                      edges: [...previousResult.request.diary.diarySearch.edges, ...newEdges],
                      pageInfo,
                    }
                  }
                }
              }
            },
          })
        },
      },
    }
  }
})(IngredientSearchMenu)

export default IngredientSearch
