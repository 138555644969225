import { gql } from '@apollo/client';

const copyEntriesFromDate = gql`
  mutation CopyEntriesFromDate (
    $dateSrc: DateTime!,
    $dateDest: DateTime!,
    $category: String!,
    $tag: String
  ){
    diary {
      copyEntriesFromDate (
          dateSrc: $dateSrc,
          dateDest: $dateDest,
          category: $category,
          tag: $tag
      ) {
        status
      }
    }
  }
`;

export default copyEntriesFromDate;