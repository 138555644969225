import React from 'react'
import moment from 'moment'

export default function WidgetDates({ weekdates }) {
  const from = moment(weekdates[0]).format('DD.MM.YYYY')
  const to = moment(weekdates[6]).format('DD.MM.YYYY')

  return (
    <div className="widget-dates">
      <em>Data fra perioden <strong>{from}</strong> til <strong>{to}</strong>.</em>
    </div>
  )
}
