import { gql } from '@apollo/client';

const updateHealthProblems = gql`
  mutation UpdateHealthProblems (
    $data: JSONString,
  ){
    user {
      healthProblems(
        data: $data,
      ) {
        status
      }
    }
  }
`;

export default updateHealthProblems;
