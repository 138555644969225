import { gql } from '@apollo/client';

const getAboutMe = gql`
query User {
  user {
    userData {
      userId
      gender
      height
      initialWeight
      birthDate
    }
  }
}`;


export default getAboutMe;
