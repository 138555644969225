import { gql } from '@apollo/client'

export default gql`
  mutation ResetStartDate($date: Date, $restartOnboarding: Boolean) {
    user {
      resetStartDate(date: $date, restartOnboarding: $restartOnboarding) {
        status
      }
    }
  }
`
