import { GET_DIET_DATA } from 'queries'

const refetchQueries = () => {
  const date = window.location.pathname.match(/\d{4}-\d{2}-\d{2}/)[0]
  const dateRegistrations = `${date}T00:00:00`
  const dateCache = date

  return [
    {
      query: GET_DIET_DATA,
      variables: { dateRegistrations, dateCache },
    },
  ]
}

export { refetchQueries }