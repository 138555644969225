import { useQuery } from "@apollo/client";
import Loading from "components/ui/Loading";
import Membership from "components/ui/Membership";
import Wrapper from "components/ui/Wrapper";
import GET_MEMBERSHIPS from "queries/getMemberships";
import { Link } from "react-router-dom";
import s from "./MembershipPage.module.scss";

export default function MembershipPage({ location }) {
  const { data, loading, error, refetch } = useQuery(GET_MEMBERSHIPS);

  const memberships = data?.user?.memberships?.edges?.map((e) => e.node) || [];
  console.log(error);
  console.log(memberships);
  if (loading) return <Loading />;

  return (
    <Wrapper>
      <div className={s.memberships}>
        <div>
          <Link className={s.back} to={`/profil/`}>
            <span>Min profil</span>
          </Link>
        </div>
        <h1>Medlemskap</h1>
        <div className="memberships">
          {memberships.map((membership, index) => (
            <Membership
              key={index}
              membership={membership}
              refetch={refetch}
              listing={true}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
}
