import { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { ClockIcon, XMarkIcon } from "@heroicons/react/24/solid";
import s from "./styles/CourseDetails.module.scss";
import { toast } from "react-toastify";

const START_COURSE_MEMBERSHIP = gql`
  mutation StartCourseMembership(
    $membershipId: Int!
    $courseId: Int!
    $courseType: String
  ) {
    membership {
      startCourseMembership(
        membershipId: $membershipId
        courseId: $courseId
        courseType: $courseType
      ) {
        status
      }
    }
  }
`;

export default function CourseDetails({
  course,
  membershipId,
  close,
  setCourseCallback,
  refetch,
  dayName,
}) {
  const [courseId, setCourseId] = useState(course.id);
  const [startCourseMembership] = useMutation(START_COURSE_MEMBERSHIP);
  const [courseTime, setCourseTime] = useState(
    `${course?.startTime} - ${course?.endTime}`
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async () => {
    if (!membershipId && !setCourseCallback) return;
    if (loading) return;
    if (!membershipId) {
      setCourseCallback({
        courseId,
        nettcourseInstructorId: null,
        name: `${course?.name} med ${course?.instructor?.name}`,
        time: `${dayName} kl. ${courseTime}`,
        description: course?.description,
        place: course?.place,
        weekday: null,
      });
      close();
      return;
    }
    setLoading(true);
    const variables = { membershipId, courseId };
    try {
      const result = await startCourseMembership({ variables });
      if (result?.data?.membership?.startCourseMembership?.status !== "error") {
        await refetch();
        navigate(`/coach/${membershipId}?redirect_status=quickstart_started`);
      } else {
        toast.error(
          "Something went wrong, you cannot assigned to this course at the moment."
        );
      }

      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={s.overlay}></div>
      <div className={s.modal}>
        <button className={s.closeButton} onClick={close}>
          <XMarkIcon />
        </button>
        <div className={s.wrapper}>
          <div className={s.course}>
            <h2 className={s.courseName}>{course.name}</h2>
            <p className={s.courseLocation} data-type={course.type}>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${course.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {course.place}
              </a>
            </p>
            {course.otherTimes && course.otherTimes.length > 0 ? (
              <div className={s.courseTimes}>
                <label>
                  <input
                    type="radio"
                    name="gender"
                    value={course.id}
                    required
                    checked={courseId === course.id}
                    onChange={() => {
                      setCourseId(course.id);
                      setCourseTime(`${course.startTime} - ${course.endTime}`);
                    }}
                  />
                  <span>{`${course.startTime} - ${course.endTime}`}</span>
                </label>
                {course.otherTimes.map((otherTime) => (
                  <label key={otherTime.courseId}>
                    <input
                      type="radio"
                      name="gender"
                      value={otherTime.courseId}
                      required
                      checked={courseId === otherTime.courseId}
                      onChange={() => {
                        setCourseId(otherTime.courseId);
                        setCourseTime(
                          `${otherTime.startTime} - ${otherTime.endTime}`
                        );
                      }}
                    />
                    <span>{`${otherTime.startTime} - ${otherTime.endTime}`}</span>
                  </label>
                ))}
              </div>
            ) : (
              <p className={s.courseTime}>
                <ClockIcon />
                kl. {`${course.startTime} - ${course.endTime}`}
              </p>
            )}
            {(membershipId || setCourseCallback) && (
              <button onClick={submit} className={s.courseSubmit}>
                Bekreft kurs og fortsett
              </button>
            )}
          </div>
          <div className={s.intructorAndCourseType}>
            <div className={s.instructor}>
              <div className={s.instructorHeader}>
                <Image instructor={course.instructor} />
                <h3>{course.instructor.name}</h3>
                <p>{course.instructor.title}</p>
              </div>
              <div className={s.instructorBio}>
                {course.instructor.bioBullet1 && (
                  <ul>
                    <li>{course.instructor.bioBullet1}</li>
                    {course.instructor.bioBullet2 && (
                      <li>{course.instructor.bioBullet2}</li>
                    )}
                    {course.instructor.bioBullet3 && (
                      <li>{course.instructor.bioBullet3}</li>
                    )}
                  </ul>
                )}
                {course.instructor.shortDescription &&
                  course.instructor.shortDescription !== "None" && (
                    <p>{course.instructor.shortDescription}</p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Image({ instructor }) {
  const image =
    instructor.image ??
    "https://storage.googleapis.com/NEDiVEKT-app/instructors/no_image_user.png";

  return (
    <img
      src={`https://www.roede.com/cdn-cgi/image/w=500,h=375,q=60,f=auto/${image}`}
      alt={instructor.name}
    />
  );
}
