import React from 'react'
import styles from './Count.module.scss'
import Row from './Row'
import TextUnder from './TextUnder'
import isInRange from 'library/utils/isInRange'
import createRange from 'library/utils/createRange'
import R from 'res/R'

export default class Count extends React.Component<Props> {
  render() {
    const { value, recommended, minValue, maxValue, name, unit } = this.props
    const amountText = `${value} ${unit}`
    const recommendedText = `av anbefalt ${recommended} ${unit}`
    const isGood = isInRange(value, minValue, maxValue)

    const textUnder = (
      <TextUnder
        amountText={amountText}
        recommendedText={recommendedText}
        name={name}
      />
    )

    const content = (
      <div className={styles.container}>
        {this.makeCount()}
      </div>
    )

    return (
      <Row
        content={content}
        face={isGood ? R.images.faceHappy : R.images.faceSad}
        textUnder={textUnder}
        height={100}
      />
    )
  }

  makeCount = () => {
    const { filledImage, emptyImage } = this.props
    return createRange(1, this.props.recommended).map((number) => {
      const source = number <= this.props.value ? filledImage : emptyImage
      return (
        <img key={number} src={source} className={styles.image} alt="" />
      )
    })
  }
}
