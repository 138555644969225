import { gql } from '@apollo/client';

const getActivities = gql`
query User {
  user {
    userData {
      userId
      activityLevel
      exerciseHabits
    }
  }
}`;


export default getActivities;
