import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

const SubNavigation = ({ selectedOption, ...props }) => {
  const [isOpen, toggle] = useState(false);

  return (
    <nav className="subNavigation" data-isopen={isOpen}>
      <div>
        <button type="button" onClick={() => toggle(!isOpen)}>
          {selectedOption}
        </button>
        <ul>
          <li>
            <NavLink to="/min-mat/matvarer/" onClick={() => toggle(false)}>
              Matvarer
            </NavLink>
          </li>
          <li>
            <NavLink to="/min-mat/oppskrifter/" onClick={() => toggle(false)}>
              Oppskrifter
            </NavLink>
          </li>
          <li>
            <NavLink to="/min-mat/maltider/" onClick={() => toggle(false)}>
              Måltider
            </NavLink>
          </li>
          <li>
            <NavLink to="/min-mat/mine-dager/" onClick={() => toggle(false)}>
              Mine dager
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SubNavigation;
