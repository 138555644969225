import React, { useState } from 'react'
import { Confirm as ConfirmChange  } from './ConfirmChange'
import moment from 'moment'
import p from '../Popup.module.scss'
import s from './styles.module.scss'

interface Props {    
  startDate:string;
  startDateHistory:any;
  close?: () => void;
}

export const StartDateModal:React.FC<Props> = ({ startDate, startDateHistory, close }) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const dates = parseStartDateHistory(startDateHistory)
  const openConfirm = () => setIsConfirmVisible(true)
  const closeConfirm = () => setIsConfirmVisible(false)

  return (
    <div className={p.overlay}>
      <div className={p.modal}>
        <header className={p.modalHeader}>
          <h1 className={p.modalTitle}>Startdato</h1>
          <button className={p.modalClose} onClick={close} aria-label="Lukk">
            <i className="icon-close" />
          </button>
        </header>
        <div className={p.modalBody}>
          <div className={s.container}>
            <div className={s.content}>
              <p>Her kan du sette din nye startdato.</p>
              <p>
                Din nåværende startdato er:{' '}
                <strong>
                  {moment(startDate).format('DD.MM.YYYY')}
                </strong>{' '}
                og din nye startdato vil være:{' '}
                <strong>{moment().format('DD.MM.YYYY')}</strong>.
              </p>
            </div>
            <button type="button" className={s.button} onClick={openConfirm}>
              Start på nytt <i className="icon-reload" />
            </button>
            {dates.length > 0 && (
              <div className={s.history}>
                <h3>Historie</h3>
                <ul>
                  {dates.map((item) => (
                    <li key={item.date}>
                      {moment(item.date).format('DD.MM.YYYY')}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <ConfirmChange isVisible={isConfirmVisible} close={closeConfirm} />
        </div>
      </div>
    </div>
  )
}

function parseStartDateHistory(startDateHistory) {
  if (!startDateHistory) {
    return []
  }

  const history = JSON.parse(startDateHistory)

  return Object.entries(history)
    .map(([date, data]) => ({ date, data }))
    .sort((a, b) => b.data['timestamp'].localeCompare(a.data['timestamp']))
}
