// @flow

import React from 'react'
import styles from './Flag.module.scss'
import Row from './Row'
import TextUnder from './TextUnder'
import R from 'res/R'

export default class Flag extends React.Component<Props> {
  render() {
    const { value, name } = this.props
    const label = value ? 'yes' : 'no'
    const amountText = R.strings.foodlog.result.vitamin[label]

    const textUnder = (
      <TextUnder
        amountText={amountText}
        recommendedText=""
        name={name}
      />
    )

    const content = (
      <div className={styles.container}>
        {this.makeFlag()}
      </div>
    )

    return (
      <Row
        content={content}
        face={value ? R.images.faceHappy : R.images.faceSad}
        textUnder={textUnder}
        height={100}
      />
    )
  }

  makeFlag = () => {
    const { value, filledImage, emptyImage } = this.props
    const source = (value) ? filledImage : emptyImage

    return <img src={source} className={styles.image} alt="" />
  }
}
