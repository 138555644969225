import React from 'react'
import s from './styles/Focus.module.scss'

export default function Focus({ report }) {
  const feedback = JSON.parse(report.feedback)
  const files = feedback?.files ? feedback?.files : []
  if (feedback) {
    return (
      <div className={s.container}>
        <h2> {report.user.firstName}, takk for følge så langt!</h2>
        <p>
            Dette er siste bookede veiledning fra meg.
        </p>
        <p>

        Jeg håper du er godt rustet for å holde tak i dine nye vaner, og her er de <b>3 siste supertipsene</b> jeg tenker du har nytte av i tiden som kommer:
        </p>
        <div className={s.focus}>
          <ol>
            <li>{feedback.focus_1}</li>
            <li>{feedback.focus_2}</li>
            <li>{feedback.focus_3}</li>
          </ol>
        </div>
        <p>Etter avsluttet nettkurs vil du fortsatt ha tilgang til å gå tilbake til tidligere ukers veiledninger og tema. Oppretthold kontakten med meg ved å godkjenne videre kommunikasjon under <a href="https://www.roede.com/u/profil/">Min profil</a>. </p>

        <p>Jeg anbefaler deg å opprettholde et videre <a href="https://www.roede.com/gull/">gullmedlemskap</a> i NEDiVEKT-appen for å holde fast ved nye vaner og få inspirasjon fra Roede-kjøkkenet.</p>
        {files.length > 0 && (
          <div className={s.files}>
            <p>Disse verktøyene kan hjelpe deg femover:</p>
            <ul>
              {files.map((file) => (
                <li key={file.id}>
                  Les om <a href={file.slug_url}>{file.filename}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return null
}
