import { gql } from '@apollo/client'

export default gql`
  mutation SendResetCode($email: String!) {
    registration {
      resetCode(email: $email) {
        status
        message
      }
    }
  }
`
