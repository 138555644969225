import React from 'react'
import DeleteDailymenu from './DeleteDailyMenu'
import { withAuth } from 'components/container/withAuth'
import { GET_DAILY_MENUS } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { formatDate } from 'utils'

const DailyMenusListWithData = ({
  auth: { user },
  data: { loading, request: {
    diary: {
      dailyMenus: {
        edges: dailyMenusEdges = []
      } = {}
    } = {}
  } = {} },
}) => {
  const dailyMenus = dailyMenusEdges
    .map(edge => edge.node)

  if (loading) {
    return <div className="loading" />
  }

  if (dailyMenus.length === 0) {
    return (
      <div className="emptyResultset">
        <i className="icon-info"></i> Ingen standard-dager
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <th>Standard-dager</th>
          <th className="date">Dato</th>
          <th className="delete">&nbsp;</th>
        </tr>
        {dailyMenus.map(dailyMenu => (
          <DeleteDailymenu
            key={dailyMenu.objectId}
            objectId={dailyMenu.objectId}
            render={({ deleting, deleteDailyMenu }) => (
              <tr data-deleting={deleting}>
                <td>{dailyMenu.name}</td>
                <td>{formatDate(dailyMenu.created)}</td>
                <td>
                  <button type="button" aria-label="Slett" onClick={deleteDailyMenu}>
                    <i className="icon-cancel-circle" />
                  </button>
                </td>
              </tr>
            )}
          />
        ))}
      </tbody>
    </table>
  )
}

const GetDailyMenusWrapper = graphql(GET_DAILY_MENUS)(DailyMenusListWithData)

export default withAuth(GetDailyMenusWrapper)
