import React from 'react';
import { useMutation } from '@apollo/client'
import formStyles from 'assets/styles/Form.module.scss'
import { DISCONNECT_TO_POLAR } from 'queries'
import { GET_POLAR_DATA } from 'queries';
import {PopupButton} from 'components/ui/Popup'

interface Props {    
    close?: () => void;
}

export const PolarCancelationUI:React.FC<Props> = ({  close, ...props}) => {

    const [save, {loading}] = useMutation(DISCONNECT_TO_POLAR, { 
        onCompleted() {
            close();
        }
    });

    const submit = async (event) => {
        event.preventDefault()
        const variables = {}    
        try {
            await save({variables, refetchQueries: [GET_POLAR_DATA]})
        } catch (error) {
            alert(error)
        }
    }

    return (<form  className={formStyles.form} onSubmit={submit}>
        <button disabled={loading} type="submit">Avbryt kobling</button>
    </form>)
}


export default function PolarCancelation({...props}) {
    return(
      <PopupButton  title="Polar koble sammen" show={props.show}>
        <PolarCancelationUI {...props}  />
      </PopupButton>
    )
}
