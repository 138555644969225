import React, { Component, Fragment } from 'react';
import EntryFormModal from '../EntryFormModal';

class EditEntry extends Component {
  state = {
    entry: null
  }

  openEntryModal = (event, dietEntry) => {
    event.preventDefault()

    const entry = {
      ...dietEntry,
      ...{ date_time: dietEntry.dateTime },
    }

    this.setState({ entry })
  }

  closeModal = () => {
    this.setState({ entry: null })
    if (this.props.callback) { this.props.callback() }
  }

  render() {
    const { entry, children } = this.props;

    return (
      <Fragment>
        <em onClick={e => this.openEntryModal(e, entry)}>{children}</em>
        {this.state.entry &&
          <EntryFormModal
            entry={this.state.entry}
            close={this.closeModal}
          />
        }
      </Fragment>
    )
  }
}

export default EditEntry;
