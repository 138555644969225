import React from 'react'
import SubNavigation from 'shared/SubNavigation'
import RecipesList from './RecipesList'
import FavoriteRecipesList from './FavoriteRecipesList'

const UserRecipesPage = () => (
  <div className="myFoodPage">
    <h1>Min mat</h1>
    <SubNavigation selectedOption="Oppskrifter" />
    <div className="container">
      <div className="dataTables">
        <RecipesList />
        <FavoriteRecipesList />
      </div>
      <div className="textContent">
        <h2>Egne oppskrifter</h2>
        <p>Du kan legge inn egne oppskrifter slik at de blir tilgjengelig i din dagbok. Etter registrering av oppskriften i dagboken, kan du klikke på navnet på oppskriften for å se næringsberegning. Næringsinnholdet vil selvfølgelig også inkluderes i dagens oversikt over energiinntak.</p>
        <p>For å lage en ny oppskrift klikker du Lag ny oppskrift. Deretter fyller du inn feltene 1 til 5:</p>
        <ol>
            <li><strong>Om oppskriften:</strong> Lag en kort beskrivelse til oppskriften og legg inn antall porsjoner den er beregnet til. Her kan du også krysse av for om oppskriften er hjertevennlig, det vil si at 2/3 av fettinnholdet er umettet fett.</li>
            <li><strong>Ingredienser:</strong> Søk opp ingredienser på samme måte som når du registrerer det du har spist og velg riktig mengde.</li>
            <li><strong>Fremgangsmåte:</strong> Skriv inn fremgangsmåte, eventuelt steketemperatur og steketid.</li>
            <li><strong>Velg kategori:</strong> Kryss av for hva slags type oppskrift det er du legger inn.</li>
            <li><strong>Oppskriftsbilde:</strong> Legg gjerne inn et bilde av oppskriften dersom du har det.</li>
        </ol>
        <h2>Favorittoppskrifter</h2>
        <p>Her kan du slå av favorittmerkingen på oppskrifter som du tidligere har merket som favoritt.</p>
      </div>
    </div>
  </div>
)

export default UserRecipesPage
