import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { GET_MESSAGES, DISMISS_MESSAGE } from 'queries'
import { withAuth } from 'components/container/withAuth'
import cloneDeep from 'lodash/cloneDeep'
import './styles.scss'

const transformData = user => {
  let messages = []

  try {
    messages = user.messages.edges.map(edge => ({
      id: edge.node.messageId,
      title: edge.node.title,
      body: edge.node.messageBody,
    }))
  } catch(e) {}

  return messages
}

const dismissOptimisticResponse = {
  messageHandler: {
    userDismissedMessages: {
      status: 'success',
      __typename: 'UserDismissedMessages',
    },
    __typename: 'MessageHandlerMutation',
  },
  __typename: 'Mutation',
}

const updateStore = (proxy, variables, messageId) => {
  const cache = proxy.readQuery({ query: GET_MESSAGES, variables })
  const data = cloneDeep(cache)
  const messageEdges = data.user.messages.edges
  const messages = messageEdges.filter(edge => edge.node.messageId !== messageId)
  data.user.messages.edges = messages
  proxy.writeQuery({ query: GET_MESSAGES, variables, data })
}

class SystemMessagesUI extends React.Component {
  dismiss = messageId => {
    const { data: { variables }, mutate } = this.props

    mutate({
      variables: { messageId },
      optimisticResponse: dismissOptimisticResponse,
      update: proxy => updateStore(proxy, variables, messageId),
    })
  }

  render() {
    const { data: { loading, user } } = this.props

    if (loading) {
      return null
    }

    const messages = transformData(user)

    if (!messages.length) {
      return null
    }

    return (
      <ul className="systemMessages">
        {messages.map(message => (
          <li key={message.id} className="systemMessage">
            <h2 className="systemMessage-title">{message.title}</h2>
            <div className="systemMessage-content" dangerouslySetInnerHTML={{ __html: message.body }} />
            <button onClick={() => this.dismiss(message.id)} aria-label="Dismiss" className="systemMessage-dismiss">
              <i className="icon-close" />
            </button>
          </li>
        ))}
      </ul>
    )
  }
}

const DismissMessageWrapper = graphql(DISMISS_MESSAGE)(SystemMessagesUI)

const GetMessagesWrapper = graphql(GET_MESSAGES, {
  options: ({ auth: { user }, slotId }) => ({
    variables: { slotId: slotId }
  })
})(DismissMessageWrapper)

const SystemMessagesWithData = withAuth(GetMessagesWrapper)

const SystemMessagesWithAuth = ({ auth: { user }, slotId }) => {
  return <SystemMessagesWithData slotId={slotId}/>
}

const SystemMessages = withAuth(SystemMessagesWithAuth)

export default SystemMessages
