import React, { Fragment } from 'react'
import CustomFoodProductModal from 'shared/CustomFoodProductModal'

class EditFoodProduct extends React.Component {
  state = {
    foodProduct: null,
  }

  editFoodProduct = e => {
    e.preventDefault()

    const foodProduct = formatCustomFoodProduct(this.props.foodProduct)
    this.setState({ foodProduct })
  }

  closeModal = () => this.setState({ foodProduct: null })

  render() {
    return (
      <Fragment>
        {this.state.foodProduct &&
          <CustomFoodProductModal
            foodProduct={this.state.foodProduct}
            close={this.closeModal}
          />
        }
        {this.props.render({
          editFoodProduct: this.editFoodProduct,
        })}
      </Fragment>
    )
  }
}

const formatCustomFoodProduct = foodProduct => {
  const data = JSON.parse(foodProduct.data)

  return {
    objectId: foodProduct.objectId,
    name: foodProduct.name,
    kcalPer100Grams: data.nutrition.kcalPer100Grams,
    gramsOfProteinPer100Grams: data.nutrition.gramsOfProteinPer100Grams,
    gramsOfCarbohydratePer100Grams: data.nutrition.gramsOfCarbohydratePer100Grams,
    gramsOfSugarPer100Grams: data.nutrition.gramsOfSugarPer100Grams,
    gramsOfFatPer100Grams: data.nutrition.gramsOfFatPer100Grams,
    gramsOfSaturatedFatPer100Grams: data.nutrition.gramsOfSaturatedFatPer100Grams,
    gramsOfPolyunsaturatedFatPer100Grams: data.nutrition.gramsOfPolyunsaturatedFatPer100Grams,
    gramsOfMonounsaturatedFatPer100Grams: data.nutrition.gramsOfMonounsaturatedFatPer100Grams,
    gramsOfFiberPer100Grams: data.nutrition.gramsOfFiberPer100Grams,
    gramsOfSaltPer100Grams: data.nutrition.gramsOfSaltPer100Grams,
    milligramsOfCalciumPer100Grams: data.nutrition.milligramsOfCalciumPer100Grams,
    gramsOfVegetablesPer100Grams: data.nutrition.gramsOfVegetablesPer100Grams,
    gramsOfFruitsPer100Grams: data.nutrition.gramsOfFruitsPer100Grams,
    gramsOfMilkPer100Grams: data.nutrition.gramsOfMilkPer100Grams,
    allergens: data.allergens,
    isHeartFriendly: data.isHeartFriendly,
    adGrRecommendation: data.adGrRecommendation,
  }
}

export default EditFoodProduct