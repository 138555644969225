import { gql } from '@apollo/client';

const getDiaryEntries = gql`
  query DiaryEntries ($date_from: DateTime, $date_to: DateTime, $category: String) {
    request {
      diary {
        diaryRegistration (dateFrom: $date_from, dateTo: $date_to, category: $category) {
          edges {
            node {
              registrationId
              dateTime
              lastModified
              category
              tag
              description
              calories
              data
            }
          }
        }
      }
    }
  }
`;

export default getDiaryEntries;