import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY, GET_DIARY_ENTRIES } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'
import moment from 'moment'
import withRouter from '../../../../../../../../../utils/withRouter'
import { formatQuantity } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import styles from './UnitScreen.module.scss'

const handleFormatQuantity = event => {
  event.target.value = formatQuantity(event.target.value)
}

const UnitScreenContainer = ({ entry, cancel, close, mutate, match }) => {
  let quantityRef
  let unitRef
  let entryData = entry.data

  const setFrequentValues = (event, obj) => {
    event.preventDefault();
    event.stopPropagation();

    quantityRef.value = obj.quantity;
    unitRef.value = obj.name;
    close();
    saveEntry(event);
  }

  const saveEntry = event => {
    event.preventDefault()

    const unitFactor = (quantity, unit, units) => {
      const { gramsPerUnit } = units.find(u => u.name.trim() === unit.trim())
      return gramsPerUnit / 100 * quantity
    }

    const quantityRefValue = parseFloat(quantityRef.value.replace(',', '.'))
    const selectedUnit = entryData.units.find(unit => unit.name.trim() === unitRef.value)
    const calories = Math.round((selectedUnit.gramsPerUnit / 100) * entryData.nutrition.kcalPer100Grams * quantityRefValue)
    const description = `${quantityRefValue} ${unitRef.value} ${entryData.name} ${calories} Kcal`
    const factor = unitFactor(quantityRefValue, unitRef.value, entryData.units)

    // remove frequentUnits from data
    delete entry.data.frequentUnits

    // The rest of the nutritional values, such as protein, carbs or fiber are
    // calculaed and appended to the registration data on the server side
    const calculatedNutrients = {
      alcohol: 'gramsOfAlcoholPer100Grams',
      nuts: 'gramsOfNutsPer100Grams'
    }

    // Append calculated nutrients based on the selected amount/unit to the
    // registration data
    Object.keys(calculatedNutrients).forEach((nutrient) => {
      if (calculatedNutrients[nutrient] in entryData.nutrition) {
        const value = entryData.nutrition[calculatedNutrients[nutrient]]
        entryData[nutrient] = Math.round(factor * value * 100) / 100
      }
    })

    const variables = {
      ...entry,
      ...{
        calories,
        description,
        data: JSON.stringify({
          ...entryData,
          ...{
            quantity: quantityRefValue,
            unit: unitRef.value,
          },
          'source': 'web',
        }),
      }
    }

    const optimisticResponse = {
      diary: {
        diaryRegister: {
          status: 'success',
          registrationId: entry.registrationId || Math.round(Math.random() * -1000000),
          __typename: 'DiaryRegister',
        },
        __typename: 'DiaryMutation',
      },
      __typename: 'Mutation',
    }

    const update = (proxy, { data: { diary } }) => {
      const registrationId = diary.diaryRegister.registrationId
      const dateTime = moment(match.params.date).format('YYYY-MM-DDT00:00:00')
      const lastModified = moment(match.params.date).format('YYYY-MM-DDTHH:mm:ss')

      const cache = proxy.readQuery({
        query: GET_DIARY_ENTRIES,
        variables: { date_from: dateTime, category: 'food' },
      })

      const data = cloneDeep(cache)

      const edge = {
        node: {
          ...variables,
          registrationId,
          dateTime,
          lastModified,
          __typename: 'DiaryRegistration'
        },
        __typename: 'DiaryRegistrationEdge',
      }

      const index = data.request.diary.diaryRegistration.edges.findIndex(e => (
        e.node.registrationId === edge.node.registrationId
      ))

      if (index === -1) {
        data.request.diary.diaryRegistration.edges.push(edge)
      } else {
        data.request.diary.diaryRegistration.edges[index] = edge
      }

      proxy.writeQuery({
        data,
        query: GET_DIARY_ENTRIES,
        variables: { date_from: dateTime, category: 'food' },
      })
    }

    close()

    mutate({
      variables,
      refetchQueries: refetchQueries(),
      optimisticResponse,
      update,
    })
  }

  const { name, quantity, unit, units } = entryData
  const {
    frequentUnits = []
  } = entry.data;

  return (
    <form className={styles.form} onSubmit={saveEntry}>
      <h2 className={styles.title}>{name}</h2>
      <div className={styles.row}>
        <div className={styles.field} data-size="1/3">
          <label className={styles.fieldLabel} htmlFor="entryQuantity">Antall</label>
          <input className={styles.fieldInput} data-type="number" id="entryQuantity" type="text" onChange={handleFormatQuantity} name="quantity" defaultValue={quantity && quantity.toString().replace('.', ',')} autoFocus ref={input => quantityRef = input } autoComplete="off" required />
        </div>
        <div className={styles.field}>
          <label className={styles.fieldLabel} htmlFor="entryAmount">Type måle-enhet</label>
          <select className={styles.fieldInput} data-type="select" id="entryAmount" defaultValue={unit} ref={input => unitRef = input}>
            {units.map((unit) => (
              <option value={unit.id} key={unit.name}>{unit.name}</option>
            ))}
          </select>
        </div>
      </div>
      <footer className={styles.buttons}>
        <div className={styles.unitButtons}>
          {frequentUnits.map((fqu, key) => (
            <button key={key} className={styles.button} type="submit" onClick={(e) => setFrequentValues(e, fqu)}>{`${fqu.quantity} ${fqu.name}`}</button>
          ))}
        </div>
        <div className={styles.actionButtons}>
          <button className={styles.button} type="button" onClick={cancel}>Avbryt</button>
          <button className={styles.button} type="submit">Lagre</button>
        </div>
      </footer>
    </form>
  )
}

const UnitScreen = withRouter(graphql(ADD_ENTRY)(UnitScreenContainer))

export default UnitScreen
