import { gql } from '@apollo/client';

const saveCustomRecipe = gql`
  mutation saveCustomRecipe (
    $objectId: Int,
    $name: String!,
    $kcal100g: Int,
    $kcalPortion: Int,
    $data: JSONString,
    $picture: Upload
  ) {
    diary {
      saveCustomRecipe (
        objectId: $objectId,
        name: $name,
        kcal100g: $kcal100g,
        kcalPortion: $kcalPortion,
        data: $data,
        picture: $picture
      ) {
        status
        customRecipe
      }
    }
  }
`;

export default saveCustomRecipe;