import { gql } from "@apollo/client";

export default gql`
  mutation CheckoutGold(
    $membershipType: String!
    $cancelUrl: String!
    $successUrl: String!
    $membershipTypeId: Int
    $paymentSource: String
    $optionId: Int
    $stripePrice: String
    $coupon: String
    $weekday: Int
    $frequency: Int
    $renewMembershipId: Int
    $cid: String
    $courseId: Int
    $nettcourseInstructorId: Int
  ) {
    checkout(
      membershipType: $membershipType
      membershipTypeId: $membershipTypeId
      cancelUrl: $cancelUrl
      successUrl: $successUrl
      paymentSource: $paymentSource
      optionId: $optionId
      stripePrice: $stripePrice
      coupon: $coupon
      weekday: $weekday
      frequency: $frequency
      renewMembershipId: $renewMembershipId
      courseId: $courseId
      nettcourseInstructorId: $nettcourseInstructorId
      cid: $cid
    ) {
      status
      message
      stripeSessionId
      klarnaRedirectUrl
      vippsUrl
    }
  }
`;
// membership_type = graphene.String(required=True)
// membership_type_id = graphene.Int(required=True)
