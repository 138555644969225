import React from 'react'
import Panel from 'shared/Panel'
import { formatAmount } from 'utils'
import './styles.scss'

const WeekSumary = ({
  loading, user,
  data: { weight, waistCircumference },
}) => (
  <Panel loading={loading}>
    <Panel.Header>
      <Panel.Title>Min vekt og mål</Panel.Title>
    </Panel.Header>
    <Panel.Body>
      <div className="summaryBoxes">
        <dl className="summaryBox">
          <dt>Vekt nå</dt>
            <dd><strong>{formatAmount(weight.current)}</strong> kg</dd>
          <dt>Endring i vekt siden forrige uke</dt>
            <dd><strong>{formatAmount(weight.changeSinceLastWeek)}</strong> kg</dd>
          <dt>Endring i vekt siden Startdato</dt>
            <dd><strong>{formatAmount(weight.changeSinceStartDate)}</strong> kg</dd>
        </dl>
        {user.hasPermissions &&
        <dl className="summaryBox">
          <dt>Midjemål nå</dt>
            <dd><strong>{formatAmount(waistCircumference.current)}</strong> cm</dd>
          <dt>Endring i midjemål siden forrige uke</dt>
            <dd><strong>{formatAmount(waistCircumference.changeSinceLastWeek)}</strong> cm</dd>
          <dt>Endring i midjemål siden Startdato</dt>
            <dd><strong>{formatAmount(waistCircumference.changeSinceStartDate)}</strong> cm</dd>
        </dl>
        }
        {!user.hasPermissions &&
        <div className="summaryBox goldMemberAd">
          <h3>Få mer ut av medlemskapet!</h3>
          <p>Få din daglige kostholdscore, energifordeling, treningsdata og flere motivasjonsverktøy.</p>
          <a href="/roedeklubben/">Bli GULL-MEDLEM her!</a>
        </div>
        }
      </div>
    </Panel.Body>
  </Panel>
)

export default WeekSumary