import "./styles.scss";

import { useQuery } from "@apollo/client";
import moment from "moment";

import Loading from "../../components/ui/Loading";
import Wrapper from "../../components/ui/Wrapper";

import AboutMe from "../../components/ui/Popup/AboutMe";
import Activities from "../../components/ui/Popup/Activities";
import Allergies from "../../components/ui/Popup/Allergies";
import ChangePassword from "../../components/ui/Popup/ChangePassword";
import Gdpr from "../../components/ui/Popup/Gdpr";
import Goals from "../../components/ui/Popup/Goals";
import KcalPerDay from "../../components/ui/Popup/KcalPerDay";
import NewsLetter from "../../components/ui/Popup/NewsLetter";
import { StartDate } from "../../components/ui/Popup/StartDate";
import UpdateContact from "../../components/ui/Popup/UpdateContact";
import WeightGoal from "../../components/ui/Popup/WeightGoal";
// import BuyGold from '../../components/ui/BuyGold';
// import Motivation from '../../components/ui/Popup/Motivation';
import { Link } from "react-router-dom";
import UnpaidInvoiceNotice from "../../components/ui/UnpaidInvoiceNotice";

import { GET_USER_DATA } from "queries";
import PolarProfile from "../../components/ui/PolarProfile";
import { profileSettings } from "../../conf";

const ProfilePage = ({ props }) => {
  const { data, loading, refetch } = useQuery(GET_USER_DATA);
  const userData = data?.user?.userData;

  if (loading) return <Loading />;

  const getMotivation = () => {
    if (userData.motivation in profileSettings.motivation) {
      const motivation = profileSettings.motivation[userData.motivation];
      return motivation.title + " - " + motivation.text;
    } else {
      return "Hva er ditt mål?";
    }
  };

  const getAge = () => {
    if (userData.birthDate) {
      return moment().diff(userData.birthDate, "years") + " år";
    } else {
      return "n/a";
    }
  };

  const getGender = (gender) => {
    if (gender === "male") return "Mann";
    if (gender === "female") return "Kvinne";
    return "n/a";
  };

  const showChallenges = () => {
    const challenges =
      userData &&
      userData.healthProblems &&
      JSON.parse(userData.healthProblems);
    const arr = [
      "hasDiabetes",
      "hasHypertension",
      "hasHighCholesterol",
      "hasHypothyroidism",
      "isPregnant",
      "isBreastFeeding",
    ];
    if (challenges) {
      const res = arr
        .filter((key) => challenges[key])
        .map((key) => profileSettings.health[key]);
      return res.length > 0 ? res.join(", ") : "Har du noen \n Helse?";
    } else {
      return "Har du noen \n Helse?";
    }
  };
  const showAllergies = () => {
    const allergies = JSON.parse(userData?.allergiesList);
    const arr = [
      "isAllergicToEgg",
      "isAllergicToFish",
      "isAllergicToGluten",
      "isAllergicToMilk",
      "isAllergicToLactose",
      "isAllergicToNut",
      "isAllergicToShellFish",
    ];

    if (allergies) {
      const choices = profileSettings.allergy.choices;
      const res = arr
        .filter((key) => allergies[key])
        .map((key) => choices[key]);
      return res.length > 0 ? res.join(", ") : profileSettings.allergy.title;
    } else {
      return profileSettings.allergy.title;
    }
  };

  const getActivities = (key) => {
    const mapper = {
      "1.3": "low",
      "1.4": "moderate",
      "1.5": "high",
    };

    return key in mapper
      ? profileSettings.active.activities[mapper[key]]
      : "n/a";
  };

  const getExerciseHabits = (key) => {
    const mapper = {
      "2": "veryLow",
      "5": "low",
      "10": "moderate",
      "15": "high",
      "20": "veryHigh",
    };

    return key in mapper
      ? profileSettings.active.exerciseHabits[mapper[key]]
      : "n/a";
  };

  const round5 = (x) => {
    return x % 5 >= 2.5 ? (x / 5) * 5 + 5 : (x / 5) * 5;
  };

  return (
    <Wrapper>
      <div className="ProfileWrapper">
        <UnpaidInvoiceNotice />
        <div className="Wrapper">
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Kontaktinformasjon</div>
              <div className="ItemAction">
                <UpdateContact
                  email={userData.email ? userData.email : ""}
                  name={userData.name ? userData.name : ""}
                  phone={userData.cellPhone ? userData.cellPhone : ""}
                  address={userData.address ? userData.address : ""}
                  zipCode={userData.zipCode ? userData.zipCode : ""}
                  city={userData.city ? userData.city : ""}
                  refetch={refetch}
                />
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                <div className="LabelWrapper">
                  {userData.name ? userData.name : "n/a"}
                </div>
              </div>

              <div className="TextWrapper">
                <div className="LabelWrapper">{userData.email}</div>
              </div>
              <div className="TextWrapper">
                <div className="LabelWrapper">
                  {userData.cellPhone ? userData.cellPhone : "n/a"}
                </div>
              </div>
              <div className="TextWrapper">
                <div className="LabelWrapper">
                  {userData.address ? userData.address : "n/a"}
                </div>
                <div className="LabelWrapper">
                  {userData.zipCode ? userData.zipCode : "n/a"}{" "}
                  {userData.city ? userData.city : "n/a"}
                </div>
              </div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Passord</div>
              <div className="ItemAction">
                <ChangePassword />
              </div>
            </div>
            <div className="ItemBody">
              <div className="TableItem">
                <div className="ColItem ItemLabel"></div>
              </div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Om meg</div>
              <div className="ItemAction">
                <AboutMe
                  gender={userData.gender}
                  height={userData.height}
                  weight={userData.initialWeight}
                  birthDate={userData.birthDate}
                  refetch={refetch}
                ></AboutMe>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                <span className="ValueWrapper">
                  {getGender(userData.gender)}, {getAge()}
                </span>
              </div>
              <div className="TextWrapper">
                <span className="LabelWrapper">Høyde</span>{" "}
                <span className="ValueWrapper">
                  {userData.height ? userData.height + " cm" : "n/a"}
                </span>
              </div>
              <div className="TextWrapper">
                <span className="LabelWrapper">Startvekt</span>{" "}
                <span className="ValueWrapper">
                  {userData.initialWeight
                    ? userData.initialWeight + " kg"
                    : "n/a"}
                </span>
              </div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Mål</div>
              <div className="ItemAction">
                <Goals
                  motivation={userData?.motivation}
                  refetch={refetch}
                ></Goals>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">{getMotivation()}</div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Ønsket vekt</div>
              <div className="ItemAction">
                <WeightGoal
                  weightGoal={userData.weightGoal}
                  height={userData.height}
                  weight={userData.weight}
                ></WeightGoal>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                {`${
                  userData.weightGoal ? userData.weightGoal + "kg" : "Ikke satt"
                }`}
              </div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Aktivitetsnivå</div>
              <div className="ItemAction">
                <Activities
                  activityLevel={userData.activityLevel}
                  exerciseHabits={userData.exerciseHabits}
                ></Activities>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                <span className="LabelWrapper">
                  {profileSettings.active.activityTitle} -{" "}
                </span>{" "}
                <span className="ValueWrapper">
                  {getActivities(userData.activityLevel)}
                </span>
              </div>
              <div className="TextWrapper">
                <span className="LabelWrapper">
                  {profileSettings.active.exerciseHabitsTitle}:{" "}
                </span>{" "}
                <span className="ValueWrapper">
                  {getExerciseHabits(userData.exerciseHabits)}
                </span>
              </div>
            </div>
            <div className="ItemBody">
              <h4>Energiberegning</h4>
              <div className="TextWrapper">
                <div className="checkItem">
                  <div>Hvilestoffskifte:</div>
                  <div className="energyCalculationValue">
                    <strong>{round5(userData.restingMetabolism)}</strong> kcal
                  </div>
                </div>
                <div className="checkItem">
                  <div>Daglig energibehov:</div>
                  <div className="energyCalculationValue">
                    <strong>{round5(userData.trainingMetabolism)}</strong> kcal
                  </div>
                </div>
                <div className="checkItem">
                  <div>Anbefalt energiinntak:</div>
                  <div className="energyCalculationValue">
                    <strong>
                      {round5(userData.calculatedRecommendedKcalIn)}
                    </strong>{" "}
                    kcal
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ItemWrapper">
            <div className="ItemHeader">
              <div className="ItemTitle">Min Meny (kcal per dag)</div>
              <div className="ItemAction">
                <KcalPerDay
                  recommendedKcalIn={userData.recommendedKcalIn}
                ></KcalPerDay>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                {userData.recommendedKcalIn} kcal
              </div>
            </div>
          </div>

          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Matallergier</div>
              <div className="ItemAction">
                <Allergies
                  allergies={
                    userData &&
                    userData.allergiesList &&
                    JSON.parse(userData.allergiesList)
                  }
                />
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">{showAllergies()}</div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Medlemskap og Betaling</div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                <Link to={`/medlemskap/`}>Alle Medlemskap</Link>
              </div>
              <div className="TextWrapper">
                <Link to={`/faktura/`}>Se Fakturaer</Link>
              </div>
              <h4>Standard Betalingsmåte</h4>
              <div className="TextWrapper">
                {userData.defaultPaymentMethod}{" "}
                <a
                  href={`https://api.roede.com/api/payment/update?next_url=${window.location}`}
                >
                  oppdater
                </a>
              </div>
            </div>
          </div>
          <PolarProfile />
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Samtykker</div>
              <div className="ItemAction">
                <NewsLetter
                  newsletterDinnerTips={userData.newsletterDinnerTips}
                  newsletterWeekly={userData.newsletterWeekly}
                  newsletterInformation={userData.newsletterInformation}
                  newsletterSms={userData?.newsletterSms}
                ></NewsLetter>
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                <div>
                  {userData.newsletterDinnerTips ? (
                    <span>
                      <i className="icon-check-circle-2"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="icon-cancel-circle"></i>
                    </span>
                  )}{" "}
                  <span>Dagens middagstips</span>
                </div>
                <div>
                  {userData.newsletterWeekly ? (
                    <span>
                      <i className="icon-check-circle-2"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="icon-cancel-circle"></i>
                    </span>
                  )}{" "}
                  <span>Ukentlig Nyhetsbrev (lettere helg)</span>
                </div>
                <div>
                  {userData.newsletterInformation ? (
                    <span>
                      <i className="icon-check-circle-2"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="icon-cancel-circle"></i>
                    </span>
                  )}{" "}
                  <span>
                    Informasjon og tilbud fra Roede-veileder (ca 4 ganger pr
                    mnd)
                  </span>
                </div>
                <div>
                  {userData.newsletterSms ? (
                    <span>
                      <i className="icon-check-circle-2"></i>
                    </span>
                  ) : (
                    <span>
                      <i className="icon-cancel-circle"></i>
                    </span>
                  )}{" "}
                  <span>Tilbud på sms</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Startdato</div>
              <div className="ItemAction">
                <StartDate
                  startDate={userData?.startDate}
                  startDateHistory={userData?.startDateHistory}
                />
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">
                {moment(userData.startDate).format("DD.MM.YYYY")}
              </div>
            </div>
          </div>

          <div className="ItemWrapper ">
            <div className="ItemHeader">
              <div className="ItemTitle">Brukerdata og konto</div>
              <div className="ItemAction">
                <Gdpr />
              </div>
            </div>
            <div className="ItemBody">
              <div className="TextWrapper">Last ned data og slett konto.</div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProfilePage;
