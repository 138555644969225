import React, { Fragment } from 'react'
import Panel from 'shared/Panel'
import withRouter from '../../../utils/withRouter'
import { withAuth } from 'components/container/withAuth'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY } from 'queries'
import { formatQuantity, formatAmount } from 'utils'
import moment from 'moment'
import './styles.scss'

class RegisterMeasurementsWithMutation extends React.Component {
  state = {
    processing: false,
    isEditing: false,
    weight: '',
    neckCircumference: '',
    upperArmCircumference: '',
    chestCircumference: '',
    waistCircumference: '',
    hipCircumference: '',
    thighCircumference: '',
    weekRating: '',
  }

  static getDerivedStateFromProps (props, state) {
    if (props.data.registrationId !== state.registrationId) {
      return {
        weight: props.data.weight || '',
        neckCircumference: props.data.neckCircumference || '',
        upperArmCircumference: props.data.upperArmCircumference || '',
        chestCircumference: props.data.chestCircumference || '',
        waistCircumference: props.data.waistCircumference || '',
        hipCircumference: props.data.hipCircumference || '',
        thighCircumference: props.data.thighCircumference || '',
        weekRating: props.data.weekRating || '',
        registrationId: props.data.registrationId,
      }
    }

    return null
  }

  handleQuantityChange = event => {
    const value = formatQuantity(event.target.value)
    this.setState({ [event.target.name]: value })
  }

  handleOptionChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()

    const measurements = {
      ...measurementsEntry.data,
      ...{
        weight: parseFloat(this.state.weight.toString().replace(',', '.')) || null,
        neckCircumference: parseFloat(this.state.neckCircumference.toString().replace(',', '.')) || null,
        upperArmCircumference: parseFloat(this.state.upperArmCircumference.toString().replace(',', '.')) || null,
        chestCircumference: parseFloat(this.state.chestCircumference.toString().replace(',', '.')) || null,
        waistCircumference: parseFloat(this.state.waistCircumference.toString().replace(',', '.')) || null,
        hipCircumference: parseFloat(this.state.hipCircumference.toString().replace(',', '.')) || null,
        thighCircumference: parseFloat(this.state.thighCircumference.toString().replace(',', '.')) || null,
        weekRating: this.state.weekRating || null,
      },
      source:'web'
    }

    Object.keys(measurements).forEach(key => (measurements[key] === null) && delete measurements[key])

    const data = JSON.stringify(measurements)

    const dateTime = getDateFromParams(this.props.match.params)

    const date_time = dateTime

    const entry = {
      ...measurementsEntry,
      ...{ dateTime, date_time, data },
    }

    entry.registrationId = this.state.registrationId

    this.setState({ processing: true })

    this.props.mutate({
      variables: entry
    }).then(({ data }) => {
      this.setState({ processing: false })
      this.props.refetch()
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { loading, auth: { user } } = this.props

    const {
      processing,
      weight,
      neckCircumference,
      upperArmCircumference,
      chestCircumference,
      waistCircumference,
      hipCircumference,
      thighCircumference,
      weekRating,
    } = this.state

    return (
      <Panel loading={loading}>
        <Panel.Header>
          <Panel.Title>Registrer vekt og mål</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <form name="registerMeasurements" className="registerMeasurements group" action="" method="post" onSubmit={this.handleSubmit} data-processing={processing} data-success={false}>
            <div className="fields group">
              <div className="field">
                <label>Din vekt</label>
                <input type="text" name="weight" required value={weight.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                <span>kg</span>
              </div>
              {user.hasPermissions &&
              <Fragment>
                <div className="field">
                  <label>Hals</label>
                  <input type="text" name="neckCircumference" value={neckCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                  <span>cm</span>
                </div>
                <div className="field">
                  <label>Overarm</label>
                  <input type="text" name="upperArmCircumference" value={upperArmCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                  <span>cm</span>
                </div>
                <div className="field">
                  <label>Bryst</label>
                  <input type="text" name="chestCircumference" value={chestCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                  <span>cm</span>
                </div>
              </Fragment>
              }
              <div className="field">
                <label>Midje</label>
                <input type="text" name="waistCircumference" value={waistCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                <span>cm</span>
              </div>
              {user.hasPermissions &&
              <Fragment>
                <div className="field">
                  <label>Hofte/ende</label>
                  <input type="text" name="hipCircumference" value={hipCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                  <span>cm</span>
                </div>
                <div className="field">
                  <label>Lår</label>
                  <input type="text" name="thighCircumference" value={thighCircumference.toString().replace('.', ',')} onChange={this.handleQuantityChange} />
                  <span>cm</span>
                </div>
              </Fragment>
              }
            </div>
            {user.hasPermissions &&
            <Fragment>
              <div className="totalBox">
                <h4>Total</h4>
                <div><strong>{formatAmount(measurementsSum(this.state))}</strong> cm</div>
              </div>
              <div className="weekRating">
                <h4>Kommentar</h4>
                <div className="group">
                  <label>
                    <input type="radio" name="weekRating" value="good" checked={weekRating==='good'} onChange={this.handleOptionChange} />
                    <i className="icon-gr-good" /> <span>Veldig fornøyd</span>
                  </label>
                  <label>
                    <input type="radio" name="weekRating" value="ok" checked={weekRating==='ok'} onChange={this.handleOptionChange} />
                    <i className="icon-gr-ok" /> <span>Fornøyd</span>
                  </label>
                  <label>
                    <input type="radio" name="weekRating" value="bad" checked={weekRating==='bad'} onChange={this.handleOptionChange} />
                    <i className="icon-gr-bad" /> <span>Lite fornøyd</span>
                  </label>
                </div>
              </div>
            </Fragment>
            }
            <button type="submit">Lagre</button>
          </form>
        </Panel.Body>
      </Panel>
    )
  }
}

const measurementsSum = measurements => {
  const fields = [
    'neckCircumference',
    'upperArmCircumference',
    'chestCircumference',
    'waistCircumference',
    'hipCircumference',
    'thighCircumference',
  ]

  return fields.reduce((total, field) => {
    total += parseFloat(measurements[field].toString().replace(',', '.') || 0)
    return total
  }, 0)
}

const getDateFromParams = ({ year, week }) => (
  moment().year(year).isoWeek(week).startOf('week').format('YYYY-MM-DDT00:00:00')
)

const measurementsEntry = {
  registrationId: null,
  dateTime: null,
  date_time: null,
  category: 'measurements',
  tag: 'measurements',
  calories: null,
  description: null,
  data: {
    weight: null,
    neckCircumference: null,
    upperArmCircumference: null,
    chestCircumference: null,
    waistCircumference: null,
    hipCircumference: null,
    thighCircumference: null,
  },
  lastModified: null,
  __typename: 'DiaryRegistration',
}

const RegisterMeasurements = withAuth(withRouter(graphql(ADD_ENTRY)(RegisterMeasurementsWithMutation)))

export default RegisterMeasurements
