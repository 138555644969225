import React from 'react'
import AddEntriesModal from 'components/ui/AddEntriesModal'

class SaveRegistrations extends React.Component {
  state = {
    isOpen: false
  }

  openModal = () => this.setState({ isOpen: true })

  closeModal = () => {
    this.setState({ isOpen: false })
    if (this.props.callback) { this.props.callback() }
  }

  render() {
    const { isOpen } = this.state
    const { meal, render } = this.props

    return (
      <React.Fragment>
        {render({ saveRegistrations: this.openModal })}
        {isOpen && <AddEntriesModal tag={meal.id} close={this.closeModal} />}
      </React.Fragment>
    )
  }
}

export default SaveRegistrations
