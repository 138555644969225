import React, {useState} from 'react';
import { useMutation } from '@apollo/client'
import { GET_USER_DATA } from 'queries';
import { UPDATE_ACTIVITY_AND_EXCERCISE_HABITS } from 'queries'
import { profileSettings } from '../../../../conf';


import formStyles from 'assets/styles/Form.module.scss'
import {PopupButton} from 'components/ui/Popup'



const mapActivityLevel = {
  "low": 1.3,
  "moderate": 1.4,
  "high": 1.5
}

const mapExercisesLevel = {
  "veryLow": 2,
  "low": 5,
  "moderate": 10,
  "high": 15,
  "veryHigh": 20,
}

function reverseMap(obj:any, find:any){
  const v = Object.entries(obj).find(([key, value]) => {if (value === find){return value} return null});
  if (v){
    return v[0]
  }
}


//         variables: 


interface Props {
  activityLevel?: number;
  exerciseHabits?: number;
  close?: () => void;
}



export const ActivitiesForm:React.FC<Props> = ({activityLevel, exerciseHabits, close, ...props}) => {
  const [activityTmp, setActivity] = useState(reverseMap(mapActivityLevel, activityLevel))
  const [exercise, setExercise] = useState(reverseMap(mapExercisesLevel, exerciseHabits))

  const [save, {loading}] = useMutation(UPDATE_ACTIVITY_AND_EXCERCISE_HABITS, { 
    onCompleted() {
      close();
    }
  });

  const submit = async (event) => {
    event.preventDefault()
    const variables = {activity: mapActivityLevel[activityTmp], exerciseHabits: mapExercisesLevel[exercise]}
    try {
      await save({variables, refetchQueries: [GET_USER_DATA]})
    } catch (error) {
      alert(error)
    }
  }

  return <form  className={formStyles.form} onSubmit={submit}>
    <div className={formStyles.optioninputs}>
      <h2>{profileSettings.active.activityTitle}</h2>

      {Object.entries(profileSettings.active.activities).map(([activity, description]) => {
        const text =  description.split(":")     
        return(
          <label key={activity} className={formStyles.optioninput}>
            <input type="radio" name="activity" value={activity} required
              checked={activityTmp === activity}
              onChange={() => setActivity(activity)} />
            <div>
                <h3>{text[0]}</h3>{text[1]}
            </div>
          </label>
      )})}
    </div>
    <hr />
    <div className={formStyles.optioninputs}>
      <h2>{profileSettings.active.exerciseHabitsTitle}</h2>

      {Object.entries(profileSettings.active.exerciseHabits).map(([activity, description]) => {
        const text =  description.split(":")        
        return(
          <label key={activity} className={formStyles.optioninput}>
            <input type="radio" name="exercise" value={activity} required
              checked={exercise === activity}
              onChange={() => setExercise(activity)} />
            <div>
              <h3>{text[0]}</h3>{text[1]}
            </div>
          </label>
        )})}
    </div>
    <hr />
    <button disabled={loading} type="submit">Lagre</button>
  </form>
}


export default function ActivitiesButton(props) {
  return(
    <PopupButton  title="Aktivitetsnivå" show={props.show} {...props}>
      <ActivitiesForm {...props}  />
    </PopupButton>
  )
}