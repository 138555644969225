import React from 'react'
import SubNavigation from 'shared/SubNavigation'
import DailyMenusList from './DailyMenusList'
import './styles.scss'

const DailyMenusPage = () => (
  <div className="myFoodPage">
    <h1>Min mat</h1>
    <SubNavigation selectedOption="Mine dager" />
    <div className="container">
      <div className="dataTables">
        <DailyMenusList />
      </div>
      <div className="textContent">
        <h2>Mine dager</h2>
        <p>Her har du oversikt over dine standard-dager. Du kan endre navn på dagen eller slette den. <br />Bruk av standard-dag funksjonen gjør registreringen av mat mye enklere, og det blir lettere å registrere hver dag.</p>
        <h2>Bruk av eksempeldag</h2>
        <p>Øverst på registreringssiden for mat kan du velge «Legg inn mat for standard-dag», og du kan velge mellom Eksempeldag meny 1 - 5. Velger du «Eksempeldag meny 2» får du opp et forslag på hvordan du kan følge meny 2. Videre kan du slette enkeltmatvarer eller hele måltider og erstatte med det du faktisk har spist.</p>
        <h2>Egne standard-dager</h2>
        <p>Nederst på siden for registrering av mat kan velge «Lagre som standard-dag». Har du registrert en vanlig arbeidsdag kan du f. eks. lagre den som «Arbeidsdag med matpakke» og senere hente denne fra listen over standard-dager. Neste gang du velger «Arbeidsdag med matpakke» kan du slette lunsjen og erstatte med salat og lagre som «Arbeidsdag med salat». Om du i tillegg da endrer til riktig middag vil du kanskje ha en ganske nøyaktig registrering uten for mye arbeid.</p>
        <p><strong>HUSK!</strong> <br />I starten anbefaler vi alle å gjøre en nøye registrering av maten for å lære om energiinnhold i ulike matvarer og for å sikre riktig energiinntak. Men etter hvert kan man tillate seg å være litt mindre nøye, og forenklet føring kan være det som skal til for at du fortsetter å registrere og dermed få kostholdsscore.</p>
      </div>
    </div>
  </div>
)

export default DailyMenusPage
