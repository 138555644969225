// @flow

import { gql } from '@apollo/client'

const updateActivityAndExerciseHabits = gql`
  mutation updateActivityAndExerciseHabits (
    $activity: String,
    $exerciseHabits: String
  ) {
    user {
      activityAndExerciseHabits(
        activity: $activity,
        exerciseHabits: $exerciseHabits
      ) {
        status
      }
    }
  }
`
export default updateActivityAndExerciseHabits
