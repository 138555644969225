import React from 'react';
import Modal from '../Modal';
import { graphql } from '@apollo/client/react/hoc';
import { withDate } from '../../util/withDate';
import { ADD_CUSTOM_FOOD_PRODUCT } from '../../../queries';
import './styles.scss';

const nutritionFields = [
  {
    label: 'Kalorier',
    name: 'kcalPer100Grams',
    unit: 'kcal',
    required: true,
    dataType: 'integer',
  },
  {
    label: 'Fett *',
    name: 'gramsOfFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Mettet *',
    name: 'gramsOfSaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Enumettet',
    name: 'gramsOfMonounsaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Flerumettet',
    name: 'gramsOfPolyunsaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Karbohydrater *',
    name: 'gramsOfCarbohydratePer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Sukkerarter *',
    name: 'gramsOfSugarPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Kostfiber *',
    name: 'gramsOfFiberPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Proteiner *',
    name: 'gramsOfProteinPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Salt *',
    name: 'gramsOfSaltPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Kalsium *',
    name: 'milligramsOfCalciumPer100Grams',
    unit: 'milligram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Frukt',
    name: 'gramsOfFruitsPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Grønnsaker',
    name: 'gramsOfVegetablesPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Melk',
    name: 'gramsOfMilkPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
];

const allergensFields = [
  {
    label: 'Inneholder gluten?',
    value: 'Gluten',
  },
  {
    label: 'Inneholder melk?',
    value: 'Melk',
  },
  {
    label: 'Inneholder egg?',
    value: 'Egg',
  },
  {
    label: 'Inneholder sennep?',
    value: 'Sennep',
  },
  {
    label: 'Inneholder fisk?',
    value: 'Fisk',
  },
  {
    label: 'Inneholder bløtdyr?',
    value: 'Bløtdyr',
  },
  {
    label: 'Inneholder skalldyr?',
    value: 'Skalldyr',
  },
  {
    label: 'Inneholder nøtter?',
    value: 'Nøtter',
  },
  {
    label: 'Inneholder peanøtter?',
    value: 'Peanøtter',
  },
  {
    label: 'Inneholder soya?',
    value: 'Soya',
  },
  {
    label: 'Inneholder sesamfrø?',
    value: 'Sesam',
  },
  {
    label: 'Inneholder selleri?',
    value: 'Selleri',
  },
  {
    label: 'Inneholder lupin?',
    value: 'Lupin',
  },
  {
    label: 'Inneholder svoveldioksid og sulfitter?',
    value: 'Svoveldioksid og sulfitter',
  },
];

const AddCustomFoodProductModalUI = ({ tag, date, inputValue, close, openEntryModal, mutate }) => {
  const saveFoodProduct = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const variables = {
      name: formData.get('name'),
      allergens: formData.getAll('allergens'),
      isHeartFriendly: Boolean(formData.get('isHeartFriendly')),
      adGrRecommendation: Boolean(formData.get('adGrRecommendation')),
    };

    for (let field of nutritionFields) {
      variables[field.name] = (field.dataType === 'integer') ?
        parseInt(formData.get(field.name), 10) || 0 :
        parseFloat(formData.get(field.name)) || 0;
    }

    mutate({
      variables,
    }).then(({ data }) => {
      const { customFoodProductId } = data.diary.saveCustomFoodProduct;

      close();

      openEntryModal({
        registrationId: null,
        category: 'food',
        tag: tag,
        date_time: date,
        description: null,
        calories: 0,
        data: {
          id: customFoodProductId,
          type: 'custom product',
          name: variables.name,
          quantity: null,
          unit: null,
          units: [{ name: 'g', gramsPerUnit: 1 }],
          allergens: variables.allergens,
          isHeartFriendly: variables.isHeartFriendly,
          quality: null,
          nutrition: {
            kcalPer100Grams: variables.kcalPer100Grams,
            gramsOfProteinPer100Grams: variables.gramsOfProteinPer100Grams,
            gramsOfCarbohydratePer100Grams: variables.gramsOfCarbohydratePer100Grams,
            gramsOfFatPer100Grams: variables.gramsOfFatPer100Grams,
            milligramsOfCalciumPer100Grams: variables.milligramsOfCalciumPer100Grams,
            gramsOfFruitsPer100Grams: variables.gramsOfFruitsPer100Grams,
            gramsOfVegetablesPer100Grams: variables.gramsOfVegetablesPer100Grams,
          }
        },
      });
    }).catch((error) => {
      console.log(error);
      close();
    });
  }

  return (
    <Modal title="Legg til egen matvare" onClose={close}>
      <form onSubmit={saveFoodProduct}>
        <Modal.Body>
          <div className="field">
            <label htmlFor="name">Navn på matvare *</label>
            <input type="text" name="name" id="name" defaultValue={inputValue} autoFocus />
          </div>
          <div className="field field-message"><i className="icon-info"></i> Alle verdier per 100 gram.</div>
          {nutritionFields.map(field => (
            <div className="field field-amount" key={field.name}>
              <label htmlFor={field.name}>{field.label}</label>
              <input type="text" name={field.name} id={field.name} maxLength="5" className="amount" required={field.required} />
              <span>{field.unit}</span>
            </div>
          ))}
          {allergensFields.map((field, index) => (
            <div className="field field-checkbox" key={index}>
              <label>
                <input type="checkbox" name="allergens" value={field.value} style={{'WebkitAppearance': 'checkbox', 'width': '16px', 'height': '16px'}} /> {field.label}
              </label>
            </div>
          ))}
          <div className="field field-checkbox">
            <label>
              <input type="checkbox" name="isHeartFriendly" value="true" style={{'WebkitAppearance': 'checkbox', 'width': '16px', 'height': '16px'}} /> Hjertevennlig
            </label>
          </div>
          <div className="field field-checkbox">
            <label>
              <input type="checkbox" name="adGrRecommendation" value="true" style={{'WebkitAppearance': 'checkbox', 'width': '16px', 'height': '16px'}} /> Tips Roede om denne matvaren
            </label>
          </div>
          <div className="field">Feltene merket * må fylles ut for å kunne tipse oss om matvaren.</div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={close}>Avbryt</button>
          <button type="submit">Lagre</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

const AddCustomFoodProductModal = withDate(graphql(ADD_CUSTOM_FOOD_PRODUCT)(AddCustomFoodProductModalUI));

export default AddCustomFoodProductModal;
