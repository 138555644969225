import { gql } from '@apollo/client'

export default gql`
  mutation RunMembershipAction(
    $membershipId: Int!
    $action: String!
  ) {
  membership {
    runAction(
        membershipId: $membershipId
        action: $action
      ) {
        status
      }
    }
  }
`
