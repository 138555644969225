import React, { useState } from 'react'
import {PopupButton} from 'components/ui/Popup'
import { useMutation } from '@apollo/client'
import CHANGE_PASSWORD from 'queries/changePassword'
import s from 'assets/styles/Form.module.scss'

export default function ChangePassword(props) {
  return (
    <PopupButton title="Endre passord">
      <ChangePasswordForm {...props} />
    </PopupButton>
  )
}

interface Props {
  close?: () => void;
}

const ChangePasswordForm:React.FC<Props> = ({ close }) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [changePassword, { loading, data }] = useMutation(CHANGE_PASSWORD)
  const { status, message } = data?.registration?.changePassword ?? {}

  const handleSubmit = async (event) => {
    event.preventDefault()
    const variables = { currentPassword, newPassword, confirmPassword }
    try {
      await changePassword({variables})
    } catch (error) {
      alert(error)
    }
  }

  const errorMessages = {
    "The new password and the confirmation password don't match":
      "Det nye passordet og bekreftelsespassordet samsvarer ikke.",
    "The current password is not valid":
      "Det nåværende passordet er ikke gyldig.",
  }

  if (status === 'success') {
    return (
      <div className={s.form}>
        <div className={s.group} style={{justifyContent: 'center'}}>
          Passordet ditt ble endret.
        </div>
        <button type="button" onClick={close}>Lukk</button>
      </div>
    )
  }

  return (
    <form className={s.form} onSubmit={handleSubmit}>
      {status === 'error' && (
        <div className={`${s.group} ${s.error}`}>
          {errorMessages[message] ?? message}
        </div>
      )}
      <div className={s.group}>
        <label>
          <input
            type="password"
            name="currentPassword"
            required
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <span>Nåværende passord</span>
        </label>
      </div>
      <hr />
      <div className={s.group}>
        <label>
          <input
            type="password"
            name="newPassword"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <span>Nytt passord</span>
        </label>
      </div>
      <div className={s.group}>
        <label>
          <input
            type="password"
            name="confirmNewPassword"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span>Bekrefte nytt passord</span>
        </label>
      </div>
      <hr />
      <button disabled={loading} type="submit">Lagre</button>
    </form>
  )
}
