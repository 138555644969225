import glassempty from './images/glass-empty.png'
import glassfull from './images/glass-full.png'
import heartempty from './images/heart-empty.png'
import heartfull from './images/heart-full.png'
import vitaminempty from './images/vitamin-empty.png'
import vitaminfull from './images/vitamin-full.png'

const images = {
  glassempty,
  glassfull,
  heartempty,
  heartfull,
  vitaminempty,
  vitaminfull,
}

export default images
