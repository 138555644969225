import { gql } from '@apollo/client';

const addCustomFoodProduct = gql`
  mutation saveCustomFoodProduct (
    $objectId: Int,
    $name: String!,
    $kcalPer100Grams: Int!,
    $gramsOfProteinPer100Grams: Float!,
    $gramsOfCarbohydratePer100Grams: Float!,
    $gramsOfSugarPer100Grams: Float!,
    $gramsOfFatPer100Grams: Float!,
    $gramsOfSaturatedFatPer100Grams: Float!,
    $gramsOfPolyunsaturatedFatPer100Grams: Float!,
    $gramsOfMonounsaturatedFatPer100Grams: Float!,
    $gramsOfFiberPer100Grams: Float!,
    $gramsOfSaltPer100Grams: Int!,
    $milligramsOfCalciumPer100Grams: Int!,
    $gramsOfVegetablesPer100Grams: Int!,
    $gramsOfFruitsPer100Grams: Int!,
    $gramsOfMilkPer100Grams: Int!,
    $allergens: [String],
    $isHeartFriendly: Boolean!,
    $adGrRecommendation: Boolean!
  ){
    diary {
      saveCustomFoodProduct (
        objectId: $objectId,
        name: $name,
        kcalPer100Grams: $kcalPer100Grams,
        gramsOfProteinPer100Grams: $gramsOfProteinPer100Grams,
        gramsOfCarbohydratePer100Grams: $gramsOfCarbohydratePer100Grams,
        gramsOfSugarPer100Grams: $gramsOfSugarPer100Grams,
        gramsOfFatPer100Grams: $gramsOfFatPer100Grams,
        gramsOfSaturatedFatPer100Grams: $gramsOfSaturatedFatPer100Grams,
        gramsOfPolyunsaturatedFatPer100Grams: $gramsOfPolyunsaturatedFatPer100Grams,
        gramsOfMonounsaturatedFatPer100Grams: $gramsOfMonounsaturatedFatPer100Grams,
        gramsOfFiberPer100Grams: $gramsOfFiberPer100Grams,
        gramsOfSaltPer100Grams: $gramsOfSaltPer100Grams,
        milligramsOfCalciumPer100Grams: $milligramsOfCalciumPer100Grams,
        gramsOfVegetablesPer100Grams: $gramsOfVegetablesPer100Grams,
        gramsOfFruitsPer100Grams: $gramsOfFruitsPer100Grams,
        gramsOfMilkPer100Grams: $gramsOfMilkPer100Grams,
        allergens: $allergens,
        isHeartFriendly: $isHeartFriendly,
        adGrRecommendation: $adGrRecommendation
      ) {
        status
        customFoodProductId
      }
    }
  }
`;

export default addCustomFoodProduct;