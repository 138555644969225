import { gql } from '@apollo/client';

const addEntriesFromCustomMeal = gql`
  mutation addEntriesFromCustomMeal (
    $date: DateTime!,
    $customGroupId: Int!,
    $category: String!,
    $tag: String!
  ){
    diary {
      addEntriesFromCustomGroup (
          date: $date,
          customGroupId: $customGroupId,
          category: $category,
          tag: $tag
      ) {
        status
      }
    }
  }
`;

export default addEntriesFromCustomMeal;