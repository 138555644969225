import DateNavigation from 'shared/DateNavigation'
import MeasurementsSummary from './MeasurementsSummary'
import DietSummary from './DietSummary'
import AboutButton from './AboutButton'

const WeekSummary = () => {
  return <>
    <DateNavigation />
    <MeasurementsSummary />
    <DietSummary />
    <AboutButton />
  </>

}

export default WeekSummary
