import withRouter from '../../../../../../../../../utils/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRIES_FROM_CUSTOM_MEAL } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'

const AddCustomMeal = ({
  item, tag, close, mutate, match
}) => {
  const variables = {
    date: `${match.params.date}T00:00:00`,
    category: 'food',
    tag: tag,
    customGroupId: item.id,
  }

  mutate({
    variables,
    refetchQueries: refetchQueries(),
  })

  close()

  return null
}

const AddEntriesFromCustomMealWrapper = graphql(ADD_ENTRIES_FROM_CUSTOM_MEAL)(AddCustomMeal)

export default withRouter(AddEntriesFromCustomMealWrapper)
