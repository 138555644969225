import React from 'react'
import { Wrapper } from 'components/ui/Section'
import s from './Message.module.scss'

export default function Banner({ children, type }) {
  return (
    <div className={s.message} data-type={type}>
      <Wrapper>
        <div className={s.content}>{children}</div>
      </Wrapper>
    </div>
  )
}
