import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

const modalRoot = document.getElementById('modal-root');

class Modal extends Component {
  handleOverlayClick = event => {
    const { onClose, enableOverlayClose = true } = this.props
    return enableOverlayClose ? onClose : event.preventDefault()
  }

  render() {
    const {
      title,
      size = 'small',
      onClose,
      children
    } = this.props;

    return ReactDOM.createPortal(
      (
        <div className="modal-overlay" onClick={this.handleOverlayClick}>
          <div className={`modal modal--${size}`} onClick={e => e.stopPropagation()}>
            <header className="modal-header">
              {title}
              <button aria-label="Lukk" onClick={onClose}><i className="icon-close"></i></button>
            </header>
            {children}
          </div>
        </div>
      ),
      modalRoot,
    );
  }
}

Modal.Body = class Body extends Component {
  render() {
    return <div className="modal-body">{this.props.children}</div>
  }
}

Modal.Footer = class Footer extends Component {
  render() {
    return <div className="modal-footer">{this.props.children}</div>
  }
}

export default Modal;