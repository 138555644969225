import { gql } from '@apollo/client'

const updateAboutMe = gql`
  mutation updateAboutMe (
    $initialWeight: Float,
    $height: Float,
    $gender: String,
    $birthDate: DateTime,
  ){
    user {
      initialWeight(
        initialWeight: $initialWeight,
      ) {
        status
      }
      updateMemberProperties(
        gender: $gender,
        height: $height,
        birthDate: $birthDate
      ) {
        status
      }
    }
  }
`


export default updateAboutMe
