import { gql } from '@apollo/client'

export default gql`
  mutation ChangePassword(
    $currentPassword: String
    $newPassword: String
    $confirmPassword: String
  ) {
    registration {
      changePassword(
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      ) {
        status
        message
      }
    }
  }
`
