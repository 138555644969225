import React, { Fragment, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

interface Props {
  section: string;
  close: () => void;
}

const DayCalendar: React.FC<Props> = ({ close, section, ...props }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [month, setMonth] = useState<moment.Moment>(
    moment(params?.date).startOf("month")
  );

  const getListOfDaysInMonth = () => {
    const currentMonth = month.format("YYYY-MM-");
    const selectedDate = moment(params?.date).format("YYYY-MM-D");
    const today = moment().format("YYYY-MM-D");

    return Array(month.daysInMonth())
      .fill(undefined)
      .map((_, i) => ({
        number: i + 1,
        selectedClass:
          `${currentMonth}${i + 1}` === selectedDate ? "selected" : "",
        todayClass: `${currentMonth}${i + 1}` === today ? "today" : "",
      }));
  };

  const setDate = (e) => {
    if (e.target.tagName === "LI" && e.target.innerText) {
      const date =
        month.format("YYYY-MM-") + `00${e.target.innerText}`.slice(-2);
      navigate(`/${section}/${date}/`);
      close();
    }
  };

  return (
    <Fragment>
      <div className="overlay calendar-overlay" onClick={close}></div>
      <div className="calendar">
        <div className="calendar-header">
          <button onClick={() => setMonth(month.clone().add(-1, "month"))}>
            <i className="icon-arrow-2-left"></i>
          </button>
          <strong>{month.format("MMMM YYYY")}</strong>
          <button onClick={() => setMonth(month.clone().add(1, "month"))}>
            <i className="icon-arrow-2-right"></i>
          </button>
        </div>
        <ul className="calendar-weekdays">
          <li>ma</li>
          <li>ti</li>
          <li>on</li>
          <li>to</li>
          <li>fr</li>
          <li>lø</li>
          <li>sø</li>
        </ul>
        <ul className="calendar-days" onClick={(e) => setDate(e)}>
          {Array(month.startOf("month").weekday())
            .fill(undefined)
            .map((_, i) => (
              <li key={i} className="empty"></li>
            ))}
          {getListOfDaysInMonth().map((day, i) => (
            <li key={i} className={`${day.todayClass} ${day.selectedClass}`}>
              {day.number}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

// class DayCalendar extends React.Component {
//   state = {
//     month: moment(this.props.match.params.date).startOf('month')
//   }

//   setDate = (e) => {
//     if (e.target.tagName === 'LI' && e.target.innerText) {
//       const date = this.state.month.format('YYYY-MM-') + (`00${e.target.innerText}`).slice(-2);
//       const [, section] = this.props.location.pathname.split('/')
//       this.props.history.push(`/${section}/${date}/`);
//       this.props.close();
//     }
//   }

//   getListOfDaysInMonth = () => {
//     const currentMonth = this.state.month.format('YYYY-MM-');
//     const selectedDate = moment(this.props.match.params.date).format('YYYY-MM-D');
//     const today = moment().format('YYYY-MM-D');

//     return Array(this.state.month.daysInMonth()).fill().map((_, i) => ({
//       number: i+1,
//       selectedClass: (`${currentMonth}${i+1}` === selectedDate) ? 'selected' : '',
//       todayClass: (`${currentMonth}${i+1}` === today) ? 'today' : ''
//     }));
//   }

//   render() {
//     const { month } = this.state;

//     return (
//       <Fragment>
//         <div className="overlay calendar-overlay" onClick={this.props.close}></div>
//         <div className="calendar">
//           <div className="calendar-header">
//             <button onClick={() => this.setState({ month: month.add(-1, 'month') })}><i className="icon-arrow-2-left"></i></button>
//             <strong>{month.format('MMMM YYYY')}</strong>
//             <button onClick={() => this.setState({ month: month.add(1, 'month') })}><i className="icon-arrow-2-right"></i></button>
//           </div>
//           <ul className="calendar-weekdays">
//             <li>ma</li>
//             <li>ti</li>
//             <li>on</li>
//             <li>to</li>
//             <li>fr</li>
//             <li>lø</li>
//             <li>sø</li>
//           </ul>
//           <ul className="calendar-days" onClick={e => this.setDate(e)}>
//             {Array(this.state.month.startOf('month').weekday()).fill().map((_, i) => (
//               <li key={i} className="empty"></li>
//             ))}
//             {this.getListOfDaysInMonth().map((day, i) => (
//               <li key={i} className={`${day.todayClass} ${day.selectedClass}`}>{day.number}</li>
//             ))}
//           </ul>
//         </div>
//       </Fragment>
//     )
//   }
// }

export default DayCalendar;
