import moment from 'moment'

const SEARCH_MIN_CHARS = 2

const MEALS = {
  breakfast: 'Frokost',
  lunch:     'Lunsj',
  snackMeal: 'Mellommåltid',
  dinner:    'Middag',
  supper:    'Kvelds',
}

function includesValue(item, inputValue) {
  return item.name.toLowerCase().includes(inputValue.toLowerCase())
}

function getFavorites ({
  recipes: { edges: recipesEdges = [] } = {},
  ingredients: { edges: ingredientsEdges = [] } = {},
}, inputValue) {
  const recipes = recipesEdges.map(recipe => ({
    id: recipe.node.recipeId,
    name: recipe.node.name,
    kcalPer100g: recipe.node.kcal100g,
    type: 'recipe',
    typeName: 'Oppskrift',
    image: getImage(recipe.node.image),
  }))

  const ingredients = ingredientsEdges.map(ingredient => ({
    id: ingredient.node.objectId,
    name: ingredient.node.name,
    kcalPer100g: ingredient.node.kcal100g,
    type: 'food_product',
    typeName: 'Matvare',
  }))

  const favorites = ingredients.concat(recipes)

  if (inputValue.length >= SEARCH_MIN_CHARS) {
    return favorites.filter(item => includesValue(item, inputValue))
  }

  return favorites
}

function getCustom ({
  customMealResult: { edges: mealsEdges = [] } = {},
  recipeCustomResult: { edges: recipesEdges = [] } = {},
  foodProductCustomResult: { edges: ingredientsEdges = [] } = {},
}, inputValue) {
  const meals = mealsEdges.map(meal => ({
    id: meal.node.objectId,
    name: meal.node.name,
    kcalPer100g: null,
    type: 'custom_meal',
    typeName: 'Måltid',
  }))

  const recipes = recipesEdges.map(recipe => ({
    id: recipe.node.objectId,
    name: recipe.node.name,
    kcalPer100g: recipe.node.kcal100g,
    type: 'custom_recipe',
    typeName: 'Oppskrift',
  }))

  const ingredients = ingredientsEdges.map(ingredient => ({
    id: ingredient.node.objectId,
    name: ingredient.node.name,
    kcalPer100g: ingredient.node.kcal100g,
    type: 'custom_food_product',
    typeName: 'Matvare',
  }))

  const custom = meals.concat(recipes, ingredients)

  if (inputValue.length >= SEARCH_MIN_CHARS) {
    return custom.filter(item => includesValue(item, inputValue))
  }

  return custom
}

function getCurrentMeal() {
  const hour = moment().format('H')

  if (hour >= 4 && hour < 11)
    return 'Frokost'
  else if (hour >= 11 && hour < 13)
    return 'Lunsj'
  else if (hour >= 13 && hour < 15)
    return 'Mellommåltid'
  else if (hour >= 15 && hour < 19)
    return 'Middag'
  else if ((hour >= 19 && hour <= 23) || (hour >= 0 && hour < 4))
    return 'Kvelds'
}

function getMealName(meal) {
  return (MEALS[meal]) ? MEALS[meal] : getCurrentMeal()
}

function getImage(image) {
  if (image && image.url) {
    return `https://www.roede.com/cdn-cgi/image/w=84,h=84,q=60,f=auto/${image.url}`
  }

  return null
}

function getMealClock({ mealClock = [] }, meal) {
  const mealName = getMealName(meal)

  const { slots = [] } = mealClock.find(m => m.category === mealName) || {}

  return slots.map(slot => ({
    id: slot.recipe.recipeId,
    name: slot.recipe.name,
    kcalPer100g: slot.recipe.kcal100g,
    type: 'recipe',
    typeName: 'Oppskrift',
    image: getImage(slot.recipe.image),
  }))
}

function getTypeName(type) {
  const labels = {
    'recipe': 'Roede oppskrift',
    'food_product': 'Matvare',
  }

  return labels[type] || ''
}

function getResults(results) {
  if (!results) return null

  return results.map(result => ({
    id: result.obj_id,
    name: result.name,
    kcalPer100g: result.kcal_100g,
    type: result.type,
    typeName: getTypeName(result.type),
  }))
}

const getData = ({ results, isLoading, fetchMore }, data, meal, inputValue) => {
  const { kitchen, diary } = data
  const favorites = getFavorites(kitchen, inputValue)
  const custom = getCustom(diary, inputValue)
  const search = getResults(results, favorites, custom)
  const mealClock = inputValue.length >= SEARCH_MIN_CHARS ? [] : getMealClock(kitchen, meal)

  //console.log({inputValue}, {results}, {isLoading}, {fetchMore}, {favorites}, {custom}, {mealClock})

  return {
    favorites,
    custom,
    mealClock,
    search,
    isLoading,
    fetchMore,
  }
}

export { getData, getMealName, SEARCH_MIN_CHARS }
