import { gql } from '@apollo/client';

const getInvoices = gql`
  query InvoiceQuery($status: String, $membershipId: Int) {
    user {
      invoices(status: $status, membershipId: $membershipId) {
        edges {
          node {
            id,
            dateTime,
            status
            formatedAmount,
            data,
            membership{
              membershipId
              name
            }
          }
        }
      }
    }
  }
`;



export default getInvoices;
