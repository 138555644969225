import Panel from 'shared/Panel'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'

import { withAuth } from 'components/container/withAuth'
import { Link, useParams } from 'react-router-dom'
import './styles.scss'

type Props = {
  auth: any,
  linkUrl: string,
  linkTitle: string
}

const MeasurementsSummaryWithData = ({
  auth: { user },
  linkUrl,
  linkTitle,
  ...props
}: Props) => {

  let params = useParams()

  let year = params.year || moment().format('YYYY')
  let week = params.week || moment().format('W')

  const {loading, data} = useQuery(GET_MEASUREMENTS_SUMMARY, {
    variables: { dateTo: getDateTo(year, week) },
    fetchPolicy: "no-cache"
  })

  const diary = data?.diary;

  const { weight, waist } = getSummary(diary)
  const startOfWeek = getStartOfWeek(year, week).format('DD.MM.YY')
  const endOfWeek = getEndOfWeek(year, week).format('DD.MM.YY')

  return (
    <Panel loading={loading}>
      <Panel.Header>
        <Panel.Title>
          Sammendrag for uke {week}
          <span className="panelSubTitle">(man {startOfWeek} - søn {endOfWeek})</span>
        </Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <div className="summaryBoxes">
          <dl className="summaryBox">
            <dt>Vekt nå</dt>
              <dd><strong>{formatNumber(weight.current)}</strong> kg</dd>
            <dt>Endring i vekt siden forrige uke</dt>
              <dd><strong>{formatNumber(weight.changeSinceLastWeek)}</strong> kg</dd>
            <dt>Endring i vekt siden Startdato</dt>
              <dd><strong>{formatNumber(weight.changeSinceStartDate)}</strong> kg</dd>
          </dl>
          {user.hasPermissions &&
          <dl className="summaryBox">
            <dt>Midjemål nå</dt>
              <dd><strong>{formatNumber(waist.current)}</strong> cm</dd>
            <dt>Endring i midjemål siden forrige uke</dt>
              <dd><strong>{formatNumber(waist.changeSinceLastWeek)}</strong> cm</dd>
            <dt>Endring i midjemål siden Startdato</dt>
              <dd><strong>{formatNumber(waist.changeSinceStartDate)}</strong> cm</dd>
          </dl>
          }
          {!user.hasPermissions &&
          <div className="summaryBox goldMemberAd">
            <h3>Få mer ut av medlemskapet!</h3>
            <p>Få din daglige kostholdscore, energifordeling, treningsdata og flere motivasjonsverktøy.</p>
            <a href="/roedeklubben/">Bli GULL-MEDLEM her!</a>
          </div>
          }
        </div>
        <div>
          {(linkUrl && linkTitle) &&
            <Link className="moreLink" to={linkUrl}>
              <span>{linkTitle}</span>
            </Link>
          }
        </div>
      </Panel.Body>

    </Panel>
  )
}

function getSummary(diary) {
  const { summaryMeasurements = '{}' } = diary || {}

  const { computedValues = {} } = JSON.parse(summaryMeasurements)

  const {
    currentWeight = 0,
    lastWeekWeightDifference = 0,
    startDayWeight = 0,
    currentWaistCircumference = 0,
    lastWeekWaistCircumferenceDifference = 0,
    startDayWaistCircumference = 0,
  } = computedValues

  return {
    weight: {
      current: currentWeight,
      changeSinceLastWeek: lastWeekWeightDifference,
      changeSinceStartDate: startDayWeight,
    },
    waist: {
      current: currentWaistCircumference,
      changeSinceLastWeek: lastWeekWaistCircumferenceDifference,
      changeSinceStartDate: startDayWaistCircumference,
    },
  }
}

function formatNumber(number) {
  if (isNaN(Number(number))) { return 'n/a' }
  const options = { maximumFractionDigits: 1 }
  return Number(number).toLocaleString('nb-NO', options)
}

function getStartOfWeek(year, week) {
  return moment().year(year).isoWeek(week).startOf('week')
}

function getEndOfWeek(year, week) {
  return moment().year(year).isoWeek(week).endOf('week')
}

function getDateTo(year, week) {
  if (!year || !week){
    year  = moment().format('YYYY')
    week = moment().format('W')
  }
  return getEndOfWeek(year, week).format('YYYY-MM-DDT23:59:59')
}

const GET_MEASUREMENTS_SUMMARY = gql`
  query GetMeasurementsSummary (
    $dateTo: DateTime
  ) {
    diary {
      summaryMeasurements(
        dateTo: $dateTo
      )
    }
  }
`






const MeasurementsSummary = withAuth(MeasurementsSummaryWithData)
export default MeasurementsSummary
