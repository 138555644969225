// @flow

import React from 'react'
import dietScore from 'conf/dietScore'
import Count from './Count'
import Flag from './Flag'
import Progress from './Progress'
import R from 'res/R'

export default class RowMaker extends React.Component {
  constructor(diet) {
    super(diet)
    this.diet = diet
  }

  makeTotalKcal = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.totalkcal,
      R.strings.common.unit.kcal
    )
  }

  makeProtein = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.protein,
      R.strings.common.unit.percent
    )
  }

  makeCarbs = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.carbs,
      R.strings.common.unit.percent
    )
  }

  makeFat = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.fat,
      R.strings.common.unit.percent
    )
  }

  makeEnergyDensity = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.energyDensity,
      R.strings.common.unit.kcal
    )
  }

  makeCalcium = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.calcium,
      R.strings.common.unit.mg
    )
  }

  makeVegetables = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.vegetable,
      R.strings.common.unit.g
    )
  }

  makeFruit = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.fruit,
      R.strings.common.unit.g
    )
  }

  makeVoucherKcal = (kcalValue, maxValue) => {
    const value = kcalValue
    const minValue = 0

    return this.makeProgress(
      value,
      0,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.snack,
      R.strings.common.unit.kcal
    )
  }

  makeProgress = (
    value: number,
    recommended: number,
    minValue: number,
    maxValue: number,
    name: string,
    unit: string,
    decimals?: number
  ) => {
    return (
      <Progress
        value={value}
        recommended={recommended}
        minValue={minValue}
        maxValue={maxValue}
        name={name}
        unit={unit}
        decimals={decimals}
      />
    )
  }

  makeWater = () => {
    return (
      <Count
        value={this.diet.numGlassOfWater}
        recommended={dietScore.water.recommendedNumGlasses}
        minValue={dietScore.water.minNumGlasses}
        maxValue={dietScore.water.maxNumGlasses}
        name={R.strings.foodlog.result.items.water}
        unit={R.strings.common.unit.glass}
        filledImage={R.images.glassFilled}
        emptyImage={R.images.glass}
      />
    )
  }

  makeHearts = () => {
    return (
      <Count
        value={this.diet.numEntriesHeartFriendly}
        recommended={dietScore.heartFriendly.minNumberOf}
        minValue={dietScore.heartFriendly.minNumberOf}
        maxValue={dietScore.heartFriendly.maxNumberOf}
        name={R.strings.foodlog.result.items.heart}
        unit={R.strings.common.unit.piece}
        filledImage={R.images.heartFull}
        emptyImage={R.images.heartEmpty}
      />
    )
  }

  makeFiber = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.fiber,
      R.strings.common.unit.g
    )
  }

  makeNuts = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.nuts,
      R.strings.common.unit.g
    )
  }

  makeVitamin = () => {
    return (
      <Flag
        value={this.diet.supplementPillWasTaken}
        name={R.strings.foodlog.result.items.vitamin}
        unit={R.strings.common.unit.piece}
        filledImage={R.images.omega}
        emptyImage={R.images.omegaUnchecked}
      />
    )
  }

  makeScore = (value, recommended, minValue, maxValue) => {
    return this.makeProgress(
      value,
      recommended,
      minValue,
      maxValue,
      R.strings.foodlog.result.items.score,
      '',
      1
    )
  }
}
