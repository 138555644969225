import React, { Fragment } from 'react'
import NutrutionBar from '../NutritionBar'
import { dietScore } from 'conf'
import { withAuth } from 'components/container/withAuth'
import Results from 'components/ui/DietReportSections/Results'

const unitFactor = ({ quantity, unit, units }) => {
  return units.find(u => u.name.trim() === unit.trim()).gramsPerUnit / 100 * quantity
}

const getSafe = (fn) => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}

class DietReportSectionsUI extends React.Component {
  state = {
    resultsVisible: false
  }

  render() {
    const { meals, auth: { user }, dailyDiaryCache } = this.props
    const { resultsVisible } = this.state

    const diet = meals
      .map(meal => meal.entries)
      .reduce((acc, curr) => [...acc, ...curr], [])
      .reduce((tally, curr) => {
        const factor = unitFactor(curr.data)
        tally['kcal'] += Math.round(curr.data.nutrition.kcalPer100Grams * factor)
        return tally
      }, { kcal: 0, proteins: 0, carbs: 0, fat: 0, calcium: 0, fruits: 0, vegetables: 0 })

    const recommended = getSafe(() => dietScore.kcal.menus[user.kcalMenu].recommended) || 0

    return (
      <Fragment>
        <div className="DietReportSection">
          <div className="DietReportItem">
            <h3 className="DietReportItem__title" data-tooltip="Antall kcal spist hittil i dag">Kcal</h3>
            {user.hasPermissions &&
              <p className="DietReportItem__recommended">Anbefalt ca {recommended} kcal.</p>
            }
            <NutrutionBar
              recommendedMin={recommended - 150}
              recommendedMax={recommended + 150}
              maxValue={recommended + 500}
              value={diet.kcal}
              unit="kcal"
            />
          </div>
        </div>
        <div className="DietReportSection__toggle">
          <button onClick={this.openResults}>
            Resultater
          </button>
        </div>
        {resultsVisible && (
          <Results
            close={this.closeResults}
            user={user}
            dailyDiaryCache={dailyDiaryCache} />
        )}
      </Fragment>
    )
  }

  openResults = () => {
    this.setState({ resultsVisible: true })
  }

  closeResults = () => {
    this.setState({ resultsVisible: false })
  }
}

const DietReportSections = withAuth(DietReportSectionsUI)

export default DietReportSections
