import { gql } from '@apollo/client'

const deleteUser = gql`
  mutation DeleteUser {
    user {
      deleteUser {
        status
      }
    }
  }
`

export default deleteUser
