import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./styles.scss";

const Calendar = ({ date, close, ...props }) => {
  const [month, setMonth] = useState(moment(date.clone().format("YYYY-MM-01")));

  const getListOfDaysInMonth = () => {
    const currentMonth: string = month.format("YYYY-MM-");
    const selectedDate: string = date.format("YYYY-MM-D");
    const today: string = moment().format("YYYY-MM-D");

    return Array(month.daysInMonth())
      .fill(undefined)
      .map((_, i) => ({
        number: i + 1,
        selectedClass:
          `${currentMonth}${i + 1}` === selectedDate ? "selected" : "",
        todayClass: `${currentMonth}${i + 1}` === today ? "today" : "",
      }));
  };

  const setDate = (e) => {
    if (e.target.tagName === "LI" && e.target.innerText) {
      const selectedDate =
        month.format("YYYY-MM-") + `00${e.target.innerText}`.slice(-2);
      props.match.navigate(`/kosthold/${selectedDate}`);
      close();
    }
  };

  return (
    <div>
      <div className="overlay calendar-overlay" onClick={close}></div>
      <div className="calendar">
        <div className="calendar-header">
          <button onClick={() => setMonth(month.clone().add(-1, "month"))}>
            <i className="icon-arrow-2-left"></i>
          </button>
          <strong>{month.format("MMMM YYYY")}</strong>
          <button onClick={() => setMonth(month.clone().add(1, "month"))}>
            <i className="icon-arrow-2-right"></i>
          </button>
        </div>
        <ul className="calendar-weekdays">
          <li>ma</li>
          <li>ti</li>
          <li>on</li>
          <li>to</li>
          <li>fr</li>
          <li>lø</li>
          <li>sø</li>
        </ul>
        <ul className="calendar-days" onClick={(e) => setDate(e)}>
          {Array(month.startOf("month").weekday())
            .fill(undefined)
            .map((_, i) => (
              <li key={i} className="empty"></li>
            ))}
          {getListOfDaysInMonth().map((day, i) => (
            <li key={i} className={`${day.todayClass} ${day.selectedClass}`}>
              {day.number}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const DateNavigation = ({ date, ...props }) => {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div>
      <nav className="DateNavigation">
        <Link
          to={`/kosthold/${date.clone().add(-1, "day").format("YYYY-MM-DD")}`}
          aria-label="Forrige"
          className="DateNavigation__prev"
        >
          <i className="icon-arrow-2-left"></i>
        </Link>
        <p className="DateNavigation__content">
          <span className="DateNavigation__weekday">
            {date.format("dddd")}{" "}
          </span>
          {date.format("D. MMMM YYYY")}
          <span className="DateNavigation__sep">|</span>
          <button
            className="DateNavigation__button"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <i className="icon-calendar DateNavigation__button-icon"></i> Velg
            dato
          </button>
        </p>
        <Link
          to={`/kosthold/${date.clone().add(1, "day").format("YYYY-MM-DD")}`}
          aria-label="Neste"
          className="DateNavigation__next"
        >
          <i className="icon-arrow-2-right"></i>
        </Link>
      </nav>
      {showCalendar && (
        <Calendar close={() => setShowCalendar(false)} date={date} {...props} />
      )}
    </div>
  );
};

export default DateNavigation;
