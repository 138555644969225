import { gql } from '@apollo/client'

const updateAllergies = gql`
  mutation UpdateAllergies (
    $data: JSONString,
  ){
    user {
      updateAllergies(
        data: $data,
      ) {
        status
      }
    }
  }
`
export default updateAllergies
