import React from 'react'
import styles from './GoldMemberAd.module.scss'

export default class GoldMemberAd extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <h3 className={styles.title}>Få mer ut av medlemskapet!</h3>
        <p className={styles.text}>Få din daglige kostholdscore, energifordeling, treningsdata og flere motivasjonsverktøy.</p>
        <a href="/roedeklubben/" className={styles.button}>Bli GULL-MEDLEM her!</a>
      </div>
    )
  }
}
