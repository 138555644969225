import {  Navigate, useParams } from 'react-router-dom'

import { graphql } from '@apollo/client/react/hoc'
import { useCoachingMembershipURL } from 'utils'
import { withAuth } from 'components/container/withAuth';
import Loading from 'components/ui/Loading';
import { gql } from '@apollo/client';





import { BuyMembership } from 'pages/BuyMembershipPage'


const membershipQuery = gql`
query Membership($membershipId: Int!) {
  user {
    membership(membershipId: $membershipId) {
      membershipId,
      membershipType,
      courseFirstMeetingStart,
      thisWeeksReport{
          reportId
      }
      lastReport {
        reportId
      }
      nextMeeting{
        courseMeetingId
      }
      currentWeekMeeting{
        courseMeetingId
      }
    }
  }
}`;

function MembershipRedirectWrapped({data, ...params}){
  const coach_url = useCoachingMembershipURL(data?.user?.membership)
  if (data.loading || !data?.user?.membership){
    return <Loading />
  }

  return <Navigate replace to={coach_url} />
}

const MembershipRedirect = graphql(membershipQuery, {
  options: ({ membershipId }) => ({ variables: { membershipId } }),
})(MembershipRedirectWrapped)

function CoachPage({ auth }){

    let params = useParams()
    let membershipId:number = params.membershipId


    if (membershipId||auth?.user?.defaultCoachingMembership?.membershipId){
      return <MembershipRedirect membershipId={membershipId||auth?.user?.defaultCoachingMembership?.membershipId} />
    }

    return <BuyMembership slug='medlemskap' />
  }

export default withAuth(CoachPage)
