import React from 'react'
import Downshift from 'downshift'
import AddFood from './AddFood'
import SearchResults from './SearchResults'
import { debounce } from 'utils'
import { graphql } from '@apollo/client/react/hoc'
import { GET_SEARCH_SUGGESTIONS } from 'queries'
import { withAuth } from 'components/container/withAuth'
import { getData, getMealName, SEARCH_MIN_CHARS } from './helpers'
import styles from './FoodSearch.module.scss'

const DEBOUNCE_TIME = 250
const PAGE_SIZE = 10

const fetchResults = (q, from = 0) => {
  window._foodSearchCache = window._foodSearchCache || {}

  const size = PAGE_SIZE
  const url = `${process.env.REACT_APP_SEARCH_URL}?q=${q}&size=${size}&from=${from}`
  const cache = window._foodSearchCache
  const cached = cache && cache[url]

  if (cached) return cached

  return fetch(url).then(response => {
    const data = response.json()
    cache[url] = data
    return data
  })
}

class FoodSearchWithData extends React.Component {
  state = {
    currValue: '',
    item: null,
    results: null,
    isLoading: false,
    offset: 0,
    hasNextPage: false,
  }

  inputRef = React.createRef()
  downshiftRef = React.createRef()

  componentWillUnmount() {
    this.setState = () => {}
  }

  componentDidUpdate(prevProps, prevState) {
    const { currValue, results, item } = this.state

    if (currValue.length >= SEARCH_MIN_CHARS && prevState.currValue !== currValue && !item) {
      this.setState({ isLoading: true })
      fetchResults(currValue, 0).then(this.updateResults)
    }

    if (currValue.length < SEARCH_MIN_CHARS && results) {
      this.setState({ results: null })
    }
  }

  onChange = item => this.setState({ item })

  onStateChange = debounce(({ inputValue }) => {
    if (typeof inputValue !== 'undefined') {
      this.setState({ currValue: inputValue })
    }
  }, DEBOUNCE_TIME)

  cancel = () => {
    this.setState({ item: null })
    this.inputRef.current.focus()
    this.downshiftRef.current.clearSelection()
  }

  updateResults = (searchResults, loadMore = false) => this.setState(state => {
    const results = loadMore && state.results ? state.results.concat(searchResults) : searchResults
    const offset = loadMore ? state.offset + PAGE_SIZE : PAGE_SIZE
    const isLoading = false
    const hasNextPage = results.length === PAGE_SIZE

    return { results, offset, isLoading, hasNextPage }
  })

  fetchMore = () => {
    const { currValue, offset } = this.state
    this.setState({ isLoading: true })
    fetchResults(currValue, offset).then(results => this.updateResults(results, true))
  }

  openEntryModal = item => this.setState({ item })

  render() {
    const { currValue, item, results, isLoading, hasNextPage } = this.state
    const { placeholder, data, meal, close } = this.props
    const fetchMore = hasNextPage ? this.fetchMore : null
    const search = { results, isLoading, fetchMore }

    return (
      <Downshift
        onStateChange={this.onStateChange}
        onChange={this.onChange}
        itemToString={i => (i === null ? '' : i.name)}
        ref={this.downshiftRef}
      >
        {({
          getInputProps,
          getItemProps,
          inputValue,
          itemToString,
          selectedItem,
          highlightedIndex,
        }) =>
          <div>
            <div className={styles.search}>
              <input
                {...getInputProps({placeholder})}
                className={styles.searchInput}
                ref={this.inputRef}
                autoFocus
              />
              <i className={`icon-magnifier-1 ${styles.searchIcon}`} />
            </div>
            {!data.loading &&
              <SearchResults
                {...{
                  data: getData(search, data, meal, currValue),
                  mealName: getMealName(meal),
                  currValue,
                  highlightedIndex,
                  getItemProps,
                  tag: meal,
                  openEntryModal: this.openEntryModal,
                }}
              />
            }
            {item &&
              <AddFood item={item} tag={meal} cancel={this.cancel} close={close} />
            }
          </div>
        }
      </Downshift>
    )
  }
}

const config = {
  options: ({
    auth: {
      user: { favorites = [] }
    }
  }) => {
    const recipes = favorites.filter(f => f.objType === 'recipe').map(f => f.objId)
    const ingredients = favorites.filter(f => f.objType === 'food_product').map(f => f.objId)

    return {
      variables: {
        recipes,
        ingredients,
      }
    }
  }
}

const GetSearchSuggestionsWrapper = graphql(GET_SEARCH_SUGGESTIONS, config)(FoodSearchWithData)

export default withAuth(GetSearchSuggestionsWrapper)
