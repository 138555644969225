import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { getSafe } from "utils";

import Message from "components/ui/Message";
import { canBeRenewed } from "../Membership/";

const QUERY = gql`
  query MembershipQuery {
    user {
      memberships(status: "active") {
        edges {
          node {
            id: membershipId
            membershipId
            status
            name
            membershipType
            renewalStatus
            paymentSource
            statusText
            courseSignup
            prepaidMeetings
            frequency
          }
        }
      }
    }
  }
`;

export default function RenewMembershipNotice() {
  const { data } = useQuery(QUERY);
  const memberships =
    getSafe(() => data.user.memberships.edges.map((edge) => edge.node)) || [];

  return (
    <>
      {memberships
        .filter((membership) => membership.courseSignup)
        .map((membership) => (
          <Message key={membership.id}>
            <Link to={`/medlemskap/${membership.id}`}>
              Fullfør påmelding på kurs
            </Link>
          </Message>
        ))}
      {memberships
        .filter((membership) => canBeRenewed(membership))
        .map((membership) => (
          <Message key={membership.id}>
            <Link to={`/medlemskap/${membership.id}`}>
              {membership.name} – {membership.statusText}, forny medlemskap
            </Link>
          </Message>
        ))}
    </>
  );
}
