import { useState } from 'react'
import InputSearch from './InputSearch'
import { useSearchParams } from 'react-router-dom'
import s from './styles/CourseMembership.module.scss'
import './styles/styles.scss'

type Props = {
  radius: string
  placeName: string
}

export default function PlaceSearch({ radius, placeName }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = Object.fromEntries(searchParams)
  const prefix = /^\d{4}$/.test(placeName) ? 'postnummer ' : ''

  const toggleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsOpen((value) => !value)
  }

  const setRadius = (radius: string) => {
    setIsOpen(false)
    setSearchParams({...urlParams, radius})
  }

  const setPlace = (placeId: string, placeName: string) => {
    setIsOpen(false)
    setSearchParams({...urlParams, placeId, placeName})
  }

  return (
    <section className={s.section}>
      <h2>Sted</h2>
      {isOpen ? (
        <div className={s.placeSearch}>
          <InputSearch placeName={placeName} setPlace={setPlace} />
          <div className={s.distanceSelector}>
            {['10', '20', '40', '60'].map((value) => (
              <button
                key={value}
                onClick={() => setRadius(value)}
                data-selected={value === radius}
              >
                {value}km
              </button>
            ))}
          </div>
        </div>
      ) : (
        <p><b>{radius}km</b> unna <b> {prefix}{placeName}</b></p>
      )}
      <p>
        <a href="/" onClick={toggleOpen}>{isOpen ? 'Lukk' : 'Endre'}</a>
      </p>
    </section>
  )
}


