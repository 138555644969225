import React from 'react'
import ReactMarkdown from 'react-markdown'
import s from './styles/Feedback.module.scss'
import markdownStyles from 'assets/styles/Markdown.module.scss'

export default function Feedback({ report }) {
  const feedback = JSON.parse(report.feedback)
  const { nettcourseInstructor: instructor } = report
  const image = `https://www.roede.com/cdn-cgi/image/w=500,h=500,q=60,f=auto/${instructor.image}`

  if (feedback && feedback.text) {
    return (
      <div className={s.feedback}>
        <div className={s.content}>
          {feedback.text && (
            <div>
              <ReactMarkdown className={markdownStyles.markdown}>
                {feedback.text}
              </ReactMarkdown>
            </div>
          )}
        </div>
        <div className={s.instructor}>
          <img src={image} alt={instructor.name} />
          <div className={s.contact}>
            <h3>Tilbakemeldinger eller noe du lurer på?</h3>
            <p>Ta kontakt så svarer jeg deg innen 24 timer.</p>
            <a href={`mailto:${instructor.email}`}>Kontakt</a>
          </div>
        </div>
      </div>
    )
  }

  return null
}
