import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CHECKOUT } from "queries";

import stripe from "utils/stripe";

import { ReactComponent as NEDiVEKTLogo } from "assets/img/NEDiVEKT-transparent.svg";
import appAndroidImg from "assets/img/app-android.svg";
import appIosImg from "assets/img/app-ios.svg";
import AuthContext from "components/auth/AuthContext";
import { CourseMembership } from "components/ui/CourseMembership/CourseMembership";
import courseMembershipStyles from "components/ui/CourseMembership/styles/CourseMembership.module.scss";
import Loading from "components/ui/Loading";
import RegistrationForm from "components/ui/RegistrationForm";
import registrationFormStyles from "components/ui/RegistrationForm/RegistrationForm.module.scss";
import ImgApp from "components/ui/Section/ImgApp";
import ImgRecipesApp from "components/ui/Section/ImgRecipesApp";
import SignupHero from "components/ui/SignupHero";
import s from "./BuyMembershipPage.module.scss";
import vippsImage from "./img/pay_with_vipps_pill_250_NO.svg";

import CourseOverlay from "./CourseOverlay";

const QUERY = gql`
  query MembershipType($slug: String) {
    membershipType(slug: $slug) {
      membershipTypeId
      membershipType
      descriptionType
      description
      imageUrl
      name
      trialPeriodDays
      courseSignup
      coupon
      allreadyMember
      defaultPrice
      oneTimePrices {
        edges {
          node {
            priceId
            numberOfMeetings
            displayPricePerMeeting
            displayPrice
            stripeMetadata
          }
        }
      }
      subscriptions {
        edges {
          node {
            priceId
            interval
            months
            displayPricePerMonth
            stripeMetadata
          }
        }
      }
    }
  }
`;

export function BuyMembership({
  trialPeriodDays,
  refetch,
  membershipType,
  courseSignup,
  defaultCoupon,
  oneTimeMembershipTypeId,
  subscriptionMembershipTypeId,
  subscriptions,
  oneTimePrices,
  defaultCourseId,
  defaultNettcourseInstructorId,
  defaultWeekday,
  freequency = 4,
  renewMembershipId,
}) {
  const [errorMsg, setErrorMsg] = useState("");
  const [coupon, setCoupon] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [membershipTypeId, setMembershipTypeId] = useState(null);
  const [paymentType, setPaymentType] = useState("subscription");
  const [userStatus, userCallBack] = useState(0);
  const [courseId, setCourseId] = useState(null);
  const [nettcourseInstructorId, setNettcourseInstructorId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const auth = useContext(AuthContext);
  const [checkout] = useMutation(CHECKOUT);
  const [radius, setRadius] = useState("20");
  const [placeId, setPlaceId] = useState("");

  const [placeName, setPlaceName] = useState("");
  const [weekday, setWeekday] = useState(null);
  let params = useParams();
  useEffect(() => {
    setNettcourseInstructorId(defaultNettcourseInstructorId);
  }, [defaultNettcourseInstructorId]);

  useEffect(() => {
    setCourseId(defaultCourseId);
  }, [defaultCourseId]);

  useEffect(() => {
    setWeekday(defaultWeekday);
  }, [defaultWeekday]);
  useEffect(() => {
    if (renewMembershipId) {
      userCallBack(10);
    }
  }, [renewMembershipId]);

  const setOneTimePrice = (priceId) => {
    setSelectedPrice(priceId);
    setMembershipTypeId(oneTimeMembershipTypeId);
    setPaymentType("oneTime");
  };

  const setSubscriptionPrice = (priceId) => {
    setSelectedPrice(priceId);
    setMembershipTypeId(subscriptionMembershipTypeId);
    setPaymentType("subscription");
  };

  const handleSubmit = ({ useVipps }) => {
    if (processing) return;

    if (userStatus >= 10 && selectedPrice) {
      setProcessing(true);

      const cancelUrl = window.location.href;
      const successUrl = `${window.location.protocol}//${window.location.hostname}/velkommen`;

      checkout({
        variables: {
          membershipType: "membership",
          cid: auth?.cid,
          stripePrice: selectedPrice,
          paymentSource: useVipps ? "vipps" : "stripe",
          cancelUrl,
          successUrl,
          courseId,
          nettcourseInstructorId,
          membershipTypeId,
          coupon,
          weekday,
          freequency,
          renewMembershipId,
        },
      })
        .then(({ data }) => {
          const { status, message, stripeSessionId, vippsUrl } = data.checkout;
          if (status === "success") {
            if (useVipps && vippsUrl) {
              window.location = vippsUrl;
            } else {
              stripe.then(function (result) {
                result
                  .redirectToCheckout({
                    sessionId: stripeSessionId,
                  })
                  .then(function (result) {
                    setProcessing(false);
                  });
              });
            }
          } else {
            setProcessing(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (String(error).includes("No such coupon")) {
            setErrorMsg("Ugyldig rabattkode");
          } else {
          }
          setProcessing(false);
        });
    }
  };
  function setCourseIdCallback({ courseId, nettcourseInstructorId, weekday }) {
    if (courseId) {
      setCourseId(courseId);
      setNettcourseInstructorId(null);
      setWeekday(null);
    } else {
      setCourseId(null);
      setNettcourseInstructorId(nettcourseInstructorId);
      setWeekday(weekday);
    }
  }

  const setDiscountCode = (event) => {
    setCoupon(event.target.value);
    setErrorMsg("");
  };

  const closeOverlay = () => {
    setPlaceId("");
    setPlaceName("");
  };
  const both = subscriptions.length > 0 && oneTimePrices.length > 0;

  return (
    <>
      <div className={s.appAdLayout}>
        <div className={courseMembershipStyles.buyMembershipPage}>
          <section className={courseMembershipStyles.section}>
            {both && <h2>Velg oppfølging</h2>}
            {oneTimePrices.length > 0 && (
              <>
                {both ? <h3>Pakke</h3> : <h2>Velg pakke</h2>}
                <div className={courseMembershipStyles.options}>
                  {oneTimePrices.map((price) => (
                    <OneTimeOption
                      key={price.priceId}
                      price={price}
                      selectedPrice={selectedPrice}
                      setSelectedPrice={setOneTimePrice}
                    />
                  ))}
                </div>
              </>
            )}
            {subscriptions.length > 0 && (
              <>
                {both ? (
                  <h2>
                    {subscriptions.length === 1
                      ? "Medlemskap"
                      : "Medlemskap, velg bindingstid"}
                  </h2>
                ) : (
                  <h2>
                    {subscriptions.length === 1
                      ? "Månedspris"
                      : "Velg bindingstid"}
                  </h2>
                )}
                <div className={courseMembershipStyles.options}>
                  {subscriptions.map((price) => (
                    <Option
                      key={price.priceId}
                      price={price}
                      selectedPrice={selectedPrice}
                      setSelectedPrice={setSubscriptionPrice}
                    />
                  ))}
                </div>
                <div className={s.freeTrialInfo}>
                  {trialPeriodDays ? (
                    <>
                      {!courseSignup ? (
                        <>
                          <strong>
                            {" "}
                            Prøv {trialPeriodDays} dager gratis, ingen
                            bindingstid
                          </strong>
                          <p>
                            Hvis medlemskapet sies opp i gratisperioden, trekkes
                            ikke betalingskortet.
                          </p>
                        </>
                      ) : (
                        <>
                          <strong> Prøv {trialPeriodDays} dager gratis</strong>
                          <p>
                            Hvis medlemskapet sies opp i gratisperioden, trekkes
                            ikke betalingskortet.
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {
                    <ul>
                      <li>
                        Medlemskapet løper inntil du selv sier det opp,
                        uavhengig av bindingstid.
                      </li>
                      <li>
                        Oppsigelse i bindingstid: Medlemskap avsluttes ved
                        bindingstidens sluttdato.
                      </li>
                      <li>
                        Oppsigelse uten/etter bindingstid: En måned pluss det
                        gjenstående av inneværende betalingsmåned.
                      </li>
                    </ul>
                  }
                </div>
              </>
            )}
          </section>
          {!renewMembershipId && (
            <section className={courseMembershipStyles.section}>
              <h2>Lag bruker eller logg inn</h2>
              <RegistrationForm
                userCallBack={userCallBack}
                userPostalCodeCallBack={setPostalCode}
                callback={refetch}
              />
            </section>
          )}

          {userStatus >= 10 &&
            courseSignup &&
            !defaultCourseId &&
            !defaultNettcourseInstructorId && (
              <CourseMembership
                postalCode={postalCode}
                membershipId={null}
                slug={params.slug}
                setCourseIdCallback={setCourseIdCallback}
                refetch={() => {}}
              />
            )}

          {userStatus >= 10 &&
            (!courseSignup || courseId || nettcourseInstructorId) && (
              <section className={courseMembershipStyles.section}>
                <h2>Gå til betaling</h2>
                {paymentType === "subscription" && (
                  <div className={registrationFormStyles.registrationform}>
                    {defaultCoupon && (
                      <input
                        type="hidden"
                        name="coupon"
                        required
                        value={defaultCoupon}
                      />
                    )}
                    {!defaultCoupon && (
                      <>
                        <label className={s.coupon} data-field="coupon">
                          <input
                            type="text"
                            name="coupon"
                            required
                            value={coupon}
                            onChange={setDiscountCode}
                          />
                          <span>Rabattkode</span>
                        </label>
                        {errorMsg && <p className={s.error}>{errorMsg}</p>}
                      </>
                    )}
                    <button
                      className={s.purchasesebutton}
                      disabled={processing || !selectedPrice}
                      onClick={handleSubmit}
                    >
                      {selectedPrice
                        ? `Gå til betaling`
                        : `Velg abonnement for å fortsette`}
                    </button>
                  </div>
                )}
                {paymentType === "oneTime" && (
                  <div style={{ marginTop: "2rem" }}>
                    <button
                      className={s.purchasesebutton}
                      disabled={processing || !selectedPrice}
                      onClick={handleSubmit}
                    >
                      {selectedPrice
                        ? `Betal med kort eller Klarna`
                        : `Velg abonnement for å fortsette`}
                    </button>

                    {!processing && selectedPrice && (
                      <div style={{ marginTop: "2rem" }}>
                        <button
                          onClick={() => handleSubmit({ useVipps: true })}
                        >
                          <img src={vippsImage} alt="Betal med Vipps" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </section>
            )}
        </div>
        <div className={s.appAd}>
          {membershipType === "recipes" ? (
            <>
              <ImgRecipesApp />
              <div className={s.appDownload}>
                <div>Last ned:</div>
                <a
                  href="https://apps.apple.com/app/matnyttig/id6446114563"
                  className={s.appDownloadIos}
                >
                  <img src={appIosImg} alt="Apple App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.roede.Recipes&utm_source=roede&utm_medium=intern&utm_campaign=forside"
                  className={s.appDownloadAndroid}
                >
                  <img src={appAndroidImg} alt="Google Play Store" />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className={s.appLogo}>
                {membershipType !== "gold" && (
                  <span>Inkluderer full tilgang til appen</span>
                )}
                <NEDiVEKTLogo />
              </div>
              <ImgApp />
              <div className={s.appDownload}>
                <div>Last ned:</div>
                <a
                  href="https://itunes.apple.com/app/apple-store/id1439940296?pt=119365671&ct=forside&mt=8"
                  className={s.appDownloadIos}
                >
                  <img src={appIosImg} alt="Apple App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.roede.Roede&utm_source=roede&utm_medium=intern&utm_campaign=forside"
                  className={s.appDownloadAndroid}
                >
                  <img src={appAndroidImg} alt="Google Play Store" />
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      {membershipType === "membership" && placeId !== "" && (
        <CourseOverlay
          radius={radius}
          placeId={placeId}
          slug={params.slug}
          close={closeOverlay}
        />
      )}
    </>
  );
}

export default function BuyMembershipPage() {
  let params = useParams();

  const { data, loading, refetch } = useQuery(QUERY, {
    variables: { slug: params.slug ? params.slug : "medlemskap" },
  });

  if (loading || !data) {
    return <Loading />;
  }

  const {
    trialPeriodDays,
    membershipType,
    name,
    description,
    imageUrl,
    courseSignup,
    coupon: defaultCoupon,
    membershipTypeId,
    subscriptions,
    oneTimePrices,
  } = data?.membershipType;
  const _subscriptions = subscriptions.edges.map((edge) => edge.node);
  const _oneTimePrices = oneTimePrices.edges.map((edge) => edge.node);

  return (
    <div>
      <SignupHero
        title={name}
        membershipType={membershipType}
        description={description}
        image={
          imageUrl
            ? `https://www.roede.com/cdn-cgi/image/w=1280,h=720,q=60,f=auto/${imageUrl}`
            : null
        }
      />
      <BuyMembership
        trialPeriodDays={trialPeriodDays}
        subscriptions={_subscriptions}
        oneTimePrices={_oneTimePrices}
        membershipType={membershipType}
        courseSignup={courseSignup}
        defaultCoupon={defaultCoupon}
        oneTimeMembershipTypeId={membershipTypeId}
        subscriptionMembershipTypeId={membershipTypeId}
        refetch={refetch}
      />
    </div>
  );
}

export function Option({ price, selectedPrice, setSelectedPrice }) {
  const metadata = JSON.parse(price.stripeMetadata);
  const contract = metadata?.contract;
  const contract_description = metadata?.contract_description;

  return (
    <button
      type="button"
      data-selected={selectedPrice === price.priceId}
      onClick={() => setSelectedPrice(price.priceId)}
    >
      <b>
        {price.interval !== "1 mnd" && <>{price.interval} - </>}
        {price.displayPricePerMonth},- pr mnd
      </b>
      <span>
        {contract && (
          <>
            {contract === "0"
              ? "ingen bindingstid"
              : `${contract} måneder bindingstid`}
            * <br />
          </>
        )}
        {contract_description && <>{contract_description}</>}
      </span>
    </button>
  );
}

export function OneTimeOption({ price, selectedPrice, setSelectedPrice }) {
  return (
    <button
      data-selected={selectedPrice === price.priceId}
      onClick={() => setSelectedPrice(price.priceId)}
    >
      <b>
        {price.numberOfMeetings} møter: {price.displayPrice},-
      </b>
      <span>{price.displayPricePerMeeting},- per møte</span>
    </button>
  );
}
