// @flow

import { gql } from '@apollo/client'

const exportUserData = gql`
  mutation ExportUserData {
    user {
      exportUserData {
        status
        msg
      }
    }
  }
`

export default exportUserData
