import React from 'react'
import { gql, useQuery } from '@apollo/client'
import Wrapper from 'components/ui/Wrapper'
import CourseUpdateUi from 'components/ui/CourseUpdate'
import Header from './Header'
import Loading from 'components/ui/Loading'
import {useParams} from 'react-router-dom'
import s from './MeetingPage.module.scss'


class ReportPage extends React.Component {
  render() {    
    if (this.props.data.loading) {
      return (
        <main className={s.main}>
          <Loading />
        </main>
      )
    }   
  
    const courseUpdate = this.props.data?.data?.courseUpdate

    return (
      <React.Fragment>
        <main className={s.main}>
          <div className={s.wrapper}>
            <div className={s.header}>
              <Wrapper className={s.headerWrapper}>
                <Header
                  week={courseUpdate?.week}
                  prev={courseUpdate?.prevWeek}
                  next={courseUpdate?.nextWeek}
                />
              </Wrapper>
            </div>
            <div>
            </div>
            {courseUpdate && <CourseUpdateUi courseUpdate={courseUpdate} />}
          </div>
        </main>
      </React.Fragment>
    )
  }
}

const QUERY = gql`
query CourseUpdate(
  $week: String
) {
    courseUpdate(week: $week){
    courseUpdateId
    title
    week
    description
    videoEmbed
    pdfUri
    googleStorageFile
    downloadUrl
    prevWeek
    nextWeek
  }
}`

const CourseUpdate = ({...props}) => {  
  let params = useParams()
  const variables = { week: params?.week }
  const data = useQuery(QUERY, { variables }, { fetchPolicy: "no-cache" })
  
  return <ReportPage data={data} />
}


export default CourseUpdate
