import s from './BuyMembershipPage.module.scss'
import { XMarkIcon } from '@heroicons/react/24/solid'

import { useDataSearch } from '../../components/ui/CourseMembership/utils'
import Courses from '../../components/ui/CourseMembership/Courses'
import Loading from 'components/ui/Loading';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom'

type Props = {
    radius: string;
    placeId: string;    
    slug: string;
    close: () => void
}

type CourseDescriptionProps = {
    courseTypes: any[];
    courseType: number;
    setCourseType: (number) => void
}

const CourseOverlay = ({
    placeId,
    radius,
    slug,
    close
}: Props) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const variables = { placeId, radius, slug }
    const { courses, nettCourses, courseTypes, loading } = useDataSearch(variables)
    const [courseType, setCourseType] = useState()

    const refetch = () => {}
    const membershipId = null    

    const closeCourseOverlay = () => {
        searchParams.delete('courseType');
        setSearchParams(searchParams);
        close();
    }

    return <>
        <div className={s.overlay}></div>
        <div className={s.modal}>
            <button className={s.closeButton} onClick={closeCourseOverlay}>
                <XMarkIcon />
            </button>
            <div className={s.wrapper}>
                {
                    loading ? <Loading/> : <div>
                        <CourseDescription courseTypes={courseTypes} courseType={courseType} setCourseType={setCourseType} />
                        <Courses
                            key={courseType}
                            membershipId={membershipId}
                            courseData={courses}
                            nettCourses={nettCourses}
                            courseTypes={courseTypes}
                            refetch={refetch}
                            setCourseIdCallback={null}
                        />
                    </div>
                }
                
            </div>
        </div>
    </>
}

const CourseDescription = ({
    courseTypes,
    courseType,
    setCourseType
}: CourseDescriptionProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParams = Object.fromEntries(searchParams)

    const setCourseTypeId = (courseType: string) => {
        setSearchParams({ ...urlParams, courseType })
        setCourseType(courseType)        
    }

    return <section className={s.courseDescriptionSection}>
        <h2>Oppfølgingstype</h2>
        <>
            <p>
                Hvilken oppfølging passer for deg?
            </p>
            <div className={s.optionsCourseDescription}>
                {courseTypes.map(({ id, name, description }) => (
                    <button
                        key={id}
                        data-selected={id === courseType}
                        onClick={() => setCourseTypeId(id)}
                    >
                        <b>{name}</b>
                        <span>{description}</span>
                    </button>
                ))}
            </div>
        </>
    </section>
}

export default CourseOverlay