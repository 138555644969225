import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { TOGGLE_FAVORITE } from 'queries'


class ToggleFavoriteContainer extends React.Component {
  isLoading = false

  state = {
    isFavorite: this.props.isFavorite,
  }

  toggle = () => {
    const { objectId, objectType, mutate } = this.props

    this.setState(prevState => ({ isFavorite: !prevState.isFavorite }))

    if (! this.isLoading) {
      this.isLoading = true

      mutate({
        variables: { objectId, objectType }
      }).then(({ data }) => {
        const { isFavorite } = data.diary.toggleFavorite

        this.isLoading = false
        this.setState({ isFavorite })
      })
    }
  }

  render() {
    return this.props.render({
      isFavorite: this.state.isFavorite,
      toggle: this.toggle,
    })
  }
}

const ToggleFavoriteWrapper = graphql(TOGGLE_FAVORITE)(ToggleFavoriteContainer)

export default ToggleFavoriteWrapper