import { gql } from '@apollo/client'

const getIngredients = gql`
  query GetIngredients (
    $ingredients: [Int]
    $customIngredients: [Int]
  ) {
    kitchen {
      ingredients (objId: $ingredients) {
        edges {
          node {
            objectId
            o2Id
            name
            kcal100g
            protein100g
            iron100g
            carbs100g
            fat100g
            milk100g
            salt100g
            fiber100g
            sugar100g
            calcium100g
            fruits100g
            veg100g
            saturatedFat100g
            monounsaturatedFat100g
            polyunsaturatedFat100g
            vitaminA100g
            vitaminD100g
            vitaminC100g
            allergens
            dietCategory
            isHeartFriendly
            units {
              edges {
                node {
                  unit
                  gramsPerUnit
                }
              }
            }
          }
        }
      }
    }
    diary {
      foodProductCustomResult (objectId: $customIngredients) {
        edges {
          node {
            objectId
            o2Id
            name
            kcal100g
            data
          }
        }
      }
    }
  }
`

export default getIngredients