import React from 'react'
import DeleteAccount from './DeleteAccount'
import { graphql } from '@apollo/client/react/hoc'
import { DELETE_USER, EXPORT_USER_DATA } from 'queries'
import styles from '../Popup.module.scss'
import s from './Gdpr.module.scss'
import R from 'res/R'

const CACHE_KEY = 'user.exportUserData'
const ONE_DAY = 1

class GdprModalUI extends React.Component {
  state = {
    exporting: false,
    dataHasBeenExported: false,
    isDeleteVisible: false
  }

  componentDidMount() {
    const dataHasBeenExported = this.checkIfHasBeenExported()
    this.setState({ dataHasBeenExported })
  }

  render() {
    const { exporting, dataHasBeenExported, isDeleteVisible } = this.state
    const { gdpr } = R.strings.profile

    return (
      <div className={styles.overlay}>
        <div className={styles.modal}>
          <header className={styles.modalHeader}>
            <h1 className={styles.modalTitle}>{gdpr.title}</h1>
            <button
              className={styles.modalClose}
              onClick={this.props.close}
              aria-label="Lukk">
              <i className="icon-close" />
            </button>
          </header>
          <div className={styles.modalBody}>
            <div className={s.container}>
              <p className={s.content}>{gdpr.content}</p>
              <button
                type="button"
                className={[s.button, s.buttonDownload].join(' ')}
                data-exporting={exporting}
                onClick={this.handleDownload}>
                {gdpr.downloadButton}
              </button>
              {dataHasBeenExported && (
                <p className={s.exported}>
                  {gdpr.dataHasBeenExported}
                </p>
              )}
              <button
                type="button"
                className={[s.button, s.buttonDelete].join(' ')}
                onClick={this.openDeleteAccount}>
                {gdpr.deleteButton}
              </button>
            </div>
            <DeleteAccount
              close={this.closeDeleteAccount}
              delete={this.props.delete}
              isVisible={isDeleteVisible} />
          </div>
        </div>
      </div>
    )
  }

  handleDownload = async () => {
    const { exporting, dataHasBeenExported } = this.state

    if (!exporting && !dataHasBeenExported) {
      this.setState({ exporting: true })

      const response = await this.props.export()

      const exportDataStatus = response &&
        response.data &&
        response.data.user &&
        response.data.user.exportUserData &&
        response.data.user.exportUserData.status

      if (exportDataStatus === 'success') {
        this._storeData()
        this.setState({ dataHasBeenExported: true })
      }

      if (exportDataStatus !== 'success') {
        alert(R.strings.common.error.sendError)
      }

      this.setState({ exporting: false })
    }
  }

  openDeleteAccount = () => {
    this.setState({ isDeleteVisible: true })
  }

  closeDeleteAccount = () => {
    this.setState({ isDeleteVisible: false })
  }

  checkIfHasBeenExported = () => {
    const value = this._getData()

    if (!value) {
      return false
    }

    const date = new Date(JSON.parse(value))
    const now = new Date()
    const diff = Math.round((now - date) / (24 * 60 * 60 * 1000))

    if (diff >= ONE_DAY) {
      this._clearData()
      return false
    }

    return true
  }

  _storeData = () => {
    if (localStorage) {
      const value = JSON.stringify(new Date())
      localStorage.setItem(CACHE_KEY, value)
    }
  }

  _getData = () => {
    if (localStorage) {
      return localStorage.getItem(CACHE_KEY)
    }

    return null
  }

  _clearData = () => {
    if (localStorage) {
      localStorage.removeItem(CACHE_KEY)
    }
  }
}

const deleteConfig = {
  props: ({ mutate }) => ({
    delete: () => mutate()
  })
}

const exportConfig = {
  props: ({ mutate }) => ({
    export: () => mutate()
  })
}

const ExportUserDataWrapper = graphql(EXPORT_USER_DATA, exportConfig)(GdprModalUI)
const DeleteUserWrapper = graphql(DELETE_USER, deleteConfig)(ExportUserDataWrapper)

export default DeleteUserWrapper
