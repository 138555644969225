import { gql } from '@apollo/client';

const getCustomFoodProduct = gql`
  query getCustomFoodProduct ($objectId: [Int]) {
    request {
      diary {
        foodProductCustomResult (objectId: $objectId) {
          edges {
            node {
              objectId
              o2Id
              name
              kcal100g
              data
            }
          }
        }
      }
    }
  }
`;

export default getCustomFoodProduct;