import Panel from '../../../shared/Panel'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY } from '../../../queries'
import './styles.scss'

const ReviewItem = ({ title, name, value, updateReview }) => (
  <div className="dailyReviewSection">
    <h3>{title}</h3>
    <label aria-label="Bra">
      <input type="radio" name={name} value="well" defaultChecked={value==='well'} onChange={updateReview} />
      <i className="icon-gr-good"></i>
    </label>
    <label aria-label="OK">
      <input type="radio" name={name} value="ok" defaultChecked={value==='ok'} onChange={updateReview} />
      <i className="icon-gr-ok"></i>
    </label>
    <label aria-label="Dårlig">
      <input type="radio" name={name} value="bad" defaultChecked={value==='bad'} onChange={updateReview} />
      <i className="icon-gr-bad"></i>
    </label>
  </div>
)

const DailyReviewWithMutation = ({ loading, entry, updateReview }) => (
  <Panel loading={loading}>
    <Panel.Header>
      <Panel.Title>Din vurdering av dagen</Panel.Title>
    </Panel.Header>
    <Panel.Body className="dailyReview">
       <form name="dailyReviewRatings" className="dailyReviewRatings" action="" method="post" onSubmit={e => e.preventDefault()}>
        <ReviewItem key={entry.registrationId ? 'sleep' : 'sleepLoaded'} title="Søvn" name="sleep" value={entry.data.sleep} updateReview={updateReview} />
        <ReviewItem key={entry.registrationId ? 'stress' : 'stressLoaded'} title="Stress" name="stress" value={entry.data.stress} updateReview={updateReview} />
        <ReviewItem key={entry.registrationId ? 'comfort' : 'comfortLoaded'} title="Trivsel" name="comfort" value={entry.data.comfort} updateReview={updateReview} />
      </form>
      <form name="dailyReviewComments" className="dailyReviewComments" action="" method="post" onSubmit={e => { e.preventDefault(); updateReview(e); }}>
        <textarea
          key={entry.registrationId ? 'comments' : 'commentsLoaded'}
          name="comments"
          placeholder="Skriv kommentarer..."
          defaultValue={entry.data.comments}
          required
        ></textarea>
        <button type="submit">Lagre kommentar</button>
      </form>
    </Panel.Body>
  </Panel>
)

const DailyReview = graphql(ADD_ENTRY, {
  props: ({ ownProps: { entry, date, refetch }, mutate }) => ({
    updateReview: (event) => {
      const element = (event.target.tagName.toLowerCase() === 'form') ? event.target.querySelector('textarea') : event.target

      document.querySelector('.dailyReview').setAttribute('data-processing', true)

      entry.data[element.getAttribute('name')] = element.value
      entry.data['source'] = 'web'
      entry.data = JSON.stringify(entry.data)

      mutate({
        variables: entry,
      }).then(({ data }) => {
        refetch()
        document.querySelector('.dailyReview').removeAttribute('data-processing')
      }).catch((error) => {
        console.log(error)
      })
    },
  }),
})(DailyReviewWithMutation)

export default DailyReview
