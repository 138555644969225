import { gql } from '@apollo/client';

const getGoals = gql`
query User {
  user {
    userData {
      userId
      motivation
    }
  }
}`;


export default getGoals;
