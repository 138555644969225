import { useSearchParams } from "react-router-dom";

const getSafe = (fn, def = undefined) => {
  try {
    return fn();
  } catch (e) {
    return def;
  }
};

const formatDate = (dateTime) =>
  `${dateTime.substring(8, 10)}.${dateTime.substring(5, 7)}.${dateTime.substring(2, 4)}`;

const debounce = (func, wait, options) => {
  let lastArgs,
    lastThis,
    maxWait,
    result,
    timerId,
    lastCallTime,
    lastInvokeTime = 0,
    leading = false,
    maxing = false,
    trailing = true;

  wait = Number(wait) || 0;

  function invokeFunc(time) {
    let args = lastArgs,
      thisArg = lastThis;

    lastArgs = lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  }

  function leadingEdge(time) {
    lastInvokeTime = time;
    timerId = setTimeout(timerExpired, wait);

    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    let timeSinceLastCall = time - lastCallTime,
      timeSinceLastInvoke = time - lastInvokeTime,
      result = wait - timeSinceLastCall;

    return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
  }

  function shouldInvoke(time) {
    let timeSinceLastCall = time - lastCallTime,
      timeSinceLastInvoke = time - lastInvokeTime;

    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      (maxing && timeSinceLastInvoke >= maxWait)
    );
  }

  function timerExpired() {
    const time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }

    timerId = setTimeout(timerExpired, remainingWait(time));
  }

  function trailingEdge(time) {
    timerId = undefined;

    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = undefined;
    return result;
  }

  function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function debounced() {
    let time = Date.now(),
      isInvoking = shouldInvoke(time);
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
};

const formatQuantity = (quantity) => {
  let value = String(quantity)
    .replace(/[^\d,.]/g, "")
    .replace(/\./g, ",")
    .replace(/,{1,}/g, ",");

  let [int, dec] = value.split(",");

  int = int === "00" ? "0" : int;

  value = value.includes(",") ? `${int},` : int;

  if (dec) {
    dec = dec.substr(0, 1);
    int = int === "" ? "0" : int;
    value = `${int},${dec}`;
  }

  return value;
};

const formatAmount = (amount) => amount.toLocaleString("nb-NO");

function getCalories(registration) {
  const {
    data: { units, unit, quantity },
  } = registration;
  const factor =
    (units.find((u) => u.name.trim() === unit.trim()).gramsPerUnit / 100) *
    quantity;
  return Math.round(registration.data.nutrition.kcalPer100Grams * factor);
}

const getCourseMeetingId = (membership) => {
  if (membership?.courseFirstMeetingStart) {
    const courseFirstMeetingStart = new Date(
      membership?.courseFirstMeetingStart
    ).toISOString();
    const currentDate = new Date().toISOString();

    if (courseFirstMeetingStart > currentDate) {
      return membership?.nextMeeting?.courseMeetingId;
    }
  }

  if (membership?.courseMeetingId) {
    return membership?.courseMeetingId;
  }

  // this is current week meetings
  if (membership?.currentWeekMeeting?.courseMeetingId) {
    return membership?.currentWeekMeeting?.courseMeetingId;
  }

  // this is next meeting (can be current and can be next week, depending on current date...)
  if (membership?.nextMeeting?.courseMeetingId) {
    return membership?.nextMeeting?.courseMeetingId;
  }

  return null;
};

const useCoachingMembershipURL = (membership) => {
  const [searchParams] = useSearchParams();
  if (!membership) return "/coach/";

  const redirectStatus = searchParams.get("redirect_status");
  const redirect_status = redirectStatus
    ? `?redirect_status=${redirectStatus}`
    : "";
  const reportId =
    membership?.reportId ||
    membership?.thisWeeksReport?.reportId ||
    membership?.lastReport?.reportId;
  const courseMeetingId = getCourseMeetingId(membership);

  if (reportId) {
    return `/coach/${membership.membershipId}/rapport/${reportId}${redirect_status}`;
  } else if (courseMeetingId) {
    return `/coach/${membership.membershipId}/mote/${courseMeetingId}${redirect_status}`;
  } else {
    return "/coach/";
  }
};

export {
  getSafe,
  formatDate,
  debounce,
  formatQuantity,
  formatAmount,
  getCalories,
  useCoachingMembershipURL,
};
