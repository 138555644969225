import { gql } from '@apollo/client'

const deleteCustomMeal = gql`
  mutation DeleteCustomMeal (
    $objectId: Int!
  ) {
    diary {
      deleteCustomMeal (
        objectId: $objectId
      ) {
        status
      }
    }
  }
`

export default deleteCustomMeal
