import Wrapper from "components/ui/Wrapper";
import moment from "moment";
import React from "react";
import s from "./styles/WaitingForFeedback.module.scss";

export default class WaitingForFeedback extends React.Component {
  componentDidMount() {
    document.body.classList.add("orange-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("orange-bg");
  }

  render() {
    const date = moment(this.props.date).format("D/M [kl] HH");

    return (
      <Wrapper>
        <div className={s.wrapper}>
          <div className={s.text}>
            <p className={s.introText}>
              <b>Bra utført!</b> Dine svar er sendt til din veileder, og du vil
              få svar innen <u>{date}</u>.
            </p>
            <p>I mellomtiden anbefaler vi deg å gjøre deg kjent med Roede:</p>
          </div>
          <ul className={s.linkItems}>
            <li className={s.linkItem}>
              <a
                href="https://www.roede.com/hvordan-fungerer-nettkurset"
                className={s.link}
              >
                Gå inn på denne siden og les om nettkurset
              </a>
            </li>
            <li className={s.linkItem}>
              <a href="/gull" className={s.link}>
                Dersom du ikke allerede har lastet ned NEDiVEKT-appen, kan du
                gjøre det nå.
              </a>
            </li>
            <li className={s.linkItem}>
              <a href="/oppskrifter/" className={s.link}>
                Ta gjerne en tur innom Roede-kjøkkenet og la deg inspirere av
                alle våre oppskrifter.
              </a>
            </li>
          </ul>
        </div>
      </Wrapper>
    );
  }
}
