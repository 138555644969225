import React from 'react'
import { UpdateContactForm } from './UpdateContactForm'
import { PopupButton } from 'components/ui/Popup'





export default function GoalsButton({...props}) {  
  return(
    <PopupButton  title="Kontaktinformasjon" show={props.show}>
      <UpdateContactForm {...props}  />
    </PopupButton>
  )
}
