import React from 'react'
import SubNavigation from 'shared/SubNavigation'
import FoodProductsList from './FoodProductsList'
import FavoriteFoodProductsList from './FavoriteFoodProductsList'

import '../DailyMenusPage/styles.scss'

const FoodProductsPage = () => (
  <div className="myFoodPage">
    <h1>Min mat</h1>
    <SubNavigation selectedOption="Matvarer" />
    <div className="container">
      <div className="dataTables">
        <FoodProductsList />
        <FavoriteFoodProductsList />
      </div>
      <div className="textContent">
        <h2>Egne matvarer</h2>
        <p>Her kan du slette og redigere de matvarene du selv har lagt inn.</p>
        <p>Når du lagrer egne matvarer må du legge inn andel frukt eller grønnsaker denne matvaren inneholder for at det skal telle med i oppsummeringen av ditt kosthold. Det samme gjelder for kalsium.</p>
        <p><strong>Noen eksempler</strong> <br />Legger du f. eks. inn din egen juice, må du skrive inn 100 g i feltet for frukt. Legger du inn en ferdigmiddag må du skrive inn antall gram grønnsaker retten inneholder per 100 g.</p>
        <h2>Favoritter</h2>
        <p>Her kan du slå av favorittmerkingen på matvarer som du tidligere har merket som favoritt. <br />Fordelen med å bruke favorittmerking er at de matvarene du bruker oftest vil komme øverst i søkeresultatet ditt når du registrerer mat.</p>
      </div>
    </div>
  </div>
)

export default FoodProductsPage
