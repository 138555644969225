import { gql } from '@apollo/client';

const getFrequentlyUsedUnits = gql`
  query GetFrequentlyUsedUnits($foodId: Int!, $foodType: String!) {
    request {
      diary {
        frequentlyUsedUnits(foodId: $foodId, foodType: $foodType) {
          name
          quantity
        }
      }
    }
  }
`;

export default getFrequentlyUsedUnits;
