import React from 'react'
import AddCustomFoodProductModal from './AddCustomFoodProductModal'
import s from './AddCustomFoodButton.module.scss'

class AddCustomFoodButton extends React.Component {
  state = {
    isModalOpen: false
  }

  openModal = () => this.setState({ isModalOpen: true })

  closeModal = () => this.setState({ isModalOpen: false })

  render() {
    const {meal, currValue, openEntryModal } = this.props

    return (
      <React.Fragment>
        <div className={s.addCustomFood}>
          <button className={s.addCustomFoodButton} onClick={this.openModal}>
            <i className="icon-plus-circle"></i> Klikk her for å legge til matvaren
          </button>
        </div>
        {this.state.isModalOpen &&
          <AddCustomFoodProductModal
            tag={meal}
            inputValue={currValue}
            openEntryModal={openEntryModal}
            close={this.closeModal}
          />
        }
      </React.Fragment>
    )
  }
}

export default AddCustomFoodButton
