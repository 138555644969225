import React, { useState } from 'react';
import { useMutation } from '@apollo/client'
import { GET_USER_DATA } from 'queries';
import { UPDATE_ALLERGIES } from 'queries'
import {PopupButton} from 'components/ui/Popup'
import formStyles from 'assets/styles/Form.module.scss'
import styles from '../Popup.module.scss';
import { profileSettings } from '../../../../conf';

interface Props {
  allergies?: any;
  close?: () => void;
}

export default function Allergies(props) {
  return(
    <PopupButton  title="Matallergier" show={props.show}>
      <AllergiesContent {...props}  />
    </PopupButton>
  )
}

export const AllergiesContent:React.FC<Props> = ({allergies: allergiesTmp, close, ...props}) => {
  const [allergies, setAllergies] = useState(allergiesTmp)

  const [save, {loading}] = useMutation(UPDATE_ALLERGIES, { 
    onCompleted() {
      close();
    }
  });

  const submit = async (event) => {
    event.preventDefault()
    const variables = {data: JSON.stringify(allergies)}

    try {
      await save({variables, refetchQueries: [GET_USER_DATA]})
    } catch (error) {
      alert(error)
    }
  }
  
  function getClassName(key:string) {
    return allergies[key] ? styles.selectedItemActive : styles.selectedItem;
  }

  function handleChange(e:React.MouseEvent<HTMLDivElement, MouseEvent>, key:string) {
    let value = {}
    value[key] = !allergies[key];
    setAllergies({...allergies, ...value})
  }

  function resetAll(e:React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const chl = {};

    Object.keys(allergies).forEach(function(key, d) {
      chl[key] = false;
    });

    setAllergies(chl);
  }
  
  return (<form  className={formStyles.form} onSubmit={submit}>
    <div className={styles.modalBody}>
      <div className={styles.modalContainer}>
        <div className={styles.selectedItemWrapper}>
          {Object.keys(profileSettings.allergy.choices).map((key, d) => (
            <div key={key} className={getClassName(key)} onClick={(e) => handleChange(e, key)}>
              <div className={styles.titleWrapper}>
                {profileSettings.allergy.choices[key]}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.selectedItemWrapper}>
          <div className={styles.selectedItem} onClick={(e) => resetAll(e)}>
            <div className={styles.titleWrapper}>
              Ingen
            </div>
          </div>
        </div>
      </div>
    </div>
    <button disabled={loading} type="submit">Lagre</button>
  </form>) 
}