import { gql } from '@apollo/client';

const getRecipe = gql`
  query getRecipe ($recipeId: [Int]) {
    request {
      kitchen {
        recipes (recipeId: $recipeId) {
          edges {
            node {
              id: recipeId
              recipeId
              slug
              name
              image {
                url
              }
              allergens {
                edges {
                  node {
                    name
                  }
                }
              }
              dietCategory
              isHeartFriendly
              portionGrams
              kcal100g
              protein100g
              carbs100g
              fat100g
              calcium100g
              fruits100g
              veg100g
              fiber100g
              nuts100g
            }
          }
        }
      }
    }
  }
`;

export default getRecipe;
