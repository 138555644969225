import React from 'react'
import Panel from 'shared/Panel'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY } from 'queries'
import './styles.scss'

class RegisterStepsWithMutation extends React.Component {
  state = {
    numSteps: this.props.entry.data.numSteps,
    processing: false,
    success: false,
  }

  static getDerivedStateFromProps (props, state) {
    if (props.entry.registrationId !== state.registrationId) {
      return {
        numSteps: props.entry.data.numSteps,
        registrationId: props.entry.registrationId,
      }
    }

    return null
  }

  handleChange = event => this.setState({
    numSteps: event.target.value.replace(/[^\d]/g,'')
  })

  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      processing: true,
      success: false,
    })

    this.props.entry.data.numSteps = parseInt(this.state.numSteps, 10)
    this.props.entry.data.source = 'web'
    this.props.entry.data = JSON.stringify(this.props.entry.data)

    const variables = this.props.entry
    variables.date_time = variables.dateTime

    this.props.mutate({
      variables,
    }).then(() => {
      this.props.refetch().then(() => {
        this.setState({
          processing: false,
          success: true,
        })
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { numSteps, processing, success } = this.state

    const { registrationId } = this.props.entry

    return (
      <Panel loading={this.props.loading}>
        <Panel.Header>
          <Panel.Title>Registrer skritt</Panel.Title>
          <Panel.InfoText>Anbefalt 10 000 skritt per dag</Panel.InfoText>
        </Panel.Header>
        <Panel.Body>
          <form name="registerSteps" className="registerSteps" action="" method="post" onSubmit={this.handleSubmit} data-processing={processing} data-success={success}>
            <p><i className="icon-warning-circle"></i> OBS! Skritt gir ikke automatisk treningspoeng. Bruk "Registrer trening"</p>
            <fieldset>
              <label htmlFor="numSteps">Antall skritt i dag:</label>
              <input type="text" name="numSteps" id="numSteps" pattern="[0-9]*" value={numSteps} placeholder="Fyll inn" required onChange={this.handleChange} />
              <button type="submit">{registrationId ? 'Endre' : 'Lagre'}</button>
              <i className="icon-check-circle-2"></i>
            </fieldset>
          </form>
        </Panel.Body>
      </Panel>
    )
  }
}

const RegisterSteps = graphql(ADD_ENTRY)(RegisterStepsWithMutation)

export default RegisterSteps
