import React from 'react'
import ReactDOM from 'react-dom'
import styles from './Results.module.scss'
import RowMaker from './RowMaker'
import dietScore from 'conf/dietScore'
import GoldMemberAd from './GoldMemberAd'

const modalRoot = document.getElementById('modal-root')

class Results extends React.Component {
  render() {
    const { dailyDiaryCache: { diet } , user, close } = this.props
    const energy = dietScore.energyDistribution.normal
    const userKcalMenu = user.kcalMenu || dietScore.defaultKcalMenu
    const menu = dietScore.kcal.menus[userKcalMenu]
    const rowMaker = new RowMaker(diet)

    return ReactDOM.createPortal(
      (
        <div className={styles.overlay} onClick={close}>
          <div className={styles.modal} onClick={e => e.stopPropagation()}>
            <header className={styles.modalHeader}>
              <h1 className={styles.modalTitle}>Resultater</h1>
              <button className={styles.modalClose} onClick={close} aria-label="Lukk">
                <i className="icon-close" />
              </button>
            </header>
            <div className={styles.modalBody} data-isgold={user.hasPermissions}>
              {user.hasPermissions ? (
                <React.Fragment>
                  <section>
                    <h3 className={styles.sectionTitle}>Energi</h3>
                    {rowMaker.makeTotalKcal(
                      diet.totalEatenKcal,
                      menu.recommended,
                      menu.min,
                      menu.max
                    )}
                    {rowMaker.makeCarbs(
                      diet.energyShareFromEatenCarbs,
                      energy.carbohydrate.recommended,
                      energy.carbohydrate.minShare,
                      energy.carbohydrate.maxShare
                    )}
                    {rowMaker.makeProtein(
                      diet.energyShareFromProteins,
                      energy.protein.recommended,
                      energy.protein.minShare,
                      energy.protein.maxShare
                    )}
                    {rowMaker.makeFat(
                      diet.energyShareFromEatenFat,
                      energy.fat.recommended,
                      energy.fat.minShare,
                      energy.fat.maxShare
                    )}
                    {rowMaker.makeEnergyDensity(
                      diet.energyDensity,
                      energy.energyDensity.recommended,
                      energy.energyDensity.minShare,
                      energy.energyDensity.maxShare
                    )}
                  </section>
                  <section>
                    <h3 className={styles.sectionTitle}>Næring</h3>
                    {rowMaker.makeScore(
                      diet.score,
                      dietScore.score.recommended,
                      dietScore.score.min,
                      dietScore.score.max
                    )}
                    {rowMaker.makeFruit(
                      diet.totalEatenFruits,
                      dietScore.fruit.recommendedGrams,
                      dietScore.fruit.minGrams,
                      dietScore.fruit.maxGrams
                    )}
                    {rowMaker.makeVegetables(
                      diet.totalEatenVegetables,
                      dietScore.vegetables.recommendedGrams,
                      dietScore.vegetables.minGrams,
                      dietScore.vegetables.maxGrams
                    )}
                    {rowMaker.makeCalcium(
                      diet.totalEatenCalcium,
                      dietScore.calcium.recommendedMilligrams,
                      dietScore.calcium.minMilligrams,
                      dietScore.calcium.maxMilligrams
                    )}
                    {rowMaker.makeFiber(
                      diet.totalEatenFiber,
                      dietScore.fiber.recommendedGrams,
                      dietScore.fiber.minGrams,
                      dietScore.fiber.maxGrams
                    )}
                    {rowMaker.makeNuts(
                      diet.totalEatenNuts,
                      dietScore.nuts.recommendedGrams,
                      dietScore.nuts.minGrams,
                      dietScore.nuts.maxGrams
                    )}
                    {rowMaker.makeVitamin()}
                    {rowMaker.makeHearts()}
                    {rowMaker.makeWater()}
                    {userKcalMenu && rowMaker.makeVoucherKcal(
                      diet.voucherMealKcal || 0,
                      userKcalMenu,
                      dietScore.vocherMealFactor * dietScore.vouchers.daily.recommendedNumberOf
                    )}
                  </section>
                </React.Fragment>
              ) : (
                <GoldMemberAd />
              )}
            </div>
          </div>
        </div>
      ),
      modalRoot
    )
  }
}

export default Results
