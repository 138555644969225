import { useQuery } from '@apollo/client'
import getMembershipTypeCourses from 'queries/getMembershipTypeCourses'
import getMembershipTypeCoursesSearch from 'queries/getMembershipTypeCoursesSearch'

export function useData(variables) {
  
  const { data, loading } = useQuery(variables?.membershipId ? getMembershipTypeCourses: getMembershipTypeCoursesSearch, { variables })
  const { availibleCourses, availibleNettcourses } = (variables?.membershipId ?  data?.user?.membership: data) ?? {}
  const courses = availibleCourses?.edges?.map((e) => e.node) ?? []
  const nettCourses = availibleNettcourses?.edges?.map((e) => e.node) ?? []
  const courseTypeDescriptions = data?.courseTypeDescriptions?.edges?.map((e) => e.node) ?? []
  const courseTypes = getCourseTypes(courses, nettCourses, courseTypeDescriptions)

  return { courses, nettCourses, courseTypes, loading }
}

export function useDataSearch(variables) {

  const { data, loading } = useQuery(getMembershipTypeCoursesSearch, { variables })
  const { availibleCourses, availibleNettcourses } = data ?? {}
  const courses = availibleCourses?.edges?.map((e) => e.node) ?? []
  const nettCourses = availibleNettcourses?.edges?.map((e) => e.node) ?? []
  const courseTypeDescriptions = data?.courseTypeDescriptions?.edges?.map((e) => e.node) ?? []
  const courseTypes = getCourseTypes(courses, nettCourses, courseTypeDescriptions)

  return { courses, nettCourses, courseTypes, loading }
}

function getCourseTypes(courses, nettCourses, courseTypeDescriptions) {
  const courseTypes = []
  const courseTypesCourses = [...new Set(courses.map((_) => _.courseTypeId))]
  const courseTypesNettCourses = [...new Set(nettCourses.map((_) => _.nettcourseId))]
  courseTypesCourses.forEach((typeId) => {
    const type = courseTypeDescriptions.find((_) => _.courseTypes && _.courseTypes.includes(typeId))
    type && !courseTypes.find((_) => _.courseTypes?.includes(typeId)) && courseTypes.push(type)
  })

  courseTypesNettCourses.forEach((typeId) => {
    const type = courseTypeDescriptions.find((_) => _.nettcourse && _.nettcourse.includes(typeId))
    type  && !courseTypes.find((_) => _.nettcourse?.includes(typeId)) && courseTypes.push(type)
  })
  courseTypes.sort((a, b) => a.id - b.id)
  return courseTypes
}
