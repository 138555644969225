import "stream-chat-react/dist/css/index.css";
import "./styles.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { StreamChat } from "stream-chat";
import {
  Channel,
  ChannelList,
  Chat,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from "stream-chat-react";

import AdminSupportHeader from "components/ui/AdminSupportHeader";
import Loading from "components/ui/Loading";

import { withAuth } from "components/container/withAuth";

const STREAM_KEY = `${process.env.REACT_APP_STREAM_KEY}`;

const AdminSupport = ({ auth, props }) => {
  const user = auth?.user;
  const [client, setClient] = useState(null);
  const [filters, setFilters] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.streamioAdminToken) {
      navigate("/chatstreamio/");
    }

    async function init() {
      console.log(process.env);
      console.log(STREAM_KEY);
      const client = new StreamChat(STREAM_KEY);

      await client.connectUser(
        {
          id: "Roede-kontoret",
          name: "Roede-kontoret",
        },
        user.streamioAdminToken
      );

      setClient(client);
      setFilters({ type: "public", members: { $in: ["Roede-kontoret"] } });
    }

    init();
  }, []);

  if (!client) {
    return <Loading />;
  }

  return (
    <>
      <Chat client={client} theme={"messaging light"}>
        <ChannelList sort={{ last_message_at: -1 }} filters={filters} />
        <Channel>
          <Window>
            <AdminSupportHeader />
            <MessageList />
            <MessageInput focus />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </>
  );
};

export default withAuth(AdminSupport);
