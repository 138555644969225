import { gql } from '@apollo/client'

const dismissMessage = gql`
  mutation DismissMessage (
    $messageId: Int!
  ) {
    messageHandler {
      userDismissedMessages (
        messageId: $messageId
      ) {
        status
      }
    }
  }
`

export default dismissMessage