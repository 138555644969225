import { gql } from '@apollo/client'

const deleteDailyMenu = gql`
  mutation DeleteDailyMenu (
    $objectId: Int!
  ) {
    diary {
      deleteDailyCustomMenu (
        objectId: $objectId
      ) {
        status
      }
    }
  }
`

export default deleteDailyMenu
