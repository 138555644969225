import { NavLink, useLocation } from "react-router-dom";
import Wrapper from "../Wrapper";
import moment from "moment";
import "./styles.scss";

import { getSafe, useCoachingMembershipURL } from "utils";

const Navbar = ({ ...props }) => {
  const { pathname } = useLocation();

  const defaultDate = moment().format("YYYY-MM-DD");
  const defaultYear = moment().format("YYYY");
  const defaultWeek = moment().format("W");

  const defaultCoachingMembership = getSafe(
    () => props.user.defaultCoachingMembership
  );
  const coach_url = useCoachingMembershipURL(defaultCoachingMembership);

  const user = getSafe(() => props.auth.user);

  return (
    <div className="Navbar" id="Navbar">
      <Wrapper>
        <ul className="Navbar__items" role="navigation">
          <li className="Navbar__item Navbar__item--dashboard">
            <NavLink
              to={`/`}
              className={isDashboardActive(pathname) ? "active" : ""}
            >
              <span>Dashboard</span>
            </NavLink>
          </li>
          {user?.roles?.indexOf("nettcourse") !== -1 && (
            <li className="Navbar__item Navbar__item--coach">
              <NavLink
                to={`${coach_url}`}
                className={isCoachPageActive(pathname) ? "active" : ""}
              >
                <span>Mine kurs</span>
              </NavLink>
            </li>
          )}

          <li className="Navbar__item Navbar__item--food">
            <NavLink
              to={`/kosthold/${defaultDate}/`}
              className={isFoodActive(pathname) ? "active" : ""}
            >
              <span>Kosthold</span>
            </NavLink>
          </li>
          <li className="Navbar__item Navbar__item--exercise">
            <NavLink
              to={`/mosjon/${defaultDate}/`}
              className={isFitnessPageActive(pathname) ? "active" : ""}
            >
              <span>Aktivitet</span>
            </NavLink>
          </li>
          <li className="Navbar__item Navbar__item--me">
            <NavLink
              to={`/vekt-og-mal/${defaultYear}/${defaultWeek}/`}
              className={isMeasurementsPageActive(pathname) ? "active" : ""}
            >
              <span>Vekt</span>
            </NavLink>
          </li>
        </ul>
      </Wrapper>
    </div>
  );
};

const isDietPageActive = (pathname) => /kosthold/.test(pathname);
const isRecipePageActive = (pathname) => /oppskrifter/.test(pathname);

const isToolboxPageActive = (pathname) => /verktoy/.test(pathname);

const isCoachPageActive = (pathname) =>
  isToolboxPageActive(pathname) || /coach/.test(pathname);

const isFitnessPageActive = (pathname) => /mosjon/.test(pathname);

const isMeasurementsPageActive = (pathname) => /vekt-og-mal/.test(pathname);

const isFoodActive = (pathname) =>
  isDietPageActive(pathname) || isRecipePageActive(pathname);

const isDashboardActive = (pathname) =>
  !isFoodActive(pathname) &&
  !isFitnessPageActive(pathname) &&
  !isMeasurementsPageActive(pathname) &&
  !isCoachPageActive(pathname);

export default Navbar;
