import React from 'react'
import s from './styles/Focus.module.scss'

export default function Focus({ report }) {
  const feedback = JSON.parse(report.feedback)
  const files = feedback?.files ? feedback?.files : []

  if (feedback) {
    return (
      <div className={s.container}>
        <h2>{report.user.firstName}, klar for en ny uke?</h2>
        <p>
          Dette er noe av det jeg ønsker du skal ta med deg inn i kommende uke:
        </p>
        <div className={s.focus}>
          <ol>
            <li>{feedback.focus_1}</li>
            {feedback.focus_2 && feedback.focus_2 !== '' && (
              <li>{feedback.focus_2}</li>
            )}
            {feedback.focus_3 && feedback.focus_3 !== '' && (
              <li>{feedback.focus_3}</li>
            )}
          </ol>
        </div>
        {files.length > 0 && (
          <div className={s.files}>
            <p>Disse verktøyene kan hjelpe deg igjennom uken:</p>
            <ul>
              {files.map((file) => (
                <li key={file.id}>
                  Les om <a href={file.slug_url}>{file.filename}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return null
}
