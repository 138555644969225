import { Fragment } from 'react'
import DateNavigation from '../../shared/DateNavigation'
import RegisterSteps from './RegisterSteps'
import RegisterExercise from './RegisterExercise'
import WorkoutSummary from './WorkoutSummary'
import DailyReview from './DailyReview'
import AboutButton from '../../shared/AboutButton'
import Paywall from 'components/ui/Paywall'
import { GET_DIARY_ENTRIES, GET_WEIGHT } from 'queries'
import { withAuth } from 'components/container/withAuth'
import moment from 'moment'
import { transformFitnessData } from './transformFitnessData'
import { getSafe } from 'utils'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'




const getWeight = (user, requestWeight) => {
  let weight =
    getSafe(
      () =>
        JSON.parse(requestWeight.diary.diaryRegistration.edges[0].node.data)
          .weight
    ) || user.weight
  weight = parseFloat(weight) || 0

  return weight > 110 ? 110 : weight
}

function FitnessPage({auth, ...props}) {


  let params = useParams()
  const user = auth?.user
  const date = params?.date

  const {loading: loading1, data: data1, refetch: refetch1} = useQuery(GET_DIARY_ENTRIES, {
    variables: {
      date_from: moment(date).startOf('week').format('YYYY-MM-DDT00:00:00'),
      date_to: moment(date).endOf('week').format('YYYY-MM-DDT23:59:59'),
      category: 'exercise',
    },
    fetchPolicy: "no-cache"
  })

  const {loading: loading2, data: data2} = useQuery(GET_WEIGHT, {
    variables: {
      date_to: moment(date).endOf('week').format('YYYY-MM-DDT23:59:59'),
    },
    fetchPolicy: "no-cache"
  })

  const {steps, exercises, tally, review} = transformFitnessData(data1?.request, date)
  const weight = getWeight(user, data2?.request)

  return <Fragment>
    <Paywall isVisible={!user.hasPermissions} />
    <DateNavigation loading={loading1} refetch={refetch1} />
    <RegisterSteps
      loading={loading1}
      entry={steps}
      date={date}
      refetch={refetch1}
    />
    <RegisterExercise
      loading={loading1 || loading2}
      entries={exercises}
      totals={tally.exercise.day}
      weight={weight}
      refetch={refetch1}
    />
    {user.hasPermissions && (
      <Fragment>
        <WorkoutSummary loading={loading1} tally={tally} weight={weight} />
        {!loading1 && (
          <DailyReview loading={loading1} entry={review} date={date}refetch={refetch1} key={date} />
        )}
      </Fragment>
    )}
    <AboutButton />
  </Fragment>
}

export default withAuth(FitnessPage)
