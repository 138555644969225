import { gql } from '@apollo/client'

const searchFood = gql`
  query searchFood (
    $keyword: String!
    $types: [String]
    $first: Int!
    $cursor: String
  ) {
    request {
      diary {
        diarySearch (
          keyword: $keyword
          types: $types
          first: $first
          after: $cursor
          size: 100
        ) {
          edges {
            node {
              objId
              type
              name
              kcal100g
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`

export default searchFood