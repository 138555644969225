import { gql } from '@apollo/client'

export default gql`
  mutation ChangeEmail($email: String!) {
    auth {
      changeEmail(email: $email) {
        status
        message
      }
    }
  }
`
