import { gql } from '@apollo/client';

const getNewslettersettings = gql`
query User {
  user {
    userData {
      userId
      newsletterDinnerTips
      newsletterWeekly
      newsletterInformation
    }
  }
}`;


export default getNewslettersettings;
