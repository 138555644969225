import { gql } from '@apollo/client'

export default gql`
  query getMembershipTypeCoursesSearch(    
    $placeId: String!
    $radius: Int
    $slug: String
  ) {
    availibleCourses(placeId: $placeId, radius: $radius, slug: $slug) {
      edges {
        node {
          id: courseId
          meetingWeekday
          meetingStartTime
          meetingEndTime
          instructorId
          instructor {
            id: instructorId
            instructorId
            name
            image
            email
            phone
            title
            bioBullet1
            bioBullet2
            bioBullet3
            shortDescription
          }
          fullAddress
          firstBookableMeetingStartTime
          name
          fewSpots
          isFull
          coursetypeComment
          courseTypeId
          courseType {
            id: courseTypeId
            courseTypeId
            name
            description
            descriptionLong
            meetingLocation
            schedulingAlgorithm
          }
        }
      }
    }
    availibleNettcourses(slug: $slug) {
      edges {
        node {
          id: instructorId
          nettcourseId
          nettcourse{
            id: nettcourseId
            name
          }
          availableSpots
          instructor {
            id: instructorId
            name
            image
            title
          }
        }
      }
    }
    courseTypeDescriptions {
      edges {
        node {
          id: courseTypeDescriptionId
          courseTypeDescriptionId
          courseTypes
          nettcourse
          name
          description
          image
        }
      }
    }
  }
`
