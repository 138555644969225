import { withDate } from '../../util/withDate';
import { graphql } from '@apollo/client/react/hoc';
import { COPY_ENTRIES_FROM_DATE } from '../../../queries';
import moment from 'moment';
import { refetchQueries } from 'utils/refetchQueries';

const CopyEntriesContainer = ({
  tag,
  date: dateDest,
  mutate,
  render,
}) => {
  const dateSrc = moment(dateDest).add(-1, 'day').format('YYYY-MM-DDT00:00:00');
  const category = 'food';

  const copy = (e, loadingIndicator) => {
    e.preventDefault();

    loadingIndicator.show();

    mutate({
      variables: { dateSrc, dateDest, category, tag },
      refetchQueries: refetchQueries(),
    }).then(({ data }) => {
      loadingIndicator.hide();
    }).catch((error) => {
      console.log(error);
    });
  }

  return render({ copy });
}

const CopyEntries = withDate(graphql(COPY_ENTRIES_FROM_DATE)(CopyEntriesContainer));

export default CopyEntries;
