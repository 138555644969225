import { gql } from '@apollo/client'

const updateWeightGoal = gql`
  mutation updateWeightGoal (
    $weightGoal: Float
  ){
    user {
      updateMemberProperties(
        weightGoal: $weightGoal
      ) {
        status
      }
    }
  }
`


export default updateWeightGoal
