import React from 'react'
import withRouter from '../../utils/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { GET_WEEK_REPORT, GENERATE_REPORT } from 'queries'
import WaitingToOpen from './WaitingToOpen'
import moment from 'moment'
import s from './styles/GenerateReport.module.scss'

function isButtonVisible(report) {
  const today = moment()
  const { status, week } = report

  return (
    status &&
    status.includes('open') &&
    status !== 'open_with_data' &&
    moment(week) <= today
  )
}

class GenerateReport extends React.Component {
  state = {
    loading: false,
  }

  render() {
    const date = moment(this.props.report.week)

    if (isButtonVisible(this.props.report)) {
      return (
        <button
          style={{ maxWidth: '400px', margin: '0 auto 200px auto' }}
          className={s.button}
          data-loading={this.state.loading}
          onClick={this.generate}
        >
          Hent data fra dagboken
        </button>
      )
    }

    return <WaitingToOpen date={date} />
  }

  generate = async () => {
    this.setState({ loading: true })

    await this.props
      .mutate({
        variables: this.props.match.params,
        refetchQueries: [
          {
            query: GET_WEEK_REPORT,
            variables: this.props.match.params,
          },
        ],
      })
      .then(({ data }) => {
        const res = data.netcourse.netcourseGenerateReportData.status
        if (res !== 'success') {
          alert(data.netcourse.netcourseGenerateReportData.message)
        }
      })
      .catch((error) => {
        alert(error)
      })
  }
}

const GenerateReportWrapper = graphql(GENERATE_REPORT)(GenerateReport)

export default withRouter(GenerateReportWrapper)
