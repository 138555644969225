import React, { useEffect, useState }  from 'react'
import { useMutation } from '@apollo/client'
import formStyles from 'assets/styles/Form.module.scss'

import { PopupButton } from 'components/ui/Popup'

import { UPDATE_ABOUT_ME } from 'queries'
import { GET_USER_DATA } from 'queries';

import moment from 'moment'


interface BDProps {
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>
}


const BirthDate:React.FC<BDProps> = ({value, onChange}) => {
  const [birthDate] = useState(value ? moment(value) : moment() );
  const [day, setDay] = useState(birthDate.day());
  const [month, setMonth] = useState(birthDate.month());
  const [year, setYear] =  useState(birthDate.year());
  const [error] =  useState(null);

  useEffect(() => {
    if(day && day>0 && month && month > 0 && year && year > 1900 ){
      var newDate = moment().year(year).month(month).day(day)
      onChange(newDate.format())
    }    
  },[day, month, year, onChange])
  
  return (
    <React.Fragment>
      <div className={formStyles.group}>
      <label ><input type="number"  min="1" max="31"  value={day} onChange={(event) => setDay(parseInt(event.target.value))}  /><span>dag</span></label>
      <label ><input type="number"  min="1" max="12"  value={month} onChange={(event) => setMonth(parseInt(event.target.value))} /><span>måned</span></label>
      <label ><input type="number"  min="1900" max="2020"  value={year} onChange={(event) => setYear(parseInt(event.target.value))} /><span>år</span></label>
      </div>
      <div className={formStyles.error}><input type="text" tabIndex={-1} data-hidden="true"  value={birthDate.format()} onChange={() => (null)} required />{error}</div>
    </React.Fragment>
  )
}



interface Props {
  gender?: string;
  height?: number;
  weight?: number;
  birthDate?: string;
  close?: () => void;
}

export const AboutMeForm:React.FC<Props> = ({
  gender,
  height,
  weight,
  birthDate,
  close,
  ...props
}) => {
  const [genderTmp, changeGender] = useState(gender)
  const [heightTmp, handleHeight] = useState(height)
  const [weightTmp, handleWeight] = useState(weight)
  const [birthDateTmp, handleBirthDate] = useState(birthDate)
  
  const [saveAboutMe, {loading}] = useMutation(UPDATE_ABOUT_ME, { 
    onCompleted() {
      close();
    }
  });

  const submit = async (event) => {
    event.preventDefault()
    const variables = {
      initialWeight: weightTmp,
      height: heightTmp,
      gender: genderTmp,
      birthDate: birthDateTmp
    }

    try {
      await saveAboutMe({variables, refetchQueries: [GET_USER_DATA], })
    } catch (error) {
      alert(error)
    }
  }

  return (
    <form  className={formStyles.form} onSubmit={submit}>
      <div className={formStyles.optioninputs}>
        <label className={formStyles.optioninput}>
          <input type="radio" name="gender" value="female" required
            checked={genderTmp === "female"}
            onChange={() => changeGender('female')} />
          <div>
            Kvinne
          </div>
        </label>

        <label className={formStyles.optioninput}>
          <input type="radio" name="gender" value="male" required
            checked={genderTmp === "male"}
            onChange={() => changeGender('male')} />
          <div>
            Mann
          </div>
        </label>
      </div>
      <hr/>
      <h2>Fødselsdato</h2>
      <BirthDate
        value={birthDateTmp}
        onChange={handleBirthDate}
      />
      <hr/>
      <div className={formStyles.group}>
        <label><input type="number" name="height" required value={heightTmp} onChange={(e) => handleHeight(+e.target.value)} /><span>Høyde</span></label>
      </div>
      <div className={formStyles.group}>
        <label><input type="number" name="weight" required value={weightTmp} onChange={(e) => handleWeight(+e.target.value)} /><span>Startvekt</span></label>
      </div>
      <hr/>
      <button disabled={loading} type="submit">Lagre</button>
    </form>
  )
}

export default function AboutMeButton(props) {
  return(
    <PopupButton  title="Om meg" show={props.show} {...props}>
      <AboutMeForm {...props}  />
    </PopupButton>
  )
}

