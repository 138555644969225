import { gql } from '@apollo/client';

export default gql`
query Gold($slug: String) {
  gold(slug: $slug) {
      goldId
      descriptionType
      description
      name
      stripeSubscriptionPlan
      trialPeriodDays
      coupon
      allreadyMember
      subscriptions{
          edges{
              node{
                priceId
                interval
                months
                displayPricePerMonth
              }
          }
      }
  }
}`;
