import { gql } from '@apollo/client'

export default gql`
  mutation GenerateReport(
    $reportId: Int!
    $membershipId: Int!
  ) {
    netcourse {
      netcourseGenerateReportData(
        reportId: $reportId
        membershipId: $membershipId
      ) {
        status
        message
      }
    }
  }
`
