import React from 'react'
import s from './styles/Questions.module.scss'




const MAX_LETTERS = 500;

class Question extends React.Component {
  state = {
    answer: this.props.answer ? this.props.answer : ''
  }

  render() {
    const {question,
    index} = this.props
    const { answer } = this.state


    if (!question) {
      return null
    }
    let max = ""
    if (this.state.answer.length > MAX_LETTERS*0.90){
      max = "warning"
    }
    else if (this.state.answer.length > MAX_LETTERS*0.80){
      max = "close"
    }


    return (
      <div className={s.question} data-max={max}>
      <div className={s.questionLabel} data-started={Boolean(this.state.answer)}>{index}</div>
      <div className={s.questionWrapper}>
      <blockquote>{question}</blockquote>
        <textarea
          //name={`${step}.${index}`}
          placeholder="Svar her..."
          rows="5"
          value={answer}
          onChange={this.handleChange}
          required
        ></textarea>
        {max && <div className = {s.maxWarning}>{this.state.answer.length} / {MAX_LETTERS}</div>}
               </div>
    </div>
    )
  }

  // componentDidUpdate(prevProps){
  //   if (prevProps.sectionId !== this.props.sectionId || prevProps.membershipId !== this.props.membershipId || prevProps.membershipId !== this.props.membershipId ){
  //   }

  handleChange = (event) => {

    const anser = event.target.value.substring(0, MAX_LETTERS)


      const { index, handleAnswerChange} = this.props
      this.setState({ answer: anser})
      handleAnswerChange(index, anser)

  }

}



class Questions extends React.Component {
  render() {
    const {
      questions,
      handleAnswerChange,
      prefix
    } = this.props

    return (
      <div className={s.questions}>

        {Object.entries(questions).map(([index, data]) => (
          <Question
            key={`${prefix}.${index}`}
            {...data}
            index={index}
            handleAnswerChange={handleAnswerChange}
          />
        ))}

      </div>
    )
  }
}

export default Questions
