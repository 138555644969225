import { gql } from '@apollo/client';

const getWeeklyCache = gql`
  query CacheQuery ($date: Date) {
    request {
      diary {
        weeklyDiaryCache(date: $date) {
          data
        }
      }
    }
  }
`;

export default getWeeklyCache;