import { gql } from '@apollo/client'

export default gql`
  mutation SubmitStep(
    $step: String!
    $reportId: Int!
    $membershipId: Int!
    $answers: JSONString!
    $submitStep: Boolean!
  ) {
    netcourse {
      netcourseSubmitStep(
        step: $step
        reportId: $reportId
        membershipId: $membershipId
        answers: $answers
        submitStep: $submitStep
      ) {
        status
        message
      }
    }
  }
`
