import moment from 'moment'

const transformFitnessData = (request, date) => {
  const entries = getSafe(() => request.diary.diaryRegistration.edges.map(edge => edge.node), [])

  const dateTime = moment(date).format('YYYY-MM-DDT00:00:00+00:00')

  const parsedEntries = entries.map(entry => {
    return {
      ...entry,
      ...{ data: JSON.parse(entry.data) }
    }
  })

  return {
    steps: steps(parsedEntries, dateTime),
    exercises: exercises(parsedEntries, dateTime),
    tally: tally(parsedEntries, dateTime),
    review: review(parsedEntries, dateTime),
  }
}

const steps = (parsedEntries, dateTime) => {
  const stepsEntry = parsedEntries.find(entry => {
    return entry.tag.trim() === 'steps' && entry.dateTime.substr(0, 10) === dateTime.substr(0, 10)
  })

  const stepsDefault = {
    registrationId: null,
    dateTime: dateTime,
    category: 'exercise',
    tag: 'steps',
    calories: null,
    description: null,
    data: { numSteps: '' },
    lastModified: null,
    __typename: 'DiaryRegistration',
  }

  return { ...stepsDefault, ...stepsEntry }
}

const exercises = (parsedEntries, dateTime) => {
  return parsedEntries.filter(entry => (
    entry.tag.trim() === 'exercise' && entry.dateTime.substr(0, 10) === dateTime.substr(0, 10)
  ))
}

const tally = (parsedEntries, dateTime) => {
  return parsedEntries.reduce((tally, entry) => {
    if (entry.tag.trim() === 'exercise') {
      if (entry.dateTime.substr(0, 10) === dateTime.substr(0, 10)) {
        tally.exercise.day.points += entry.data.exercisePoints
        tally.exercise.day.calories += entry.calories
      }

      tally.exercise.week.points += entry.data.exercisePoints
      tally.exercise.week.calories += entry.calories

      if (entry.data.strengthPoints) {
        tally.strengthPoints += entry.data.strengthPoints
      }
    }

    if (entry.tag.trim() === 'steps') {
      if (entry.dateTime === dateTime) {
        tally.steps.day += entry.data.numSteps
      }

      tally.steps.week += entry.data.numSteps
    }

    return tally
  }, {
    exercise: {
      day: {
        points: 0,
        calories: 0,
      },
      week: {
        points: 0,
        calories: 0,
      },
    },
    steps: {
      day: 0,
      week: 0,
    },
    strengthPoints: 0,
  })
}

const review = (parsedEntries, dateTime) => {
  const reviewEntry = parsedEntries.find(entry => {
    return entry.tag.trim() === 'review' && entry.dateTime.substr(0, 10) === dateTime.substr(0, 10)
  })

  const reviewDefault = {
    registrationId: null,
    dateTime: dateTime,
    date_time: dateTime, // mutation variable should be camelCase so we can remove this line
    category: 'exercise',
    tag: 'review',
    calories: null,
    description: null,
    data: {
      sleep: null,
      stress: null,
      comfort: null,
      comments: null,
    },
    lastModified: null,
    __typename: 'DiaryRegistration',
  }

  return { ...reviewDefault, ...reviewEntry }
}

const getSafe = (fn, def = undefined) => {
  try {
    return fn()
  } catch (e) {
    return def
  }
}

export { transformFitnessData }