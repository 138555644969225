import s from "./BookCarousel.module.scss";
const imageResizer =
  "https://www.roede.com/cdn-cgi/image/w=500,h=375,q=60,f=auto/";

const books = [
  {
    title: "Mage, rumpe, lår med strikk",
    description: "Mage, rumpe, lår med strikk - øvelser og programmer",
    image:
      "https://storage.googleapis.com/roede-app/books/mage_rumpe_laar_strikk.jpg",
    url: "https://butikk.roede.com/products/mage-rumpe-lar-med-strikk-ovelser-og-programmer",
  },
  {
    title: "5 kg på 5 uker",
    description:
      "Aktivitets- og treningsopplegget for 5 uker for 5 kg vektreduksjon. Du skal spiser og bokstavelig talt går ned i vekt.",
    image:
      "https://storage.googleapis.com/roede-app/books/5kg%20pa%CC%8A%205%20uker.png",
    url: "https://butikk.roede.com/products/5-kg-pa-5-uker",
  },
  {
    title: "Et FETT liv",
    description:
      "Et åpent og ærlig innblikk i en manns kamp mot kiloene og alle opp- og nedturer på veien ned i vekt. Mannen, Sven Erik jobber i Grete Roede og boka er skrevet til hjelp og inspirasjon for deg som ønsker å gå ned i vekt.",
    image:
      "https://storage.googleapis.com/roede-app/books/ett%20fett%20liv.png",
    url: "https://butikk.roede.com/products/et-fett-liv",
  },
  {
    title: "Forbrenningsøkter fra 100 til 1000 kcal",
    description:
      "Hvordan kan du enklest og mest mulig effektivt forbrenne alt fra 100 til 1000 kcal pr økt når du går, jogger eller løper? Svaret og treningsøktene får du i denne boka!",
    image:
      "https://storage.googleapis.com/roede-app/books/Forbrennings%C3%B8kter%20100%20-%201000%20kcal.png",
    url: "https://butikk.roede.com/products/forbrenningsokter-fra-100-til-1000-kcal",
  },
  {
    title: "Forbrenningstoppen på 9 uker",
    description:
      "Opplegg over 3 x 3 uker med trening som gir deg en heftig utvikling av form og forbrenningsevne! Du trenger ikke å være i god form når du starter og treningen er spesiallaget for at du skal kunne forbrenne mye.",
    image:
      "https://storage.googleapis.com/roede-app/books/Forbrenningstoppen%20pa%CC%8A%209%20uker.png",
    url: "https://butikk.roede.com/products/forbrenningsmaskin-pa-9-uker",
  },
  {
    title: "Forbrenningstrening Topp 10",
    description:
      "Her får du de 10 mest effektive måtene å trene på når målet er høy forbrenning.",
    image:
      "https://storage.googleapis.com/roede-app/books/Forbrenningstrening%20Topp%2010.png",
    url: "https://butikk.roede.com/products/forbrenningstrening-topp-10-og-bann-10",
  },
  {
    title:
      "Hjertetrening - Sterkt hjerte, høyere forbrenning og økt livskvalitet",
    description:
      "Hjertet er svært trenbart og helt avgjørende for din helse, form og forbrenningsevne. Boka gir deg smart utholdenhetstrening som raskt gir sterkere hjerte, bedre form og forbrenningsevne.",
    image: "https://storage.googleapis.com/roede-app/books/Hjertetrening.png",
    url: "https://butikk.roede.com/products/hjertetrening-sterkt-hjerte---forbrenning---livskvalitet",
  },
];

function Book({ book }) {
  return (
    <a href={book.url} className={s.book}>
      <img src={`${imageResizer}${book.image}`} alt={book.title} />
      <h3>{book.title}</h3>
    </a>
  );
}

export default function BookCarousel() {
  return (
    <div className={s.bookCarouselWrapper}>
      <h2>Roede bøker</h2>
      <div className={s.bookCarousel}>
        <div className={s.books}>
          {books.map((book, index) => (
            <Book key={index} book={book} />
          ))}
        </div>
      </div>
    </div>
  );
}
