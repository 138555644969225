import React from 'react';
import moment from 'moment';
import { getSafe } from 'utils'
import s from './Invoice.module.scss'

class InvoiceLine extends React.Component {

  render() {

    const formatAmount = (amount) => (
      new Intl.NumberFormat('no-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2  }).format(amount/100.0)
      )

    const line = this.props.line
    const amount = line.amount
    if (!amount){
      return null
    }
    var period = ''
    if(line.subscription && line.price?.type ==='one_time' ){
      period = 'Forhåndsbetaling'
    }
    else if (!line.subscription && line.price?.type ==='one_time'){
      period = ''

    }
    else if (line.period){
      period = moment(line.period.start).format('DD-MM-YY') + ' - ' + moment(line.period.end).format('DD-MM-YY')
    }




    return (<tr>

    <td>{period}</td>
    <td>{line.price?.nickname}</td>
    <td>{line.quantity}</td>
    <td>{formatAmount(line.price?.unit_amount)},-</td>
    <td>{formatAmount(line.amount)},-</td>
    </tr>)
  }
}

class Invoice extends React.Component {






  render() {
    const {invoice} = this.props
    const data = getSafe(() => JSON.parse(invoice.data) ) || {}

    const invoiceLines = data.lines?.data|| []
    const hostedInvoiceUrl = data.hosted_invoice_url
    return(
        <div className={s.invoice} >

          <div className={s.invoiceDetails} data-status={invoice.status} >
              <h2>  {moment(invoice.dateTime).format('DD/MM-YY')} </h2>

              <p><strong>Medlemskap: {invoice.membership?.name} ({invoice.membership?.membershipId})</strong></p>
              <p><strong>{invoice.formatedAmount}</strong></p>
          </div>

          {invoiceLines && (
            <table>
            <thead>
                <tr>
                  <th >Periode</th>
                  <th >Beskrivelse</th>
                  <th >Antall</th>
                  <th >Pris</th>
                  <th >Totalt</th>

                </tr>
            </thead>

            <tbody>
              {invoiceLines.map(invoiceLine => (<InvoiceLine key={invoiceLine.id} line={invoiceLine} />))}
          </tbody>
          </table>
          )}
          {hostedInvoiceUrl && (
          <div className={s.invoiceUrl}>
            <a href={hostedInvoiceUrl}  target="_blank" rel="noopener noreferrer">
            {invoice.status !== 'paid'? 'Betal nå' :'Last ned kvitering'}</a>
          </div>
          )}




      </div>
      )}
}

export default Invoice
