import { gql } from "@apollo/client";

export default gql`
  query Course($id: Int) {
    course(id: $id) {
      id: courseId
      availibleInCourseSearch
      courseId
      autoName
      fullAddress
      courseTypeId
      frequency
      firstBookableMeetingStartTime
      isFull
      forSale
      place {
        id: placeId
        postalcode
        address
        postalCode {
          id: postalcode
          place
        }
      }
      subscriptions {
        edges {
          node {
            priceId
            interval
            months
            displayPricePerMonth
            stripeMetadata
          }
        }
      }
      prepaidPackages {
        edges {
          node {
            priceId
            numberOfMeetings
            displayPricePerMeeting
            displayPrice
            stripeMetadata
          }
        }
      }
      courseType {
        id: courseTypeId
        formatedPricePerMeeting
        pricePerMeeting
        meetingLocation
        descriptionLong
        defaultPrepaidMembershipType {
          id: membershipTypeId
          oneTimePrices {
            edges {
              node {
                priceId
                numberOfMeetings
                displayPricePerMeeting
                displayPrice
                stripeMetadata
              }
            }
          }
        }
        defaultMembershipType {
          id: membershipTypeId
          membershipType
          availibleOnCoursePage
          description
          defaultSubscription
          slug
          name
          subscriptions {
            edges {
              node {
                priceId
                interval
                months
                displayPricePerMonth
                stripeMetadata
              }
            }
          }
        }
      }
      instructor {
        id: instructorId
        name
        image
        slug
        shortDescription
        bioBullet1
        bioBullet2
        bioBullet3
        title
        slug
      }
    }
    user {
      memberships(status: "active", membershipType: "course") {
        edges {
          node {
            id: membershipId
            course {
              id: courseId
            }
          }
        }
      }
    }
  }
`;
