import React, { useEffect, useState } from 'react'
import s from './Paywall.module.scss'

export default function Paywall(props) {
  const isVisible =
    process.env.REACT_APP_FEATURE_PAYWALL === 'true' && props.isVisible

  const [option, setOption] = useState('')

  useEffect(() => {
    isVisible && document.body.classList.add('paywall')
    return () => document.body.classList.remove('paywall')
  }, [isVisible])

  if (!isVisible) {
    return null
  }

  return (
    <div className={s.paywall}>
      <div className={s.wrapper}>
        <div className={s.heading}>
          <h1>For å se denne siden må du ha gull-medlemskap</h1>
        </div>
        <form method="get" action="/gull" className={s.gold}>
          <input
            type="hidden"
            value={option}
            onChange={() => false}
            name="price"
          />
          <h2>
            Roede <span>Gull</span>
          </h2>
          <ul>
            <li>Personlig måltidsplan</li>
            <li>Over 1000 sunne og gode oppskrifter</li>
            <li>En daglig kostholdsscore for hvor sunt du spiser</li>
            <li>Oversikt over trening og kosthold</li>
          </ul>
          <button
            type="button"
            className={s.option}
            data-checked={option === 'price_1L5l7KAL4l6v423g34wH9U0C'}
            onClick={() => setOption('price_1L5l7KAL4l6v423g34wH9U0C')}
          >
            <span className={s.optionName}>
              <strong>1 Måned</strong> Se resultater
            </span>
            <span className={s.optionPrice}>
              <strong>129,-</strong>
            </span>
          </button>
          <button
            type="button"
            className={s.option}
            data-checked={option === 'price_1L5lydAL4l6v423gsQgjN0vP'}
            data-popular={true}
            onClick={() => setOption('price_1L5lydAL4l6v423gsQgjN0vP')}
          >
            <span className={s.optionName}>
              <strong>12 måneder</strong> Behold resultatene
            </span>
            <span className={s.optionPrice}>
              <strong>1188,-</strong> 99,- pr mnd
            </span>
          </button>
          <button type="submit" className={s.submit} disabled={option === null}>
            Neste
          </button>
        </form>
        <div className={s.appInfo}>
          <div className={s.appPreTitle}>Full kontroll med</div>
          <h2>NEDiVEKT-appEN</h2>
          <ul>
            <li>For deg som vil ned i vekt på en sunn og effektiv måte.</li>
            <li>
              For deg som vil ha hjelp til et sunnere kosthold, for mer
              overskudd og bedre helse.
            </li>
          </ul>
          <p className={s.disclaimer}>
            *Hvis medlemskapet sies opp før prøveperioden er over vil det ikke
            bli trukket noe fra betalingskortet. Medlemskapet fortsetter å løpe
            etter valgt innmeldingsperiode. Medlemskapet løper inntil du selv
            sier det opp.
          </p>
        </div>
      </div>
    </div>
  )
}
