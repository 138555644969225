import React from 'react'
import { NavLink, Navigate, useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import GET_WEEK_REPORT from 'queries/getWeekReport'
import Wrapper from 'components/ui/Wrapper'
import MembershipSelector from 'components/ui/MembershipSelector'
import Loading from 'components/ui/Loading'
import Navigation from './Navigation'
import Header from './Header'
import Section from './Section'
import WaitingForFeedback from './WaitingForFeedback'
import ReportError from './ReportError'
import s from './styles/ReportPage.module.scss'

export default function ReportPage({match, ...props}) {
  const { pathname } = useLocation()
  let params = useParams()

  const variables = getVariables(params)

  const { data, loading, error } = useQuery(GET_WEEK_REPORT, { variables })
  const report = data?.request?.netcourse?.netcourseWeekReport

  if (loading) return <LoadingReport />
  if (error) return <ReportError error={error} />
  if (!report) return <ReportError error={404} />

  const { membershipId, reportId, weekdates, membership } = report
  const sections = Object.values(JSON.parse(report.qa || '{}'))

  const sectionId:number = getSectionId(sections, params?.sectionId)
  const type:string = getType(pathname)

  if (isNaN(params.reportId)) {
    return <Navigate replace to={`/coach/${membershipId}/${type}/${reportId}`} />
  }

  return (
    <>
      <main className={s.main}>
        <div className={s.wrapper}>
          <div className={s.header}>
            <Wrapper className={s.headerWrapper}>
              <div className={s.actionButtons}>
                <MembershipSelector
                  membershipName={membership?.name}
                  membershipId={membershipId}
                />
                <NavLink to="/verktoy/" className={s.toolsButton}>
                  Din verktøykasse
                </NavLink>
              </div>
              <Header
                week={report.week}
                submitDeadline={report.submitDeadline}
                weekdates={weekdates}
                membershipId={membershipId}
                prev={report.prevReport?.reportId}
                next={
                  report.status === 'closed'
                    ? report.nextReport?.reportId
                    : null
                }
              />
            </Wrapper>
          </div>
          <div className={s.report}>
            {report.status === 'submitted' ? (
              <WaitingForFeedback date={report.feedbackDeadline} />
            ) : (
              <>
                <Navigation
                  report={report}
                  sections={sections}
                  sectionId={sectionId}
                  key={sectionId}
                />
                <Section
                  key={`${membershipId}.${reportId}.${sectionId}`}
                  sectionId={sectionId}
                  sections={sections}
                  report={report}
                />
              </>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

function getVariables({ membershipId, reportId }) {
  if (!isNaN(reportId)) {
    return {
      membershipId: +membershipId,
      reportId: +reportId,
    }
  }

  return {
    membershipId: +membershipId,
  }
}

function getSectionId(sections, sectionId) {
  if (sectionId) {
    return +sectionId
  }

  sectionId = sections.findIndex((section) => !section.sent)
  if (sectionId >= 0){
    return sectionId
  }
  else{
    return 0
  }
}

function getType(pathname:string) {


  if (/rapport/.test(pathname)) {
    return 'rapport'
  }

  if (/mote/.test(pathname)) {
    return 'mote'
  }

  return ''

}


function LoadingReport() {
  return (
    <main className={s.main}>
      <Loading />
    </main>
  )
}
