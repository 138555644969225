import React from 'react'
import { useMutation } from '@apollo/client'
import RESET_START_DATE from 'queries/resetStartDate'
import GET_USER_DATA from 'queries/getUserData'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import s from './styles.module.scss'

interface Props {    
  isVisible:boolean;
  close?: () => void;
}

export const Confirm:React.FC<Props> = ({ isVisible, close }) => {
  const date = moment()

  const [save, { data, loading }] = useMutation(RESET_START_DATE, {
    update(cache, result) {
      if (result.data.user.resetStartDate.status === 'success') {
        const query = GET_USER_DATA
        const cachedData = cache.readQuery({ query })
        const data = cloneDeep(cachedData)
        data.user.userData.startDate = date.format('YYYY-MM-DDT00:00:00')
        cache.writeQuery({ data, query })
      }
    },
  })

  const resetStartDate = async () => {
    try {
      save()
    } catch (error) {
      alert(error)
    }
  }

  const transform = isVisible ? `translateX(0)` : `translateX(100%)`

  if (loading) {
    return (
      <div className={s.confirm} style={{ transform }}>
        <div>
          <div className={s.loading} />
        </div>
      </div>
    )
  }

  if (data) {
    return (
      <div className={s.confirm} style={{ transform }}>
        <div>
          <p className={s.content}>
            Din nye startdato er: <strong>{date.format('DD.MM.YYYY')}</strong>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={s.confirm} style={{ transform }}>
      <div>
        <div className={s.content}>
          <p>
            <strong>Er du sikker?</strong>
          </p>
        </div>
        <div className={s.buttons}>
          <button
            type="button"
            className={`${s.button} ${s.buttonYes}`}
            onClick={resetStartDate}
          >
            Ja
          </button>
          <button type="button" className={s.button} onClick={close}>
            Nei
          </button>
        </div>
      </div>
    </div>
  )
}
