import { gql } from '@apollo/client'

const getDietData = gql`
  query GetDietData (
    $dateRegistrations: DateTime
    $dateCache: Date
  ) {
    request{
      diary {
        dailyDiaryCache (date: $dateCache) {
          edges {
            node {
              data
            }
          }
        }
        diaryRegistration (
          dateFrom: $dateRegistrations
          category: "food"
        ) {
          edges {
            node {
              registrationId
              dateTime
              lastModified
              category
              tag
              description
              calories
              data
            }
          }
        }
        weeklyDiaryCache (date: $dateCache) {
          data
        }
      }
    }
  }
`

export default getDietData
