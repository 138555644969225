import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { StartDateModal } from './StartDateModal'
import moment from 'moment'
import p from '../Popup.module.scss'

interface Props {    
  startDate:string;
  startDateHistory:JSON;
}


export const StartDate:React.FC<Props> = ({ startDate, startDateHistory }) => {
  const [isVisible, setIsVisible] = useState(false)

  const open = () => setIsVisible(true)
  const close = () => setIsVisible(false)

  return (
    <>
      {!moment(startDate).isSame(new Date(), 'day') && (
        <button type="button" className={p.button} onClick={open}>
          Endre
        </button>
      )}
      {isVisible &&
        ReactDOM.createPortal(
          <StartDateModal
            startDate={startDate}
            startDateHistory={startDateHistory}
            close={close}
          />,
          document.getElementById('modal-root')
        )}
    </>
  )
}
