import React from 'react'
import UnitScreen from '../UnitScreen'
import { withDate } from 'components/util/withDate'
import { graphql } from '@apollo/client/react/hoc'
import { GET_CUSTOM_FOOD_PRODUCT, GET_FREQUENTLY_USED_UNITS } from 'queries'

const getSafe = (fn) => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}

const AddCustomFoodProductWithData = ({
  data: { loading, error, request },
  frequentUnits: {loading: loadin1, error: error1, request: unitRequest},
  tag, cancel, close, date
}) => {
  const food = getSafe(() => request.diary.foodProductCustomResult.edges[0].node)
  const fqUnit = getSafe(() => unitRequest.diary.frequentlyUsedUnits.slice(0, 3))

  if (food) {
    const foodData = JSON.parse(food.data)

    const foodUnits = foodData.units.map(unit => ({
      name: Object.keys(unit)[0],
      gramsPerUnit: Object.values(unit)[0],
    }))

    return <UnitScreen
      entry={{
        registrationId: null,
        category: 'food',
        tag: tag,
        date_time: date,
        description: null,
        calories: 0,
        data: {
          id: food.objectId,
          type: 'custom product',
          name: food.name,
          image: null,
          quantity: null,
          unit: null,
          units: foodUnits,
          allergens: foodData.allergens,
          heartFriendly: foodData.isHeartFriendly,
          quality: null,
          nutrition: {
            kcalPer100Grams: foodData.nutrition.kcalPer100Grams,
            gramsOfProteinPer100Grams: foodData.nutrition.gramsOfProteinPer100Grams,
            gramsOfCarbohydratePer100Grams: foodData.nutrition.gramsOfCarbohydratePer100Grams,
            gramsOfFatPer100Grams: foodData.nutrition.gramsOfFatPer100Grams,
            milligramsOfCalciumPer100Grams: foodData.nutrition.milligramsOfCalciumPer100Grams,
            gramsOfFruitsPer100Grams: foodData.nutrition.gramsOfFruitsPer100Grams,
            gramsOfVegetablesPer100Grams: foodData.nutrition.gramsOfVegetablesPer100Grams,
            gramsOfFiberPer100Grams: foodData.nutrition.gramsOfFiberPer100Grams
          },
          frequentUnits: fqUnit
        },
      }}
      cancel={cancel}
      close={close}
    />
  }

  return null
}

const GetFrequentlyUsedUnitsWrapper = graphql(GET_FREQUENTLY_USED_UNITS, {
  name: "frequentUnits",
  options: ({ objectId }) => ({ variables: { foodId: objectId, foodType: 'custom product' } })
})(AddCustomFoodProductWithData)

const GetCustomFoodProductWrapper = graphql(GET_CUSTOM_FOOD_PRODUCT, {
  options: ({ objectId }) => ({ variables: { objectId: [objectId] } })
})(GetFrequentlyUsedUnitsWrapper)

export default withDate(GetCustomFoodProductWrapper)
