import { useSearchParams } from 'react-router-dom'

const courseTypeMap = {
  lokalt: {'name': 'Gruppe lokalt', order: 1 },
  video: {'name': 'Gruppe på video', order: 2 },
  nett: {'name': 'Skirftelig nettkurs', order: 3 },
}


export function useCourseCalendarData(courseData, nettCourses, availableTypes) {
  
  const [searchParams] = useSearchParams()
  const courseType =  searchParams.get('courseType')

  const schedule = [
    { weekday: 1, name: 'Mandager', courses: [],  nettCourses: [] },
    { weekday: 2, name: 'Tirsdager', courses: [],  nettCourses: [] },
    { weekday: 3, name: 'Onsdager', courses: [],  nettCourses: [] },
    { weekday: 4, name: 'Torsdager', courses: [],  nettCourses: [] },
    { weekday: 5, name: 'Fredager', courses: [],  nettCourses: [] },
    { weekday: 6, name: 'Lørdager', courses: [],  nettCourses: [] },
    { weekday: 7, name: 'Søndager', courses: [],  nettCourses: [] },
  ]

  const instructors = {}
  const courseTypes = {}

  let prevStartTime = [null, null, null, null, null, null, null];
  const courses = courseData?.map((node) => {
    const type = getType(node)
    courseTypes[type] = courseTypeMap[type]
    const typeId = getTypeId(node, availableTypes)
    const startTime = node.meetingStartTime.substr(0, 5)
    const showStartTime = (startTime !== prevStartTime[node.meetingWeekday - 1]);
    prevStartTime[node.meetingWeekday - 1] = startTime
    return {
      id: node.id,
      weekday: node.meetingWeekday,
      name: node.courseType.name,
      description: node.coursetypeComment ? node.courseType.description + ' – ' + node.coursetypeComment : node.courseType.description,
      schedulingAlgorithm: node.courseType.schedulingAlgorithm,
      startTime: startTime,
      startHour: Number.parseInt(startTime.substr(0,3)),
      showStartTime: showStartTime,
      endTime: node.meetingEndTime.substr(0, 5),
      otherTimes: [],
      instructor: node.instructor,
      place: getPlace(node),
      address: node.fullAddress,
      status: getStatus(node),
      type: type,
      typeId: typeId,
      link: `/kurs/${node.id}`,
      courseType: node.courseType,
      inGroup: false
    }
  })

  courses?.forEach((course) => {
    if (courseType && String(course.typeId) !== courseType) {
      return false
    }

    schedule[course.weekday - 1].courses.push(course)
  })


  schedule.map((day) => {
    const instructorPrevCourse = {}
    day.courses.map((course) => {
      if (course.schedulingAlgorithm === '11'){
        if (instructorPrevCourse[course.instructor.instructorId]){
          const prevCourse = instructorPrevCourse[course.instructor.instructorId]
          if (prevCourse.courseType.courseTypeId === course.courseType.courseTypeId  && course.startHour-prevCourse.startHour <=2 ){
            course.inGroup = true
            prevCourse.otherTimes.push({courseId: course.id, startTime: course.startTime, endTime: course.endTime})
            return true

          }
          else{
            instructorPrevCourse[course.instructor.instructorId] = course
            return true
          }

        }
        else{
          instructorPrevCourse[course.instructor.instructorId] = course
          return true
        }


      }


      else{
        instructorPrevCourse[course.instructor.id] = null
        return true
      }


    })
    return true

  })

  const selectedType = availableTypes.find((_) => _.id === +courseType)
  const isNettCourse = selectedType?.nettcourse?.length > 0

  if (isNettCourse) {
    nettCourses.forEach((nettCourse) => {
      if (nettCourse.availableSpots !== null){
      const availableSpots = JSON.parse(nettCourse.availableSpots)
      for (let i = 1; i < 8; i++) {
        if (availableSpots[i] > 0) {
          schedule[i-1].nettCourses.push({
            ...nettCourse,
            'availableSpots': availableSpots[i],
          })
        }
      }
      }
    })
  }
  return {courseTypes, instructors, schedule }
}

function getType(course) {
  if (course.courseType.meetingLocation === 'video') {
    return 'video'
  }
  return course.courseType.schedulingAlgorithm
}

function getTypeId(course, availableTypes) {
  return availableTypes.find(_ => _.courseTypes && _.courseTypes.includes(course.courseType.id))?.id ?? 0
}

function getPlace(course) {
  if (course.courseType.meetingLocation === 'video') {
    return 'Video'
  }
  return course.fullAddress
}

function getStatus(course) {
  if (course.isFull) {
    return 'full'
  }
  if (course.fewSpots) {
    return 'few'
  }
  return 'available'
}


