import React from 'react'
import EntryFormModal from '../EntryFormModal'
import { graphql } from '@apollo/client/react/hoc'
import { GET_CUSTOM_FOOD_PRODUCT } from 'queries'
import { getSafe } from 'utils'


const AddCustomIngredientModalContainer = ({ data: { loading, error, request }, addIngredient, close }) => {
  const food = getSafe(() => request.diary.foodProductCustomResult.edges[0].node)

  if (food) {
    const foodData = JSON.parse(food.data)

    const foodUnits = foodData.units.map(unit => ({
      name: Object.keys(unit)[0],
      gramsPerUnit: Object.values(unit)[0],
    }))

    return <EntryFormModal
      ingredient={{
        amount: null,
        fpId: food.objectId,
        fpType: 'custom_food_product',
        gramsPerUnit: null,
        name: food.name,
        o2FpId: 1676689,
        o2Id: food.o2Id,
        unitName: null,
      }}
      entry={{
        registrationId: null,
        category: 'food',
        description: null,
        calories: 0,
        data: {
          id: food.objectId,
          fpId: food.objectId,
          o2Id: food.o2Id,
          type: 'custom_food_product',
          name: food.name,
          quantity: null,
          unit: null,
          units: foodUnits,
          allergens: foodData.allergens,
          heartFriendly: foodData.isHeartFriendly,
          quality: null,
          nutrition: {
            kcalPer100Grams: foodData.nutrition.kcalPer100Grams,
            gramsOfProteinPer100Grams: foodData.nutrition.gramsOfProteinPer100Grams,
            gramsOfCarbohydratePer100Grams: foodData.nutrition.gramsOfCarbohydratePer100Grams,
            gramsOfFatPer100Grams: foodData.nutrition.gramsOfFatPer100Grams,
            milligramsOfCalciumPer100Grams: foodData.nutrition.milligramsOfCalciumPer100Grams,
            gramsOfFruitsPer100Grams: foodData.nutrition.gramsOfFruitsPer100Grams,
            gramsOfVegetablesPer100Grams: foodData.nutrition.gramsOfVegetablesPer100Grams,
          }
        },
      }}
      addIngredient={addIngredient}
      close={close}
    />
  }

  return null
}

const AddCustomIngredientModal = graphql(GET_CUSTOM_FOOD_PRODUCT, {
  options: ({ objectId }) => ({ variables: { objectId } })
})(AddCustomIngredientModalContainer)

export default AddCustomIngredientModal
