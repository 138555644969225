import React, { Component, Fragment } from 'react';
import AddEntriesModal from '../../ui/AddEntriesModal';

class AddEntries extends Component {
  state = {
    showModal: false
  }

  add = () => this.setState({ showModal: true });

  render() {
    return (
      <Fragment>
        {this.props.render({ add: this.add })}
        {this.state.showModal &&
          <AddEntriesModal
            tag={this.props.tag}
            close={() => this.setState({showModal: false})}
          />
        }
      </Fragment>
    );
  }
}

export default AddEntries;
