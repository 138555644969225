import { gql } from '@apollo/client';

const getPolarData = gql`
query User {
  user {
    isPolarEmailRegistered
    polarRegistrationStatus
    polarEmail
  }
}`;

export default getPolarData;
