import { useMutation } from "@apollo/client";
import { PopupButton } from "components/ui/Popup";
import { GET_USER_DATA, SET_NEWSLETTER } from "queries";
import React, { useState } from "react";
import styles from "../Popup.module.scss";

interface Props {
  newsletterDinnerTips: boolean;
  newsletterWeekly: boolean;
  newsletterInformation: boolean;
  newsletterSms: boolean;
  close?: () => void;
}

const NewsLetterContent: React.FC<Props> = ({
  newsletterDinnerTips: dinnerTips,
  newsletterWeekly: weekly,
  newsletterInformation: information,
  newsletterSms: sms,
  close,
  ...props
}) => {
  const [newsletterDinnerTips, setDinnerTips] = useState(dinnerTips);
  const [newsletterWeekly, setWeekly] = useState(weekly);
  const [newsletterInformation, setInformation] = useState(information);
  const [newsletterSms, setNewsletterSms] = useState(sms);

  const [save, { loading }] = useMutation(SET_NEWSLETTER, {
    onCompleted() {
      close();
    },
  });

  const submit = async (event) => {
    event.preventDefault();
    const variables = {
      newsletterDinnerTips: newsletterDinnerTips,
      newsletterWeekly: newsletterWeekly,
      newsletterInformation: newsletterInformation,
      newsletterSms: newsletterSms,
    };
    try {
      await save({ variables, refetchQueries: [GET_USER_DATA] });
    } catch (error) {
      alert(error);
    }
  };

  return (
    <form onSubmit={submit}>
      <div className={styles.modalBody}>
        <div className={styles.modalContainer}>
          <div className={styles.newsletterWrapper}>
            <label>
              <input
                type="checkbox"
                className={styles.newsletterItem}
                checked={newsletterDinnerTips}
                onChange={() => setDinnerTips(!newsletterDinnerTips)}
                autoComplete="off"
              />{" "}
              Dagens middagstips
            </label>
          </div>
          <div className={styles.newsletterWrapper}>
            <label>
              <input
                type="checkbox"
                className={styles.newsletterItem}
                checked={newsletterWeekly}
                onChange={() => setWeekly(!newsletterWeekly)}
                autoComplete="off"
              />{" "}
              Ukentlig Nyhetsbrev (lettere helg)
            </label>
          </div>
          <div className={styles.newsletterWrapper}>
            <label>
              <input
                type="checkbox"
                className={styles.newsletterItem}
                checked={newsletterInformation}
                onChange={() => setInformation(!newsletterInformation)}
                autoComplete="off"
              />{" "}
              Informasjon og tilbud fra Roede-veileder (ca 4 ganger pr mnd)
            </label>
          </div>

          <div className={styles.newsletterWrapper}>
            <label>
              <input
                type="checkbox"
                className={styles.newsletterItem}
                checked={newsletterSms}
                onChange={() => setNewsletterSms(!newsletterSms)}
                autoComplete="off"
              />{" "}
              Tilbud på sms
            </label>
          </div>
        </div>
      </div>
      <button disabled={loading} type="submit">
        LAGRE
      </button>
    </form>
  );
};

export default function NewsLetter(props) {
  return (
    <PopupButton
      title="Ønsker du å motta nyhetsbrev?"
      show={props.show}
      {...props}
    >
      <NewsLetterContent {...props} />
    </PopupButton>
  );
}
