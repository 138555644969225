import React from 'react'
import s from './Gdpr.module.scss'
import R from 'res/R'

class DeleteAccount extends React.Component {
  state = {
    status: 'default'
  }

  render() {
    const { status } = this.state
    const { isVisible } = this.props
    const transform = isVisible ? `translateX(0)` : `translateX(100%)`

    return(
      <div
        className={s.deleteContainer}
        style={{ transform }}
        data-status={status}>
        {this.renderStatus()}
      </div>
    )
  }

  renderStatus = () => {
    const { gdpr } = R.strings.profile

    if (this.state.status === 'deleting') {
      return (
        <div className={s.deleting} />
      )
    }

    if (this.state.status === 'deleted') {
      return (
        <p className={s.deleteContent}>{gdpr.dataHasBeenDeleted}</p>
      )
    }

    return (
      <React.Fragment>
        <p className={s.deleteContent}>{gdpr.areYouSure}</p>
        <div className={s.deleteButtons}>
          <button
            type="button"
            className={s.deleteButton}
            onClick={this.confirm}>
            Ja
          </button>
          <span className={s.deleteButtonsSeparator}>|</span>
          <button
            type="button"
            className={s.deleteButton}
            onClick={this.cancel}>
            Nei
          </button>
        </div>
      </React.Fragment>
    )
  }

  cancel = () => {
    this.props.close()
  }

  confirm = async () => {
    this.setState({ status: 'deleting' })

    const response = await this.props.delete()

    const deleteUserStatus = response &&
      response.data &&
      response.data.user &&
      response.data.user.deleteUser &&
      response.data.user.deleteUser.status

    const status = (deleteUserStatus === 'success') ? 'deleted' : 'error'
    this.setState({ status })

    if (deleteUserStatus === 'success') {
      setTimeout(() => {
        window.location.href = '/api/logout'
      }, 3000)
      return
    }

    alert(R.strings.common.error.sendError)
  }
}

export default DeleteAccount
