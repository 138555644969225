const durationOptions = [
  { value: 5, text: '5 minutter' },
  { value: 10, text: '10 minutter' },
  { value: 15, text: '15 minutter' },
  { value: 20, text: '20 minutter' },
  { value: 25, text: '25 minutter' },
  { value: 30, text: '30 minutter' },
  { value: 40, text: '40 minutter' },
  { value: 45, text: '45 minutter' },
  { value: 50, text: '50 minutter' },
  { value: 60, text: '1 time' },
  { value: 70, text: '1 time og 10 minutter' },
  { value: 75, text: "1 time og 15 minutter"}, 
  { value: 80, text: '1 time og 20 minutter' },
  { value: 90, text: '1 time og 30 minutter' },
  { value: 100, text: '1 time og 40 minutter' },
  { value: 105, text: "1 time og 45 minutter" }, 
  { value: 110, text: '1 time og 50 minutter' },
  { value: 120, text: '2 timer' },
  { value: 135, text: "2 time og 15 minutter" }, 
  { value: 150, text: '2 timer og 30 minutter' },
  { value: 165, text: "2 time og 45 minutter" }, 
  { value: 180, text: '3 timer' },
  { value: 195, text: "3 timer og 15 minutter" }, 
  { value: 210, text: '3 timer og 30 minutter' },
  { value: 225, text: "3 timer og 45 minutter" }, 
  { value: 240, text: '4 timer' },
  { value: 255, text: "4 timer og 15 minutter" }, 
  { value: 270, text: "4 timer og 30 minutter" }, 
  { value: 285, text: "4 timer og 45 minutter" }, 
  { value: 300, text: "5 time" }, 
  { value: 315, text: "5 timer og 15 minutter" }, 
  { value: 330, text: "5 timer og 30 minutter" }, 
  { value: 345, text: "5 timer og 45 minutter" }, 
  { value: 360, text: "6 time" }, 
  { value: 375, text: "6 timer og 15 minutter" }, 
  { value: 390, text: "6 timer og 30 minutter" }, 
  { value: 405, text: "6 timer og 45 minutter"}
]

export { durationOptions }