import React, { Fragment } from 'react'
import DateNavigation from 'shared/DateNavigation'
import RegisterMeasurements from './RegisterMeasurements'
import WeekSummary from './WeekSummary'
import { useQuery } from '@apollo/client' 
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { transformMeasurementsData } from './transformMeasurementsData'
import { GET_MEASUREMENTS_ENTRIES } from 'queries'
import { withAuth } from 'components/container/withAuth'
import Loading from 'components/ui/Loading';



const MeasurementsPage = ({auth, ...props}) => {
  const user = auth?.user;
  const params = useParams() 

  const {data, loading, refetch} = useQuery(GET_MEASUREMENTS_ENTRIES, {
    variables: {
      dateFrom: moment().year(params?.year).isoWeek(params?.week).startOf('week').format('YYYY-MM-DDT00:00:00'),
      dateTo: moment().year(params?.year).isoWeek(params?.week).endOf('week').format('YYYY-MM-DDT00:00:00'),
    },
    fetchPolicy: "no-cache"
  })

  if (loading) {
    return <Loading/>
  }

  const { measurements, summary } = transformMeasurementsData(data)
  
  return (
    <Fragment>
      <DateNavigation
        loading={loading}
        refetch={refetch}
      />
      <RegisterMeasurements
        loading={loading}
        data={measurements}
        refetch={refetch}
      />
      <WeekSummary
        loading={loading}
        data={summary}
        user={user}
      />
    </Fragment>
  )

  


}

// const MeasurementsPage = withAuth(graphql(GET_MEASUREMENTS_ENTRIES, {
//   options: ({
//     match: { params: { year, week } },
//   }) => ({
//     variables: {
//       dateFrom: moment().year(year).isoWeek(week).startOf('week').format('YYYY-MM-DDT00:00:00'),
//       dateTo: moment().year(year).isoWeek(week).endOf('week').format('YYYY-MM-DDT23:59.59'),
//     },
//     fetchPolicy: "no-cache"
//   })
// })(MeasurementsPageWithData))

export default withAuth(MeasurementsPage)
