import React from 'react'
import UnitScreen from '../UnitScreen'
import { withDate } from 'components/util/withDate'
import { graphql } from '@apollo/client/react/hoc'
import { GET_CUSTOM_RECIPE } from 'queries'

const getSafe = (fn) => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}

const AddCustomRecipeWithData = ({ data: { loading, error, request }, tag, cancel, close, date }) => {
  const recipe = getSafe(() => request.diary.recipeCustomResult.edges[0].node)

  if (recipe) {
    const recipeData = JSON.parse(recipe.data)
    const recipeImage = recipeData.picture || null

    return <UnitScreen
      entry={{
        registrationId: null,
        category: 'food',
        tag: tag,
        date_time: date,
        description: null,
        calories: 0,
        data: {
          id: recipe.objectId,
          type: 'custom recipe',
          name: recipe.name,
          image: recipeImage,
          quantity: null,
          unit: null,
          units: [
            {
              name: 'gram',
              gramsPerUnit: 1,
            },
            {
              name: 'porsjoner',
              gramsPerUnit: recipeData.portionGrams,
            },
          ],
          allergens: recipeData.allergens,
          heartFriendly: recipeData.isHeartFriendly,
          quality: null,
          nutrition: {
            kcalPer100Grams: recipe.kcal100g,
            gramsOfProteinPer100Grams: recipeData.nutrition.gramsOfProteinPer100Grams,
            gramsOfCarbohydratePer100Grams: recipeData.nutrition.gramsOfCarbohydratePer100Grams,
            gramsOfFatPer100Grams: recipeData.nutrition.gramsOfFatPer100Grams,
            milligramsOfCalciumPer100Grams: recipeData.nutrition.milligramsOfCalciumPer100Grams,
            gramsOfFruitsPer100Grams: recipeData.nutrition.gramsOfFruitsPer100Grams,
            gramsOfVegetablesPer100Grams: recipeData.nutrition.gramsOfVegetablesPer100Grams,
            gramsOfFiberPer100Grams: recipeData.nutrition.gramsOfFiberPer100Grams
          }
        },
      }}
      cancel={cancel}
      close={close}
    />
  }

  return null
}

const AddCustomRecipe = withDate(graphql(GET_CUSTOM_RECIPE, {
    options: ({ objectId }) => ({ variables: { objectId } })
})(AddCustomRecipeWithData))

export default AddCustomRecipe
