import { gql } from "@apollo/client";

export default gql`
  query MembershipQuery(
    $membershipType: String
    $paymentSource: String
    $status: String
  ) {
    user {
      memberships(
        membershipType: $membershipType
        paymentSource: $paymentSource
        status: $status
      ) {
        edges {
          node {
            id: membershipId
            membershipId
            name
            membershipType
            membershipTypeId
            paymentSource
            availibleActions
            statusText
            prepaidMeetings
            frequency
            weekday
            status
            activatedDate
            courseSignup
            stripeStatus
            stripeTrialEnd
            stripeCurrentPeriodEnd
            stripeSubscriptionEarliestCancel
            nextScheduledMeetingStartTime
            contractTo
            cancelAt
            nextPaidReportTime
            renewalStatus
            course {
              id: courseId
              courseId
              fullAddress
            }
            nettcourseInstructor {
              instructorId
            }
            instructor {
              id: instructorId
              name
              email
              phone
            }
            lastReport {
              id: reportId
            }
          }
        }
      }
    }
  }
`;
