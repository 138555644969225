import { gql } from '@apollo/client';

const getUser = gql`
  query WeeklyVouchers ($date_from: DateTime, $date_to: DateTime) {
    request {
      diary {
        diaryRegistration (dateFrom: $date_from, dateTo: $date_to, category: "food", tag: "voucherMeal") {
          edges {
            node {
              registrationId
              dateTime
              category
              tag
              description
              calories
              data
            }
          }
        }
      }
    }
  }
`;

export default getUser;