import React, { useState }  from 'react'
import ReactDOM from 'react-dom';

import styles from './Popup.module.scss';

interface PBProps {
  children: JSX.Element | JSX.Element[] | null;
  refetch?: () => void;
  show?: boolean;
  title: string;
  buttonStyle?: any;
  buttonText?: string;
}

interface PProps {
  children: JSX.Element | JSX.Element[] | null;
  title: string;
  close: () => void;
  style?: any;
}


export const Popup: React.FC<PProps> = ({ children, title, close, style=null }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <header className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>{title}</h1>
          <button className={styles.modalClose} onClick={close} aria-label="Lukk">
            <i className="icon-close" />
          </button>
        </header>
        {children}
      </div>
    </div>
  )
};

export const PopupButton: React.FC<PBProps> = ({children, refetch,  show: defaultShow, title, buttonStyle=styles.button, buttonText='Endre' }) => {
  const [show, setShow] = useState(defaultShow);

  const openSearch = () => {
    setShow(!show )
  }

  const closeSearch = () => {
    setShow(false)
    refetch && refetch();
  }

  const child = React.cloneElement(
    React.Children.only(children),
    { close: closeSearch  }
  );

  return (
    <React.Fragment>
      <button  className={buttonStyle} aria-label="" onClick={openSearch}>{buttonText}</button>
      {show &&
        ReactDOM.createPortal(
          <Popup title={title} close={closeSearch}>{child}</Popup>,
          document.getElementById('modal-root')
        )
      }
    </React.Fragment>
  )
}
