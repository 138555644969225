import React from "react";
import Modal from "components/ui/Modal";
import { nutritionFields } from "./nutritionFields";
import { allergensFields } from "./allergensFields";
import { getDataFromCustomFoodProductForm } from "./getDataFromCustomFoodProductForm";
import { graphql } from "@apollo/client/react/hoc";
import { ADD_CUSTOM_FOOD_PRODUCT } from "queries";
import "./styles.scss";

const AddCustomFoodProductModalUI = ({
  foodProduct,
  close,
  saveCustomFoodProduct,
}) => (
  <Modal title="Endre egen matvare" onClose={close}>
    <form onSubmit={saveCustomFoodProduct}>
      <input
        type="hidden"
        name="objectId"
        defaultValue={foodProduct.objectId}
      />
      <Modal.Body>
        <div className="field" style={{ width: "100%" }}>
          <label htmlFor="name">Navn på matvare *</label>
          <input
            type="text"
            name="name"
            id="name"
            defaultValue={foodProduct.name}
            autoFocus
          />
        </div>
        <div className="field field-message">
          <i className="icon-info"></i> Alle verdier per 100 gram.
        </div>
        {nutritionFields.map((field) => (
          <div className="field field-amount" key={field.name}>
            <label htmlFor={field.name}>{field.label}</label>
            <input
              type="text"
              name={field.name}
              id={field.name}
              maxLength="5"
              className="amount"
              required={field.required}
              defaultValue={foodProduct[field.name]}
            />
            <span>{field.unit}</span>
          </div>
        ))}
        {allergensFields.map((field, index) => (
          <div className="field field-checkbox" key={index}>
            <label>
              <input
                type="checkbox"
                name="allergens"
                value={field.value}
                style={{
                  WebkitAppearance: "checkbox",
                  width: "16px",
                  height: "16px",
                }}
                defaultChecked={foodProduct.allergens.includes(field.value)}
              />{" "}
              {field.label}
            </label>
          </div>
        ))}
        <div className="field field-checkbox">
          <label>
            <input
              type="checkbox"
              name="isHeartFriendly"
              value="true"
              style={{
                WebkitAppearance: "checkbox",
                width: "16px",
                height: "16px",
              }}
              defaultChecked={foodProduct.isHeartFriendly}
            />{" "}
            Hjertevennlig
          </label>
        </div>
        <div className="field field-checkbox">
          <label>
            <input
              type="checkbox"
              name="adGrRecommendation"
              value="true"
              style={{
                WebkitAppearance: "checkbox",
                width: "16px",
                height: "16px",
              }}
              defaultChecked={foodProduct.adGrRecommendation}
            />{" "}
            Tips Roede om denne matvaren
          </label>
        </div>
        <div className="field">
          Feltene merket * må fylles ut for å kunne tipse oss om matvaren.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={close}>
          Avbryt
        </button>
        <button type="submit">Lagre</button>
      </Modal.Footer>
    </form>
  </Modal>
);

const AddCustomFoodProductModal = graphql(ADD_CUSTOM_FOOD_PRODUCT, {
  props: ({ ownProps: { foodProduct, close }, mutate }) => ({
    saveCustomFoodProduct: (event) => {
      event.preventDefault();

      mutate({
        variables: getDataFromCustomFoodProductForm(event.target),
      })
        .then(({ data }) => {
          close();
          window.location.href = "/min-mat/matvarer/";
        })
        .catch((error) => {
          close();
          console.log(error);
        });
    },
  }),
})(AddCustomFoodProductModalUI);

export default AddCustomFoodProductModal;
