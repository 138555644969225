import "./styles.scss";

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { ChannelHeader, useChatContext } from "stream-chat-react";
// import { Link } from 'react-router-dom';

import Loading from "components/ui/Loading";
import { USER_INFO } from "../../../queries";

type AdminSupportHeader = {
  [key: string]: string;
};

const genders = {
  male: "Mann",
  female: "Kvinne",
};

const motivations = {
  health: "Overskudd - Føl deg bedre, kvikkere, friskere",
  keep: "Hold vekten - For deg som nylig har gått ned i vekt",
  lose: "Ned i vekt - Få nye vaner som varer",
};

const activityLevels = {
  1.3: "Lav: Inaktiv hverdag. Under 5000 skritt per dag.",
  1.4: "Moderat: Noe aktivitet. 5000-8000 skritt per dag.",
  1.5: "Høy: Aktiv hverdag. Over 8000 skritt per dag.",
};

const exerciseHabitsData = {
  2: "Svært lav (0-1 t/uke)",
  5: "Lavt (2-3 t/uke)",
  10: "Moderat (3-5 t/uke)",
  15: "Høyt (6-8 t/uke)",
  20: "Svært høyt (mer enn 9 t/uke)",
};

function parseJsonListToString(value, type) {
  const data = JSON.parse(value) || {};
  const parseMap = type in dataMap ? dataMap[type] : {};
  const parseSpecialMap = type in mapSpecialCase ? mapSpecialCase[type] : {};

  const dataList = Object.keys(parseMap)
    .filter((key) => data[key])
    .map((key) => parseMap[key]);
  const dataList2 = Object.keys(parseSpecialMap)
    .filter((key) => data[key])
    .map((key) => data[parseMap[key]]);
  const final = [...dataList, ...dataList2];

  return final.join(", ");
}

const allergiesMap = {
  isAllergicToEgg: "Egg",
  isAllergicToFish: "Fisk",
  isAllergicToGluten: "Gluten",
  isAllergicToMilk: "Melk",
  isAllergicToLactose: "Laktoseintolerant",
  isAllergicToNut: "Nøtter",
  isAllergicToShellFish: "Skalldyr",
};

const healthProblemsMap = {
  hasDiabetes: "Diabetes",
  hasHighCholesterol: "Høyt kolesterol",
  hasHypertension: "Høyt blodtrykk",
  hasHypothyroidism: "Lavt stoffskifte",
  isBreastFeeding: "Ammende",
  isPregnant: "Gravid",
};

const mapSpecialCase = {
  allergiesMap: {
    isAllergicToOther: "otherAllergies",
  },
  healthProblemsMap: {
    hasOtherChallenges: "otherChallenges",
  },
};

const dataMap = {
  allergiesMap: allergiesMap,
  healthProblemsMap: healthProblemsMap,
};

const UserInfo = ({ userId, ...props }) => {
  const [open, setOpen] = useState(false);
  const { loading, data } = useQuery(USER_INFO, {
    variables: {
      userId: userId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  const user = data?.central?.userInfo;

  if (!user) {
    return <div className="user-info-container">User details not found.</div>;
  }

  const health = parseJsonListToString(
    user.healthProblems,
    "healthProblemsMap"
  );
  const alergens = parseJsonListToString(user.allergiesList, "allergiesMap");

  return (
    <div className="user-info-container">
      <div className="user-general-info-container">
        <div className="user-general-info">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://assistent.roede.com/nye-kursdeltakere/${user.userId}`}
          >
            {user.name}
          </a>
          , hq-id: {user.userId}
          {user.gender && <>, {genders[user.gender]}</>}
          {user.age > 0 && <>, {user.age} år</>}
          {user.bmi && <>, bmi: {user.bmi}</>}
        </div>
        <div className="toggle-wrapper" onClick={() => setOpen(!open)}>
          {open ? "close" : "more"}
        </div>
      </div>
      {open && (
        <div className="user-details-wrapper">
          {user.email && (
            <>
              <strong>{user.email}</strong>
            </>
          )}
          {user.cellPhone && (
            <>
              , phone: <strong>{user.cellPhone}</strong>
            </>
          )}
          {user.address && (
            <>
              , address: <strong>{user.address}</strong>
            </>
          )}
          {user.height && (
            <>
              , høyde: <strong>{user.height}</strong> cm
            </>
          )}
          {user.weight && (
            <>
              , vekt: <strong>{user.weight}</strong> kg
            </>
          )}
          {user.kcalMenu && (
            <>
              , meny: <strong>{user.kcalMenu}</strong>
            </>
          )}
          {user.motivation && (
            <>
              , mål: <strong>{motivations[user.motivation]}</strong>
            </>
          )}
          {user.restingMetabolism && (
            <>
              , hvilestoffskifte: <strong>{user.restingMetabolism}</strong> kcal
            </>
          )}
          {user.trainingMetabolism && (
            <>
              , daglig energibehov: <strong>{user.trainingMetabolism}</strong>{" "}
              kcal
            </>
          )}
          {user.recommendedKcalIn && (
            <>
              , anbefalt energiinntak: <strong>{user.recommendedKcalIn}</strong>{" "}
              kcal
            </>
          )}
          {user.activityLevel && (
            <>
              , hverdagsaktivitet:{" "}
              <strong>{activityLevels[user.activityLevel]}</strong>
            </>
          )}
          {user.exerciseHabits && (
            <>
              , svett trening per uke:{" "}
              <strong>{exerciseHabitsData[user.exerciseHabits]}</strong>
            </>
          )}
          {health && (
            <>
              , Helse: <strong>{health}</strong>
            </>
          )}
          {alergens && (
            <>
              , matallergier: <strong>{alergens}</strong>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const AdminSupportHeader = () => {
  const { channel } = useChatContext();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const createdBy = channel?.data?.created_by as AdminSupportHeader;
    if (createdBy.id) {
      setUserId(+createdBy.id);
    }
  }, [channel]);

  return (
    <>
      <ChannelHeader />
      {userId && <UserInfo userId={userId} />}
    </>
  );
};

export default AdminSupportHeader;
