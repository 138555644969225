import { gql } from '@apollo/client'

export default gql`
  mutation SetMultipleProperties($properties: JSONString) {
    user {
      setMultipleProperties(properties: $properties) {
        status
      }
    }
  }
`
