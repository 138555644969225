import React from 'react'
import EditFoodProduct from './EditFoodProduct'
import DeleteFoodProduct from './DeleteFoodProduct'
import ToggleFavorite from 'components/container/ToggleFavorite'
import { GET_CUSTOM_FOOD_PRODUCT } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { withAuth } from 'components/container/withAuth'
import { getSafe } from 'utils'

const FoodProductsListWithData = ({
  data: { loading, request, refetch },
  auth: { user },
}) => {
  const favorites = user.favorites
    .filter(favorite => favorite.objType === 'custom_food_product')
    .map(favorite => favorite.objId)

  const foodProducts = getSafe(() => (
    request.diary.foodProductCustomResult.edges.map(edge => ({
      ...edge.node,
      isFavorite: favorites.includes(edge.node.objectId),
    }))
  ), [])

  if (loading) {
    return <div className="loading" />
  }

  if (foodProducts.length === 0) {
    return (
      <div className="emptyResultset">
        <i className="icon-info"></i> Ingen egne matvarer
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <th>Mine egne matvarer</th>
          <th className="delete">&nbsp;</th>
        </tr>
        {foodProducts.map(foodProduct => (
          <DeleteFoodProduct
            key={foodProduct.objectId}
            objectId={foodProduct.objectId}
            render={({ deleting, deleteFoodProduct }) => (
              <tr data-deleting={deleting}>
                <td>
                  <ToggleFavorite
                    objectId={foodProduct.objectId}
                    objectType="custom_food_product"
                    isFavorite={foodProduct.isFavorite}
                    render={({ isFavorite, toggle }) => (
                      <i className={isFavorite ? 'icon-star' : 'icon-star-empty'} onClick={toggle} />
                    )}
                  />
                  {foodProduct.name}
                  <EditFoodProduct
                    foodProduct={foodProduct}
                    render={({ editFoodProduct }) => (
                      <a href="#endre" onClick={editFoodProduct}>Endre</a>
                    )}
                  />
                </td>
                <td>
                  <button type="button" aria-label="Slett" onClick={deleteFoodProduct}>
                    <i className="icon-cancel-circle" />
                  </button>
                </td>
              </tr>
            )}
          />
        ))}
      </tbody>
    </table>
  )
}

const GetCustomFoodProductWrapper = graphql(GET_CUSTOM_FOOD_PRODUCT)(FoodProductsListWithData)

export default withAuth(GetCustomFoodProductWrapper)
