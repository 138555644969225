import { graphql } from "@apollo/client/react/hoc";
import Loading from "components/ui/Loading";
import styles from "components/ui/Popup/Popup.module.scss";
import { RUN_MEMBERSHIP_ACTION } from "queries";
import React from "react";
import { getSafe } from "utils";
import s from "./MembershipAction.module.scss";
const infoTexts = {
  course: {
    pause: [
      "Du er i ferd med å pause medlemskapet ditt.",
      "Er du sikker på at du ønsker å pause nå? Når du pauser mister du din reserverte plass i kursgruppen, men du kan få plassen din tilbake dersom kursgruppen ikke er fulltegnet.",
      "Når du pauser mister du også dine Gull rettigheter i Roede-klubben.",
      "Visste du at du kan fortsette medlemskapet i Roede med Gull-medlemskap i Roede-klubben /NEDiVEKT-appen.",
      "Din bindingstid er på de 4 neste kursmøtene i henhold til den oppsatte kurskalenderen for dette kurset. (som regel tilsvarer dette en måned).",
      "Du vil bli fakturert for reserverte kursmøter som enda ikke er fakturert.",
    ],
    restart: [
      "Du er i ferd med å gjenoppta medlemskapet ditt.",
      "Du vil fortsette på kursmøtene i henhold til kurskalender.",
      "Vi minner om at den månedlige medlemsavgiften forfaller den 1. i hver måned. Våre medlemsbetingelser krever at bindingstiden er forhåndsbetalt frem til medlemskapet avsluttes eller pauses. Dersom du har færre forhåndsbetalte møter igjen enn hva bindingstiden tilsier vil du faktureres for disse i tillegg til gjennomførte møter ved neste forfall.",
    ],
    cancel: [
      "Du er i ferd med å avslutte medlemskapet ditt i Roede.",
      '<strong>Er du sikker på at du ønsker å avslutte nå?</strong> Snakk gjerne med din veileder eller kontakt oss i Roede på telefon 66 98 32 00 eller sende en e-post til <a href="mailto:post@roede.com">post@roede.com</a>. Vi vet at tett oppfølging etter at man har nådd sitt vektmål, er den beste garantien for at du ikke går opp igjen i vekt.',
      "Når du avslutter ditt medlemskap, mister du også rettighetene i NEDiVEKT-appen. Du kan fortsette medlemskapet i NEDiVEKT-appen.",
      "Din bindingstid er på de 4 neste kursmøtene i henhold til den oppsatte kurskalenderen for dette kurset. (som regel tilsvarer dette en måned).",
      "Du vil bli fakturert for reserverte kursmøter som enda ikke er fakturert.",
    ],

    convert_to_membership: [
      "Oppgrader til Roede Medlemskap. Siden du allerede er kunde hos oss, vil du få en månedspris på <strong>kr 999,-</strong> uten bindingstid.",
      "Normalt er det 12 måneders bindingstid på denne prisen.",
    ],
  },
  membership: {
    restart: [
      "Du er i ferd med å gjenoppta medlemskapet ditt.",
      "Du vil fortsette på kursmøtene i henhold til kurskalender.",
    ],
    cancel: [
      "Du er i ferd med å avslutte medlemskapet ditt i Roede.",
      '<strong>Er du sikker på at du ønsker å avslutte nå?</strong> Snakk gjerne med din veileder eller kontakt oss i Roede på telefon 66 98 32 00 eller sende en e-post til <a href="mailto:post@roede.com">post@roede.com</a>. Vi vet at tett oppfølging etter at man har nådd sitt vektmål, er den beste garantien for at du ikke går opp igjen i vekt.',
      "Når du avslutter ditt medlemskap, mister du også rettighetene i NEDiVEKT-appen. Du kan fortsette medlemskapet i NEDiVEKT-appen.",
      "Medlemskapet løper ut bindingstiden. Har du ikke bindingstid, vil medlemskapet løpe ut en måned etter neste forfall.",
    ],
    cancel_at_earliest_opportunity: [
      "Du er i ferd med å avslutte medlemskapet ditt i Roede.",
      '<strong>Er du sikker på at du ønsker å avslutte nå?</strong> Snakk gjerne med din veileder eller kontakt oss i Roede på telefon 66 98 32 00 eller sende en e-post til <a href="mailto:post@roede.com">post@roede.com</a>. Vi vet at tett oppfølging etter at man har nådd sitt vektmål, er den beste garantien for at du ikke går opp igjen i vekt.',
      "Når du avslutter ditt medlemskap, mister du også rettighetene i NEDiVEKT-appen. Du kan fortsette medlemskapet i NEDiVEKT-appen.",
      "Oppsigelse i bindingstid: Medlemskap avsluttes ved bindingstidens sluttdato.",
      "Oppsigelse uten/etter bindingstid: En måned pluss det gjenstående av inneværende betalingsmåned.",
    ],
  },
  gold: {
    cancel: [
      "Du er i ferd med å avslutte medlemskapet ditt i Roede.",
      "<strong>Er du sikker på at du ønsker å avslutte nå?</strong>",
      "Når du avslutter ditt medlemskap, mister du også rettighetene i NEDiVEKT-appen.",
    ],
    restart: [
      "Fortsett abonnementet ditt",
      "Abonnementet ditt vil da ikke avsluttes i slutten av perioden.",
    ],
  },
};

infoTexts.training = infoTexts.gold;
infoTexts.nettcourse = infoTexts.course;

class MembershipActionModalUI extends React.Component {
  state = {
    running: false,
  };

  render() {
    const { action, membership } = this.props;
    const infoText =
      getSafe(() => infoTexts[membership.membershipType][action.action]) || [];

    return (
      <div className={styles.overlay}>
        <div className={styles.modal}>
          <header className={styles.modalHeader}>
            <h1 className={styles.modalTitle}>{action.name}</h1>

            <button
              className={styles.modalClose}
              onClick={this.props.close}
              aria-label="Lukk"
            >
              <i className="icon-close" />
            </button>
          </header>

          <div className={styles.modalBody}>
            <div className={s.content}>
              {infoText && (
                <ul>
                  {infoText.map((line, index) => (
                    <li
                      key={index}
                      dangerouslySetInnerHTML={{ __html: line }}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={styles.modalFooter}>
            {this.state.running ? (
              <Loading />
            ) : (
              <button
                type="button"
                className={styles.saveButton}
                onClick={this.runAction}
              >
                {action.name}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  runAction = async () => {
    const { running } = this.state;

    if (!running) {
      this.setState({ running: true });

      const { mutate, action, membership, refetch, close } = this.props;
      mutate({
        variables: {
          membershipId: membership.membershipId,
          action: action.action,
        },
      }).then(({ data }) => {
        this.setState({ running: false });
        refetch();
        close();
      });
    }
  };
}

export default graphql(RUN_MEMBERSHIP_ACTION)(MembershipActionModalUI);
