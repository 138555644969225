import { gql } from '@apollo/client';

const getCache = gql`
  query GetExercises {
    request {
      diary {
        exercise {
          edges {
            node {
              name
              isStrength
              intensities {
                edges {
                  node {
                    name
                    metValue
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getCache;