import { gql } from '@apollo/client'

export default gql`
  query GetWeekReport(
    $membershipId: Int!
    $reportId: Int
  ) {
    request {
      netcourse {
        netcourseWeekReport(
          membershipId: $membershipId
          reportId: $reportId
        ) {
          membership{
            id: membershipId
            name
            data
          }
          id: reportId
          userId
          reportId
          membershipId
          week
          submitDeadline
          feedbackDeadline
          reportType
          availibleFrom
          weekdates
          status
          intro
          qa
          diaryData
          feedback
          courseUpdate {
            id: courseUpdateId
            courseUpdateId
            title
            description
            videoEmbed
            pdfUri
            downloadUrl
          }
          prevReport {
            id: reportId
            reportId
          }
          nextReport {
            id: reportId
            reportId
          }
          user {
            userId
            name
            firstName
            kcalMenu
          }
          nettcourseInstructor {
            id: instructorId
            name
            image
            email
          }
        }
      }
    }
  }
`
