const dietScore = {
  defaultKcalMenu: '3',
  kcalLimit: 900, // Don't calculate diet score if kcal is below this value
  exercisePoints: {
    daily:  { recommended: 1 },
    weekly: { recommended: 7 },
  },
  strengthPoints: {
    weekly: { recommended: 2, max: 3 },
  },
  steps: {
    daily:  { recommended: '10_000' },
    weekly: { recommended: '70_000' },
  },
  kcal: {
    menus: {
      '0':   { recommended: 1000, min:  850, max: 1150, barLength: 1215, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '0+':  { recommended: 1100, min:  950, max: 1250, barLength: 1335, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '0++': { recommended: 1200, min: 1050, max: 1350, barLength: 1465, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '1mm': { recommended: 1100, min:  950, max: 1250, barLength: 1335, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '1m':  { recommended: 1200, min: 1050, max: 1350, barLength: 1465, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '1':   { recommended: 1300, min: 1150, max: 1450, barLength: 1595, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 400, supper:   0 },
      '1+':  { recommended: 1400, min: 1250, max: 1550, barLength: 1705, breakfast: 220, lunch: 220, snackMeal: 110, dinner: 400, supper:   0 },
      '1++': { recommended: 1500, min: 1350, max: 1650, barLength: 1815, breakfast: 220, lunch: 220, snackMeal:   0, dinner: 600, supper:   0 },
      '2' :  { recommended: 1600, min: 1450, max: 1750, barLength: 1925, breakfast: 220, lunch: 220, snackMeal: 110, dinner: 400, supper: 110 },
      '2+':  { recommended: 1700, min: 1550, max: 1850, barLength: 2035, breakfast: 220, lunch: 220, snackMeal: 220, dinner: 400, supper: 110 },
      '2++': { recommended: 1800, min: 1650, max: 1950, barLength: 2145, breakfast: 220, lunch: 220, snackMeal: 110, dinner: 600, supper: 110 },
      '3' :  { recommended: 1900, min: 1750, max: 2050, barLength: 2255, breakfast: 220, lunch: 330, snackMeal: 110, dinner: 600, supper: 220 },
      '3+':  { recommended: 2000, min: 1850, max: 2150, barLength: 2365, breakfast: 220, lunch: 330, snackMeal: 220, dinner: 600, supper: 220 },
      '3++': { recommended: 2100, min: 1950, max: 2250, barLength: 2475, breakfast: 220, lunch: 330, snackMeal: 110, dinner: 800, supper: 220 },
      '4' :  { recommended: 2200, min: 2050, max: 2350, barLength: 2585, breakfast: 330, lunch: 330, snackMeal: 220, dinner: 600, supper: 220 },
      '4+':  { recommended: 2300, min: 2150, max: 2450, barLength: 2695, breakfast: 330, lunch: 330, snackMeal: 330, dinner: 600, supper: 220 },
      '4++': { recommended: 2400, min: 2250, max: 2550, barLength: 2805, breakfast: 330, lunch: 330, snackMeal: 220, dinner: 800, supper: 220 },
      '5' :  { recommended: 2500, min: 2350, max: 2650, barLength: 2915, breakfast: 330, lunch: 330, snackMeal: 220, dinner: 800, supper: 330 },
      '5+':  { recommended: 2600, min: 2450, max: 2750, barLength: 3025, breakfast: 330, lunch: 330, snackMeal: 330, dinner: 800, supper: 330 },
      '5++': { recommended: 2700, min: 2550, max: 2850, barLength: 3135, breakfast: 330, lunch: 330, snackMeal: 440, dinner: 800, supper: 330 },
      '6':   { recommended: 2800, min: 2550, max: 2850, barLength: 3135, breakfast: 330, lunch: 330, snackMeal: 440, dinner: 800, supper: 330 },
      '6+':  { recommended: 2900, min: 2550, max: 2850, barLength: 3135, breakfast: 330, lunch: 330, snackMeal: 440, dinner: 800, supper: 330 },
    },
    unit: 'kcal',
    weighting: 0.26, // When calculating diet score, we're only checking if total kcal is inside valid range, not checking the meals
  },
  fruit: {
    recommendedKcal: 200,
    recommendedGrams: 300,
    minGrams: 200,
    maxGrams: 400,
    barLength: 440,
    weighting: 0.13,
  },
  fiber: {
    recommendedKcal: 15,
    recommendedGrams: 30,
    minGrams: 15,
    maxGrams: 60
  },
  nuts: {
    recommendedKcal: 10,
    recommendedGrams: 20,
    minGrams: 10,
    maxGrams: 25
  },
  calcium: {
    recommendedMilligrams: 800,
    minMilligrams: 500,
    maxMilligrams: 1300,
    barLength: 1550,
    weighting: 0.08,
  },
  vouchers: {
    recommendedKcal: 50,
    daily: { recommendedNumberOf:  2 },
    weekly: { recommendedNumberOf: 14 },
  },
  water: {
    recommendedNumGlasses: 10,
    minNumGlasses:  7,
    maxNumGlasses: 25,
    weighting: 0.07,
  },
  supplementPillTaken: {
    recommendedNumberOf: 1,
    minNumberOf: 1,
    maxNumberOf: 1,
    weighting: 0.07,
  },
  vegetables: {
    recommendedGrams: 300,
    minGrams:  250,
    maxGrams: 2000,
    barLength: 2600,
    weighting: 0.17,
  },
  energyDistribution: {
    normal: {
      protein: {
        minShare: 0.15,
        maxShare: 0.35,
        recommended: [0.15, 0.35],
      },
      carbohydrate: {
        minShare: 0.3,
        maxShare: 0.6,
        recommended: [0.3, 0.6],
      },
      fat: {
        minShare: 0.25,
        maxShare: 0.45,
        recommended: [0.25, 0.45],
      },
      energyDensity: {
        minShare: 75,
        maxShare: 175,
        recommended: 125,
      },
    },
    carboReduced: {
      protein: {
        minShare: 0.15,
        maxShare: 0.35,
      },
      carbohydrate: {
        minShare: 0.3,
        maxShare: 0.6,
      },
      fat: {
        minShare: 0.25,
        maxShare: 0.45,
      },
    },
    barLength: 100,
    weighting: 0.08,
  },
  heartFriendly: {
    minNumberOf: 2,
    maxNumberOf: 20,
    weighting: 0.14,
  },
  score: {
    min: 7,
    max: 10,
    recommended: 7
  },
  vocherMealFactor: 25,
}

export default dietScore
