import React, { Fragment, useState } from "react";
import DayCalendar from "./DayCalendar";
import WeekCalendar from "./WeekCalendar";
import { Link, useParams, useLocation } from "react-router-dom";

import moment from "moment";
import "./styles.scss";

function getSection(pathname: string) {
  if (/ukesammendrag/.test(pathname)) {
    return "ukesammendrag";
  }

  if (/mosjon/.test(pathname)) {
    return "mosjon";
  }

  if (/vekt-og-mal/.test(pathname)) {
    return "vekt-og-mal";
  }

  return pathname
    .split("/")
    .filter((x) => x !== "")
    .filter((x) => x !== "u")[0];
}

function getDateNavigationTexts(
  year: number,
  week: number,
  date: moment.Moment
) {
  if (week) {
    const dateFrom = moment()
      .year(year)
      .isoWeek(week)
      .startOf("week")
      .format("dddd DD.MM.YY");
    const dateTo = moment()
      .year(year)
      .isoWeek(week)
      .endOf("week")
      .format("dddd DD.MM.YY");

    return {
      currentDate: `Uke ${week}`,
      currentDateExtended: `(${dateFrom} - ${dateTo})`,
      buttonLabel: "Velg uke",
    };
  }

  return {
    currentDate: moment(date).format("D. MMMM YYYY"),
    currentDateExtended: moment(date).format("dddd"),
    buttonLabel: "Velg dato",
  };
}

function getDateNavigationUrls(
  section: string,
  date: moment.Moment,
  year: number,
  week: number
) {
  if (week) {
    const previousWeek = moment().year(year).isoWeek(week).add(-1, "week");
    const nextWeek = moment().year(year).isoWeek(week).add(1, "week");

    return {
      linkPrevious: `/${section}/${previousWeek.format("YYYY")}/${previousWeek.format("W")}/`,
      linkNext: `/${section}/${nextWeek.format("YYYY")}/${nextWeek.format("W")}/`,
    };
  }

  const previousDay = moment(date).add(-1, "day").format("YYYY-MM-DD");
  const nextDay = moment(date).add(1, "day").format("YYYY-MM-DD");

  return {
    linkPrevious: `/${section}/${previousDay}/`,
    linkNext: `/${section}/${nextDay}/`,
  };
}

const DateNavigation = ({ ...props }) => {
  let params = useParams();
  const { pathname } = useLocation();

  const [showCalendar, setShowCalendar] = useState(false);

  const openCalendar = () => {
    setShowCalendar(true);
  };
  const closeCalendar = () => {
    setShowCalendar(false);
  };

  let year: number = parseInt(params.year);
  let week: number = parseInt(params.week);
  let section: string = getSection(pathname);
  let date: moment.Moment = moment(params.date);

  const { currentDate, currentDateExtended, buttonLabel } =
    getDateNavigationTexts(year, week, date);
  const { linkPrevious, linkNext } = getDateNavigationUrls(
    section,
    date,
    year,
    week
  );

  return (
    <Fragment>
      <nav className="DateNavigation shared">
        <Link
          to={linkPrevious}
          aria-label="Forrige"
          className="DateNavigation__prev"
        >
          <i className="icon-arrow-2-left" />
        </Link>
        <p className="DateNavigation__content">
          <span
            className="DateNavigation__date"
            data-extended={currentDateExtended}
            data-week={Boolean(params.week)}
          >
            {currentDate}
          </span>
          <span className="DateNavigation__sep">|</span>
          <button className="DateNavigation__button" onClick={openCalendar}>
            <i className="icon-calendar DateNavigation__button-icon" />{" "}
            {buttonLabel}
          </button>
        </p>
        <Link to={linkNext} aria-label="Neste" className="DateNavigation__next">
          <i className="icon-arrow-2-right" />
        </Link>
      </nav>
      {showCalendar && week ? (
        <WeekCalendar close={closeCalendar} section={section} />
      ) : (
        ""
      )}
      {showCalendar && !week ? (
        <DayCalendar close={closeCalendar} section={section} />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default DateNavigation;
