import { gql } from '@apollo/client';

const getCustomMeals = gql`
  query CustomMeals {
    request {
      diary {
        customMealResult {
          edges {
            node {
              objectId
              name
              created
            }
          }
        }
      }
    }
  }
`;

export default getCustomMeals;