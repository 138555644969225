import { gql } from '@apollo/client';

const addEntry = gql`
  mutation AddEntry (
    $registrationId: Int,
    $category: String,
    $tag: String,
    $date_time: DateTime,
    $description: String,
    $calories: Int,
    $data: JSONString
  ){
    diary {
      diaryRegister(
        registrationId: $registrationId,
        category: $category,
        tag: $tag,
        dateTime: $date_time,
        description: $description,
        calories: $calories,
        data: $data
      ) {
        status
        registrationId
      }
    }
  }
`;

export default addEntry;