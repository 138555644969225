import React from 'react'
import RegisterFoodModal from './RegisterFoodModal'
import s from './AddRegistration.module.scss'

class AddRegistration extends React.Component {
  state = {
    mealId: null
  }

  openSearch = () => {
    this.setState({ meal: this.props.meal })
  }

  closeSearch = () => {
    this.setState({ meal: null })
  }

  render() {
    const { meal } = this.state

    return (
      <React.Fragment>
        <button className={s.button} aria-label="Legg til" onClick={this.openSearch}>+</button>
        {meal &&
          <RegisterFoodModal
            meal={meal}
            close={this.closeSearch}
          />
        }
      </React.Fragment>
    )
  }
}

export default AddRegistration
