import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import SET_MULTIPLE_PROPERTY from "queries/setMultipleProperty";
import SET_EMAIL from "queries/setEmail";
import { validateEmail } from "components/ui/RegistrationForm";
import R from "res/R";
import styles from "assets/styles/Form.module.scss";

interface Props {
  address?: string;
  city?: string;
  email?: string;
  name?: string;
  phone?: string;
  zipCode?: number;
  close?: () => void;
  refetch?: () => void;
}

export const UpdateContactForm: React.FC<Props> = (props) => {
  const [errors, setErrors] = useState({});
  const [processing, setProcessing] = useState(false);
  const [values, setValues] = useState({
    name: props.name,
    phone: props.phone,
    address: props.address,
    zip_code: props.zipCode,
    email: props.email,
    city: props.city,
  });

  const [setProperties] = useMutation(SET_MULTIPLE_PROPERTY);
  const [setEmail] = useMutation(SET_EMAIL);

  const { name, phone, address, zip_code, email, city } = values;

  const setValue = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      delete errors[e.target.name];
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    const errors = validate(values);
    const { name, email, phone, address, zip_code, city } = values;
    const properties = JSON.stringify({ name, phone, address, zip_code, city });

    if (Object.keys(errors).length === 0) {
      setProcessing(true);

      let hasEmailErrors = false;

      if (email.trim() !== props.email.trim()) {
        const variables = { email };
        const result = await setEmail({ variables });
        const { status, message, error } = result.data.auth.changeEmail;
        const { contactInfo } = R.strings.profile;
        if (status === "error" && message.includes("already registered")) {
          setErrors({ email: contactInfo.emailAlreadyRegistered });
          hasEmailErrors = true;
        } else if (status === "error" && message.includes("change email")) {
          setErrors({ email: contactInfo.emailChangeError });
          hasEmailErrors = true;
        } else if (error) {
          alert(error);
          hasEmailErrors = true;
        }
      }

      if (!hasEmailErrors) {
        const variables = { properties };
        const result = await setProperties({ variables });
        const { status, message, error } =
          result.data.user.setMultipleProperties;
        if (status === "success") {
          props.refetch();
          props.close();
        } else if (status === "error") {
          alert(message);
        } else if (error) {
          alert(error);
        }
      }

      setProcessing(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <form className={styles.form} onSubmit={submit}>
      <div className={styles.group}>
        <label htmlFor="name" data-error={errors["name"]}>
          <input
            type="text"
            name="name"
            value={name}
            onChange={setValue}
            autoComplete="off"
          />
          <span>Navn</span>
        </label>
        {errors["name"]}
      </div>
      <div className={styles.group}>
        <label htmlFor="email" data-error={errors["email"]}>
          <input
            type="text"
            name="email"
            value={email}
            onChange={setValue}
            autoComplete="off"
          />
          <span>E-post</span>
          <div className={styles.error}>{errors["email"]}</div>
        </label>
      </div>
      <div className={styles.group}>
        <label htmlFor="phone" data-error={errors["phone"]}>
          <input
            type="text"
            name="phone"
            value={phone}
            onChange={setValue}
            autoComplete="off"
          />
          <span>Mobil</span>
          <div className={styles.error}>{errors["phone"]}</div>
        </label>
      </div>
      <div className={styles.group}>
        <label htmlFor="address" data-error={errors["address"]}>
          <input
            type="text"
            name="address"
            value={address}
            onChange={setValue}
            autoComplete="off"
          />
          <span>Adresse</span>
          <div className={styles.error}>{errors["address"]}</div>
        </label>
      </div>
      <div className={styles.group}>
        <label
          htmlFor="zip_code"
          data-error={errors["zip_code"]}
          data-field="postal_code"
        >
          <input
            type="text"
            name="zip_code"
            value={zip_code}
            onChange={setValue}
            autoComplete="off"
          />
          <span>Postnummer</span>
          <div className={styles.error}>{errors["zip_code"]}</div>
        </label>
        <label htmlFor="city" data-error={errors["city"]} data-field="city">
          <input
            type="text"
            name="city"
            value={city}
            onChange={setValue}
            autoComplete="off"
          />
          <span>Poststed</span>
          <div className={styles.error}>{errors["city"]}</div>
        </label>
      </div>
      <button type="submit" disabled={processing}>
        Lagre
      </button>
    </form>
  );
};

function validate({ name, email, phone, address, zip_code, city }) {
  let errors = {};
  const { contactInfo } = R.strings.profile;
  const ck_name = /^[åøæÅØÆa-zA-Z0-9- ]+$/;
  const ck_address = /^[åøæÅØÆa-zA-Z0-9\s,.'-]{3,}$/;

  if (name.trim().length < 5) {
    errors["name"] = contactInfo.nameMinimumChars;
  }
  if (name.trim() === "" && name === null) {
    errors["name"] = contactInfo.nameCharsOnly;
  }
  if (name.trim() !== "" && !ck_name.test(name.trim())) {
    errors["name"] = contactInfo.nameCharsOnly;
  }
  if (email.trim() === "" && name === null) {
    errors["email"] = contactInfo.emailError;
  }
  if (email.trim() !== "" && !validateEmail(email.trim())) {
    errors["email"] = contactInfo.emailError;
  }
  if (isNaN(phone)) {
    errors["phone"] = contactInfo.phoneOnlyNumbers;
  }
  if (phone.trim().length !== 8) {
    errors["phone"] = contactInfo.phoneCharsNumber;
  }
  if (address.trim().length < 3) {
    errors["address"] = contactInfo.addressMinimumChars;
  }
  if (address.trim() !== "" && !ck_address.test(address.trim())) {
    errors["address"] = contactInfo.addressChars;
  }
  if (zip_code.trim().length !== 4) {
    errors["zip_code"] = contactInfo.postalCharsNumber;
  }
  if (isNaN(zip_code)) {
    errors["zip_code"] = contactInfo.postalCharsNumber;
  }
  if (city.trim().length < 1) {
    errors["zip_code"] = contactInfo.cityMinimumChars;
  }

  return errors;
}
