import { gql } from '@apollo/client'

export default gql`
mutation
    register($email: String!,
             $password: String!,
             $firstName: String,
             $postalCode: String,
             $cid: String
             $lastName: String,
             $address: String,
             $place: String,
             $phone: String)
        { auth
            { register(email: $email,
                       password: $password,
                       firstName: $firstName,
                       postalCode: $postalCode,
                       cid: $cid,
                       lastName: $lastName,
                       address: $address,
                       place: $place,
                       phone: $phone,
                       )
                { status message }
            }
        }
`