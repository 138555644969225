import React from 'react'
import Panel from '../../../shared/Panel'
import MissingWeightMessage from '../MissingWeightMessage'
import { formatPoints, formatCalories, formatNumSteps } from '../util'
import './styles.scss'

const WorkoutSummary = ({ loading, tally: { exercise, steps, strengthPoints }, weight }) => (
  <Panel loading={loading}>
    <Panel.Header>
      <Panel.Title>Min trening</Panel.Title>
      <Panel.ReloadButton />
    </Panel.Header>
    <Panel.Body>
      <MissingWeightMessage weight={weight} />
      <div className="workoutSummary">
        <section>
          <h3>Treningspoeng</h3>
          <ul className="scoreList">
            <li>
              <i className="icon-gr-exercise" data-completed={exercise.day.points >= 1} /> <br />
              {formatPoints(exercise.day.points)} <br />
              (<strong>{formatCalories(exercise.day.calories)}</strong> kcal)
              <span>Hittil i dag</span>
              Anbefalt minst 1
            </li>
            <li>
              <i className="icon-gr-exercise" data-completed={exercise.week.points >= 7} /> <br />
              {formatPoints(exercise.week.points)} <br />
              (<strong>{formatCalories(exercise.week.calories)}</strong> kcal)
              <span>Hittil denne uken</span>
              Anbefalt minst 7
            </li>
          </ul>
        </section>
        <section>
          <h3>Skritt</h3>
          <ul className="scoreList">
            <li>
              <i className="icon-gr-steps" data-completed={steps.day >= 10000} /> <br />
              <strong>{formatNumSteps(steps.day)}</strong>
              <span>Hittil i dag</span>
              Anbefalt minst <em>10 000</em>
            </li>
            <li>
              <i className="icon-gr-steps" data-completed={steps.day >= 70000} /> <br />
              <strong>{formatNumSteps(steps.week)}</strong>
              <span>Hittil denne uken</span>
              Anbefalt minst <em>70 000</em>
            </li>
          </ul>
        </section>
        <section>
          <h3>Styrkepoeng</h3>
          <div>
            <i className="icon-gr-strength" data-completed={strengthPoints >= 1} />
            <i className="icon-gr-strength" data-completed={strengthPoints >= 2} />
            <i className="icon-gr-strength" data-completed={strengthPoints >= 3} />
          </div>
          <span>Hittil denne uken</span>
          Anbefalt 2-3 økter à 15 min
        </section>
      </div>
    </Panel.Body>
  </Panel>
)

export default WorkoutSummary