import { gql } from '@apollo/client';

const getUserData = gql`
query User {
  user {
    userData {
      userId
      weight
      defaultPaymentMethod
      initialWeight
      motivationText
      height
      age
      birthDate
      motivation
      weightGoal
      activityLevel
      exerciseHabits
      recommendedKcalIn
      gender
      kcalMenu
      name
      email
      cellPhone
      address
      zipCode
      city

      newsletterDinnerTips
      newsletterWeekly
      newsletterInformation
      newsletterSms

      restingMetabolism

      trainingMetabolism

      calculatedRecommendedKcalIn
      healthProblems
      allergiesList
      properties
      startDate
      startDateHistory
    }
  }
}
`;

export default getUserData;
