import withRouter  from 'utils/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { COPY_ENTRIES_FROM_DATE } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'
import moment from 'moment'

const CopyRegistrationsContainer = ({ meal, callback, match, mutate, render }) => {
  const dateSrc = moment(match.params.date).add(-1, 'day').format('YYYY-MM-DDT00:00:00')
  const dateDest = `${match.params.date}T00:00:00`
  const category = 'food'
  const tag = meal && meal.id

  const copyRegistrations = () => {
    mutate({
      variables: { dateSrc, dateDest, category, tag },
      refetchQueries: refetchQueries(),
    }).then(({ data }) => {
      if (callback) { callback() }
    })
  }

  return render({ copyRegistrations })
}

const CopyEntriesFromDateWrapper = graphql(COPY_ENTRIES_FROM_DATE)(CopyRegistrationsContainer)

export default withRouter(CopyEntriesFromDateWrapper)
