import React from 'react'
import AuthContext from 'components/auth/AuthContext'

function AuthWrapper({ component: WrappedComponent, ...props}) {
  const auth = React.useContext(AuthContext)
  return <WrappedComponent auth={auth} {...props} />
}

export function withAuth(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <AuthWrapper component={WrappedComponent} {...this.props} />
    }
  }
}
