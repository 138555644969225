import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import s from "./styles/Navigation.module.scss";

class Navigation extends React.PureComponent {
  state = {
    navButtonsVisible: false,
    prevVisible: false,
    nextVisible: true,
  };

  navScrollerRef = React.createRef();
  navLinksRef = React.createRef();

  resizeObserver = null;

  lastKnownScrollPosition = 0;
  ticking = false;

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.navLinksRef.current);
    this.navScrollerRef.current.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.navLinksRef.current);
    this.navScrollerRef.current.removeEventListener(
      "scroll",
      this.handleScroll
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { navButtonsVisible, prevVisible, nextVisible } = this.state;
    if (
      navButtonsVisible &&
      prevState.prevVisible === prevVisible &&
      prevState.nextVisible === nextVisible
    ) {
      this.navScrollerRef.current.scrollLeft = 164 * prevProps.sectionId;
    }
  }

  render() {
    const { sections, report, sectionId } = this.props;
    const { navButtonsVisible, prevVisible, nextVisible } = this.state;

    if (["new", "scheduled"].includes(report.status)) {
      return null;
    }

    return (
      <div className={s.navigation}>
        <div className={s.wrapper} data-navbuttons={navButtonsVisible}>
          {prevVisible && (
            <button className={s.buttonPrev} onClick={this.prev}>
              Forrige
            </button>
          )}
          <div className={s.navLinksScroller} ref={this.navScrollerRef}>
            <div className={s.navLinks} ref={this.navLinksRef}>
              {sections.map((section, key) => (
                <NavItem
                  key={key}
                  section={{ ...section, id: key, active: key === sectionId }}
                  prevSection={sections[key - 1]}
                  report={report}
                />
              ))}
            </div>
          </div>
          {nextVisible && (
            <button className={s.buttonNext} onClick={this.next}>
              Neste
            </button>
          )}
        </div>
      </div>
    );
  }

  handleResize = () => {
    const { clientWidth, scrollWidth } = this.navLinksRef.current;
    this.setState({ navButtonsVisible: scrollWidth > clientWidth });
  };

  handleScroll = (e) => {
    this.lastKnownScrollPosition = e.target.scrollLeft;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        const { scrollWidth } = this.navLinksRef.current;
        if (e.target.scrollLeft <= 0) {
          this.setState({ prevVisible: false });
        } else if (e.target.scrollLeft >= scrollWidth - window.innerWidth) {
          this.setState({ nextVisible: false });
        } else {
          this.setState({ prevVisible: true, nextVisible: true });
        }
        this.ticking = false;
      });
      this.ticking = true;
    }
  };

  prev = () => {
    const { scrollLeft } = this.navScrollerRef.current;
    const offsetLeft = parseInt((scrollLeft - 1) / 164) * 164;
    this.navScrollerRef.current.scrollLeft = offsetLeft;
  };

  next = () => {
    const { scrollLeft } = this.navScrollerRef.current;
    const offsetLeft = (parseInt(scrollLeft / 164) + 1) * 164;
    this.navScrollerRef.current.scrollLeft = offsetLeft;
  };
}

function NavItem({ section, prevSection, report }) {
  const status = getStatus(section, prevSection, report);
  const tabIndex =
    status.value === "locked" || report.status === "submitted" ? -1 : null;

  return (
    <NavLink
      to={`/coach/${report.membershipId}/rapport/${report.reportId}/${section.id}`}
      className={s.navLink}
      data-status={status.value}
      data-sent={section.sent}
      data-active={section.active}
      tabIndex={tabIndex}
    >
      <span className={s.sectionStatus}>{status.text}</span>
      <strong className={s.sectionName}>{section.name}</strong>
    </NavLink>
  );
}

function getStatus(section, prevSection, report) {
  const { week } = report;

  //disable locking, and let data be live
  //const openForData = true; //moment(week) <= moment()
  const lockedDate = false; //section.closed === 'date' && !openForData
  const lockedPrev = false; //section.closed === 'prev' && !prevSection.sent
  const locked = lockedDate || lockedPrev;

  if (locked) {
    return {
      value: "locked",
      text: lockedDate ? `Åpner ${moment(week).format("D/M")}` : "En om gangen",
    };
  }

  if (section.sent) {
    return { value: "done", text: "Ferdig" };
  }

  if (section.active) {
    return { value: "ongoing", text: "Pågår" };
  }

  return { value: "open", text: "Åpen" };
}

export default Navigation;
