import React, { Fragment } from 'react'
import ExerciseModal from '../ExerciseModal'
import withRouter from '../../../../utils/withRouter'
import { GET_EXERCISES } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { getSafe } from 'utils'
import moment from 'moment'

class ActivitySelectorWithData extends React.Component {
  state = {
    entry: null
  }

  handleChange = e => {
    const activities = getActivities(this.props.data.request)

    const {
      name: activity,
      isStrength: hasStrengthTraining,
      intensities = null,
      isFitnessTracker = false,
    } = activities.find(activity => activity.name === e.target.value)

    const data = {
      ...exerciseEntry.data,
      ...{ activity, hasStrengthTraining, intensities, isFitnessTracker },
    }

    const dateTime = moment(this.props.match.params.date).format('YYYY-MM-DDT00:00:00')

    const date_time = dateTime

    const entry = {
      ...exerciseEntry,
      ...{ dateTime, date_time, data },
    }

    this.setState({ entry })
  }

  render() {
    const { entry } = this.state

    const activities = getActivities(this.props.data.request)

    return (
      <Fragment>
        <select name="activity" key={entry} onChange={this.handleChange}>
          <option value="">Velg aktivitet</option>
          {activities.map((activity, index) => (
            <option key={index} value={activity.name}>{activity.name}</option>
          ))}
        </select>
        {entry &&
          <ExerciseModal
            entry={entry}
            close={e => this.setState({ entry: null })}
            weight={this.props.weight}
            refetch={this.props.refetch}
          />
        }
      </Fragment>
    )
  }
}

const getActivities = request => {
    const activities = getSafe(() => (
      request.diary.exercise.edges
        .map(e => ({
          name: e.node.name,
          isStrength: Boolean(e.node.isStrength),
          intensities: e.node.intensities.edges.map(e => ({
            name: e.node.name,
            metValue: e.node.metValue,
          })),
        })).filter(e => Boolean(e.intensities.length))
    ), [])

    activities.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })

    return [{ name: 'Pulsklokke', isFitnessTracker: true }].concat(activities)
}

const exerciseEntry = {
  registrationId: null,
  dateTime: null,
  date_time: null,
  category: 'exercise',
  tag: 'exercise',
  calories: null,
  description: null,
  data: {
    activity: null,
    intensity: null,
    intensities: null,
    duration: null,
    exercisePoints: null,
    hasStrengthTraining: null,
    weight: null,
    isFitnessTracker: null,
    fitnessTrackerDescription: null,
  },
  lastModified: null,
  __typename: 'DiaryRegistration',
}

const GetExercisesWrapper = graphql(GET_EXERCISES)(ActivitySelectorWithData)

export default withRouter(GetExercisesWrapper)
