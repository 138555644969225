import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DeleteRecipe from "./DeleteRecipe";
import ToggleFavorite from "components/container/ToggleFavorite";
import { GET_CUSTOM_RECIPE } from "queries";
import { graphql } from "@apollo/client/react/hoc";
import { withAuth } from "components/container/withAuth";
import { getSafe } from "utils";

const RecipesListWithData = ({
  data: { loading, request, refetch },
  auth: { user },
}) => {
  const favorites = user.favorites
    .filter((favorite) => favorite.objType === "custom_recipe")
    .map((favorite) => favorite.objId);

  const recipes = getSafe(
    () =>
      request.diary.recipeCustomResult.edges.map((edge) => ({
        ...edge.node,
        isFavorite: favorites.includes(edge.node.objectId),
      })),
    []
  );

  if (loading) {
    return <div className="loading" />;
  }

  if (recipes.length === 0) {
    return (
      <Fragment>
        <div className="emptyResultset">
          <i className="icon-info"></i> Ingen egne oppskrifter
        </div>
        {user.hasPermissions && (
          <Link to="/min-mat/oppskrifter/endre/" className="addButton">
            <i className="icon-plus-circle" /> Lag ny oppskrift
          </Link>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <table>
        <tbody>
          <tr>
            <th>Mine egne oppskrifter</th>
            <th className="delete">&nbsp;</th>
          </tr>
          {recipes.map((recipe) => (
            <DeleteRecipe
              key={recipe.objectId}
              objectId={recipe.objectId}
              render={({ deleting, deleteRecipe }) => (
                <tr data-deleting={deleting}>
                  <td>
                    <ToggleFavorite
                      objectId={recipe.objectId}
                      objectType="custom_recipe"
                      isFavorite={recipe.isFavorite}
                      render={({ isFavorite, toggle }) => (
                        <i
                          className={
                            isFavorite ? "icon-star" : "icon-star-empty"
                          }
                          onClick={toggle}
                        />
                      )}
                    />
                    {recipe.o2Id ? (
                      <a href={`/${recipe.o2Id}.o2`}>{recipe.name}</a>
                    ) : (
                      <Fragment>{recipe.name}</Fragment>
                    )}
                    &nbsp;&nbsp;<span style={{ color: "#e5e9eb" }}>|</span>
                    <Link to={`/min-mat/oppskrifter/endre/${recipe.objectId}/`}>
                      Endre
                    </Link>
                  </td>
                  <td>
                    <button
                      type="button"
                      aria-label="Slett"
                      onClick={deleteRecipe}
                    >
                      <i className="icon-cancel-circle" />
                    </button>
                  </td>
                </tr>
              )}
            />
          ))}
        </tbody>
      </table>
      {user.hasPermissions && (
        <Link to="/min-mat/oppskrifter/endre/" className="addButton">
          <i className="icon-plus-circle" /> Lag ny oppskrift
        </Link>
      )}
    </Fragment>
  );
};

const GetCustomRecipeWrapper = graphql(GET_CUSTOM_RECIPE)(RecipesListWithData);

export default withAuth(GetCustomRecipeWrapper);
