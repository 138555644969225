import React from 'react'
import Wrapper from 'components/ui/Wrapper'
import { Link } from 'react-router-dom'
import withRouter from '../../utils/withRouter'
import s from './styles/WaitingToOpen.module.scss'

class WaitingToOpen extends React.Component {
  render() {
    const { date, match } = this.props
    const { membershipId, reportId } = match.params
    const focusUrl = `/coach/${membershipId}/rapport/${reportId}/1`

    return (
      <Wrapper>
        <div className={s.wrapper}>
          <div className={s.content}>
            <h2 className={s.title}>Dagbok</h2>
            <p className={s.text}>
              Bruk dagene dine til å jobbe med{' '}
              <Link to={focusUrl}>Ukens fokus</Link>, og ta deg tid til å
              registre kosthold og trening i NEDiVEKT-appen eller på Roede.com. På
              din Roede-dag henter du opp din ukesoppsummering og kommenterer på
              den.
            </p>
            <p className={s.alert}>
              Husk at du må legge inn vekt og mål i NEDiVEKT-appen før du laster
              inn dine resultater fra dagboken.
            </p>
          </div>
          <aside className={s.sidebar}>
            <span>Ukens tema:</span>
            <h3>Åpner {date.format('dddd DD MMMM')}</h3>
            <Link to={focusUrl}>Gå til Ukens fokus</Link>
          </aside>
        </div>
      </Wrapper>
    )
  }
}

export default withRouter(WaitingToOpen)
