import { gql } from '@apollo/client';

const setNewsletterPreferencesMailchimp =  gql`
mutation User(
    $email: String!,
    $token: String,
    $newsletterDinnerTips: Boolean,
    $newsletterWeekly: Boolean,
    $newsletterInformation: Boolean,
    $newsletterSms: Boolean  
) {
    auth {
        changeEmailSubscription(
            email: $email,
            token: $token,
            newsletterDinnerTips: $newsletterDinnerTips,
            newsletterWeekly: $newsletterWeekly,
            newsletterInformation: $newsletterInformation,
            newsletterSms: $newsletterSms
        ) {
            status
            message
        }
    }
}`;

export default setNewsletterPreferencesMailchimp;
