import React, { Fragment } from 'react'
import Panel from 'shared/Panel'
import MissingWeightMessage from '../MissingWeightMessage'
import DeleteEntry from 'shared/DeleteEntry'
import ActivitySelector from './ActivitySelector'
import ExerciseModal from './ExerciseModal'
import { formatPoints, formatCalories, formatDuration } from '../util'
import './styles.scss'

class ExerciseEntry extends React.Component {
  state = {
    entry: null
  }

  openEditModal = (event, exerciseEntry) => {
    event.preventDefault()

    const entry = {
      ...exerciseEntry,
      ...{ date_time: exerciseEntry.dateTime },
    }

    this.setState({ entry })
  }

  closeEditModal = () => {
    this.setState({ entry: null })
  }

  render() {
    const { entry, weight, refetch } = this.props

    return (
      <DeleteEntry
        registrationId={entry.registrationId}
        refetch={refetch}
        render={({ deleting, deleteEntry }) => (
          <li data-deleting={deleting} key={entry.registrationId}>
            <p className="exercise">
              <span>{entry.data.activity}</span>
              <a href="edit" onClick={e => this.openEditModal(e, entry)}>
              {entry.data.isFitnessTracker ? (
                <Fragment>{entry.data.fitnessTrackerDescription}, {formatCalories(entry.calories)} kcal</Fragment>
              ) : (
                <Fragment>{entry.data.intensity}, {formatDuration(entry.data.duration)}</Fragment>
              )}
              </a>
              {this.state.entry &&
                <ExerciseModal
                  entry={this.state.entry}
                  close={this.closeEditModal}
                  weight={weight}
                  refetch={refetch}
                />
              }
            </p>
            {entry.data.strengthPoints > 0 &&
              <i className="icon-gr-strength" aria-label={`${entry.data.strengthPoints} styrkepoeng`} />
            }
            <p className="points">
              <span>{formatPoints(entry.data.exercisePoints)}</span>
              <strong>{formatCalories(entry.calories)}</strong>
            </p>
            <button onClick={deleteEntry}><i className="icon-cancel-circle"></i></button>
          </li>
        )}
      />
    )
  }
}

const RegisterExercise = ({ loading, entries, totals, weight, refetch }) => (
  <Panel loading={loading}>
    <Panel.Header>
      <Panel.Title>Registrer trening</Panel.Title>
      <Panel.InfoText>Anbefalt 1 treningspoeng per dag, 7 per uke</Panel.InfoText>
    </Panel.Header>
    <Panel.Body>
      <MissingWeightMessage weight={weight} />
      <form name="registerExercise" className="registerExercise" action="" method="post" onSubmit={e => e.preventDefault()}>
        <fieldset>
          <ActivitySelector weight={weight} refetch={refetch} />
        </fieldset>
        {entries.length > 0 &&
          <Fragment>
            <p className="equivalence">
              SUM Treningspoeng: {formatPoints(totals.points)} = {formatCalories(totals.calories)} kcal
            </p>
            <ul className="exerciseList">
              {entries.map(entry => (
                <ExerciseEntry key={entry.registrationId} entry={entry} weight={weight} refetch={refetch} />
              ))}
            </ul>
          </Fragment>
        }
      </form>
    </Panel.Body>
  </Panel>
)

export default RegisterExercise