import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import s from "./styles/Header.module.scss";

function NavItem({ name, value, membershipId }) {
  const tabIndex = value ? undefined : -1;
  return (
    <Link to={`/coach/${membershipId}/rapport/${value}/`} tabIndex={tabIndex}>
      {name}
    </Link>
  );
}

class Header extends React.Component {
  render() {
    const { membershipId, submitDeadline, prev, next } = this.props;
    //weekdates
    const weekNumber = moment(submitDeadline).format("W");
    const weekDate = moment(submitDeadline).format("DD.MM.YYYY");
    //const startOfWeek = moment(weekdates[0]).format('DD.MM.YYYY')
    //const endOfWeek = moment(weekdates[6]).format('DD.MM.YYYY')

    return (
      <div className={s.header}>
        <h4>
          <strong>Uke {weekNumber}</strong>
          <span>
            Leveringsfrist: <b>{weekDate} kl 24:00</b>
          </span>
        </h4>
        <div className={s.nav}>
          <NavItem name="Forrige" value={prev} membershipId={membershipId} />
          <NavItem name="Neste" value={next} membershipId={membershipId} />
        </div>
      </div>
    );
  }
}

export default Header;
