import { gql } from '@apollo/client';

const getCache = gql`
  query CacheQuery ($date: Date) {
    request {
      diary {
        dailyDiaryCache (date: $date) {
          edges {
            node {
              data
            }
          }
        }
        weeklyDiaryCache(date: $date) {
          data
        }
      }
    }
  }
`;

export default getCache;