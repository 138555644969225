import { gql } from '@apollo/client';

const addEntries = gql`
  mutation SaveEntriesAsCustomGroup (
    $date: DateTime!,
    $name: String!,
    $category: String!,
    $tag: String
  ){
    diary {
      saveEntriesAsCustomGroup (
          date: $date,
          name: $name,
          category: $category,
          tag: $tag
      ) {
        status
      }
    }
  }
`;

export default addEntries;