import React from 'react'
import EntryFormModal from '../EntryFormModal'
import { graphql } from '@apollo/client/react/hoc'
import { GET_FOOD_PRODUCT } from 'queries'
import { getSafe } from 'utils'


const AddIngredientModalContainer = ({ data: { loading, error, request }, addIngredient, close }) => {
  const food = getSafe(() => request.kitchen.ingredients.edges[0].node)

  if (food) {
    const foodUnits = food.units.edges
      .map(edge => edge.node)
      .map(unit => ({ name: unit.unit, gramsPerUnit: unit.gramsPerUnit }))

    return <EntryFormModal
      ingredient={{
        amount: null,
        fpId: food.objectId,
        fpType: 'product',
        gramsPerUnit: null,
        name: food.name,
        o2FpId: 1676689,
        o2Id: food.o2Id,
        unitName: null,
      }}
      entry={{
        registrationId: null,
        category: 'food',
        description: null,
        calories: 0,
        data: {
          id: food.objectId,
          fpId: food.objectId,
          o2Id: food.o2Id,
          type: 'product',
          name: food.name,
          quantity: null,
          unit: null,
          units: foodUnits,
          allergens: food.allergens,
          heartFriendly: food.isHeartFriendly,
          quality: food.dietCategory,
          nutrition: {
            kcalPer100Grams: food.kcal100g,
            gramsOfProteinPer100Grams: food.protein100g,
            gramsOfCarbohydratePer100Grams: food.carbs100g,
            gramsOfFatPer100Grams: food.fat100g,
            milligramsOfCalciumPer100Grams: food.calcium100g,
            gramsOfFruitsPer100Grams: food.fruits100g,
            gramsOfVegetablesPer100Grams: food.veg100g,
          }
        },
      }}
      addIngredient={addIngredient}
      close={close}
    />
  }

  return null
}

const AddIngredientModal = graphql(GET_FOOD_PRODUCT, {
  options: ({ objectId }) => ({ variables: { objectId: [objectId] } })
})(AddIngredientModalContainer)

export default AddIngredientModal
