import { gql } from '@apollo/client';

const getUser = gql`
  query UserQuery {
    request {
      user {
        roles
        onboardingDone
        userData {
          userId
          firstName
          kcalMenu
          favorites {
            objId
            objType
          }
        }
      }
    }
  }
`;

export default getUser;
