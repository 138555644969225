
import React, { useState } from 'react';
import { useMutation } from '@apollo/client'
import formStyles from 'assets/styles/Form.module.scss'
import { CONNECT_TO_POLAR } from 'queries'
import { GET_POLAR_DATA } from 'queries';
import {PopupButton} from 'components/ui/Popup'
import styles from '../Popup.module.scss';

interface Props {    
  close?: () => void;
  email?: string;
}

const PolarRegistrationUI:React.FC<Props> = ({email: emailTmp, close, ...props}) => {
  const [email, handleEmail] = useState(emailTmp)
  const [save, {loading}] = useMutation(CONNECT_TO_POLAR, { 
      onCompleted() {
          close();
      }
  });

  const submit = async (event) => {
      event.preventDefault()
      const variables = {polarEmail: email.trim(),}    
      try {
          await save({variables, refetchQueries: [GET_POLAR_DATA]})
      } catch (error) {
          alert(error)
      }
  }

  return (<form  className={formStyles.form} onSubmit={submit}>
    <div className={styles.modalBody}>
      <div className={styles.modalContainer}>
        <div className={styles.inputContainer}>
          <div className={styles.labelContainer}>Polar-bruker</div>
          <div className={styles.inputWrapper}>
              <input type="text" placeholder="E-post" defaultValue={email} onChange={(e) => handleEmail(e.target.value)} autoComplete="off" className={styles.inputText} />
          </div>
        </div>
        <button disabled={loading} type="submit">KOBLE SAMMEN</button>
      </div>
    </div>  
  </form>)
}

export default function PolarRegistration({...props}) {
  return(
    <PopupButton  title="Polar koble sammen" show={props.show}>
      <PolarRegistrationUI {...props}  />
    </PopupButton>
  )
}