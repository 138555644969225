import Modal from 'shared/Modal'
import { durationOptions } from './durationOptions'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY } from 'queries'
import withRouter from '../../../../utils/withRouter'
import './styles.scss'

const FitnessTrackerFields = ({ entry }) => (
  <div className="fitnessTrackerFields">
    <div>
      <label>Beskrivelse</label>
      <input type="text" name="fitnessTrackerDescription" defaultValue={entry.data.fitnessTrackerDescription} required autoFocus />
    </div>
    <div>
      <label>Kcal</label>
      <input type="number" name="calories" className="amount" defaultValue={entry.calories} required />
    </div>
  </div>
)

const ExerciseFields = ({ entry }) => (
  <div className="exerciseFields">
    <div>
      <label>Intensitet</label>
      <select name="intensity" defaultValue={entry.data.intensity} autoFocus>
        {entry.data.intensities.map((option, index) => (
          <option key={index} value={option.name}>{option.name}</option>
        ))}
      </select>
    </div>
    <div>
      <label>Varighet</label>
      <select name="duration" defaultValue={entry.data.duration}>
        {durationOptions.map((option, index) => (
          <option key={index} value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  </div>
)

const ExerciseModalWithMutation = ({ entry, close, saveExercise }) => (
  <Modal title="Legg inn trenning" onClose={close}>
    <form name="exerciseForm" method="post" action="" onSubmit={saveExercise}>
      <Modal.Body>
        <h2>{entry.data.activity}</h2>
        {entry.data.isFitnessTracker ? (
          <FitnessTrackerFields entry={entry} />
        ) : (
          <ExerciseFields entry={entry} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" onClick={close}>Avbryt</button>
        <button type="submit">Lagre</button>
      </Modal.Footer>
    </form>
  </Modal>
)

const AddEntryWrapper = graphql(ADD_ENTRY, {
  props: ({ ownProps: {
    entry: exerciseEntry,
    close,
    refetch,
    weight,
    match: { params: { date } },
  }, mutate }) => ({
    saveExercise: event => {
      event.preventDefault()
      const entry = processExerciseEntry(exerciseEntry, event.target, weight)

      mutate({
        variables: entry,
      }).then(() => {
        refetch().then(() => close())
      })
    },
  }),
})(ExerciseModalWithMutation)

const ExerciseModal = withRouter(AddEntryWrapper)

const processExerciseEntry = (entry, form, weight) => {
  if (entry.data.isFitnessTracker) {
    return processFitnessTrackerEntry(entry, form)
  }

  const intensity = form.intensity.value

  const { metValue } = entry.data.intensities.find(option => (
    option.name === intensity
  ))

  const duration = parseInt(form.duration.value, 10)

  const calories = Math.round(parseFloat(metValue) * parseFloat(weight) * duration / 60)

  const exercisePoints = calories / 200

  const strengthPoints = (duration >= 15 && entry.data.hasStrengthTraining) ? 1 : 0

  const data = JSON.stringify({
    ...entry.data,
    ...{ intensity, duration, weight, exercisePoints, strengthPoints },
    source: 'web'
  })

  return {
    ...entry,
    ...{ calories, data },
  }
}

const processFitnessTrackerEntry = (entry, form) => {
  const calories = Math.round(parseFloat(form.calories.value))

  const exercisePoints = calories / 200

  const fitnessTrackerDescription = form.fitnessTrackerDescription.value

  const strengthPoints = (/Styrketrening/i.test(fitnessTrackerDescription) && calories > 100) ? 1 : 0

  const data = JSON.stringify({
    ...entry.data,
    ...{ exercisePoints, strengthPoints, fitnessTrackerDescription },
  })

  return {
    ...entry,
    ...{ calories, data },
  }
}

export default ExerciseModal
