import { gql } from '@apollo/client';

const toggleFavorite = gql`
  mutation ToggleFavorite (
      $objectId: Int!,
      $objectType: String!
  ){
    diary {
      toggleFavorite (
          objectId: $objectId,
          objectType: $objectType
      ) {
        status
        isFavorite
      }
    }
  }
`;

export default toggleFavorite;