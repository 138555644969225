import React, { useState } from 'react';
import { useMutation } from '@apollo/client'
import { GET_USER_DATA } from 'queries';
import { SET_PROPERTY } from 'queries'
import formStyles from 'assets/styles/Form.module.scss'
import {PopupButton} from 'components/ui/Popup'

interface Props {
  recommendedKcalIn?: number
  close?: () => void;
}

export const KcalPerDayContentForm:React.FC<Props> = ({recommendedKcalIn: recommendedKcalInTmp, close, ...props}) => {

  const [recommendedKcalIn, handleChange] = useState(recommendedKcalInTmp); 

  const [save, {loading}] = useMutation(SET_PROPERTY, { 
    onCompleted() {
      close();
    }
  });

  const submit = async (event) => {
    event.preventDefault()
    const variables = {key: "recommended_kcal_in", value: recommendedKcalIn}
    try {
      await save({variables, refetchQueries: [GET_USER_DATA]})
    } catch (error) {
      alert(error)
    }
  }

  return <form  className={formStyles.form} onSubmit={submit}>
    <div className={formStyles.optioninputs}>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="1000" required
            checked={recommendedKcalIn === 1000}
            onChange={() => handleChange(1000)} />
        <div>
            <h3>Kickstart</h3> 1000 kcal
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="1300" required
            checked={recommendedKcalIn === 1300}
            onChange={() => handleChange(1300)} />
        <div>
            <h3>Meny 1</h3> 1300 kcal
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="1600" required
            checked={recommendedKcalIn === 1600}
            onChange={() => handleChange(1600)} />
        <div>
            <h3>Meny 2</h3> 1600 kcal
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="1900" required
            checked={recommendedKcalIn === 1900}
            onChange={() => handleChange(1900)} />
        <div>
            <h3>Meny 3</h3> 1900 kcal
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="2200" required
            checked={recommendedKcalIn === 2200}
            onChange={() => handleChange(2200)} />
        <div>
            <h3>Meny 4</h3> 2200 kcal
        </div>
      </label>
      <label className={formStyles.optioninput}>
        <input type="radio" name="recommendedKcalIn" value="2500" required
            checked={recommendedKcalIn === 2500}
            onChange={() => handleChange(2500)} />
        <div>
            <h3>Meny 5</h3> 2500 kcal
        </div>
      </label>
    </div>


      <button disabled={loading} type="submit">Lagre</button>
  </form>
}

export default function KcalPerDayButton(props) {
  return(
    <PopupButton  title="Min Meny (kcal per dag)" show={props.show}>
      <KcalPerDayContentForm {...props}  />
    </PopupButton>
  )
}



