import { useSearchParams } from 'react-router-dom'
import Wrapper from 'components/ui/Wrapper'
import PlaceSearch from './PlaceSearch'
import CourseTypes from './CourseTypes'
import Courses from './Courses'
import { LoadingInline } from 'components/ui/Loading'
import { useData, useDataSearch } from './utils'
import s from './styles/CourseMembership.module.scss'

type Props = {
  membershipId: number | null
  postalCode: string
  refetch: () => void
  setCourseIdCallback?: (courseId: string) => void | null
  formattedAddress?: string
  slug?: string | null | undefined
}

export default function CourseMembershipWrapper(  {membershipId,
  postalCode,
  refetch,
  setCourseIdCallback,
}: Props){
  return(
    <div className={s.page}>
      <Wrapper>
        <h1>Velg type oppfølging</h1>
        <CourseMembership membershipId={membershipId} postalCode={postalCode} refetch={refetch} setCourseIdCallback={setCourseIdCallback} />
        </Wrapper>
    </div>)
    }

export function CourseMembership({
  membershipId,
  postalCode,
  refetch,
  setCourseIdCallback,
  formattedAddress,
  slug
}: Props) {
  const [searchParams] = useSearchParams()
  const placeId = searchParams.get('placeId') ?? postalCode
  const placeName = searchParams.get('placeName') ?? formattedAddress ?? placeId
  const radius = searchParams.get('radius') ?? '20'
  const courseType = searchParams.get('courseType') ?? ''
  const variables = { membershipId, placeId, radius, slug }
  const { courses, nettCourses, courseTypes, loading } = useData(variables)

  return (
    <>
        <PlaceSearch radius={radius} placeName={placeName} />
        {loading  ? <LoadingInline /> : 
          <>
          <CourseTypes courseType={courseType} courseTypes={courseTypes} />
          <Courses
            key={courseType}
            membershipId={membershipId}
            courseData={courses}
            nettCourses={nettCourses}
            courseTypes={courseTypes}
            setCourseIdCallback={setCourseIdCallback}
            refetch={refetch}
          />
        </>}
      </>
  )
}
