import React from "react";
// import moment from 'moment'
import { graphql } from "@apollo/client/react/hoc";
import { Link } from "react-router-dom";
import { getSafe } from "utils";

import { withAuth } from "components/container/withAuth";
import Loading from "../../components/ui/Loading";
import Wrapper from "components/ui/Wrapper";
import Invoice from "components/ui/Invoice";
import UnpaidInvoiceNotice from "components/ui/UnpaidInvoiceNotice";

import s from "./InvoicePage.module.scss";

import { GET_INVOICES } from "queries";

class InvoicePageWithData extends React.Component {
  render() {
    const { data } = this.props;
    if (data.loading) return <Loading />;

    const invoices =
      getSafe(() => data.user.invoices.edges.map((edge) => edge.node)) || [];

    return (
      <Wrapper>
        <UnpaidInvoiceNotice />
        <div className={s.invoices}>
          <div>
            <Link className={s.back} to={`/profil/`}>
              <span>Min profil</span>
            </Link>
          </div>
          <h1>Faktura</h1>
          <div className="invoices">
            {invoices.map((invoice) => (
              <Invoice
                key={invoice.id}
                invoice={invoice}
                refetch={this.props.data.refetch}
              />
            ))}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const InvoicePage = withAuth(graphql(GET_INVOICES)(InvoicePageWithData));
export default InvoicePage;
