import { usePlacesWidget } from "react-google-autocomplete";
import s from "./styles/CourseMembership.module.scss";

type InputSearchProps = {
  placeName: string;
  setPlace: (placeId: string, placeName: string) => void;
};

export default function InputSearch({ placeName, setPlace }: InputSearchProps) {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyB_tBlpB8wkEwGGVNL37ZLcTb2vJkByWsA",
    options: {
      types: null,
      componentRestrictions: { country: "no" },
      language: "no",
      region: "no",
    } as google.maps.places.AutocompleteOptions,
    onPlaceSelected: (place) => {
      if (place?.place_id) {
        setPlace(place.place_id, place.formatted_address);
      }
    },
  });

  return (
    <input
      ref={ref}
      type="text"
      className={s.placeInput}
      placeholder={"Sted eller adresse"}
      defaultValue={placeName}
      required
    />
  );
}
