import React from 'react'
import SubNavigation from 'shared/SubNavigation'
import MealsList from './MealsList'

const DailyMenusPage = () => (
  <div className="myFoodPage">
    <h1>Min mat</h1>
    <SubNavigation selectedOption="Måltider" />
    <div className="container">
      <div className="dataTables">
        <MealsList />
      </div>
      <div className="textContent">
        <h2>Egne måltider</h2>
        <p>Her kan du slette egne måltider som du tidligere har lagret.</p>
        <p><strong>Bruk av egne måltider</strong> <br />Om du bruker funksjonen egne måltider gjør det enklere å registrere. Spiser du ofte den samme frokosten, kan du f. eks. lagre denne som "Hverdagsfrokost". Når du senere registrerer denne frokosten kan du enkelt endre i matvarer og/eller mengder dersom du ønsker det. Det gjør du på registreringssiden. <br />Gjør du en større endring kan du lagre på nytt som eget måltid f. eks. "Hverdagsfrokost med egg". </p>
        <p><strong>Tips</strong> <br />Det kan våre smart å lagre de grønnsakene du ofte spiser til middag som "Min grønnsaksblanding" så går registreringen raskere. Det samme gjelder for paprika, tomat, agurk og lignende som du ofte bruker på brødskiven.</p>
      </div>
    </div>
  </div>
)

export default DailyMenusPage
