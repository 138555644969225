import React, { Fragment } from 'react'
import AddCustomFoodButton from './AddCustomFoodButton'
import styles from './SearchResults.module.scss'

class SearchResults extends React.Component {
  handleScroll = ({ target }) => {
    const { data: { fetchMore, isLoading } } = this.props

    if (!Boolean(fetchMore) || isLoading) return false

    const start = 60 //start fetching next results before this number of px
    const difference = target.firstChild.clientHeight - target.clientHeight - start

    if (target.scrollTop >= difference) {
      fetchMore()
    }
  }

  render() {
    const { data, mealName, ...otherProps } = this.props
    const { favorites, custom, mealClock, search } = data

    return (
      <div className={styles.searchResults} onScroll={this.handleScroll}>
        <FoodList
          title="Favoritt oppskrifter og matvarer"
          items={favorites}
          startIndex={0}
          {...otherProps}
        />
        <FoodList
          title="Egne måltider, oppskrifter og matvarer"
          items={custom}
          startIndex={favorites.length}
          {...otherProps}
        />
        <FoodList
          title={mealName}
          items={mealClock}
          startIndex={favorites.length + custom.length}
          {...otherProps}
        />
        <FoodList
          title="Søkeresultater"
          items={search}
          startIndex={favorites.length + custom.length}
          {...otherProps}
        />
        <AddCustomFoodButton {...otherProps} />
      </div>
    )
  }
}

const FoodList = ({
  title, items, getItemProps, highlightedIndex, startIndex
}) => {
  if (items && !!items.length) {
    return (
      <section className={styles.section}>
        <h2 className={styles.sectionTitle}>{title}</h2>
        <ul className={styles.foodList}>
          {items.map((item, index) => (
            <FoodItem
              {...getItemProps({
                item,
                index: startIndex + index,
                key: item.id,
              })}
              item={item}
              isHighlighted={highlightedIndex === (startIndex + index)}
            />
          ))}
        </ul>
      </section>
    )
  }

  return null
}

const FoodItem = ({
  item: { name, typeName, kcalPer100g, image },
  isHighlighted,
  ...otherProps
}) => (
  <Fragment>
    <li className={styles.foodItem} data-hasimage={Boolean(image)} {...otherProps}>
      {image && <img src={image} alt={name} className={styles.foodItemImage} />}
      <strong className={styles.foodItemName}>{name}</strong>
      <em className={styles.foodItemType}>{typeName}</em>
      {kcalPer100g && <Fragment>{kcalPer100g} kcal/100g</Fragment>}
      <button
        className={styles.foodItemButton}
        data-ishighlighted={isHighlighted}
        aria-label="Legg til"
      />
    </li>
  </Fragment>
)

export default SearchResults
