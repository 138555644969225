import React from 'react'
import DeleteFavorite from './DeleteFavorite'
import { GET_RECIPE } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { withAuth } from 'components/container/withAuth'
import { getSafe } from 'utils'

const FavoriteRecipesListWithData = ({
  data: { loading, request, refetch },
  auth: { user },
}) => {
  const recipes = getSafe(() => (
    request.kitchen.recipes.edges.map(edge => ({
      ...edge.node,
      isFavorite: true,
    }))
  ), [])

  if (loading) {
    return <div className="loading" />
  }

  if (recipes.length === 0) {
    return (
      <div className="emptyResultset">
        <i className="icon-info"></i> Ingen favorittoppskrifter
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <th>Mine favorittoppskrifter</th>
        </tr>
        {recipes.map(recipe => (
          <DeleteFavorite
            key={recipe.recipeId}
            objectId={recipe.recipeId}
            render={({ deleting, deleteFavorite }) => (
              <tr data-deleting={deleting}>
                <td>
                  <i className="icon-star" onClick={deleteFavorite} />
                  <a href={`${recipe.slug}`}>{recipe.name}</a>
                </td>
              </tr>
            )}
          />
        ))}
      </tbody>
    </table>
  )
}

const GetRecipeWrapper = graphql(GET_RECIPE, {
  options: ({ auth: { user } }) => ({
    variables: {
      recipeId: user.favorites
        .filter(favorite => favorite.objType === 'recipe')
        .map(favorite => favorite.objId)
    }
  })
})(FavoriteRecipesListWithData)

export default withAuth(GetRecipeWrapper)
