import { gql } from '@apollo/client';

const USER_INFO = gql`
  query UserInfo($userId:Int!) {
    central {
      userInfo(userId: $userId) {
        userId
        name
        bmi
        gender
        age
        email
        cellPhone
        address
        height
        weight
        kcalMenu
        motivation
        restingMetabolism
        trainingMetabolism
        recommendedKcalIn
        activityLevel
        exerciseHabits
        healthProblems
        allergiesList
      }
    }
  }
`;

export default USER_INFO