import React from "react";
import moment from "moment";
import withRouter from "../../utils/withRouter";
import { graphql } from "@apollo/client/react/hoc";
import Questions from "./Questions.js";
import Feedback from "./Feedback.js";
import Focus from "./Focus.js";
import LastFocus from "./LastFocus.js";
import CourseUpdate from "components/ui/CourseUpdate";
import Diary from "./Diary.js";

import GenerateReport from "./GenerateReport.js";

import {
  IntroStart,
  IntroGoal,
  IntroFoodHabits,
  IntroActive,
  IntroRoede,
} from "./introwidgets.js";
import Wrapper from "components/ui/Wrapper";

import { GET_WEEK_REPORT, SUBMIT_STEP } from "queries";
import s from "./styles/ReportPage.module.scss";

class Section extends React.Component {
  state = {
    loading: false,
    answers: {},
    autosaving: false,
    written_chars: 0,
    ticks_since_last_save: 0,
    widgetReply: "",
    open: false,
  };

  autoSave() {
    if (this.state.open && !this.state.loading && !this.state.autosaving) {
      this.setState({
        autosaving: true,
        written_chars: 0,
        ticks_since_last_save: 0,
      });
      this.submit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open === false && this.props.report.status === "open") {
      this.setState({ open: true });
    }

    if (
      prevProps.sectionId !== this.props.sectionId ||
      prevProps.membershipId !== this.props.membershipId ||
      prevProps.membershipId !== this.props.membershipId
    ) {
      const { sectionId, sections } = this.props;
      const section = sections[sectionId];
      const answers = {};
      if (section && section.questions) {
        Object.entries(section.questions).map(
          ([index, data]) => (answers[index] = data.answer)
        );
      }
      this.setState({ answers: answers, sectionId: sectionId });
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    const ticks_since_last_save = this.state.ticks_since_last_save + 1;

    if (this.state.written_chars > 1 && ticks_since_last_save > 60) {
      this.autoSave();
    } else {
      this.setState({ ticks_since_last_save: ticks_since_last_save });
    }
  }

  render() {
    const { sections, report, sectionId } = this.props;
    const { courseUpdate, membershipId, reportId } = report;
    const section = sections[sectionId];
    const hasData = Boolean(report.diaryData);
    const needsData = false;
    const submitDeadline = moment(report?.submitDeadline).format("DD.MM.YYYY");

    const widget_map = {
      feedback: <Feedback report={report} />,
      focus: <Focus report={report} />,
      last_focus: <LastFocus report={report} />,
      course_update: <CourseUpdate courseUpdate={courseUpdate} />,
      diary: <Diary report={report} />,
      intro_start: (
        <IntroStart handleWidgetReplyChange={this.handleWidgetReplyChange} />
      ),
      intro_goal: (
        <IntroGoal handleWidgetReplyChange={this.handleWidgetReplyChange} />
      ),
      intro_food_habits: <IntroFoodHabits />,
      intro_active: (
        <IntroActive handleWidgetReplyChange={this.handleWidgetReplyChange} />
      ),
      intro_roede: <IntroRoede />,
    };

    if (needsData && !hasData) {
      return (
        <section id={`section${sectionId}`}>
          <GenerateReport report={report} />
        </section>
      );
    } else {
      return (
        <section id={`section${sectionId}`}>
          <Wrapper>
            <div className={s.widgetWrapper}>{widget_map[section.widget]}</div>
            <form
              method="post"
              onSubmit={this.submitForm}
              data-loading={this.stateloading}
              className={s.formWrapper}
            >
              {section["widgetReply"] !== undefined && (
                <input
                  type="text"
                  onChange={() => null}
                  style={{ width: 0, opacity: 0, float: "left" }}
                  value={this.state.widgetReply}
                  required
                />
              )}

              <div className={s.content}>
                <p className={s.intro}>{section.description}</p>
              </div>

              <Questions
                prefix={`${membershipId}.${reportId}.${sectionId} `}
                step={sectionId + 1}
                {...section}
                handleAnswerChange={this.handleAnswerChange}
                membershipId={membershipId}
                reportId={reportId}
              />
              <button
                className={s.buttonSection}
                disabled={!report?.status?.includes("open")}
                data-loading={this.state.loading}
                type="submit"
              >
                {sectionId === sections.length - 1
                  ? "Lagre"
                  : "Lagre og gå videre"}
              </button>
              {/* {&& (sectionId === sections.length - 1 )} */}
              {report?.reportType?.includes("paid") &&
                report?.status?.includes("open") && (
                  <i>Levering skjer automatisk {submitDeadline} kl 24:00</i>
                )}
              {report?.reportType?.includes("intro") &&
                report?.status?.includes("open") && (
                  <i>
                    Levering skjer med en gang alle seksjonene er lagret og har
                    en grønn hake. Leverer du ikke innen fristen blir den utsatt
                    med en uke.
                  </i>
                )}
            </form>
          </Wrapper>
        </section>
      );
    }
  }

  handleAnswerChange = (index, answer) => {
    const answers = this.state.answers;
    const written_chars = this.state.written_chars + 1;
    answers[index] = answer;
    this.setState({ answers: answers, written_chars: written_chars });
  };

  handleWidgetReplyChange = (widgetReply) => {
    const written_chars = this.state.written_chars + 1;
    this.setState({ widgetReply: widgetReply, written_chars: written_chars });
  };

  submitForm = (event) => {
    event.preventDefault();
    this.submit();
  };

  submit = async () => {
    this.setState({ loading: true });
    const { report, sections, sectionId } = this.props;
    const { membershipId, reportId } = report;
    const step = sectionId + 1;
    const answers = JSON.stringify(this.state.answers);
    const submitStep = !this.state.autosaving;
    //   const key = `${step}_question_${index}`
    //   const data = JSON.stringify({ [key]: const answer })
    await this.props
      .mutate({
        variables: { step, reportId, membershipId, answers, submitStep },
        refetchQueries: [
          {
            query: GET_WEEK_REPORT,
            variables: this.props.match.params,
          },
        ],
      })
      .then(({ data }) => {
        if (data.netcourse.netcourseSubmitStep.status === "success") {
          this.setState({ loading: false });

          if (!this.state.autosaving && sectionId + 1 < sections.length) {
            this.props.router.navigate(
              `/coach/${membershipId}/rapport/${reportId}/${sectionId + 1}`
            );
          }
          this.setState({ autosaving: false });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
}

const SubmitStepWrapper = graphql(SUBMIT_STEP)(Section);

export default withRouter(SubmitStepWrapper);
