import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import withRouter  from '../../utils/withRouter'
import { DELETE_ENTRIES } from 'queries'

class DeleteEntryWithMutation extends React.Component {
  state = {
    deleting: false
  }

  deleteEntry = () => {
    const { registrationId, mutate, match: { params: { date } }, refetch } = this.props

    const date_time = `${date}T00:00:00`

    if (! this.state.deleting) {
      this.setState({ deleting: true })

      mutate({
        variables: { registrationId, date_time },
      }).then(() => refetch())
    }
  }

  render() {
    return this.props.render({
      deleting: this.state.deleting,
      deleteEntry: this.deleteEntry,
    })
  }
}

const DeleteEntry = withRouter(graphql(DELETE_ENTRIES)(DeleteEntryWithMutation))

export default DeleteEntry