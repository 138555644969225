import React from 'react'
import LoadingContext from 'components/ui/Loading/LoadingContext'
import { withDate } from 'components/util/withDate'
import { withAuth } from 'components/container/withAuth'
import { graphql } from '@apollo/client/react/hoc'
import { GET_DAILY_MENUS, ADD_ENTRIES_FROM_DAILY_MENU } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'
import './styles.scss'

const DailyMenuFormContainer = ({
  auth: { user },
  date,
  data: { loading, error, request: {
    diary: {
      dailyMenus: {
        edges: dailyMenusEdges = []
      } = {}
    } = {}
  } = {} },
  mutate,
}) => {
  const loadingIndicator = React.useContext(LoadingContext)
  let dailyMenuRef

  const systemDailyMenus = dailyMenusEdges
    .map(edge => edge.node)
    .filter(menu => user.ownerId !== menu.memberO2)

  const userDailyMenus = dailyMenusEdges
    .map(edge => edge.node)
    .filter(menu => user.ownerId === menu.memberO2)

  const importEntries = (e, loadingIndicator) => {
    e.preventDefault()

    loadingIndicator.show()

    mutate({
      variables: {
        date,
        customGroupId: dailyMenuRef.value,
        category: 'food',
      },
      refetchQueries: refetchQueries(),
    }).then(({ data }) => {
      loadingIndicator.hide()
    }).catch((error) => {
      console.log(error)
    })
  }

  return (
    <form className="daily-menu group" onSubmit={(e) => importEntries(e, loadingIndicator)}>
      <label>Legg inn mat fra standard-dag:</label>
      <select name="dayReportId" ref={input => dailyMenuRef = input}>
        {systemDailyMenus.map(menu => (
          <option value={menu.objectId} key={menu.objectId}>{menu.name}</option>
        ))}
        {userDailyMenus.map(menu => (
          <option value={menu.objectId} key={menu.objectId}>{menu.name}</option>
        ))}
      </select>
      <button type="submit">Legg inn</button>
    </form>
  )
}

const AddEntiresFromDailyMenuWrapper = graphql(ADD_ENTRIES_FROM_DAILY_MENU)(DailyMenuFormContainer)
const GetDailyMenusWrapper = graphql(GET_DAILY_MENUS)(AddEntiresFromDailyMenuWrapper)

export default withAuth(withDate(GetDailyMenusWrapper))
