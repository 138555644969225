import { useState} from 'react'
import Modal from 'components/ui/Modal'

const AboutButton = () => {
  const [isOpen, setOpen] = useState(false)
  const open = () => {setOpen(true)}
  const close = () => {setOpen(false)}
  
  return (
    <div style={{ textAlign: 'center', marginBottom: '4rem' }} >
      <button className="aboutLink" onClick={open}>
        <i className="icon-info"></i> Om denne siden
      </button>
      {isOpen &&
        <AboutModal close={close} />
      }
    </div>
  )
}

const AboutModal = ({ close }) => (
  <Modal title="Om denne siden" onClose={close} size="default">
    <Modal.Body>
      <div className="aboutModal">
        <h2>Ukesammendrag</h2>
        <p>Her finner du oversikt over dine resultater så langt. Du ser din endring i vekt, midjemål og totalmål for den siste uken samt siden startdato. Flere av funksjonene på denne siden, er kun tilgjengelig for kursdeltakere og Gull-medlemmer i Roede-klubben.</p>
        <h2>Dag til dag-oversikt</h2>
        <p>Du får en god dag til dag oversikt over ditt daglige energiinntak, kostholdsscore, treningspoeng, styrketrening og antall registrerte skritt gjennom den siste uken. Dine kommentarer kan du også lese her.</p>
        <h2>Endre visningsuke</h2>
        <p>I feltet oppe til høyre kan du endre hvilken uke du vil se.</p>
        <h2>Glemt å registrere noe?</h2>
        <p>Om du har glemt å registrere noe, klikker du på den aktuelle dagen for å komme til registreringssiden. Her legger du til det du har glemt, klikker Oppdater og dine resultater vil være oppdaterte.</p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button type="submit" onClick={close}>Lukk</button>
    </Modal.Footer>
  </Modal>
)

export default AboutButton
