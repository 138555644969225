import React from 'react'
import Wrapper from 'components/ui/Wrapper'
import moment from 'moment'
import { gql, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { withAuth } from 'components/container/withAuth'
import { getSafe } from 'utils'
import './styles.scss'

function DietSummaryUI({ auth }) {
  let params = useParams()  

  let year:number = parseInt(params.year) || parseInt(moment().format('YYYY'))
  let week:number = parseInt(params.week) || parseInt(moment().format('W'))

  const GET_SUMMARY = getDietSummaryQuery(year, week)
  const { loading, data } = useQuery(GET_SUMMARY)
  const { days, totals } = getDietAndExerciseData(data?.diary, year, week)

  return (
    <Wrapper>
      <div className="dietTablesMsg">
        <i className="icon-arrow-2-left" />
        <i className="icon-arrow-2-right" />
        Scroll sidelengs for å se mer av tabellen
      </div>
      <div className="dietTables" data-loading={loading}>
        <table className="dietTable" data-table="days">
          <tbody>
            <tr>
              <th>Dag</th>
            </tr>
            {days.map((day) => (
              <tr key={day.cacheKey}>
                <td>
                  <Link to={`/kosthold/${day.date}/`}>{day.dateFormatted}</Link>
                </td>
              </tr>
            ))}
            <tr>
              <td>Sum</td>
            </tr>
            <tr>
              <td>Snitt</td>
            </tr>
          </tbody>
        </table>
        <div className="dietTableWrapper">
          <table className="dietTable" data-table="data">
            <tbody>
              <tr>
                <th>kcal</th>
                {auth.user.hasPermissions && <th>Kostholdscore</th>}
                <th>Treningspoeng</th>
                <th>Skritt</th>
                <th>Kommentar</th>
              </tr>
              {days.map((day) => (
                <tr key={day.cacheKey}>
                  <td>{formatNumber(day.kcal)}</td>
                  {auth.user.hasPermissions && (
                    <td>{formatNumber(day.dietScore, 1)}</td>
                  )}
                  <td>
                    {formatNumber(day.trainingPoints, 1)} /{' '}
                    {formatNumber(day.strengthPoints)}
                  </td>
                  <td>{formatNumber(day.numSteps)}</td>
                  <td>
                    <div className="dietTableComments">{day.comments}</div>
                  </td>
                </tr>
              ))}
              <tr>
                <td>{formatNumber(totals.kcal)}</td>
                {auth.user.hasPermissions && (
                  <td>{formatNumber(totals.dietScore, 1)}</td>
                )}
                <td>
                  {formatNumber(totals.trainingPoints, 1)} /{' '}
                  {formatNumber(totals.strengthPoints)}
                </td>
                <td>{formatNumber(totals.numSteps)}</td>
                <td></td>
              </tr>
              <tr>
                <td>{formatNumber(totals.kcal / totals.daysWithDiet)}</td>
                {auth.user.hasPermissions && (
                  <td>
                    {formatNumber(totals.dietScore / totals.daysWithDiet, 1)}
                  </td>
                )}
                <td>
                  {formatNumber(
                    totals.trainingPoints / totals.daysWithExercise,
                    1
                  )}
                  /{' '}
                  {formatNumber(
                    totals.strengthPoints / totals.daysWithExercise,
                    1
                  )}
                </td>
                <td>
                  {formatNumber(totals.numSteps / totals.daysWithExercise)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  )
}

function formatNumber(number, decimals = 0) {
  if (isNaN(Number(number))) {
    return '0'
  }
  const options = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }
  return Number(number).toLocaleString('nb-NO', options)
}

function getDietAndExerciseData(diary, year, week) {
  const days = getWeekDays(year, week).map((day) => {
    const { date, dateFormatted, cacheKey } = day
    const safeData = getSafe(() => JSON.parse(diary[cacheKey].edges[0].node.data)) || {}

    return {
      cacheKey: cacheKey,
      date: date,
      dateFormatted: dateFormatted,


      kcal: safeData?.diet?.totalEatenKcal,
      dietScore: safeData?.diet?.score,
      trainingPoints: safeData?.exercise?.exercisePoints,
      strengthPoints: safeData?.exercise?.strengthPoints,
      numSteps: safeData?.exercise?.numSteps,
      comments: safeData?.exercise?.comments
    }
  })

  const totals = getTotals(days)

  return { days, totals }
}

function getTotals(days) {
  const totals = days.reduce(
    (acc, curr) => {
      acc.kcal += curr.kcal
      acc.dietScore += curr.dietScore
      acc.trainingPoints += curr.trainingPoints
      acc.strengthPoints += curr.strengthPoints
      acc.numSteps += curr.numSteps
      return acc
    },
    { kcal: 0, dietScore: 0, trainingPoints: 0, strengthPoints: 0, numSteps: 0 }
  )

  totals.daysWithDiet = days.filter((day) => day.dietScore || day.kcal).length
  totals.daysWithExercise = days.filter(
    (day) => day.trainingPoints || day.strengthPoints || day.numSteps
  ).length

  return totals
}

function getStartOfWeek(year:number, week:number) {
  return moment().year(year).isoWeek(week).startOf('week')
}

function getWeekDays(year:number, week:number) {
  const startOfWeek = getStartOfWeek(year, week)

  return Array(7)
    .fill(undefined)
    .map((item, i) => {
      const day = startOfWeek.clone().add(i, 'day')

      return {
        date: day.format('YYYY-MM-DD'),
        dateFormatted: `${day.format('ddd')} ${day.format('DD.MM.YYYY')}`,
        cacheKey: `cache${day.format('YYYYMMDD')}`,
      }
    })
}

function getDietSummaryQuery(year:number, week:number) {
  const cachedDays = getWeekDays(year,week).map((day) => {
    return `
      ${day.cacheKey}: dailyDiaryCache(date: "${day.date}") {
        edges {
          node {
            data
          }
        }
      }
    `
  })

  return gql`
    query GetDietSummary {
      diary {
        ${cachedDays}
      }
    }
  `
}

export default withAuth((DietSummaryUI))
