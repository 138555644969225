import { gql } from '@apollo/client';

const checkEmail = gql`
query CheckEmail($email: String!) {
    auth {
         checkEmail(email: $email)
    }
}
`;

export default checkEmail;


