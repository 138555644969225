import { gql, useQuery } from "@apollo/client";
import Loading from "components/ui/Loading";
import { BuyMembership } from "pages/BuyMembershipPage";

// const variables = {
//   membershipType: membershipType,
//   cancelUrl,
//   successUrl,
//   renewMembershipId: membership.membershipId,
//   membershipTypeId: courseId || nettcourseinstructorId,
//   frequency: membership.frequency,
// };
// //checkout({ variables: { membershipType: membership.membershipType, membershipTypeId: courseId ? courseId :  nettcourseinstructorId, cancelUrl, successUrl,  optionId: currentPackage?.packageId, renewMembershipId: membership.membershipId, frequency: membership.frequency, weekday: membership.weekday} }).
// if (membershipType !== "membership") {
//   variables["optionId"] = selectedPrice?.packageId;
// } else {
//   variables["stripePrice"] = selectedPrice;
//   variables["membershipTypeId"] = membershipTypeId;
// }
const GET_COURSE = gql`
  query Course(
    $courseId: Int
    $nettcourseinstructorId: Int
    $frequency: String
  ) {
    nettcourseinstructor(id: $nettcourseinstructorId) {
      instructorId
      autoName
      nettcourseId
      numberOfSpots
      nettcourse {
        formatedPricePerMeeting
        pricePerMeeting
        defaultPrepaidMembershipType {
          membershipTypeId
          oneTimePrices {
            edges {
              node {
                priceId
                numberOfMeetings
                displayPricePerMeeting
                displayPrice
                stripeMetadata
              }
            }
          }
        }
        defaultMembershipType {
          membershipTypeId
          subscriptions(frequency: $frequency) {
            edges {
              node {
                priceId
                interval
                months
                displayPricePerMonth
                stripeMetadata
              }
            }
          }
        }
      }
      instructor {
        name
        image
        slug
        shortDescription
        bioBullet1
        bioBullet2
        bioBullet3
        title
      }
    }

    course(id: $courseId) {
      courseId
      autoName
      fullAddress
      courseTypeId
      frequency
      firstBookableMeetingStartTime

      subscriptions {
        edges {
          node {
            priceId
            interval
            months
            displayPricePerMonth
            stripeMetadata
          }
        }
      }
      courseType {
        formatedPricePerMeeting
        pricePerMeeting
        defaultPrepaidMembershipType {
          membershipTypeId
          oneTimePrices {
            edges {
              node {
                priceId
                numberOfMeetings
                displayPricePerMeeting
                displayPrice
                stripeMetadata
              }
            }
          }
        }
        defaultMembershipType {
          membershipTypeId
          subscriptions(frequency: $frequency) {
            edges {
              node {
                priceId
                interval
                months
                displayPricePerMonth
                stripeMetadata
              }
            }
          }
        }
      }
      instructor {
        name
        image
        slug
        shortDescription
        bioBullet1
        bioBullet2
        bioBullet3
        title
      }
    }
  }
`;

export default function Renewal({
  membership,
  courseId,
  nettcourseinstructorId,
}) {
  const getSubscription = (courseType) => {
    return (
      courseType?.defaultMembershipType?.subscriptions?.edges.map(
        (edge) => edge.node
      ) || []
    );
  };

  const getPrepaidPackages = (courseType) => {
    if (membership.frequency !== 4) {
      return [];
    }
    return (
      courseType?.defaultPrepaidMembershipType?.oneTimePrices?.edges.map(
        (edge) => edge.node
      ) || []
    );
  };
  const { loading, refetch, data } = useQuery(GET_COURSE, {
    variables: {
      courseId: courseId,
      nettcourseinstructorId: nettcourseinstructorId,
      frequency: membership.frequency,
    },
  });

  if (loading) return <Loading />;

  const courseType =
    data?.course?.courseType || data?.nettcourseinstructor?.nettcourse;
  const packages = getPrepaidPackages(courseType);

  //pass packages as json string to avoid problems with useEffect

  const _subscriptions = getSubscription(courseType);
  const membershipTypeId = courseType?.defaultMembershipType?.membershipTypeId;
  const defaultPrepaidMembershipTypeId =
    courseType?.defaultPrepaidMembershipType?.membershipTypeId;

  // set default package

  if (membership.renewalStatus === "pending_membnership") {
    return null;
  }

  console.log("packages", packages);
  console.log("_subscriptions", _subscriptions);
  if (packages.length === 0 && _subscriptions.length === 0) {
    return <div>Fant ingen produkter, kan ikke fornyes</div>;
  }
  console.log(membership);
  console.log("defaultPrepaidMembershipTypeId", defaultPrepaidMembershipTypeId);
  console.log("membershipTypeId", membershipTypeId);

  return (
    <BuyMembership
      trialPeriodDays={0}
      refetch={refetch}
      membershipType={membership.membershipType}
      courseSignup={true}
      subscriptions={_subscriptions}
      oneTimePrices={packages}
      subscriptionMembershipTypeId={membershipTypeId}
      oneTimeMembershipTypeId={defaultPrepaidMembershipTypeId}
      defaultCourseId={courseId}
      defaultNettcourseInstructorId={nettcourseinstructorId}
      renewMembershipId={membership.id}
    />
  );
}
