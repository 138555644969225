import { gql } from '@apollo/client';

const getDashboardData = gql`
  query DashboardData {
      user{
        hasPermissions,
        activeCourse,
        activeNettcourse
        userData{
          firstName
        }
          nextMeeting{
            membership{
              name
            },
            courseMeeting{
              startTime,
              courseUpdate{
                title,
                description,
                downloadUrl,
              },

              course{
                name,,
                address,
                instructor{
                  name,
                  image
                }
              }
            }
          }
          thisWeeksReport{
            membershipId,
            membership{
              name
              instructor{
                name,
                image


              }
            }
            status,
            week,
            reportId,
            courseUpdate{
              title,
              description,
              downloadUrl,
            }

          }
      }
  }
`;



export default getDashboardData;
