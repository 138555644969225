const createRange = (from, to) => {
  const ret = []
  let i = from
  while (i <= to) {
    ret.push(i)
    i += 1
  }
  return ret
}

export default createRange
