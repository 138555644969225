import { gql } from '@apollo/client';

const getFoodProduct = gql`
  query getFoodProduct ($objectId: [Int]) {
    request {
      kitchen {
        ingredients (objId: $objectId) {
          edges {
            node {
              objectId
              o2Id
              name
              kcal100g
              protein100g
              iron100g
              carbs100g
              fat100g
              milk100g
              salt100g
              fiber100g
              nuts100g
              alcohol100g
              sugar100g
              calcium100g
              fruits100g
              veg100g
              saturatedFat100g
              monounsaturatedFat100g
              polyunsaturatedFat100g
              vitaminA100g
              vitaminD100g
              vitaminC100g
              allergens
              dietCategory
              isHeartFriendly
              units {
                edges {
                  node {
                    unit
                    gramsPerUnit
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default getFoodProduct;
