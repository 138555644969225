import { gql } from '@apollo/client';

const setNewsletterPreferences =  gql`
mutation User(
  $newsletterDinnerTips: Boolean,
  $newsletterWeekly: Boolean,
  $newsletterInformation: Boolean,
  $newsletterSms: Boolean
) {
  user {
    setNewsletterPreferences(
      newsletterDinnerTips: $newsletterDinnerTips,
      newsletterWeekly: $newsletterWeekly,
      newsletterInformation: $newsletterInformation,
      newsletterSms: $newsletterSms
    ) {
      status
      message
    }
  }
}`;

export default setNewsletterPreferences;
