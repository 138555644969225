import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CourseCard from "../CourseCalendar/CourseCard";
import InstructorCard from "../CourseCalendar/InstructorCard";
import s from "../CourseCalendar/styles/CourseCalendar.module.scss";
import { useCourseCalendarData } from "../CourseCalendar/utils";
import styles from "./styles/CourseMembership.module.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  membershipId: number;
  courseData: any;
  nettCourses: any;
  courseTypes: any;
  refetch: () => void;
  setCourseIdCallback: (object) => void | null;
};

export default function Courses(props: Props) {
  const [searchParams] = useSearchParams();
  const [course, setCourse] = useState({
    courseId: null,
    nettcourseInstructorId: null,
    name: "",
    description: "",
    time: "",
    place: "",
    weekday: null || 0 || 1 || 2 || 3 || 4 || 5 || 6,
  });
  const [isOpen, setIsOpen] = useState(
    course?.courseId === null && course?.nettcourseInstructorId === null
  );
  const courseType = searchParams.get("courseType");
  useEffect(() => {
    if (course?.courseId && props?.setCourseIdCallback) {
      props.setCourseIdCallback({ courseId: course?.courseId });
      setIsOpen(false);
    } else if (course?.nettcourseInstructorId && props?.setCourseIdCallback) {
      props.setCourseIdCallback({
        nettcourseInstructorId: course?.nettcourseInstructorId,
        weekday: course?.weekday,
      });
      setIsOpen(false);
    }
  }, [course]);
  const toggleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsOpen((value) => !value);
  };

  return (
    <section className={styles.section} data-course={Boolean(courseType)}>
      <h2>Tid og sted</h2>

      {isOpen ? (
        <>
          {courseType && props.courseData.length > 0 && (
            <Calendar {...props} setCourse={setCourse} />
          )}
        </>
      ) : (
        <div>
          <p>{course?.name}</p>
          {course?.time && <p>{course?.time}</p>}
          {course?.place && <p>{course?.place}</p>}
        </div>
      )}

      {(course?.courseId || course?.nettcourseInstructorId) && (
        <p>
          <a href="/" onClick={toggleOpen}>
            {isOpen ? "Lukk" : "Endre"}
          </a>
        </p>
      )}
    </section>
  );
}

function DaySelector({ schedule, weekday, setWeekday }) {
  return (
    <div className={s.weekdays}>
      {schedule?.map((day, index) => (
        <button
          key={index}
          disabled={day.courses.length === 0 && day.nettCourses.length === 0}
          data-active={index === weekday}
          onClick={() => setWeekday(index)}
        >
          {day.name.substr(0, 1)}
        </button>
      ))}
    </div>
  );
}

function Calendar({
  membershipId,
  courseData,
  nettCourses,
  courseTypes,
  refetch,
  setCourse,
}) {
  const { schedule } = useCourseCalendarData(
    courseData,
    nettCourses,
    courseTypes
  );

  const defaultWeekday = schedule.findIndex(
    (day) => day.courses.length > 0 || day.nettCourses.length > 0
  );
  const [weekday, setWeekday] = useState(
    defaultWeekday > -1 ? defaultWeekday : 0
  );

  return (
    <main className={s.main}>
      <div className={s.overview}>
        <DaySelector
          schedule={schedule}
          weekday={weekday}
          setWeekday={setWeekday}
        />
        <div className={s.wrapper}>
          <div className={s.courses}>
            {schedule.map((day, sIndex) => (
              <div
                key={sIndex}
                className={s.day}
                data-count={day.courses.length + day.nettCourses.length}
                data-active={sIndex === weekday}
              >
                <h3>{day.name}</h3>
                {day.courses.length === 0 && day.nettCourses.length === 0 && (
                  <p>Ingen kurs</p>
                )}
                <div className={s.cards}>
                  {day.courses.map((course) => {
                    return (
                      <CourseCard
                        key={course.type + course.id}
                        membershipId={membershipId}
                        refetch={refetch}
                        course={course}
                        dayName={day.name}
                        setCourseCallback={setCourse}
                      />
                    );
                  })}
                  {day.nettCourses.map((course, dIndex) => {
                    return (
                      <InstructorCard
                        key={dIndex}
                        membershipId={membershipId}
                        refetch={refetch}
                        course={course}
                        setNettcourseInstructorCallback={setCourse}
                        weekday={day.weekday}
                        dayName={day.name}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
    </main>
  );
}
