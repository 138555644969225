import { gql } from '@apollo/client';

const obbordingDone = gql`
  mutation saveCustomFoodProduct (
    $key: String,
    $value: String

  ){
    user {
      setProperties (
        key: $key
        value: $value
      ) {
        status
      }
    }
  }
`;

export default obbordingDone;
