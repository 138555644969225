import React from 'react'
import RegistrationActions from './RegistrationActions'
import ToggleFavorite from 'components/container/ToggleFavorite'
import EditEntry from 'components/ui/EditEntry'
import ViewEntry from 'components/ui/ViewEntry'
import { withAuth } from 'components/container/withAuth'
import { formatQuantity, getCalories } from 'utils'

const objTypes = {
  'product': 'food_product',
  'custom product': 'custom_food_product',
  'recipe': 'recipe',
  'custom recipe': 'custom_recipe',
}

class Registration extends React.Component {
  state = {
    deleting: false
  }

  onDelete = () => this.setState({ deleting: true })

  render() {
    const { registration, auth: { user } } = this.props
    const { deleting } = this.state

    return (
      <li key={registration.registrationId} className="entries-item" data-deleting={deleting}>
        <ToggleFavorite
          objectId={registration.data.id}
          objectType={objTypes[registration.data.type]}
          isFavorite={Boolean(user.favorites.find(f => f.objType === objTypes[registration.data.type] && f.objId === registration.data.id))}
          render={({ isFavorite, toggle }) => (
            <button className="entries-item-favorite" onClick={toggle}><i className={isFavorite ? 'icon-star' : 'icon-star-empty'}></i></button>
          )}
        />
        <div className="entry">
          <ViewEntry entry={registration}>{registration.data.name}</ViewEntry>
          <EditEntry entry={registration}>{formatQuantity(registration.data.quantity)} {registration.data.unit}</EditEntry>
        </div>
        <span className="entries-item-icons">
          {registration.data.allergens && registration.data.allergens.length > 0 &&
            <i className="icon-spell-check"></i>
          }
          {registration.data.heartFriendly &&
            <i className="icon-gr-heart-friendly-food"></i>
          }
          {registration.data.quality &&
            <i className={`icon-gr-quality-${registration.data.quality}`}></i>
          }
        </span>
        <strong>{getCalories(registration)}</strong>
        <RegistrationActions registration={registration} onDelete={this.onDelete} />
      </li>
    )
  }
}

export default withAuth(Registration)
