import React from "react";

import { withAuth } from "components/container/withAuth";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import Loading from "components/ui/Loading";

import {
  REGISTER_USER,
  CHECK_EMAIL,
  LOGIN,
  GET_USER_DATA,
  SET_MULTIPLE_PROPERTY,
} from "queries";
import s from "./RegistrationForm.module.scss";

export const validateEmail = (email) => {
  const re = new RegExp(
    /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i
  );
  return re.test(email);
};

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.passwordInput = React.createRef();
  }
  state = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    address: "",
    postalCode: "",
    place: "",
    phone: "",
    terms: "",
    coupon: "",
    cid: "",

    userStatus: 0,
    // 0: not checked
    // 1: email is in use
    // 2: email is not in use
    // 4: user is logged in
    // 5: user is registered
    // 10: all ok

    loadingCheckEmail: false,
    loadingLogin: false,
    loadedFromProfile: false,
    processing: false,
  };

  postalCodeRef = React.createRef();
  phoneRef = React.createRef();

  componentDidMount() {
    this.postalCodeRef.current.oninvalid = function (event) {
      event.target.setCustomValidity("Postnummer må være 4 siffer");
    };
    this.phoneRef.current.oninvalid = function (event) {
      event.target.setCustomValidity("Mobilnummer må være 8 siffer");
    };
    this.setState({ cid: this.props?.auth.cid });
    this.forceUpdate();
  }

  focusPassword = () => {
    this.passwordInput.current.focus();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.processing || this.props.disabled) {
      return;
    }

    if (this.state.userStatus === 4) {
      this.setState({ processing: true });

      const { firstName, lastName, phone, address, postalCode, place } =
        this.state;
      const name = `${firstName} ${lastName}`;
      const properties = JSON.stringify({
        name: name,
        phone: phone,
        address: address,
        city: place,
        zip_code: postalCode,
      });

      this.props
        .updateUser(properties)
        .then(({ data }) => {
          const status =
            data &&
            data.user &&
            data.user.setMultipleProperties &&
            data.user.setMultipleProperties.status;
          if (status === "success") {
            this.props.auth.refetch();
            this.props.data.refetch();
            this.setState({ processing: false, userStatus: 10 });
            //window.scrollTo(0,0)
          }
        })
        .catch((error) => {
          alert(error);
          this.setState({ processing: false });
        });
    } else if (this.state.userStatus === 2) {
      this.setState({ processing: true });

      this.props
        .register(this.state)
        .then(({ data }) => {
          const { status, message } = data.auth.register;

          if (status === "success") {
            this.props.auth.refetch();
            this.props.data.refetch();
            this.setState({ processing: false, userStatus: 10 });
            //window.scrollTo(0,0)
          } else {
            alert(message);
            this.setState({ processing: false });
          }
        })
        .catch((error) => {
          alert(error);
          this.setState({ processing: false });
        });
    }
  };

  checkEmail = (event) => {
    if (event) event.preventDefault();
    if (
      this.state.loadingCheckEmail ||
      this.state.userStatus > 2 ||
      !validateEmail(this.state.email)
    ) {
      return;
    }
    this.setState({ loadingCheckEmail: true });
    const { client } = this.props;
    client
      .query({
        query: CHECK_EMAIL,
        variables: { email: this.state.email },
      })
      .then((data, loading) => {
        if (this.state.userStatus <= 2) {
          const emailInUse = data.data?.auth?.checkEmail;
          if (emailInUse === true) {
            this.setState(
              { userStatus: 1, loadingCheckEmail: false },
              this.focusPassword
            );
          } else if (emailInUse === false) {
            this.setState(
              { userStatus: 2, loadingCheckEmail: false },
              this.focusPassword
            );
          }
        }
        this.setState({ loadingCheckEmail: false });
      });
  };
  handleTab = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.checkEmail();
    }
  };
  login = (event) => {
    if (event) event.preventDefault();
    if (this.state.loadingLogin) {
      return;
    }

    this.setState({ loadingLogin: true });
    this.props
      .login(this.state.email, this.state.password)

      .then(({ data }) => {
        const { status, message } = data.auth.login;
        if (status === "success") {
          this.setState({ userStatus: 4, loadingLogin: false });
          this.props.auth.refetch();
          this.props.data.refetch();
        } else {
          this.setState({ loadingLogin: false });
          alert(message);
        }

        this.props.callback();
      })
      .catch((error) => {
        alert(error);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    const {
      auth: { status },
    } = this.props;
    if (
      this.state.userStatus <= 4 &&
      status === "online" &&
      this.props.data?.user?.userData &&
      !this.state.loadedFromProfile
    ) {
      const { cellPhone, address, city, name, email, zipCode } =
        this.props.data.user.userData;
      const name_parts = name.split(" ");
      const lastName = name_parts.pop();

      const firstName = name_parts.join(" ");

      this.setState({
        loadedFromProfile: true,
        email: email,
        firstName: firstName,
        lastName: lastName,
        address: address,
        postalCode: zipCode,
        place: city,
        phone: cellPhone,
        userStatus: 4,
      });
    }

    if (
      this.props.userCallBack &&
      this.state.userStatus !== prevState.userStatus
    ) {
      this.props.userCallBack(this.state.userStatus);
    }
    if (this.props.userPostalCodeCallBack && this.state.postalCode) {
      this.props.userPostalCodeCallBack(this.state.postalCode);
    }
  }

  render() {
    if (this.auth?.loading) return <Loading />;

    const descriptionType = this.props?.descriptionType;
    const description = this.props?.description;
    const disabled = this.props.disabled;

    const { processing } = this.state;
    return (
      <div className={s.registration}>
        {descriptionType &&
          descriptionType !== "none" &&
          description &&
          description !== "" && (
            <div className={s.goldTypeDesciption} data-option={descriptionType}>
              {description}
            </div>
          )}

        {this.state.userStatus < 4 && <h2>PÅMELDING</h2>}
        {this.state.userStatus === 4 && (
          <React.Fragment>
            <h3>
              Hei! <strong>{this.state.firstName}</strong>
            </h3>
            <p className={s.text}>
              Sjekk at informasjonen under er riktig. Ikke{" "}
              {this.state.firstName}?{" "}
              <button
                className={s.logoutButton}
                disabled={this.props.auth?.loadingLogin}
                onClick={() => this.props.auth?.logout(window.location)}
              >
                Logg ut.
              </button>
            </p>
          </React.Fragment>
        )}
        {this.state.userStatus >= 10 && (
          <React.Fragment>
            <h3>
              Hei! <strong>{this.state.firstName}</strong>
            </h3>
            <p className={s.text}>
              Du er registrert med e-post <strong>{this.state.email}</strong> og
              alt er klart til å gå til neste steg!
            </p>
          </React.Fragment>
        )}

        <form
          onSubmit={this.handleSubmit}
          className={s.registrationform}
          data-hidden={this.state.userStatus >= 10}
          method="post"
        >
          <label>
            <input
              type="email"
              readOnly={this.state.userStatus === 4}
              name="email"
              required
              value={this.state.email}
              onBlur={this.checkEmail}
              onKeyDown={this.handleTab}
              onChange={(event) =>
                this.setState({ email: event.target.value.toLowerCase() })
              }
            />
            <span>E-post</span>
          </label>

          <label data-hidden={this.state.userStatus >= 4}>
            <input
              ref={this.passwordInput}
              type="password"
              name="password"
              minLength="6"
              required={this.state.userStatus < 4}
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
            />
            <span>
              {this.state.userStatus === 2 ? "Velg ett passord" : "Passord"}
            </span>
          </label>
          <div data-hidden={this.state.userStatus !== 1}>
            <p className={s.text}>
              <strong>
                Du har allerede bruker, tast inn passordet ditt og logg inn for
                å fortsette.
              </strong>
            </p>
            <button
              className={s.button}
              disabled={this.state.loadingLogin || this.state.loadingCheckEmail}
              onClick={this.login}
            >
              Login
            </button>
            <a
              className={s.forgottenPasswordLink}
              href="https://www.roede.com/api/login"
            >
              Glemt passord
            </a>
          </div>

          <p data-hidden={this.state.userStatus !== 2} className={s.text}>
            <strong>Velkommen som ny kunde</strong>
          </p>

          <hr />
          <label>
            <input
              type="text"
              name="firstName"
              disabled={this.state.userStatus < 2}
              value={this.state.firstName}
              onChange={(event) =>
                this.setState({ firstName: event.target.value })
              }
              pattern=".{2,}"
              required
            />
            <span>Fornavn</span>
          </label>
          <label>
            <input
              type="text"
              name="last_name"
              disabled={this.state.userStatus < 2}
              pattern=".{2,}"
              required
              value={this.state.lastName}
              onChange={(event) =>
                this.setState({ lastName: event.target.value })
              }
            />
            <span>Etternavn</span>
          </label>

          <label data-field="address">
            <input
              type="text"
              name="address"
              disabled={this.state.userStatus < 2}
              pattern=".{3,}"
              required
              value={this.state.address}
              onChange={(event) =>
                this.setState({ address: event.target.value })
              }
            />
            <span>Adresse</span>
          </label>
          <label data-field="postal_code">
            <input
              type="text"
              name="postal_code"
              disabled={this.state.userStatus < 2}
              maxLength="4"
              pattern="[0-9]{4}"
              required
              value={this.state.postalCode}
              onChange={(event) => {
                this.setState({ postalCode: event.target.value });
                event.target.setCustomValidity("");
              }}
              ref={this.postalCodeRef}
            />
            <span>Post</span>
          </label>
          <label data-field="city">
            <input
              type="text"
              name="place"
              disabled={this.state.userStatus < 2}
              required
              value={this.state.place}
              onChange={(event) => this.setState({ place: event.target.value })}
            />
            <span>Sted</span>
          </label>
          <label>
            <input
              type="text"
              name="phone"
              disabled={this.state.userStatus < 2}
              maxLength="8"
              pattern="[0-9]{8}"
              required
              value={this.state.phone}
              onChange={(event) => {
                this.setState({ phone: event.target.value });
                event.target.setCustomValidity("");
              }}
              ref={this.phoneRef}
            />
            <span>Mobil</span>
          </label>
          <hr />

          <label data-field="terms">
            <input
              type="checkbox"
              name="terms"
              disabled={this.state.userStatus < 2}
              required
              value="1"
            />
            <p>
              Jeg bekrefter å ha lest og godkjent{" "}
              <a
                href="https://www.roede.com/terms-and-conditions"
                rel="noopener noreferrer"
                target="_blank"
              >
                medlemsvilkårene
              </a>
            </p>
          </label>
          <input type="hidden" name="cid" id="cid" value={this.state.cid} />
          <button
            type="submit"
            className={s.button}
            disabled={this.state.userStatus < 2 || processing || disabled}
            data-margin="true"
          >
            {this.state.userStatus === 4 ? `Bekreft` : `Registrer bruker`}
          </button>
        </form>
        <div></div>
      </div>
    );
  }
}

const loginConfig = {
  props: ({ mutate }) => ({
    login: (email, password) =>
      mutate({ variables: { email: email, password: password } }),
  }),
};

const updateUserConfig = {
  props: ({ mutate }) => ({
    updateUser: (properties) => mutate({ variables: { properties } }),
  }),
};

const registerConfig = {
  props: ({ mutate }) => ({
    register: ({
      email,
      password,
      firstName,
      postalCode,
      cid,
      lastName,
      address,
      place,
      phone,
    }) =>
      mutate({
        variables: {
          email,
          password,
          firstName,
          postalCode,
          cid,
          lastName,
          address,
          place,
          phone,
        },
      }),
  }),
};

const SetMultiplePropertyWrapper = graphql(
  SET_MULTIPLE_PROPERTY,
  updateUserConfig
)(RegistrationForm);
const RegisterUserWrapper = graphql(
  REGISTER_USER,
  registerConfig
)(SetMultiplePropertyWrapper);
const LoginWrapper = graphql(LOGIN, loginConfig)(RegisterUserWrapper);
const GetUserDataWrapper = graphql(GET_USER_DATA)(LoginWrapper);

export default withApollo(withAuth(GetUserDataWrapper));
