import React from 'react'
import ReactDOM from 'react-dom'
import FoodSearch from './FoodSearch'
import styles from './RegisterFoodModal.module.scss'

// const RemoveAddressBar = ({ children }) => {
//   /mobile/i.test(navigator.userAgent) && setTimeout(function() {
//     window.scrollTo(0, 1)
//   }, 300)
//
//   return children
// }

const RegisterFoodUI = ({ meal, close }) => (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <header className={styles.modalHeader}>
          <h1 className={styles.modalTitle}>Legg til</h1>
          <button className={styles.modalClose} onClick={close} aria-label="Lukk">
            <i className="icon-close" />
          </button>
        </header>
        <div className={styles.modalBody}>
          <FoodSearch
            placeholder="Søk oppskrifter, matvarer"
            meal={meal}
            close={close}
          />
        </div>
      </div>
    </div>
)

class RegisterFoodModal extends React.Component {
  render() {
    return ReactDOM.createPortal(
      <RegisterFoodUI {...this.props} />,
      document.getElementById('modal-root')
    )
  }
}

export default RegisterFoodModal
