import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import './styles.scss'

const MissingWeightMessage = ({ weight }) => {
  if (weight) return null

  return (
    <div className="missingWeightMsg">
      <i className="icon-warning-circle" />
      Du må <Link to={`/vekt-og-mal/${moment().format('YYYY/W')}/`}>registrere vekt</Link> for at treningspoeng skal regnes ut
    </div>
  )
}

export default MissingWeightMessage