import { gql } from '@apollo/client'

const connectToPolar = gql`
  mutation RegisterUserToPolar (
    $polarEmail: String!
  ) {
    user {
      registerUserToPolar(polarEmail: $polarEmail) {
        status
      }
    }
  }
`
export default connectToPolar
