import { gql } from '@apollo/client'

const getPlace = gql`
  query Place($placeId: String) {
    place(placeId: $placeId) {
      id: formattedAddress
      formattedAddress
      searchData
    }
  }
`

export default getPlace
