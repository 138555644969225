import React from 'react';
import Modal from '../Modal';
import LoadingContext from 'components/ui/Loading/LoadingContext'
import { withDate } from '../../util/withDate';
import { graphql } from '@apollo/client/react/hoc';
import { ADD_ENTRIES } from '../../../queries';
import { refetchQueries } from 'utils/refetchQueries';
import './styles.scss';

const AddEntriesModalContainer = ({
  tag = null,
  date,
  close,
  mutate,
}) => {
  const loadingIndicator = React.useContext(LoadingContext)
  let nameRef;

  const refetchedQueries = refetchQueries();

  const saveEntries = (e, loadingIndicator) => {
    e.preventDefault();

    loadingIndicator.show();

    if (! tag) {
      refetchedQueries.push('DailyMenus');
    }

    mutate({
      variables: {
        date,
        name: nameRef.value,
        category: 'food',
        tag,
      },
      refetchQueries: refetchedQueries,
    }).then(({ data }) => {
      loadingIndicator.hide();
      close();
    }).catch((error) => {
      console.log(error);
      close();
    });
  }

  let label = (tag) ? 'Skriv navn på måltidet' : 'Navn på standard-dag';
  let placeholder = (tag) ? 'Skriv navn på måltidet' : 'Skriv navn på standard-dagen';

  return (
    <Modal title="Legg inn" onClose={close}>
      <form onSubmit={(e) => saveEntries(e, loadingIndicator)}>
        <Modal.Body>
          {/*<div className="error"><i className="icon-info"></i> Du må skrive inn et navn på standard-dagen</div>*/}
          <div className="field">
            <label htmlFor="name">{label}</label>
            <input id="name" type="text" name="name" placeholder={placeholder} autoComplete="off" autoFocus ref={input => nameRef = input } />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={close}>Avbryt</button>
          <button type="submit">Lagre</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const AddEntriesModal = withDate(graphql(ADD_ENTRIES)(AddEntriesModalContainer));

export default AddEntriesModal;
