import React from 'react'
import { withAuth } from 'components/container/withAuth'
import ViewEntry from 'components/ui/ViewEntry'
import EditEntry from 'components/ui/EditEntry'
import DeleteRegistrations from 'components/container/DeleteRegistrations'
import s from './RegistrationActions.module.scss'

class RegistrationActions extends React.Component {
  state = {
    isOpen: false
  }

  openActions = () => this.setState({ isOpen: true })

  closeActions = () => this.setState({ isOpen: false })

  render() {
    const { isOpen } = this.state
    const { registration, onDelete } = this.props

    return (
      <React.Fragment>
        <button className={s.mainButton} onClick={this.openActions} data-isopen={isOpen}>
          <i className="icon-more-squares-vertical-filled" />
        </button>
        {isOpen && (
        <React.Fragment>
          <div className={s.overlay} onClick={this.closeActions} />
          <div className={s.actions}>
            <ViewButton registration={registration} callback={this.closeActions} />
            <EditButton registration={registration} callback={this.closeActions} />
            <DeleteButton registration={registration} callback={this.closeActions} onDelete={onDelete} />
          </div>
        </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

const ViewButton = (props) => (
  <ViewEntry entry={props.registration} callback={props.callback}>
    <button className={s.actionButton}>Vis detaljer</button>
  </ViewEntry>
)


const EditButton = (props) => (
  <EditEntry entry={props.registration} callback={props.callback}>
    <button className={s.actionButton}>Endre</button>
  </EditEntry>
)


const DeleteButton = (props) => (
  <DeleteRegistrations {...props} render={({ deleteRegistrations }) => (
    <button className={s.actionButton} data-type="delete" onClick={deleteRegistrations}>Slett</button>
  )}/>
)

export default withAuth(RegistrationActions)
