import AuthContext from "components/auth/AuthContext";
import useAuth from "components/auth/useAuth";
import PageBody from "components/layout/PageBody";
import Loading from "components/ui/Loading";
import LoadingContext from "components/ui/Loading/LoadingContext";
import useLoading from "components/ui/Loading/useLoading";
import ApiProvider from "components/util/ApiProvider";
import ScrollToTop from "components/util/ScrollToTop";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import U from "./U";

function WrappedApp() {
  const auth = useAuth();
  const loading = useLoading();
  return (
    <AuthContext.Provider value={auth}>
      <LoadingContext.Provider value={loading}>
        <ScrollToTop />
        <PageBody>
          <Suspense fallback={<Loading />}>
            <U />
          </Suspense>
        </PageBody>
      </LoadingContext.Provider>
    </AuthContext.Provider>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <WrappedApp />
      </ApiProvider>
    </BrowserRouter>
  );
}
