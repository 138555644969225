import React, { Component } from 'react'
import withRouter from '../../../utils/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { ADD_ENTRY } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'

class DietExtrasUI extends Component {
  state = {
    numGlassOfWater: this.props.extras.data.numGlassOfWater ?  this.props.extras.data.numGlassOfWater : 0,
    supplementPillWasTaken: this.props.extras.data.supplementPillWasTaken ?  this.props.extras.data.supplementPillWasTaken : false,
    nutsEaten: this.props.extras.data.nutsEaten ?  this.props.extras.data.nutsEaten : false,
  }

  componentDidUpdate(prevProps) {
    const prevData = prevProps.extras.data
    const currData = this.props.extras.data

    if (
      currData.numGlassOfWater !== prevData.numGlassOfWater ||
      currData.supplementPillWasTaken !== prevData.supplementPillWasTaken ||
      currData.nutsEaten !== prevData.nutsEaten
    ) {
      this.setState({
        numGlassOfWater: parseInt(currData.numGlassOfWater, 10),
        supplementPillWasTaken: Boolean(currData.supplementPillWasTaken),
        nutsEaten: Boolean(currData.nutsEaten),
      })
    }
  }

  updateExtras = (event) => {
    const name = event.target.name
    const value = event.target.type === 'checkbox' ? event.target.checked : parseInt(event.target.value, 10)
    this.setState({ [name]: value })

    const variables = getVariables(this.props, name, value)
    const optimisticResponse = getOptimisticResponse(this.props)
    this.props.mutate({ variables, optimisticResponse, refetchQueries })
  }

  render() {
    const { numGlassOfWater, supplementPillWasTaken } = this.state

    return (
      <div className="meals-item-content group">
        <div className="diet-extras">
          <div className="glasses-of-water">
            {[1,2,3,4,5,6,7,8,9,10].map(i => (
              <label key={i}>
                <input type="radio" name="numGlassOfWater" value={i} checked={numGlassOfWater === i} onChange={this.updateExtras} /> <i className="icon-gr-water" data-selected={i <= numGlassOfWater}></i>
              </label>
            ))}
          </div>
          <div className="pills-and-nuts group">
            <div className="pills-and-nuts__field">
              <label>
                <input type="checkbox" name="supplementPillWasTaken" value="1" checked={supplementPillWasTaken} onChange={this.updateExtras} style={{'WebkitAppearance': 'checkbox'}} />
                <span className="pills-and-nuts__label">
                  <i className="pills-and-nuts__icon icon-gr-omega-3" />
                  Omega-3 og multivitamintilskudd
                </span>
              </label>
              <span className="pills-and-nuts__help" data-tooltip="Vi anbefaler en barneskje tran eller tilsvarende tilskudd av omega-3 og vitamin D daglig. Du må krysse av for tran og/eller multivitamtablett her, for at det skal komme med i oppsummeringen under 'Mitt kosthold'." data-tooltip-orientation="right"><i className="icon-question" /></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function getOptimisticResponse({ extras }) {
  return {
    diary: {
      diaryRegister: {
        status: 'success',
        registrationId: extras.registrationId || Math.round(Math.random() * -1000000),
        __typename: 'DiaryRegister',
      },
      __typename: 'DiaryMutation',
    },
    __typename: 'Mutation',
  }
}

function getVariables({ extras, match }, name, value) {
  if (extras.registrationId) {
    extras.date_time = extras.dateTime
  } else {
    extras.registrationId = null
    extras.date_time = `${match.params.date}T00:00:00`
    extras.category = 'food'
    extras.tag = 'extras'
    extras.calories = null
    extras.description = null
  }

  if (typeof extras.data === 'string' || extras.data instanceof String){
    extras.data = JSON.parse(extras.data)
  }
  extras.data[name] = value
  extras.data['source'] = 'web'
  extras.data = JSON.stringify(extras.data)

  return extras
}

export default withRouter(graphql(ADD_ENTRY)(DietExtrasUI))
