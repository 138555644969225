import React from 'react'
import { withAuth } from 'components/container/withAuth'
import CopyRegistrations from 'components/container/CopyRegistrations'
import SaveRegistrations from 'components/container/SaveRegistrations'
import DeleteRegistrations from 'components/container/DeleteRegistrations'
import s from './MealActions.module.scss'

class MealActions extends React.Component {
  state = {
    isOpen: false
  }

  openActions = () => this.setState({ isOpen: true })

  closeActions = () => this.setState({ isOpen: false })

  render() {
    const { isOpen } = this.state
    const { meal, onDelete } = this.props

    return (
      <React.Fragment>
        <button className={s.mainButton} onClick={this.openActions} data-isopen={isOpen}>
          <i className="icon-more-squares-vertical-filled" />
        </button>
        {isOpen && (
        <React.Fragment>
          <div className={s.overlay} onClick={this.closeActions} />
          <div className={s.actions}>
            <CopyButton meal={meal} callback={this.closeActions} />
            <SaveButton meal={meal} callback={this.closeActions} />
            <DeleteButton meal={meal} callback={this.closeActions} onDelete={onDelete} />
          </div>
        </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

const CopyButton = (props) => {
  const disabled = !!props.meal.entries.length

  return (
    <CopyRegistrations {...props} render={({ copyRegistrations }) => (
      <button className={s.actionButton} data-disabled={disabled} onClick={copyRegistrations}>Kopier fra i går</button>
    )}/>
  )
}

const SaveButton = (props) => {
  const disabled = !props.meal.entries.length

  return (
    <SaveRegistrations {...props} render={({ saveRegistrations }) => (
      <button className={s.actionButton} data-disabled={disabled} onClick={saveRegistrations}>Lagre som måltid</button>
    )}/>
  )
}

const DeleteButton = (props) => {
  const disabled = !props.meal.entries.length

  return (
    <DeleteRegistrations {...props}  render={({ deleteRegistrations }) => (
      <button className={s.actionButton} data-disabled={disabled} data-type="delete" onClick={deleteRegistrations}>Slett</button>
    )}/>
  )
}

export default withAuth(MealActions)
