import { gql } from '@apollo/client';

const getDailyMenus = gql`
  query DailyMenus {
    request {
      diary {
        dailyMenus {
          edges {
            node {
              objectId
              userId
              name
              created
            }
          }
        }
      }
    }
  }
`;

export default getDailyMenus;
