import React from 'react'
import './styles.scss'

class Panel extends React.Component {
  render() {
    return <div className="panel" data-loading={this.props.loading}>{this.props.children}</div>
  }
}

Panel.Header = class Header extends React.Component {
  render() {
    return (
      <header className="panelHeader">
        {this.props.children}
        <Panel.Loading />
      </header>
    )
  }
}

Panel.Body = class Body extends React.Component {
  render() {
    const { className } = this.props
    return (
      <div className={`panelBody ${className}`} data-processing={this.props.processing}>
        {this.props.children}
      </div>
    )
  }
}

Panel.Footer = class Footer extends React.Component {
  render() {
    return <footer className="panelFooter">{this.props.children}</footer>
  }
}

Panel.Title = class Title extends React.Component {
  render() {
    return (
      <h2 className="panelTitle">{this.props.children}</h2>
    )
  }
}

Panel.InfoText = class InfoText extends React.Component {
  render() {
    return <p className="panelInfoText">{this.props.children}</p>
  }
}

Panel.ReloadButton = class InfoText extends React.Component {
  render() {
    return <button className="panelReloadButton"><i className="icon-reload"></i></button>
  }
}

Panel.Loading = class Loading extends React.Component {
  render() {
    return (
      <div className="panelLoading">
        <div /> <div /> <div />
      </div>
    )
  }
}

export default Panel