import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import LOGIN from "queries/login";
import SEND_RESET_CODE from "queries/sendResetCode";
import s from "assets/styles/Form.module.scss";
import "./styles.scss";

export default function LoginScreen() {
  const location = useLocation();

  return (
    <div className="authPage">
      <section className="authLogin">
        {location.hash === "#glemt-passord" ? (
          <ForgotPasswordForm />
        ) : (
          <LoginForm />
        )}
      </section>
      <section className="authSignup">
        <h2>Er du ikke registrert hos oss?</h2>
        <div>
          <a href="/blimed" className="callToAction">
            Bli Medlem!
          </a>
        </div>
      </section>
    </div>
  );
}

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const [login, { data, loading }] = useMutation(LOGIN);
  const { message, status } = data?.auth?.login ?? {};

  const submit = (event) => {
    event.preventDefault();
    const variables = { email, password };
    login({ variables });
  };

  if (status === "success") {
    navigate(0);
    return null;
  }

  return (
    <>
      <h1>Logg inn for å se denne siden</h1>
      <form name="login" className={s.form} onSubmit={submit}>
        <p>For å se denne siden, må du være logget inn.</p>
        <div className="loginFields group">
          <label className="field">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              required
              autoFocus
            />
            <span>E-postadresse</span>
          </label>
          <label className="field">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              required
            />
            <span>Passord</span>
          </label>
          {status === "error" && <div className={s.error}>{message}</div>}
          <button disabled={loading} type="submit">
            Logg inn
          </button>
        </div>
        <a href="#glemt-passord" className="toggleLogin">
          Glemt passord
        </a>
      </form>
    </>
  );
}

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [sendResetCode, { data, loading }] = useMutation(SEND_RESET_CODE);
  const { status } = data?.registration?.resetCode ?? {};

  const submit = (event) => {
    event.preventDefault();
    const variables = { email };
    sendResetCode({ variables });
  };

  return (
    <>
      <h1>Glemt passord</h1>
      <form name="forgotPassword" className={s.form} onSubmit={submit}>
        {status ? (
          <p>
            E-post med instrukser for å bytte passord er sendt til{" "}
            <strong>{email}.</strong>
          </p>
        ) : (
          <>
            <p>
              Skriv inn e-postadressen og så vil vi hjelpe deg med å få nytt
              passord.
            </p>
            <div className="loginFields group">
              <label className="field">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  required
                  autoFocus
                />
                <span>E-postadresse</span>
              </label>
              <button disabled={loading} type="submit">
                Link for tilbakestilling av e-postpassord
              </button>
            </div>
          </>
        )}
        <a href="#login" className="toggleLogin">
          Logg inn
        </a>
      </form>
    </>
  );
}
