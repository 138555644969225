import React from 'react';
import { useQuery } from '@apollo/client'
import { GET_POLAR_DATA } from 'queries';

import Loading from '../Loading';

import PolarRegistration from '../Popup/PolarRegistration';
import PolarCancelation from '../Popup/PolarCancelation';


import { profileSettings } from '../../../conf';

interface Props {
  customClass?: string;
}

interface PropsBody {
  isPolarEmailRegistered: boolean;
  polarRegistrationStatus: string;
  polarEmail: string;
}


const PolarButton:React.FC<PropsBody> = ({
  isPolarEmailRegistered,
  polarRegistrationStatus,
  polarEmail,
  ...props
}) => {

  return (<div className="ItemAction">
      {
        (!isPolarEmailRegistered || polarRegistrationStatus === 'DENIED') ?
        <PolarRegistration email={polarEmail} /> : <PolarCancelation/>
      }
    </div>);
}


const PolarBody:React.FC<PropsBody> = ({
  isPolarEmailRegistered,
  polarRegistrationStatus,
  polarEmail,
  ...props
}) => {  

  return (<div className="TextWrapper">
    {
      !isPolarEmailRegistered && profileSettings.polarConfig.DELETED
    }
    {
      isPolarEmailRegistered && polarRegistrationStatus === 'ACCEPTED' && profileSettings.polarConfig.ACCEPTED.replace("$1", polarEmail)
    }
    {
      isPolarEmailRegistered && polarRegistrationStatus === 'REQUESTED_BY_ORG' && <div dangerouslySetInnerHTML={{ __html: profileSettings.polarConfig.REQUESTED_BY_ORG }} />
    }
    {
      isPolarEmailRegistered && polarRegistrationStatus === 'DENIED' && profileSettings.polarConfig.DENIED
    }
  </div>)
}




const PolarProfile:React.FC<Props> = ({customClass, ...props}) => {
  const {data, loading} = useQuery(GET_POLAR_DATA)
  const isPolarEmailRegistered = data?.user?.isPolarEmailRegistered
  const polarEmail = data?.user?.polarEmail
  const polarRegistrationStatus = data?.user?.polarRegistrationStatus

  if (!isPolarEmailRegistered) {
    return null;
  }

  return <div className={`ItemWrapper ${customClass}`}>
    <div className="ItemHeader">
      <div className="ItemTitle">Polar</div>
      {!loading ? <PolarButton
        isPolarEmailRegistered={isPolarEmailRegistered}
        polarRegistrationStatus={polarRegistrationStatus}
        polarEmail={polarEmail}
      ></PolarButton> : <Loading/>}
    </div>
    <div className="ItemBody">
      <div className="TextWrapper">Overført dine treningsdata direkte fra din Polar pulsklokke eller Polar Loop til dagboken</div>
      {!loading && <PolarBody
        isPolarEmailRegistered={isPolarEmailRegistered}
        polarRegistrationStatus={polarRegistrationStatus}
        polarEmail={polarEmail}
      ></PolarBody>}
    </div>
  </div>
}



export default PolarProfile