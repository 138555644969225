import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import GET_MEMBERSHIP from "queries/getMembership";
import Loading from "components/ui/Loading";
import Wrapper from "components/ui/Wrapper";
import CourseMembership from "components/ui/CourseMembership/CourseMembership";
import Membership from "components/ui/Membership";
import RenewedMembershipNotice from "components/ui/RenewedMembershipNotice";
import s from "./MembershipPage.module.scss";
import { useParams } from "react-router-dom";

export default function MembershipPage({ location, match }) {
  const params = useParams();
  const variables = params;
  const { data, loading, refetch } = useQuery(GET_MEMBERSHIP, { variables });
  const searchParams = new URLSearchParams(location?.search);
  const redirectStatus = searchParams.get("redirect_status");

  if (loading) return <Loading />;

  return (
    <>
      <Wrapper>
        {(redirectStatus === "succeeded" ||
          redirectStatus === "quickstart_started") && (
          <RenewedMembershipNotice redirectStatus={redirectStatus} />
        )}
        <div className={s.memberships}>
          <div>
            <Link className={s.back} to={`/medlemskap/`}>
              <span>Mine medlemskap</span>
            </Link>
          </div>
          <Membership membership={data?.user?.membership} refetch={refetch} />
        </div>
        {/*data?.user?.membership?.courseSignup && (
          <MembershipCourseCalendar
            membershipId={data?.user?.membership.membershipId}
            postalCode={data?.user?.userData?.postalCode}
            refetch={refetch}
          />
        )*/}
      </Wrapper>
      {data?.user?.membership?.courseSignup && (
        <CourseMembership
          membershipId={data?.user?.membership.membershipId}
          postalCode={data?.user?.userData?.postalCode}
          refetch={refetch}
        />
      )}
    </>
  );
}
