import { gql } from '@apollo/client'

export default gql`
  query GetActiveMemberships {
    user {
      memberships(
        status: "active"
        notO2: true
        membershipTypes: ["course", "nettcourse", "membership"]
      ) {
        edges {
          node {
            membershipId
            membershipType
            membershipTypeObject{
              membershipTypeId
              membershipType
              courseSignup
            }
            thisWeeksReport {
              reportId
            }
            nextMeeting {
              courseMeetingId
            }
            name
            instructor {
              name
            }
          }
        }
      }
    }
  }
`
