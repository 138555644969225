import React from 'react'
import DeleteCustomMeal from './DeleteCustomMeal'
import { GET_CUSTOM_MEALS } from 'queries'
import { graphql } from '@apollo/client/react/hoc'
import { getSafe, formatDate } from 'utils'

const MealsListWithData = ({ data: { loading, request } }) => {
  const customMeals = getSafe(() => (request.diary.customMealResult.edges
    .map(edge => edge.node)
  ), [])

  if (loading) {
    return <div className="loading" />
  }

  if (customMeals.length === 0) {
    return (
      <div className="emptyResultset">
        <i className="icon-info"></i> Ingen egne måltider
      </div>
    )
  }

  return (
    <table>
      <tbody>
        <tr>
          <th>Mine egne måltider</th>
          <th className="date">Dato</th>
          <th className="delete">&nbsp;</th>
        </tr>
        {customMeals.map(customMeal => (
          <DeleteCustomMeal
            key={customMeal.objectId}
            objectId={customMeal.objectId}
            render={({ deleting, deleteCustomMeal }) => (
              <tr data-deleting={deleting}>
                <td>{customMeal.name}</td>
                <td>{formatDate(customMeal.created)}</td>
                <td>
                  <button type="button" aria-label="Slett" onClick={deleteCustomMeal}>
                    <i className="icon-cancel-circle" />
                  </button>
                </td>
              </tr>
            )}
          />
        ))}
      </tbody>
    </table>
  )
}

const MealsList = graphql(GET_CUSTOM_MEALS)(MealsListWithData)

export default MealsList
