import { gql } from '@apollo/client';

const getWeightGoal = gql`
query User {
  user {
    userData {
      userId
      weightGoal
      height
      initialWeight
    }
  }
}`;


export default getWeightGoal;
