import React from 'react'
import AddRecipe from './AddRecipe'
import AddFoodProduct from './AddFoodProduct'
import AddCustomRecipe from './AddCustomRecipe'
import AddCustomFoodProduct from './AddCustomFoodProduct'
import AddCustomMeal from './AddCustomMeal'
import styles from './AddFood.module.scss'

const AddFood = ({ item, ...otherProps }) => (
  <div className={styles.addFood}>
    {item.type === 'recipe' &&
      <AddRecipe
        recipeId={item.id}
        {...otherProps}
      />
    }
    {item.type === 'food_product' &&
      <AddFoodProduct
        objectId={item.id}
        {...otherProps}
      />
    }
    {item.type === 'custom_recipe' &&
      <AddCustomRecipe
        objectId={item.id}
        {...otherProps}
      />
    }
    {item.type === 'custom_food_product' &&
      <AddCustomFoodProduct
        objectId={item.id}
        {...otherProps}
      />
    }
    {item.type === 'custom_meal' &&
      <AddCustomMeal
        item={item}
        {...otherProps}
      />
    }
  </div>
)

export default AddFood