import withRouter from '../../../utils/withRouter'
import { graphql } from '@apollo/client/react/hoc'
import { DELETE_ENTRIES } from 'queries'
import { refetchQueries } from 'utils/refetchQueries'

function getConfirmMessage(registration, meal) {
  if (registration) {
    return null
  }

  if (meal) {
    return 'Er du sikker på at du vil slette hele måltidet?'
  }

  return 'Er du sikker på at du vil slette all mat?'
}

const DeleteRegistrationsContainer = ({ registration, meal, callback, match, mutate, onDelete, render }) => {
  const registrationId = registration && registration.registrationId
  const tag = meal && meal.id
  const date_time = `${match.params.date}T00:00:00`
  const category = 'food'
  const confirmMessage = getConfirmMessage(registration, meal)

  const deleteRegistrations = () => {
    if (callback) { callback() }
    if (!confirmMessage || (confirmMessage && window.confirm(confirmMessage))) {
      if (onDelete) { onDelete() }

      mutate({
        variables: { registrationId, tag, category, date_time },
        refetchQueries: refetchQueries(),
      })
    }
  }

  return render({ deleteRegistrations })
}

const DeleteEntriesWrapper = graphql(DELETE_ENTRIES)(DeleteRegistrationsContainer)

export default withRouter(DeleteEntriesWrapper)
