import { gql } from '@apollo/client'

export default gql`
mutation Login($email: String!, $password: String!)
        { auth
            { login(email: $email,
                       password: $password)
                { status message }
            }
        }
`