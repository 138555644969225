const strings = {
  foodlog: {
    result: {
      items: {
        totalkcal: "spist",
        calcium: "kalsium",
        vegetable: "grønnsaker",
        fruit: "frukt",
        snack: "din utfordring",
        water: "vann",
        heart: "hjertevennlig",
        protein: "proteiner",
        carbs: "karbohydrater",
        fat: "fett",
        energyDensity: "energitetthet",
        fiber: "fiber",
        nuts: "nøtter",
        score: "kostholdscore",
        vitamin: "tran/multivitamin",
      },
      vitamin: {
        yes: "Spist",
        no: "Ingen",
      },
    },
  },
  common: {
    unit: {
      mg: "mg",
      g: "g",
      kcal: "kcal",
      glass: "glass",
      piece: "stykk",
      percent: "%",
    },
    error: {
      sendError: "Noe gikk galt. Vennligst prøv igjen litt senere.",
    },
  },
  profile: {
    gdpr: {
      title: "Brukerdata og konto",
      content:
        "Her kan du be om å få laste ned dataene dine.\n\nDet tar litt tid å samle sammen all dataene, men vi sender deg en link på e-post så snart de er klare.\n\nDataene kan ikke lastes opp igjen til Roede senere.",
      downloadButton: "Last ned dataene dine",
      deleteButton: "Slett kontoen din.",
      areYouSure:
        "Er du sikker?\n\n Du vil ikke kunne registrere en ny konto med samme e-post adresse før om 30 dager.\n\nHar du tegnet abonnement via Appstore eller Google Play må du stoppe ditt abonnement der.",
      dataHasBeenExported:
        "Vi genererer nå en fil med dine data, når den er ferdig vil du motta en e-post med en link for å laste de ned.",
      dataHasBeenDeleted: "Dataen din er blitt slettet",
    },
    contactInfo: {
      nameMinimumChars: "Navn må være minst 5 tegn",
      phoneOnlyNumbers: "Mobiltelefon skal være nummer",
      phoneCharsNumber: "Mobiltelefonen må ha 8 siffer",
      addressMinimumChars: "Adresse må være minst 3 tegn",
      addressChars: "Address needs to contain alphanumeric characters.",
      postalCharsNumber: "Postnummer må ha 4 tegn",
      cityMinimumChars: "Poststed må være minst 2 tegn",
      nameCharsOnly:
        "Name can only contain alphanumeric characters and hypehns(-)",
      emailError: "Ugyldig e-postadresse",
      emailAlreadyRegistered: "E-posten er allerede registrert",
      emailChangeError: "Kunne ikke endre e-post",
    },
  },
};

export default strings;
