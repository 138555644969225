import React from 'react'
import styles from './TextUnder.module.scss'

export default class Row extends React.Component {
  render() {
    const { amountText, recommendedText, name } = this.props

    return (
      <div className={styles.container}>
        <p className={styles.amount}>
          <strong className={styles.amountText}>{amountText}</strong>
          <span>{` ${name}`}</span>
        </p>
        <p>{recommendedText}</p>
      </div>
    )
  }
}
