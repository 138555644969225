const nutritionFields = [
  {
    label: 'Kalorier',
    name: 'kcalPer100Grams',
    unit: 'kcal',
    required: true,
    dataType: 'integer',
  },
  {
    label: 'Fett *',
    name: 'gramsOfFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Mettet *',
    name: 'gramsOfSaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Enumettet',
    name: 'gramsOfMonounsaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Flerumettet',
    name: 'gramsOfPolyunsaturatedFatPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Karbohydrater *',
    name: 'gramsOfCarbohydratePer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Sukkerarter *',
    name: 'gramsOfSugarPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Kostfiber *',
    name: 'gramsOfFiberPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Proteiner *',
    name: 'gramsOfProteinPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'float',
  },
  {
    label: 'Salt *',
    name: 'gramsOfSaltPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Kalsium *',
    name: 'milligramsOfCalciumPer100Grams',
    unit: 'milligram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Frukt',
    name: 'gramsOfFruitsPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Grønnsaker',
    name: 'gramsOfVegetablesPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
  {
    label: 'Melk',
    name: 'gramsOfMilkPer100Grams',
    unit: 'gram',
    required: false,
    dataType: 'integer',
  },
]

export { nutritionFields }
