import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import GET_ACTIVE_MEMBERSHIPS from 'queries/getActiveMemberships'
import Wrapper from 'components/ui/Wrapper'
import Loading from 'components/ui/Loading'
import { useCoachingMembershipURL } from 'utils'
import s from './styles/ReportPage.module.scss'

function Membership({ membership }) {
  const url = useCoachingMembershipURL(membership)
  return (
    <li>
      <Link to={url}>
        <strong>{membership.name}</strong> {membership.instructor.name}
      </Link>
    </li>
  )
}

export default function ReportError({ error }) {
  const params = useParams()
  const { data, loading } = useQuery(GET_ACTIVE_MEMBERSHIPS)
  const memberships = data?.user?.memberships?.edges?.map((e) => e.node) || []
  const notFoundError = getNotFoundError(memberships, params)

  if (loading) return <LoadingError />

  return (
    <main className={`${s.main} ${s.error}`}>
      <Wrapper>
        <h1>Error</h1>
        {error === 404 ? (
          <code>{notFoundError}</code>
        ) : (
          <>
            <p>Det oppsto en feil under henting av rapportdata.</p>
            <code>{JSON.stringify(error, null, 2)}</code>
          </>
        )}
        {memberships.length > 0 && (
          <>
            <p>Du kan prøve å velge et annet medlemskap:</p>
            <ul>
              {memberships.map((membership, index) => (
                <Membership key={index} membership={membership} />
              ))}
            </ul>
          </>
        )}
      </Wrapper>
    </main>
  )
}

function getNotFoundError(memberships, params) {
  const { membershipId } = params
  const membership = memberships.find((m) => m.membershipId === +membershipId)
  return membership ? 'Rapport ikke funnet.' : 'Medlemskap ikke funnet.'
}

function LoadingError() {
  return (
    <main className={s.main}>
      <Loading />
    </main>
  )
}
