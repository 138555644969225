import React, { useEffect, useState } from "react";
//import { Link } from 'react-router-dom';
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import AboutMe, { AboutMeForm } from "components/ui/Popup/AboutMe";
import Activities, { ActivitiesForm } from "components/ui/Popup/Activities";
import Goals, { GoalsForm } from "components/ui/Popup/Goals";
import WeightGoal, { WeightGoalForm } from "components/ui/Popup/WeightGoal";
import profileSettings, {
  getActivities,
  getAge,
  getExerciseHabits,
  getGender,
  getMotivation,
  getMotivationVerb,
  round5,
} from "conf/profileSettings";
import courseUpdateStyles from "./styles/CourseUpdate.module.scss";
import s from "./styles/IntroWidgets.module.scss";
import reportStyle from "./styles/ReportPage.module.scss";

const userDataQuery = gql`
  query User {
    user {
      userData {
        id: userId
        motivation
        weightGoal
        height
        weight
        initialWeight
        activityLevel
        exerciseHabits
        gender
        initialWeight
        birthDate
        restingMetabolism
        trainingMetabolism
        calculatedRecommendedKcalIn
        recommendedKcalIn
        kcalMenu
      }
    }
  }
`;

function IntroStartWrapped({ data, handleWidgetReplyChange }) {
  const userData = data?.user?.userData;

  const [widgetReply, setWidgetReply] = useState(null);
  useEffect(() => {
    if (
      userData?.gender &&
      userData?.height &&
      userData?.initialWeight &&
      userData?.birthDate
    ) {
      const newReply = `${getGender(userData?.gender)}, ${getAge(
        userData
      )}, Høyde: ${
        userData?.height ? userData.height + " cm" : "n/a"
      }, Startvekt: ${
        userData?.initialWeight ? userData.initialWeight + " kg" : "n/a"
      }`;
      if (newReply !== widgetReply) {
        setWidgetReply(newReply);
        handleWidgetReplyChange(newReply);
      }
    }
  }, [userData, widgetReply, handleWidgetReplyChange]);

  return (
    <div className={s.container}>
      <h2>La oss bli kjent</h2>

      <p>
        Nå er du i gang! På dette nettkurset skal jeg følge deg opp og hjelpe
        deg med å nå målene dine. Det gleder jeg meg til! Jeg starter med å ta
        deg gjennom en onboarding der du legger inn litt informasjon om deg
        selv, og svarer på noen spørsmål som hjelper meg med å bli bedre kjent
        med deg. Dine svar blir et viktig utgangspunkt for min videre
        veiledning.{" "}
      </p>
      <p>
        Fra neste uke vil du motta ukentlige kursrapporter fra meg, som du skal
        fylle ut. For at det skal være mulig for meg å gi deg personlig
        tilbakemelding er det viktig at du svarer på spørsmålene du får i
        rapportene, og gjør ditt beste og følger opp kurset hver uke.{" "}
      </p>

      <p>
        <strong>Da starter vi!</strong>
      </p>

      <h3>Energiberegning</h3>
      <p>
        For at jeg skal kunne gi deg et riktig menyvalg trenger jeg å vite litt
        om deg, som alder, kjønn, høyde og vekt, samt aktivitetsnivå. Sammen med
        «bli kjent spørsmålene» på de neste sidene får jeg den informasjonen jeg
        trenger for å forberede min første veiledning med deg.
      </p>
      {!data.loading &&
        userData?.gender &&
        userData?.height &&
        userData?.initialWeight &&
        userData?.birthDate && (
          <div style={{ maxWidth: "600px" }} className={s.flex}>
            <div className={s.content}>
              <p>
                <strong>Dette vet vi om deg nå</strong>
              </p>
              <p>
                {getGender(userData?.gender)}, {getAge(userData)}
              </p>
              <p>
                <strong>Høyde</strong>{" "}
                {userData?.height ? userData.height + " cm" : "n/a"}
              </p>
              <p>
                <strong>Startvekt</strong>{" "}
                {userData?.initialWeight
                  ? userData.initialWeight + " kg"
                  : "n/a"}
              </p>
            </div>
            <div className={s.sidebar}>
              <AboutMe
                gender={userData?.gender}
                height={userData?.height}
                weight={userData?.initialWeight}
                birthDate={userData?.birthDate}
                buttonText={
                  userData?.gender ? null : "Legg in basisinformasjon"
                }
                buttonStyle={userData?.gender ? null : reportStyle.button}
                close={data.refetch}
                refetch={data.refetch}
              />
            </div>
          </div>
        )}
      {!data.loading &&
        !(
          userData?.gender &&
          userData?.height &&
          userData?.initialWeight &&
          userData?.birthDate
        ) && (
          <div style={{ maxWidth: "800px" }}>
            <h3>Litt om deg</h3>

            <AboutMeForm
              gender={userData?.gender}
              height={userData?.height}
              weight={userData?.initialWeight}
              birthDate={userData?.birthDate}
              close={data.refetch}
              refetch={data.refetch}
            />
          </div>
        )}
    </div>
  );
}

export const IntroStart = graphql(userDataQuery)(IntroStartWrapped);

function IntroGoalWrapper({ data, handleWidgetReplyChange }) {
  const userData = data?.user?.userData;
  const [widgetReply, setWidgetReply] = useState(null);
  useEffect(() => {
    if (userData?.motivation && userData?.weightGoal) {
      const newReply = `Mål: ${getMotivation(userData)}, Vektmål: ${
        userData?.weightGoal
      }`;
      if (newReply !== widgetReply) {
        setWidgetReply(newReply);
        handleWidgetReplyChange(newReply);
      }
    }
  }, [userData, widgetReply, handleWidgetReplyChange]);

  return (
    <div className={s.container}>
      <h2>Mål for prosjektet</h2>
      <p>
        Du har helt sikkert noen tanker for hva du ønsker å oppnå med en
        livsstilsendring. Noe av det viktigste du gjør er å sette deg et
        motiverende og realistisk mål.
      </p>
      <p>
        For mange er målet å gå ned noen kilo (da velger du «Ned i vekt» under),
        men det er også mange som først og fremst vil jobbe for å få på plass et
        sunnere kosthold, som gir mer overskudd og energi (da velger du
        «Overskudd» under). Har du allerede gått ned i vekt og trenger å få
        kompetanse og veiledning til å holde den nye vekten, velger du "Hold
        vekten".
      </p>
      <p>
        Videre vet jeg at motivasjonen forsterkes når du har et delmål. Derfor
        er det lurt å sette deg noen delmål også, som kanskje går på helt andre
        ting enn vekten.
      </p>

      <h3>Ditt mål</h3>
      {!data.loading && userData?.motivation && (
        <div style={{ maxWidth: "600px" }} className={s.flex}>
          <div className={s.content}>
            <p> {getMotivation(userData)}</p>
          </div>
          <div className={s.sidebar}>
            <Goals
              motivation={userData?.motivation}
              refetch={data.refetch}
              close={data.refetch}
            ></Goals>
          </div>
        </div>
      )}
      {!data.loading && !userData?.motivation && (
        <div style={{ maxWidth: "800px" }}>
          <GoalsForm
            motivation={userData?.motivation}
            refetch={data.refetch}
            close={data.refetch}
          />
        </div>
      )}

      {!data.loading && userData?.motivation && userData?.weightGoal && (
        <React.Fragment>
          <h3>Ditt vektmål</h3>
          <div style={{ maxWidth: "600px" }} className={s.flex}>
            <div className={s.content}>
              <p> {`${userData.weightGoal}kg`}</p>
            </div>
            <div className={s.sidebar}>
              <WeightGoal
                weightGoal={userData?.weightGoal}
                height={userData?.height}
                weight={userData?.weight}
                refetch={data.refetch}
                close={data.refetch}
                buttonText={userData?.weightGoal ? null : "Sett vektmål"}
                buttonStyle={userData?.weightGoal ? null : reportStyle.button}
              ></WeightGoal>
            </div>
          </div>
        </React.Fragment>
      )}
      {!data.loading && userData?.motivation && !userData?.weightGoal && (
        <React.Fragment>
          <h3>Ditt vektmål</h3>
          <div style={{ maxWidth: "800px" }}>
            <WeightGoalForm
              weightGoal={userData?.weightGoal}
              height={userData?.height}
              weight={userData?.weight}
              close={data.refetch}
              refetch={data.refetch}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export const IntroGoal = graphql(userDataQuery)(IntroGoalWrapper);

export function IntroFoodHabits() {
  return (
    <div className={s.container}>
      <h2>Dine matvaner</h2>
      <p>
        Mat er kroppen sin bensin, og det du velger å spise og drikke er det
        kroppen bruker som energikilde. Den usunne maten gir ikke kroppen
        optimal energi. Det blir som å fylle saft på bilen og forvente at den
        skal virke.
      </p>
    </div>
  );
}

function IntroActiveWrapped({ data, handleWidgetReplyChange }) {
  const userData = data?.user?.userData;
  const [widgetReply, setWidgetReply] = useState(null);
  useEffect(() => {
    if (userData?.activityLevel && userData?.exerciseHabits) {
      const newReply = `${
        profileSettings.active.activityTitle
      }:   ${getActivities(userData?.activityLevel)}, ${
        profileSettings.active.exerciseHabitsTitle
      }:${getExerciseHabits(userData?.exerciseHabits)}`;
      if (newReply !== widgetReply) {
        setWidgetReply(newReply);
        handleWidgetReplyChange(newReply);
      }
    }
  }, [userData, widgetReply, handleWidgetReplyChange]);

  return (
    <div className={s.container}>
      <h2>Fysisk aktivitet – avgjørende for en sunn livsstil!</h2>
      <p>
        Kroppen vår er laget for å brukes, men livet de fleste av oss lever i
        dag med stillesittende jobber hvor vi kjører mye hit og dit. Dette er
        ikke helt optimalt. Vi trenger å holde oss i bevegelse hver dag, i
        tillegg til å ha noen kondisjonstopper med høy puls og litt
        styrketrening hver uke. Hvor aktiv er du?
      </p>

      {!data.loading && userData?.exerciseHabits && userData?.activityLevel && (
        <div style={{ maxWidth: "1000px" }} className={s.flex}>
          <div className={s.activityLevel}>
            <p>
              <strong>{profileSettings.active.activityTitle} - </strong>
              {getActivities(userData?.activityLevel)}
            </p>
            <p>
              <strong>{profileSettings.active.exerciseHabitsTitle}: </strong>
              {getExerciseHabits(userData?.exerciseHabits)}
            </p>
          </div>

          <div className={s.sidebar}>
            <Activities
              activityLevel={userData?.activityLevel}
              exerciseHabits={userData?.exerciseHabits}
              refetch={data.refetch}
              close={data.refetch}
            />
          </div>
        </div>
      )}
      {!data.loading &&
        !(userData?.exerciseHabits && userData?.activityLevel) && (
          <div style={{ maxWidth: "800px" }} className={s.flex}>
            <ActivitiesForm
              activityLevel={userData?.activityLevel}
              exerciseHabits={userData?.exerciseHabits}
              refetch={data.refetch}
              close={data.refetch}
            />
          </div>
        )}
    </div>
  );
}

export const IntroActive = graphql(userDataQuery)(IntroActiveWrapped);

export function RoedeBook() {
  return (
    <div
      className={s.container}
      style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}
    >
      <p>
        Mens du venter på første veiledning fra din veileder, kan du gjøre deg
        kjent med kursboken <strong>«Vår hemmelig oppskrift»</strong>.
      </p>

      <p>
        <a href="https://www.roede.com/book/download">
          <img
            alt="Bilde av Roedeboka"
            src="https://storage.googleapis.com/NEDiVEKT-app/books/var_hemmelige_oppskrift.jpg"
          />
        </a>
      </p>
      <p>
        <a href="https://www.roede.com/book/download">
          Last den ned og la deg inspirere til en god start på din nye reise.
        </a>
      </p>
    </div>
  );
}

export function IntroRoedeWrapped({ data }) {
  const userData = data?.user?.userData;
  return (
    <div className={s.container}>
      <div className={courseUpdateStyles.container}>
        <div className={courseUpdateStyles.content}>
          <h2>Mat og menyer</h2>
          <p>
            Her får du opp en menyanbefaling på bakgrunn av de opplysningene du
            har lagt gitt. Denne vil være et fint utgangspunkt, og så kan det
            være at jeg justerer inntaket ditt litt underveis når vi ser hvordan
            det går. Begynn gjerne å registrere både kosthold og trening
            allerede nå. På verktøy-siden på medlemssiden din finner du en video
            som forklarer hvordan du bruker appen og dagboken på Roede.com til å
            registrere.
          </p>
        </div>

        <div className={courseUpdateStyles.downloadBox}>
          <h3>Din energiberegning</h3>
          <p>
            <strong>Hvilestoffskifte:</strong>{" "}
            {round5(userData?.restingMetabolism)} kcal
          </p>
          <p>
            <strong>Daglig energibehov:</strong>{" "}
            {round5(userData?.trainingMetabolism)} kcal
          </p>
          <p>
            <strong>
              Anbefalt meny for deg som vil{" "}
              {getMotivationVerb(userData).toLowerCase()}:
            </strong>{" "}
            {round5(userData?.calculatedRecommendedKcalIn)} kcal{" "}
          </p>

          <p>
            <strong>Din valgte Roede-Meny:</strong> {userData?.kcalMenu} (
            {userData?.recommendedKcalIn || "?"} kcal)
          </p>

          {userData?.kcalMenu && (
            <a
              href={profileSettings.menuCards[userData?.kcalMenu.charAt(0)]}
              className={courseUpdateStyles.download}
            >
              Last ned menykortet ditt
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export const IntroRoede = graphql(userDataQuery)(IntroRoedeWrapped);
