import React from "react";
import IngredientSearch from "./IngredientSearch";
import AddIngredientModal from "./AddIngredientModal";
import AddCustomIngredientModal from "./AddCustomIngredientModal";
import { Link } from "react-router-dom";
import withRouter from "../../utils/withRouter";
import { withAuth } from "components/container/withAuth";
import {
  GET_CUSTOM_RECIPE,
  GET_INGREDIENTS,
  SAVE_CUSTOM_RECIPE,
} from "queries";
import { graphql } from "@apollo/client/react/hoc";
import { formatQuantity } from "utils";
import "./styles.scss";
import SystemMessages from "components/ui/SystemMessages";

const handleFormatQuantity = (event) => {
  event.target.value = formatQuantity(event.target.value);
};

const EditRecipePage = ({
  match: {
    params: { objectId },
  },
}) => {
  if (objectId) {
    return <EditRecipe objectId={objectId} />;
  } else {
    return <CreateRecipe />;
  }
};

const CreateRecipe = () => {
  const recipe = {
    objectId: null,
    name: "",
    kcal100g: 0,
    data: {
      image: "",
      status: "active",
      portions: null,
      allergens: [],
      nutrition: {
        gramsOfFatPer100Grams: 0,
        gramsOfMilkPer100Grams: 0,
        gramsOfSaltPer100Grams: 0,
        gramsOfFiberPer100Grams: 0,
        gramsOfSugarPer100Grams: 0,
        gramsOfFruitsPer100Grams: 0,
        gramsOfProteinPer100Grams: 0,
        milligramsOfIronPer100Grams: 0,
        gramsOfVegetablesPer100Grams: 0,
        gramsOfCarbohydratePer100Grams: 0,
        gramsOfSaturatedFatPer100Grams: 0,
        milligramsOfCalciumPer100Grams: 0,
        microgramsOfVitaminAPer100Grams: 0,
        microgramsOfVitaminDPer100Grams: 0,
        milligramsOfVitaminCPer100Grams: 0,
        gramsOfMonounsaturatedFatPer100Grams: 0,
        gramsOfPolyunsaturatedFatPer100Grams: 0,
      },
      procedure: "",
      categories: [],
      totalGrams: 0,
      description: "",
      ingredients: [],
      dietCategory: "neutral",
      portionGrams: 0,
      oldCategories: [],
      isHeartFriendly: false,
    },
  };

  return <RecipeForm recipe={recipe} />;
};

const EditRecipeWithData = ({ data: { loading, request } }) => {
  if (loading) {
    return <RecipeLoading />;
  }

  const recipe = {
    ...request.diary.recipeCustomResult.edges[0].node,
    data: JSON.parse(request.diary.recipeCustomResult.edges[0].node.data),
  };

  const ingredients = recipe.data.ingredients
    .filter((i) => i.fpType === "product")
    .map((i) => i.fpId);

  const customIngredients = recipe.data.ingredients
    .filter((i) => i.fpType === "custom product")
    .map((i) => i.fpId);

  return (
    <EditRecipeIngredients
      recipe={recipe}
      ingredients={ingredients}
      customIngredients={customIngredients}
    />
  );
};

const RecipeLoading = () => (
  <div className="dataTables">
    <div className="loading" />
  </div>
);

const EditRecipe = graphql(GET_CUSTOM_RECIPE, {
  options: ({ objectId }) => ({ variables: { objectId } }),
})(EditRecipeWithData);

const EditRecipeIngredientsWithData = ({
  recipe,
  data: { loading, diary, kitchen },
}) => {
  if (loading) {
    return <RecipeLoading />;
  }

  const ingredients = recipe.data.ingredients.map((recipeIngredient) => {
    if (recipeIngredient.fpType === "product") {
      const ingredient = kitchen.ingredients.edges.find(
        (e) => e.node.objectId === recipeIngredient.fpId
      ).node;

      return {
        ...recipeIngredient,
        kcal100g: ingredient.kcal100g,
        nutrition: {
          gramsOfFatPer100Grams: ingredient.fat100g,
          gramsOfMilkPer100Grams: ingredient.milk100g,
          gramsOfSaltPer100Grams: ingredient.salt100g,
          gramsOfFiberPer100Grams: ingredient.fiber100g,
          gramsOfSugarPer100Grams: ingredient.sugar100g,
          gramsOfFruitsPer100Grams: ingredient.fruits100g,
          gramsOfProteinPer100Grams: ingredient.protein100g,
          milligramsOfIronPer100Grams: ingredient.iron100g,
          gramsOfVegetablesPer100Grams: ingredient.veg100g,
          gramsOfCarbohydratePer100Grams: ingredient.carbs100g,
          gramsOfSaturatedFatPer100Grams: ingredient.saturatedFat100g,
          milligramsOfCalciumPer100Grams: ingredient.calcium100g,
          microgramsOfVitaminAPer100Grams: ingredient.vitaminA100g,
          microgramsOfVitaminDPer100Grams: ingredient.vitaminD100g,
          milligramsOfVitaminCPer100Grams: ingredient.vitaminC100g,
          gramsOfMonounsaturatedFatPer100Grams:
            ingredient.monounsaturatedFat100g,
          gramsOfPolyunsaturatedFatPer100Grams:
            ingredient.polyunsaturatedFat100g,
        },
        allergens: ingredient.allergens,
      };
    }

    if (recipeIngredient.fpType === "custom product") {
      const ingredient = diary.foodProductCustomResult.edges.find(
        (e) => e.node.objectId === recipeIngredient.fpId
      ).node;
      const ingredientData = JSON.parse(ingredient.data);
      const { kcalPer100Grams, ...ingredientNutrition } =
        ingredientData.nutrition;

      return {
        ...recipeIngredient,
        kcal100g: ingredient.kcal100g,
        nutrition: ingredientNutrition,
        allergens: ingredient.data.allergens,
      };
    }

    return null;
  });

  const customRecipe = {
    ...recipe,
    data: {
      ...recipe.data,
      ingredients,
    },
  };

  return <RecipeForm recipe={customRecipe} />;
};

const EditRecipeIngredients = graphql(GET_INGREDIENTS, {
  options: ({ ingredients, customIngredients }) => ({
    variables: { ingredients, customIngredients },
  }),
})(EditRecipeIngredientsWithData);

const getCalories = (ingredient) => {
  return Math.round(unitFactor(ingredient) * ingredient.kcal100g);
};

const unitFactor = ({ amount, gramsPerUnit }) => {
  return (gramsPerUnit / 100) * amount;
};

class RecipeFormWithMutation extends React.Component {
  state = {
    ingredient: null,
    recipe: this.props.recipe,
    picture: null,
  };

  addIngredient = (ingredient) => {
    const ingredients = this.state.recipe.data.ingredients;

    ingredients.push({
      fpId: ingredient.data.fpId,
      name: ingredient.data.name,
      o2Id: ingredient.data.o2Id,
      amount: ingredient.data.quantity,
      fpType: ingredient.data.type.replace(
        "custom_food_product",
        "custom product"
      ),
      o2FpId: null,
      unitName: ingredient.data.unit,
      gramsPerUnit: ingredient.data.units.find(
        (u) => u.name.trim() === ingredient.data.unit.trim()
      ).gramsPerUnit,
      kcal100g: ingredient.data.nutrition.kcalPer100Grams,
      nutrition: ingredient.data.nutrition,
      allergens: [],
    });

    this.setState({ ingredient: null });
  };

  deleteIngredient = (event, ingredient) => {
    event.preventDefault();
    event.stopPropagation();

    const confirmMessage = `Vil du slette linje: ${ingredient.amount} ${ingredient.unitName} ${ingredient.name} ?`;

    if (window.confirm(confirmMessage)) {
      const ingredients = this.state.recipe.data.ingredients.filter(
        (i) => i.fpId !== ingredient.fpId
      );

      const recipe = {
        ...this.state.recipe,
        data: {
          ...this.state.recipe.data,
          ingredients,
        },
      };

      this.setState({ recipe });
    }
  };

  saveCustomRecipe = (event) => {
    event.preventDefault();

    const selectedCategories = document.querySelectorAll(
      'input[name="categories"]:checked'
    );

    if (selectedCategories.length === 0) {
      alert("Minst èn kategori må velges");
      return false;
    }

    const categories = [];

    for (let i = 0; i < selectedCategories.length; ++i) {
      categories.push(selectedCategories[i].value);
    }

    document
      .querySelector(".customRecipeForm")
      .setAttribute("data-processing", true);

    const allergens = this.state.recipe.data.ingredients
      .map((ingredient) => ingredient.allergens)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((item, pos, arr) => arr.indexOf(item) === pos);

    const { totalCalories, totalGrams } =
      this.state.recipe.data.ingredients.reduce(
        (acc, ingredient) => {
          acc.totalCalories += getCalories(ingredient);
          acc.totalGrams += ingredient.gramsPerUnit * ingredient.amount;
          return acc;
        },
        {
          totalCalories: 0,
          totalGrams: 0,
        }
      );

    const nutrition = this.state.recipe.data.ingredients.reduce(
      (acc, val) => {
        const factor = (val.amount * val.gramsPerUnit) / totalGrams;

        return {
          gramsOfFatPer100Grams:
            Math.round(
              (acc.gramsOfFatPer100Grams +
                factor * val.nutrition.gramsOfFatPer100Grams) *
                10
            ) / 10,
          gramsOfMilkPer100Grams:
            Math.round(
              (acc.gramsOfMilkPer100Grams +
                factor * val.nutrition.gramsOfMilkPer100Grams) *
                10
            ) / 10,
          gramsOfSaltPer100Grams:
            Math.round(
              (acc.gramsOfSaltPer100Grams +
                factor * val.nutrition.gramsOfSaltPer100Grams) *
                10
            ) / 10,
          gramsOfFiberPer100Grams:
            Math.round(
              (acc.gramsOfFiberPer100Grams +
                factor * val.nutrition.gramsOfFiberPer100Grams) *
                10
            ) / 10,
          gramsOfSugarPer100Grams:
            Math.round(
              (acc.gramsOfSugarPer100Grams +
                factor * val.nutrition.gramsOfSugarPer100Grams) *
                10
            ) / 10,
          gramsOfFruitsPer100Grams:
            Math.round(
              (acc.gramsOfFruitsPer100Grams +
                factor * val.nutrition.gramsOfFruitsPer100Grams) *
                10
            ) / 10,
          gramsOfProteinPer100Grams:
            Math.round(
              (acc.gramsOfProteinPer100Grams +
                factor * val.nutrition.gramsOfProteinPer100Grams) *
                10
            ) / 10,
          milligramsOfIronPer100Grams:
            Math.round(
              (acc.milligramsOfIronPer100Grams +
                factor * val.nutrition.milligramsOfIronPer100Grams) *
                10
            ) / 10,
          gramsOfVegetablesPer100Grams:
            Math.round(
              (acc.gramsOfVegetablesPer100Grams +
                factor * val.nutrition.gramsOfVegetablesPer100Grams) *
                10
            ) / 10,
          gramsOfCarbohydratePer100Grams:
            Math.round(
              (acc.gramsOfCarbohydratePer100Grams +
                factor * val.nutrition.gramsOfCarbohydratePer100Grams) *
                10
            ) / 10,
          gramsOfSaturatedFatPer100Grams:
            Math.round(
              (acc.gramsOfSaturatedFatPer100Grams +
                factor * val.nutrition.gramsOfSaturatedFatPer100Grams) *
                10
            ) / 10,
          milligramsOfCalciumPer100Grams:
            Math.round(
              (acc.milligramsOfCalciumPer100Grams +
                factor * val.nutrition.milligramsOfCalciumPer100Grams) *
                10
            ) / 10,
          microgramsOfVitaminAPer100Grams:
            Math.round(
              (acc.microgramsOfVitaminAPer100Grams +
                factor * val.nutrition.microgramsOfVitaminAPer100Grams) *
                10
            ) / 10,
          microgramsOfVitaminDPer100Grams:
            Math.round(
              (acc.microgramsOfVitaminDPer100Grams +
                factor * val.nutrition.microgramsOfVitaminDPer100Grams) *
                10
            ) / 10,
          milligramsOfVitaminCPer100Grams:
            Math.round(
              (acc.milligramsOfVitaminCPer100Grams +
                factor * val.nutrition.milligramsOfVitaminCPer100Grams) *
                10
            ) / 10,
          gramsOfMonounsaturatedFatPer100Grams:
            Math.round(
              (acc.gramsOfMonounsaturatedFatPer100Grams +
                factor * val.nutrition.gramsOfMonounsaturatedFatPer100Grams) *
                10
            ) / 10,
          gramsOfPolyunsaturatedFatPer100Grams:
            Math.round(
              (acc.gramsOfPolyunsaturatedFatPer100Grams +
                factor * val.nutrition.gramsOfPolyunsaturatedFatPer100Grams) *
                10
            ) / 10,
        };
      },
      {
        gramsOfFatPer100Grams: 0,
        gramsOfMilkPer100Grams: 0,
        gramsOfSaltPer100Grams: 0,
        gramsOfFiberPer100Grams: 0,
        gramsOfSugarPer100Grams: 0,
        gramsOfFruitsPer100Grams: 0,
        gramsOfProteinPer100Grams: 0,
        milligramsOfIronPer100Grams: 0,
        gramsOfVegetablesPer100Grams: 0,
        gramsOfCarbohydratePer100Grams: 0,
        gramsOfSaturatedFatPer100Grams: 0,
        milligramsOfCalciumPer100Grams: 0,
        microgramsOfVitaminAPer100Grams: 0,
        microgramsOfVitaminDPer100Grams: 0,
        milligramsOfVitaminCPer100Grams: 0,
        gramsOfMonounsaturatedFatPer100Grams: 0,
        gramsOfPolyunsaturatedFatPer100Grams: 0,
      }
    );

    const ingredients = this.state.recipe.data.ingredients.map(
      (ingredient) => ({
        amount: ingredient.amount,
        fpId: ingredient.fpId,
        fpType: ingredient.fpType,
        gramsPerUnit: ingredient.gramsPerUnit,
        name: ingredient.name,
        o2FpId: ingredient.o2FpId,
        o2Id: ingredient.o2Id,
        unitName: ingredient.unitName,
      })
    );

    const portionsVal = parseFloat(
      event.target.elements["portions"].value.replace(",", ".")
    );

    const variables = {
      objectId: this.state.recipe.objectId,
      name: event.target.elements["name"].value,
      kcal100g: Math.round((totalCalories / totalGrams) * 100) || 0,
      kcalPortion: Math.round(totalCalories / portionsVal),
      picture: this.state.picture,
      data: JSON.stringify({
        picture: this.state.recipe.data.picture,
        status: this.state.recipe.data.status,
        portions: portionsVal,
        allergens,
        nutrition,
        procedure: event.target.elements["procedure"].value,
        categories,
        totalGrams,
        description: event.target.elements["description"].value,
        ingredients,
        dietCategory: "neutral",
        portionGrams: totalGrams / portionsVal,
        oldCategories: this.state.recipe.oldCategories,
        isHeartFriendly: event.target.elements["isHeartFriendly"].checked,
      }),
    };

    this.props
      .mutate({
        variables,
      })
      .then(({ data }) => {
        document
          .querySelector(".customRecipeForm")
          .removeAttribute("data-processing");
        window.location = "/min-mat/oppskrifter/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (!this.props.auth.user.hasPermissions) {
      this.props.history.push("/min-mat/oppskrifter/");
    }

    const { ingredient, recipe } = this.state;

    return (
      <div className="myFoodPage">
        <h1>Min mat</h1>
        <div className="SystemMessageRecipe">
          <SystemMessages slotId={5} />
        </div>

        <form
          onSubmit={this.saveCustomRecipe}
          className="customRecipeForm"
          encType="multipart/form-data"
        >
          <input type="hidden" name="objectId" />
          <section>
            <h3>Om oppskriften</h3>
            <div className="field">
              <label htmlFor="name">Navn på oppskrift*</label>
              <input
                type="text"
                name="name"
                id="name"
                defaultValue={recipe.name}
                required
                autoFocus
              />
            </div>
            <div className="field">
              <label htmlFor="description">
                Kort innledning om oppskriften
              </label>
              <textarea
                type="text"
                name="description"
                id="description"
                defaultValue={recipe.data.description}
                onChange={this.handleChange}
                style={{ height: "8.8rem" }}
              ></textarea>
            </div>
            <div className="field field-number group">
              <label htmlFor="portions">Antall porsjoner*</label>
              <input
                type="text"
                name="portions"
                id="portions"
                onChange={handleFormatQuantity}
                defaultValue={recipe.data.portions}
                required
              />
            </div>
            <div className="field field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="isHeartFriendly"
                  value="true"
                  defaultChecked={recipe.data.isHeartFriendly}
                />{" "}
                Hjertevennlig <i className="icon-gr-heart-friendly-food" />
              </label>
              <p className="field-desc">
                Oppskrifter som har en sunn fettsammensetning, det vil si mer
                umettet enn mettet fett, kan defineres som hjertevennlig.
              </p>
            </div>
          </section>
          <section>
            <h3>Ingredienser</h3>
            {recipe.data.ingredient && (
              <div className="ingredientsInfo">
                <span>
                  kcal per porsjon:{" "}
                  <em>
                    <strong>51</strong> kcal
                  </em>
                </span>
                <span>
                  kcal per 100 gr:{" "}
                  <em>
                    <strong>188</strong> kcal
                  </em>
                </span>
              </div>
            )}
            <IngredientSearch
              placeholder="Legg inn..."
              onChange={(ingredient) => this.setState({ ingredient })}
              selectedItem={ingredient}
            />
            {ingredient && ingredient.type === "food_product" && (
              <AddIngredientModal
                objectId={ingredient.objId}
                addIngredient={this.addIngredient}
                close={() => this.setState({ ingredient: null })}
              />
            )}
            {ingredient && ingredient.type === "custom_food_product" && (
              <AddCustomIngredientModal
                objectId={ingredient.objId}
                addIngredient={this.addIngredient}
                close={() => this.setState({ ingredient: null })}
              />
            )}
            <ul className="ingredientsList">
              {recipe.data.ingredients.map((ingredient, i) => (
                <li key={i}>
                  <p className="ingredient">
                    {ingredient.name}
                    <a href="edit">
                      {formatQuantity(ingredient.amount)} {ingredient.unitName}
                    </a>
                  </p>
                  <p className="nutritionInfo">
                    <span>({ingredient.kcal100g}/100 g)</span>
                    <strong>{getCalories(ingredient)}</strong>
                  </p>
                  <button
                    onClick={(event) =>
                      this.deleteIngredient(event, ingredient)
                    }
                  >
                    <i className="icon-cancel-circle"></i>
                  </button>
                </li>
              ))}
            </ul>
          </section>
          <section>
            <h3>Beskriv fremgangsmåten</h3>
            <div className="field" style={{ width: "100%" }}>
              <textarea
                type="text"
                name="procedure"
                style={{ height: "20rem" }}
                defaultValue={recipe.data.procedure}
              ></textarea>
            </div>
          </section>
          <section>
            <h3>Velg kategori*</h3>
            <div className="field field-checkbox">
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Frokost og lunsj"
                  defaultChecked={recipe.data.categories.includes(
                    "Frokost og lunsj"
                  )}
                />{" "}
                Frokost og lunsj
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Middag"
                  defaultChecked={recipe.data.categories.includes("Middag")}
                />{" "}
                Middag
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Salat"
                  defaultChecked={recipe.data.categories.includes("Salat")}
                />{" "}
                Salat
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Småretter"
                  defaultChecked={recipe.data.categories.includes("Småretter")}
                />{" "}
                Småretter
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Gjærbakst"
                  defaultChecked={recipe.data.categories.includes("Gjærbakst")}
                />{" "}
                Gjærbakst
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Dessert og kaker"
                  defaultChecked={recipe.data.categories.includes(
                    "Dessert og kaker"
                  )}
                />{" "}
                Dessert og kaker
              </label>
              <label>
                <input
                  type="checkbox"
                  name="categories"
                  value="Frokost og lunsj"
                  defaultChecked={recipe.data.categories.includes(
                    "Frokost og lunsj"
                  )}
                />{" "}
                Drikke
              </label>
            </div>
          </section>
          <section>
            <h3>Oppskriftsbilde</h3>
            <div className="field">
              <div
                className="field-picture"
                data-visible={Boolean(recipe.data.picture)}
              >
                <img src={recipe.data.picture} alt={recipe.name} />
              </div>
              <input
                type="file"
                name="picture"
                id="picture"
                onChange={({
                  target: {
                    validity,
                    files: [file],
                  },
                }) => {
                  let reader = new FileReader();

                  reader.onload = (function (theFile) {
                    return function (e) {
                      document
                        .querySelector(".field-picture")
                        .setAttribute("data-visible", true);
                      document
                        .querySelector(".field-picture img")
                        .setAttribute("src", e.target.result);
                    };
                  })(file);

                  reader.readAsDataURL(file);

                  this.setState({ picture: file });
                }}
              />
            </div>
          </section>
          <section className="buttons group">
            <Link to="/min-mat/oppskrifter/">Avbryt</Link>
            <button type="submit">Lagre</button>
          </section>
        </form>
      </div>
    );
  }
}

const SaveCustomRecipeWrapper = graphql(SAVE_CUSTOM_RECIPE)(
  RecipeFormWithMutation
);
const RecipeForm = withAuth(withRouter(SaveCustomRecipeWrapper));

export default withRouter(EditRecipePage);
