const getDataFromCustomFoodProductForm = form => ({
  objectId: parseInt(form.objectId.value, 10) || null,
  name: form.name.value,
  kcalPer100Grams: parseInt(form.kcalPer100Grams.value, 10),
  gramsOfProteinPer100Grams: parseFloat(form.gramsOfProteinPer100Grams.value),
  gramsOfCarbohydratePer100Grams: parseFloat(form.gramsOfCarbohydratePer100Grams.value),
  gramsOfSugarPer100Grams: parseFloat(form.gramsOfSugarPer100Grams.value),
  gramsOfFatPer100Grams: parseFloat(form.gramsOfFatPer100Grams.value),
  gramsOfSaturatedFatPer100Grams: parseFloat(form.gramsOfSaturatedFatPer100Grams.value),
  gramsOfPolyunsaturatedFatPer100Grams: parseFloat(form.gramsOfPolyunsaturatedFatPer100Grams.value),
  gramsOfMonounsaturatedFatPer100Grams: parseFloat(form.gramsOfMonounsaturatedFatPer100Grams.value),
  gramsOfFiberPer100Grams: parseFloat(form.gramsOfFiberPer100Grams.value),
  gramsOfSaltPer100Grams: parseInt(form.gramsOfSaltPer100Grams.value, 10),
  milligramsOfCalciumPer100Grams: parseInt(form.milligramsOfCalciumPer100Grams.value, 10),
  gramsOfVegetablesPer100Grams: parseInt(form.gramsOfVegetablesPer100Grams.value, 10),
  gramsOfFruitsPer100Grams: parseInt(form.gramsOfFruitsPer100Grams.value, 10),
  gramsOfMilkPer100Grams: parseInt(form.gramsOfMilkPer100Grams.value, 10),
  allergens: [].slice.call(form.allergens).filter(i => i.checked).map(i => i.value),
  isHeartFriendly: form.isHeartFriendly.checked,
  adGrRecommendation: form.adGrRecommendation.checked,
})

export { getDataFromCustomFoodProductForm }
