import { gql } from "@apollo/client";

export default gql`
  query NettCourse($id: Int, $frequency: String) {
    nettcourseinstructor(id: $id) {
      id: instructorId
      instructorId
      autoName
      nettcourseId
      numberOfSpots
      availableSpots

      nettcourse {
        id: nettcourseId
        name
        nettcourseId
        formatedPricePerMeeting
        pricePerMeeting
        descriptionLong
        defaultPrepaidMembershipType {
          id: membershipTypeId
          oneTimePrices {
            edges {
              node {
                priceId
                numberOfMeetings
                displayPricePerMeeting
                displayPrice
                stripeMetadata
              }
            }
          }
        }
        defaultMembershipType {
          id: membershipTypeId
          membershipType
          availibleOnCoursePage
          description
          defaultSubscription
          slug
          name

          subscriptions(frequency: $frequency) {
            edges {
              node {
                priceId
                interval
                months
                displayPricePerMonth
                stripeMetadata
              }
            }
          }
        }
        prepaidPackages {
          edges {
            node {
              id: packageId
              packageId
              name
              price
              formatedPrice
              includedMeetings
            }
          }
        }
      }
      instructor {
        id: instructorId
        instructorId
        name
        image
        slug
        shortDescription
        bioBullet1
        bioBullet2
        bioBullet3
        title
        offersMembership
      }
    }
  }
`;
