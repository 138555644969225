import { gql } from '@apollo/client'

const deleteCustomRecipe = gql`
  mutation DeleteCustomRecipe (
    $objectId: Int!
  ) {
    diary {
      deleteCustomRecipe (
        objectId: $objectId
      ) {
        status
      }
    }
  }
`

export default deleteCustomRecipe
