import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import s from "./ThanksPage.module.scss";

import Wrapper from "components/ui/Wrapper";

import posterImage from "./posterimage.png";
import video from "./roedemetoden.mp4";
import Loading from "components/ui/Loading";

const PURCHASE_STATUS = gql`
  query PurchaseStatus(
    $sessionId: String
    $sourceId: String
    $vippsId: String
  ) {
    user {
      purchaseStatus(
        sessionId: $sessionId
        sourceId: $sourceId
        vippsId: $vippsId
      ) {
        id: purchaseId
        status
        membership {
          membershipId
          name
          membershipType
          membershipTypeObject {
            id: membershipTypeId
            membershipType
          }
          courseSignup
          course {
            courseId
            autoName
            courseType {
              name

              courseTypeId
            }
          }
          nettcourseInstructor {
            instructorId
            autoName
            nettcourse {
              nettcourseId
            }
          }

          instructor {
            name
          }
        }
      }
    }
  }
`;

export default function ThanksPage() {
  //const search = new URLSearchParams(location?.search)
  const [search] = useSearchParams();

  const { data, stopPolling, startPolling } = useQuery(PURCHASE_STATUS, {
    fetchPolicy: "network-only",
    variables: {
      sessionId: search.get("session_id"),
      sourceId: search.get("source"),
      vippsId: search.get("VIPPS_ORDER_ID"),
    },
    pollInterval: 1000,
  });

  useEffect(() => {
    startPolling(1000);
  }, [startPolling]);
  const membership = data?.user?.purchaseStatus?.membership;

  const [timeout, setTimeoutVar] = useState(false);
  const [analytics, setAnalytics] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutVar(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (membership) {
      const name =
        membership?.membershipTypeObject?.name ||
        membership?.course?.courseType?.name ||
        membership?.nettcourseInstructor?.autoName;
      const productId =
        membership?.membershipTypeObject?.id ||
        membership?.course?.courseType?.courseTypeId ||
        membership?.nettcourseInstructor?.nettcourse?.nettcourseId;
      stopPolling();
      const dataLayer = window.dataLayer || [];
      console.log({
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: data?.user?.purchaseStatus?.id,
              revenue: 1,
              tax: "0",
              shipping: "0",
            },
            products: [
              {
                id: membership.membershipType + "_" + productId,
                name: name,
                variant: membership?.name,
                price: 1,
                quantity: 1,
              },
            ],
          },
        },
      });
      dataLayer.push({
        event: "purchase",
        ecommerce: {
          purchase: {
            actionField: {
              id: data?.user?.purchaseStatus?.id,
              revenue: 1,
              tax: "0",
              shipping: "0",
            },
            products: [
              {
                id: membership.membershipType + "_" + productId,
                name: name,
                variant: membership?.name,
                price: 1,
                quantity: 1,
              },
            ],
          },
        },
      });
      setAnalytics(true);
    }
  }, [membership, data, stopPolling]);

  console.log(membership, timeout, analytics);

  return (
    <Wrapper>
      <div className={s.thanksPage}>
        <section className={s.hero}>
          <video poster={posterImage} muted loop autoPlay>
            <source src={video} type="video/mp4" />
          </video>
          <h1 className={s.title}>Vent! - Ditt medlemskap aktiveres.</h1>
        </section>
      </div>
      {(!membership || !timeout || !analytics) && <Loading />}
      {timeout && analytics && (
        <>
          {membership?.courseSignup ? (
            <Navigate replace to={`/velkommen/${membership?.membershipId}`} />
          ) : (
            <>
              {membership?.membershipType === "gold" ||
              membership?.membershipTypeObject?.membershipType === "gold" ? (
                <Navigate replace to={`/velkommen/download`} />
              ) : (
                <Navigate replace to={`/coach/${membership?.membershipId}`} />
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}
