import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import s from './styles/CourseMembership.module.scss'

type Props = {
  courseType: string
  courseTypes: Array<any>
}

export default function CourseTypes({ courseType, courseTypes }: Props) {
  const [isOpen, setIsOpen] = useState(courseType === '')
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = Object.fromEntries(searchParams)
  const selectedType = courseTypes.find(({ id }) => String(id) === courseType)
  const toggleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsOpen((value) => !value)
  }

  const setCourseType = (courseType: string) => {
    setSearchParams({ ...urlParams, courseType })
    setIsOpen(false)
  }

  return (
    <section className={s.section}>
      <h2>Oppfølgingstype</h2>
      {isOpen ? (
        <>
          <p>
            Hvilken oppfølging passer for deg?
          </p>
          <div className={s.options}>
            {courseTypes.map(({ id, name, description }) => (
              <button
                key={id}
                data-selected={String(id) === courseType}
                onClick={() => setCourseType(id)}
              >
                <b>{name}</b>
                <span>{description}</span>
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>{selectedType?.name}</p>
      )}
      {courseType && (
        <p>
          <a href="/" onClick={toggleOpen}>{isOpen ? 'Lukk' : 'Endre'}</a>
        </p>
      )}
    </section>
  )
}
