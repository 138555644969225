import { gql } from '@apollo/client'

const getMeasurementsEntries = gql`
  query DiaryEntries (
    $dateFrom: DateTime,
    $dateTo: DateTime,
  ) {
    measurements: request {
      diary {
        diaryRegistration (
          dateFrom: $dateFrom,
          dateTo: $dateTo,
          category: "measurements",
          first: 1
        ) {
          edges {
            node {
              registrationId
              dateTime
              lastModified
              category
              tag
              description
              calories
              data
            }
          }
        }
      }
    }
    summary: request {
      diary {
        summaryMeasurements(dateTo: $dateTo)
      }
    }
  }
`

export default getMeasurementsEntries