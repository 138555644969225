import React from 'react';
import './styles.scss';

const numberFormat = (number, decimals = 0) => {
  return Number(number).toLocaleString('nb-NO', { minimumFractionDigits: decimals });
}

const NutrutionBar = (props) => {
  let { recommendedMin, recommendedMax, maxValue, value, unit } = props;

  recommendedMin = (recommendedMin < 0) ? 0 : recommendedMin;
  recommendedMax = (recommendedMax > maxValue) ? maxValue : recommendedMax;

  let overlayWidth = 100 * (recommendedMax - recommendedMin) / maxValue;
  let overlayLeft = 100 * recommendedMin / maxValue;

  let valueWidth = 100 * value / maxValue;
  valueWidth = (valueWidth > 100) ? 100 : valueWidth;

  let labelModifier = 'default';
  labelModifier = (value >= recommendedMin && value <= recommendedMax) ? 'success' : labelModifier;
  labelModifier = (value > recommendedMax) ? 'danger' : labelModifier;

  return (
    <div className="nutritionBar">
      <div className="nutritionBar__value" style={{ width: `${valueWidth}%` }}>
        <div className={`nutritionBar__label nutritionBar__label--${labelModifier}`}>
          {numberFormat(value)}
          {(unit === 'kcal') && <br/>}
          {unit}
        </div>
      </div>
      <div className="nutritionBar__overlay" style={{ width: `${overlayWidth}%`, left: `${overlayLeft}%` }}></div>
    </div>
  );
}

export default NutrutionBar;
